import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector
} from "react-redux"
import thunk from "redux-thunk"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage" // defaults to localStorage for web and AsyncStorage for react-native

import { configureStore, createStore } from "@reduxjs/toolkit"
// import { ENABLE_REDUX_DEV_TOOLS } from 'src/constants';
import rootReducer from "store/rootReducer"
// import { persistStore, persistReducer } from "redux-persist";
import apiMiddleware from "store/middleware/api"
// import refreshTokenMiddleware from 'store/middleware/refresh-token';

export const reduxStorageKey = "V-0.7.68"

const persistConfig = {
  key: reduxStorageKey,
  storage,
  blacklist: [
    'toastr', 
    'apiCallsInProgress',


    /**
     * This 'localize' is the slice of the 3rds party library called 
     * react-localize-redux. It is responsible for localization of the app.
     * 
     * Not to be confused with the slice called 'localizationApi'.
     * That one just stores the response from the API.
     * 
     * We need to blacklist 'localize' because the process of loading it 
     * into the redux state from the persisted local storage is very slow (~15 sec).
     */
    'localize', 

    /**
     * The 'page' slice contains the crtFormItem. When there are multiple browser tabs
     * open, they each have their own redux state in memory, but they all persist into
     * the same local storage. This means that when you refresh a tab (or open a new one), 
     * the crtFormItem is loaded from the persisted local storage into the redux state.
     * Thus, whoever saved the crtFormItem last, will overwrite the crtFormItem in the
     * next refreshed tab. This is why we need to blacklist the 'page' slice: to avoid
     * overwriting one tab's data with another tab's data.
     */
    'page',


  ] //"filtersTracking"
  // this should be used in case of nested blacklist needed - check root reducer
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: [apiMiddleware, thunk]
})

export const useSelector = useReduxSelector

export const useDispatch = () => useReduxDispatch()
export const persistor = persistStore(store)
export default store
