import { DatePicker } from "views/dev/select/DatePicker"
import MagicInput from "components/MagicInput/MagicInpuv2"
import WhiteFormCard from "components/WhiteFormCard"
import FormPormPageHeader from "components/_partials/formPormPageHeader"
import useMagicForm from "utils/useMagicForm"
import { useDispatch } from "store"
import { savePriceRequest } from "store/modules/guest.actions"
import CartProductCard from "views/shoppingCart/cartProductCard"
import { loadDynamoFormItemByPK_v2 } from "store/modules/dataView.search"
import { useEffect, useState } from "react"
import { useSelector } from "store"
import { loadItem } from "store/justActions/loadApiIems"
// import MagicButton from "components/MagicButton/MagicButton"
import "./priceRequestForm.scss"
import { setPageEdit } from "store/modules/page"
import { apiAction } from "store/middleware/api"
import { Lang, lang, Translate, withLocalize } from "components/Lang/Lang"
import * as yup from "yup"
import { printError } from "utils/printError"

export const noPriceResponse = (
  productId,
  detail,
  email,
  // unitPrice,
  // totalPrice,
  // quantity
) => {
  return apiAction({
    endPoint: "/product/goods/notify-response", //notify-request,
    method: "POST",
    data: {
      productId,
      detail,
      email,
      // unitPrice,
      // totalPrice,
      // quantity: parseInt(quantity),
      notifyTypeCode: "PRODUCT_NO_PRICE_RESPONSE_NOTIFY"
    },
    onSuccess: (data, dispatch) => {
      return {
        type: "a"
      }
    },
    onFailure: (error, dispatch) => {
      printError("noPriceResponse()", error)
      return {
        type: "a"
      }
    }
  })
}

const PriceRequestForm = ({ location }) => {
  const dispatch = useDispatch()
  const crtFormItem = useSelector((state) => state?.page?.crtFormItem)
  const [textValue, setTextValue] = useState(crtFormItem?.product?.mentions || "")
  const pageTitle = <Lang value="priceRequestForm.details" />

  let itemId = window.location.search
    ?.split?.("?")[1]
    ?.split?.("&")
    ?.find?.((item) => item?.includes?.("id="))
    ?.split?.("=")[1]
    ?.split?.("|")?.[0]

  const schema = yup.object().shape({
    unitPrice: yup
      .number()
      .typeError("Preţul unitar trebuie să fie obligatoriu un număr")
      .positive("Preţul unitar trebuie să fie obligatoriu mai mare de zero ")
      .required("Preţ unitar - field obligatoriu")
  })
  const { getProps, reset, watch } = useMagicForm({
    location,
    formName: "priceRequestForm",
    schema
  })

  //const priceForRequestedQuantity = watch("priceForRequestedQuantity");
  // const unitPrice = watch("unitPrice")

  const cancelRequest = (data) => {
    dispatch(
      savePriceRequest(
        saveSetup(
          crtFormItem,
          "CANCELLED",
          // document.getElementById("cartInfoTextArea")?.value
          //   ? document.getElementById("cartInfoTextArea")?.value
          //   : crtFormItem?.product?.mentions
          textValue
          // unitPrice * crtFormItem?.product?.selectedQuantity,
          // unitPrice
        ),
        data?.product?.selectedQuantity,
        "",
        "",
        "",
        "",
        "DELETE",
        crtFormItem?.user,
        itemId
      )
    )
  }

  const sendEmail = (data) => {
    //save to DB
    dispatch(
      savePriceRequest(
        saveSetup(
          crtFormItem,
          "NOTIFIED",
          // document.getElementById("cartInfoTextArea")?.value
          //   ? document.getElementById("cartInfoTextArea")?.value
          //   : crtFormItem?.product?.mentions,
          textValue,
          // unitPrice * crtFormItem?.product?.selectedQuantity,
          // unitPrice
        ),
        data?.product?.selectedQuantity,
        "",
        "",
        "",
        "",
        "RESPONSE",
        crtFormItem?.user,
        itemId
      )
    )

    //notify client >> maybe move this to the backend
    dispatch(
      noPriceResponse(
        crtFormItem?.product?.props?.id,
        crtFormItem?.product?.mentions,
        crtFormItem?.loginName,
        // unitPrice,
        // unitPrice * crtFormItem?.product?.selectedQuantity,
        // crtFormItem?.product?.selectedQuantity
      )
    )
  }

  useEffect(() => {
    dispatch(
      loadDynamoFormItemByPK_v2({
        input: itemId,
        combine: true,
        moreSteps: (x, dispatch) =>
          dispatch(loadItem("uniprest/user", x?.user?.id, true, true))
      })
    )
    dispatch(setPageEdit(true))
  }, [itemId])

  useEffect(() => {
    reset({
      ...crtFormItem,
      priceForRequestedQuantity:
        crtFormItem?.product?.priceForRequestedQuantity,
      unitPrice: crtFormItem?.product?.unitPrice
    })
  }, [crtFormItem])

  return (
    <>
      <FormPormPageHeader {...{ pageTitle, onlyTitle: true }} />

      <div data-test-id='Krtw' className="flex price-request-form-wrapper aifs mb6">
        <div data-test-id='3viA' className="price-request-form-left-side mr6">
          <WhiteFormCard
            title={<Lang value="priceRequestForm.product" />}
            className="mb6"
          >
            <CartProductCard
              {...{
                priceOrStockRequestForm: true,
                ...crtFormItem?.product?.props,
                brandName: crtFormItem?.product?.props?.brandName
                  ? Object?.keys?.(crtFormItem?.product?.props?.brandName)
                      .length === 0
                    ? "-"
                    : crtFormItem?.product?.props?.brandName
                  : "-",
                unit: crtFormItem?.product?.props?.unitOfMeasure
                  ? crtFormItem?.product?.props?.unitOfMeasure
                  : "buc",
                stockState: "available",
                productCode: crtFormItem?.product?.props?.code,
                orderView: false,
                quantity: crtFormItem?.product?.selectedQuantity
              }}
            />
          </WhiteFormCard>

          <div data-test-id='mOy2' className="company-section">
            <div data-test-id='4w5F' className="company-content mb2">
              <div data-test-id='MGgN' className="mr5">
                <div data-test-id='MSjH' className="title etype-2">
                  {crtFormItem?.product?.companyName}
                </div>
              </div>
            </div>
            <div data-test-id='seyq' className="company-content-subtitle"></div>
          </div>

          <WhiteFormCard className="mb4">
            <div data-test-id='PmlP' className="middle-section-item">
              <MagicInput
                fake
                className="mb6 left-section-item"
                icon="user"
                outline
                {...getProps("product.companyType")}
                label={<Lang value="priceRequestForm.type" />}
                disabled
              />
            </div>
          </WhiteFormCard>
        </div>

        <div data-test-id='SfVg' className="price-request-form-right-side">
          <WhiteFormCard
            title={<Lang value="contactForm.status" />}
            className="price-request-form-element mb6"
          >
            <button 
              data-test-id='b5z6'
              type={"button"}
              className={`btn-price-request-form-${crtFormItem?.product?.status?.toLowerCase?.()}`}
            >
              {crtFormItem?.product?.status === "CANCELLED" ? (
                "Solicitare anulata"
              ) : crtFormItem?.product?.status === "NOTIFIED" ? (
                <Lang value="priceRequestForm.fulfilled" />
              ) : (
                <Lang value="priceRequestForm.new" />
              )}
            </button>
          </WhiteFormCard>

          <div data-test-id='M9hv' className="product-form-wrapper">
            <WhiteFormCard
              title={<Lang value="priceRequestForm.action" />}
              className="price-request-form-element mb6"
            >
              
              {/* <MagicInput
                {...getProps("unitPrice")}
                className="svg-nostroke mb4"
                label={<Lang value="priceRequestForm.price" />}
                outline
                rightComponent={
                  <CurrencyBox
                    currency={crtFormItem?.product?.props?.currency}
                  />
                }
              />

              <MagicInput
                // {...getProps("priceForRequestedQuantity")}
                value={unitPrice * crtFormItem?.product?.selectedQuantity || ""}
                className="svg-nostroke mb4"
                label={<Lang value="priceRequestForm.priceRequest" />}
                outline
                disabled
                rightComponent={
                  <CurrencyBox
                    currency={crtFormItem?.product?.props?.currency}
                  />
                }
              /> */}

              <div data-test-id='2UXp' className="mt5 mb5">
                <div data-test-id='V1JG' className="mb1">
                  <label data-test-id='EXme' className="ptype-4">
                    <Lang value="priceRequestForm.add" />
                  </label>
                </div>
                <div 
                  data-test-id='F6VD'
                  style={{
                    width: "100%"
                  }}
                >
                  <textarea 
                    data-test-id='u0du'
                    id="cartInfoTextArea"
                    className="ptype-5"
                    maxlength="1000"
                    placeholder={crtFormItem?.product?.mentions}
                    style={{
                      width: `calc(100% - 1.5rem)`, //"368px",
                      padding: "10px",
                      height: "128px",
                      "background-color": "white"
                    }}
                    value={`${textValue}`}
                    onChange={(e) => {setTextValue(e.target.value)}}
                  ></textarea>
                </div>
              </div>

              <div data-test-id='eZPl' className="inline-sections">
                {textValue ? (
                  <button 
                    data-test-id='Qgsu'
                    type={"button"}
                    className={"btn-price-request-form-green"}
                    onClick={() => sendEmail(crtFormItem)}
                  >
                    {" "}
                    <Lang value="priceRequestForm.Mail" />
                  </button>
                ) : (
                  <button 
                    data-test-id='LT54'
                    type={"button"}
                    className={"btn-price-request-form-green disabled"}
                    //onClick={() => sendEmail(crtFormItem)}
                    disabled
                  >
                    {" "}
                    <Lang value="priceRequestForm.Mail" />
                  </button>
                )}

                <button 
                  data-test-id='JDbC'
                  type={"button"}
                  className={"btn-price-request-form-red"}
                  onClick={() => cancelRequest(crtFormItem)}
                >
                  <Lang value="priceReuqestForm.cancel" />
                </button>
              </div>
            </WhiteFormCard>
          </div>

          <WhiteFormCard
            title={<Lang value="questionForm.author" />}
            className="price-request-form-element arhivare mb4"
          >
            <MagicInput
              fake
              className="mb4"
              outline
              {...getProps("user.name")}
              label={<Lang value="priceRequestForm.user" />}
              disabled
            />
            <DatePicker
              className="mb2"
              outline
              iconName="calendartodayoutlined"
              {...getProps("cDate")}
              label={<Lang value="priceRequestForm.date" />}
              disabled
            />
          </WhiteFormCard>
        </div>
      </div>
    </>
  )
}

const CurrencyBox = (props) => {
  const { currency } = props
  return <div data-test-id='rEIA' className="product-form-currency-box">{currency}</div>
}

export function saveSetup(
  data,
  status,
  mentions,
  // priceForRequestedQuantity,
  // unitPrice
) {
  data["product"]["status"] = status
  data["product"]["PK"] = data?.PK
  data["product"]["SK"] = data?.SK
  data["product"]["mentions"] = mentions
  // data["product"]["priceForRequestedQuantity"] = priceForRequestedQuantity
  // data["product"]["unitPrice"] = unitPrice

  return data?.product
}

export default PriceRequestForm
