import { dynamoAction } from "store/middleware/api"
import { loadViewData } from "store/modules/dataView/dataView-dataLoader"
import { printError } from "utils/printError"

export const deleteDynamoItemByPK = ({ PK, SK, goToList=null }) =>
  dynamoAction({
    method: "DELETE",
    parameters: {
      TableName: process.env.REACT_APP_TABLE_NAME,
      Key: {
        PK: { S: PK },
        SK: { S: SK }
      }
    },
    onSuccess: (data, dispatch) => {
      goToList
        ?
        dispatch(goToList())
        :
        dispatch(loadViewData())
      return {
        type: "a"
      }
    },
    onFailure: (err) => {
      printError("deleteDynamoItemByPK()", err)
      // showMagicToaster({title: err})

      return {
        type: "a"
      }
    }
  })
