/* eslint-disable import/no-anonymous-default-export */
//import store from "store";

// import store from "store";
import { defaultOgDescription, defaultOgLocale } from "components/HelmetMetaData"
//import { defaultOgLocale } from "components/HelmetMetaData"
import { adminLeftMenu } from "mockData/adminMenu"
import { apiAction, dynamoAction } from "store/middleware/api"
import { printError } from "utils/printError"
import { defaultPageTitle } from 'components/HelmetMetaData'
import { sanitizeAnythingForRedux } from "utils/preventReactInRedux"

export const SET_COLLAPSED_MENU = "page/SET_COLLAPSED_MENU"
export const SET_CRT_LIST_ITEMS = "page/SET_CRT_LIST_ITEMS"
export const ADD_CRT_LIST_ITEMS = "page/ADD_CRT_LIST_ITEMS"
export const DELETE_CRT_LIST_ITEM = "page/DELETE_CRT_LIST_ITEM"
export const SET_LEFT_MENU_ITEMS = "page/SET_LEFT_MENU_ITEMS"
export const SET_PAGE_MODAL_DATA = "page/SET_PAGE_MODAL_DATA"
export const SET_PAGE_MODAL = "page/SET_PAGE_MODAL"
export const SET_PAGE_EDIT = "page/SET_PAGE_EDIT"
export const SET_WIZZARD_STEP = "page/SET_WIZZARD_STEP"
export const RESET_CRT_LIST = "page/RESET_CRT_LIST"
export const SET_CRT_LIST_SEARCH_STRING = "page/SET_CRT_LIST_SEARCH_STRING"
export const SET_CRT_FORM_ITEM = "page/SET_CRT_FORM_ITEM"
export const SET_SCROLL_POSITION = "page/SET_SCROLL_POSITION"
export const SET_ACTION_TO_CONFIRM = "page/SET_ACTION_TO_CONFIRM"
export const SET_TEMP_PRODUCT_LIST_DETAILS =
  "page/SET_TEMP_PRODUCT_LIST_DETAILS"
export const SET_ALL_FILED_ERRORS = "page/SET_ALL_FILED_ERRORS"
export const SET_TEMP_PRODUCT_IMPORT_DETAILS =
  "page/SET_TEMP_PRODUCT_IMPORT_DETAILS"
export const SET_IMAGE_POSITION = "page/SET_IMAGE_POSITION"
export const SET_IMAGE_WIDTH = "page/SET_IMAGE_WIDTH"
export const SET_LOADING_INDICATOR = "page/SET_LOADING_INDICATOR"
export const CLEAR_ALL_LOADING_INDICATORS = "page/CLEAR_ALL_LOADING_INDICATORS"
export const SET_TEMP_PRODUCT_PREDEFINED_LIST_DETAILS =
  "page/SET_TEMP_PRODUCT_PREDEFINED_LIST_DETAILS"
export const SET_LOCATION_NAME = "page/SET_LOCATION_NAME"
export const SET_PAGE_Y_SCROLL_POSITION = "page/SET_PAGE_Y_SCROLL_POSITION"
export const DELETE_ITEM_FROM_IMPORT = "page/DELETE_ITEM_FROM_IMPORT"
export const SET_MODAL_PRICE_AND_QUANTITY = "page/SET_MODAL_PRICE_AND_QUANTITY"
export const SET_ACCESSORY_PRICE_AND_QUANTITY =
  "page/ SET_ACCESSORY_PRICE_AND_QUANTITY"
export const SET_PAGE_SEO = "page/SET_PAGE_SEO"
export const CLEAR_PAGE_SEO = "page/CLEAR_PAGE_SEO"
export const SET_DID_ABANDON_CAMPAIGN_PAGE = "page/SET_DID_ABANDON_CAMPAIGN_PAGE"
export const SET_TEST_FIELD_FOR_DEBUGGING = "page/SET_TEST_FIELD_FOR_DEBUGGING"
export const SET_ON_BEHALF_ID = "page/SET_ON_BEHALF_ID"



const initialcrtList = {
  items: [],
  lastItemHint: null,
  searchString: ""
}

const initialSeoFields = {
  pageTitle: defaultPageTitle,
  metaTags: {
    'og:locale': defaultOgLocale,
    'og:title': defaultPageTitle,
    'og:description': defaultOgDescription,
    'description': defaultOgDescription,
  },
}

const initialState = {
  collapsedMenu: false,
  isEdit: false,
  crtFormItem: undefined,
  actionToConfirm: undefined,
  modal: undefined,
  modalData: undefined,
  crtList: initialcrtList,
  wizzardStep: 1,
  leftMenuItems: adminLeftMenu,
  fieldErrors: {},
  loadingIndicators: {},
  ...initialSeoFields,
  testFieldForDebugging: null,
}

export default function (state = initialState, unsanitizedAction) {

  const action = sanitizeAnythingForRedux(unsanitizedAction)

  switch (action.type) {

    case SET_TEST_FIELD_FOR_DEBUGGING:
      return {
        ...state,
        testFieldForDebugging: action?.payload
      }
    case SET_ON_BEHALF_ID:
      return {
        ...state,
        onBehalfId: action?.payload
      }


    case CLEAR_PAGE_SEO: return {...state,...initialSeoFields }

    case SET_PAGE_SEO:
      return {
        ...state,
        ...initialSeoFields,
        pageTitle: action.payload?.pageTitle,
        metaTags: {
          ...initialSeoFields.metaTags,
          //...state.metaTags,
          ...action.payload?.metaTags,
        }
      }
    case SET_TEMP_PRODUCT_LIST_DETAILS:
      const products = state?.crtFormItem?.products?.map?.((oldProd, index) =>
        oldProd?.id === action?.payload?.[index]?.id ? action?.payload?.[index] : oldProd
      )
      return {
        ...state,
        crtFormItem: {
          ...state.crtFormItem,
          products
        }
      }
    case SET_TEMP_PRODUCT_IMPORT_DETAILS:
      const products2 = state.crtFormItem?.products
        ? state.crtFormItem?.products
        : []
      products2?.push?.(action.payload)
      return {
        ...state,
        crtFormItem: {
          ...state.crtFormItem,
          products: products2
        }
      }
    case SET_LOADING_INDICATOR:
      return {
        ...state,
        loadingIndicators: { ...state.loadingIndicators, ...action.payload }
      }
    case CLEAR_ALL_LOADING_INDICATORS:
      return {
        ...state,
        loadingIndicators: {}
      }
    case SET_WIZZARD_STEP:
      return {
        ...state,
        wizzardStep: action.payload
      }
    case SET_ACTION_TO_CONFIRM:
      return {
        ...state,
        actionToConfirm: action.payload,
        modal: action.payload ? "SYS-confirm-action" : undefined
      }
    case SET_CRT_FORM_ITEM:
      return {
        ...state,
        crtFormItem: action.payload?.combine
          ? { ...state.crtFormItem, ...action.payload?.data }
          : action.payload?.data
      }
    case SET_TEMP_PRODUCT_PREDEFINED_LIST_DETAILS:
      //{...p, localStockQuantity: action.payload?.localStockQuantity, centralStockQuantity: action.payload?.centralStockQuantity}
      return {
        ...state,
        crtFormItem: {
          ...state.crtFormItem,
          content: state?.crtFormItem?.content?.map?.((i) =>
            i.type !== "products"
              ? i
              : {
                  ...i,
                  products: i?.products?.map?.((p, index) =>
                    p.id === action.payload?.[index]?.id
                      ? { ...p, ...action.payload?.[index], quantity: p.quantity ?? 0 }
                      : p
                  )
                }
          )
        }
      }
    case SET_LOCATION_NAME:
      return {
        ...state,
        crtFormItem: {
          ...state.crtFormItem,
          addressList: state?.crtFormItem?.addressList?.map?.((i) =>
            i.locationId !== action?.payload?.id
              ? i
              : { ...i, locationName: action.payload?.name }
          )
        }
      }
    case SET_PAGE_MODAL:
      return {
        ...state,
        modal: action.payload?.name,
        modalData: action.payload?.data //?? state.modalData
      }
    case SET_PAGE_MODAL_DATA:
      return {
        ...state,
        modalData: action.payload
      }
    case SET_MODAL_PRICE_AND_QUANTITY:
      return {
        ...state,
        modalData: { ...state.modalData, quantity: action.payload }
      }
    case SET_ACCESSORY_PRICE_AND_QUANTITY:
      return {
        ...state,
        crtList: {
          ...state.crtList,
          items: state?.crtList?.items?.map?.((i) =>
            i?.id === action.payload?.id
              ? { ...i, quantity: action.payload?.changedQuantity }
              : i
          ), // priceNet: i.priceNet * action.payload?.changedQuantity, priceGross: i.priceGross * action.payload?.changedQuantity,
          lastItemHint: action.payload?.lastItemHint
        }
      }
    case SET_ALL_FILED_ERRORS:
      return {
        ...state,
        fieldErrors: action.payload
      }
    case SET_PAGE_EDIT:
      return {
        ...state,
        isEdit: action.payload ?? false
      }
    case SET_LEFT_MENU_ITEMS:
      return {
        ...state,
        leftMenuItems: action.payload
      }
    case SET_CRT_LIST_SEARCH_STRING:
      return {
        ...state,
        crtList: { ...state.crtList, searchString: action.payload }
      }
    case SET_CRT_LIST_ITEMS:
      return {
        ...state,
        crtList: {
          ...state.crtList,
          items: action.payload?.items,
          lastItemHint: action.payload?.lastItemHint
        }
      }
    case ADD_CRT_LIST_ITEMS:
      return {
        ...state,
        crtList: {
          ...state.crtList,
          items: [...(state.crtList?.items || []), ...action.payload?.items],
          lastItemHint: action.payload?.lastItemHint
        }
      }
    case DELETE_CRT_LIST_ITEM:
      return {
        ...state,
        crtList: {
          items: state?.crtList?.items?.filter?.(
            (i) => i.id !== action.payload
          ),
          lastItemHint: state?.crtList?.lastItemHint
        }
      }
    case RESET_CRT_LIST:
      return {
        ...state,
        crtList: initialcrtList
      }
    case SET_COLLAPSED_MENU:
      return {
        ...state,
        collapsedMenu: action.payload
      }
    case SET_SCROLL_POSITION:
      return {
        ...state,
        setScrollPosition: action.payload
      }
    case SET_PAGE_Y_SCROLL_POSITION:
      return {
        ...state,
        yPageScrollPos: action.payload
      }
    default:
      return state
  }
}

export const setTestFieldForDebugging = payload => ({ type: SET_TEST_FIELD_FOR_DEBUGGING, payload })

export const setOnBehalfIdInState = (clientId) => {
  return {
    type: SET_ON_BEHALF_ID,
    payload: clientId
  }
}

export const setPageSeo = (props) => {
  const { pageTitle, metaTags } = props ?? {}
  return {
    type: SET_PAGE_SEO,
    payload: { pageTitle, metaTags }
  }
}

export const clearPageSeo = () => ({ type: CLEAR_PAGE_SEO })

export const setLoadingIndicator = (id, state) => ({
  type: SET_LOADING_INDICATOR,
  payload: { [id]: state }
})
export const clearLoadingIndicator = (id) => ({
  type: SET_LOADING_INDICATOR,
  payload: { [id]: undefined }
})
export const clearAllLoadingIndicators = () => ({
  type: CLEAR_ALL_LOADING_INDICATORS,
})
export const setActionToConfirm = (data) => ({
  type: SET_ACTION_TO_CONFIRM,
  payload: data
})
export const setAllFieldErrors = (data) => ({
  type: SET_ALL_FILED_ERRORS,
  payload: data
})

export const setPageModal = (name, data) => ({
  type: SET_PAGE_MODAL,
  payload: { name, data }
})
export const setPageModalData = (data) => ({
  type: SET_PAGE_MODAL_DATA,
  payload: data
})
export const setMainProductPriceAndQuantity = (data) => ({
  type: SET_MODAL_PRICE_AND_QUANTITY,
  payload: data
})

export const setAccessoryProductPriceAndQuantity = (data) => ({
  type: SET_ACCESSORY_PRICE_AND_QUANTITY,
  payload: data
})
export const setLeftMenuItems = (data) => ({
  type: SET_LEFT_MENU_ITEMS,
  payload: data
})
export const setWizzardStep = (data) => ({
  type: SET_WIZZARD_STEP,
  payload: data
})
export const resetCrtList = () => {
  return {
    type: RESET_CRT_LIST
    //payload: data,
  }
}

export const setCrtList = (data) => ({
  type: SET_CRT_LIST_ITEMS,
  payload: data
})


export const addCrtListItems = (data) => ({
  type: ADD_CRT_LIST_ITEMS,
  payload: data
})

export const deleteCrtListItem = (data) => ({
  type: DELETE_CRT_LIST_ITEM,
  payload: data
})

export const setCrtListSearchString = (data) => ({
  type: SET_CRT_LIST_SEARCH_STRING,
  payload: data
})


export const setCrtFormItem = (data, combine) => ({
  type: SET_CRT_FORM_ITEM,
  payload: { data, combine }
})

export const enrichCrtFrmLocations = (data, dispatch) => {
  data?.addressList?.forEach?.((i) => {
    if(!i?.locationId) return 
    dispatch(fetchLocationNames(i?.locationId, dispatch))
  }
  )
  return { type: "a" }
}

const fetchLocationNames = (locationId, dispatch) =>
  apiAction({
    endPoint: `/location/` + locationId,
    onSuccess: (data, dispatch) => {
      return { type: SET_LOCATION_NAME, payload: data }
    },
    onFailure: (error) => {
      printError("fetchLocationNames()", error)
      return {
        type: "a"
      }
    }
  })

export const setPageEdit = (data) => ({
  type: SET_PAGE_EDIT,
  payload: data
})

export const setCollapsedMenu = (flag) => ({
  type: SET_COLLAPSED_MENU,
  payload: flag
})

export const setScrollPostion = (data) => ({
  type: SET_SCROLL_POSITION,
  payload: data
})

export const setPageYScrollPosition = (data) => ({
  type: SET_PAGE_Y_SCROLL_POSITION,
  payload: data
})
