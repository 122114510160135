import React, { useContext } from "react"
import ThemeContext from "../../theme/themeProvider"

export default function MB(props) {
  const {
    column,
    flex,
    fs,
    fe,
    fc,
    g,
    dc,
    dr,
    sb,
    sa,
    hc,
    jfs, //justify-content-flex-start
    stretch, //flex-box
    br, //border radius
    h,
    w,
    className,
    onClick,
    p = 0,
    px,
    pl,
    pr,
    py,
    pt,
    pb,
    m = 0,
    mt,
    mb,
    ml,
    mr //paddings and margins
  } = props

  const theme = useContext(ThemeContext)
  const baseSpacing = theme.baseSpacing ?? 0.5 // if it's not in theme

  let divStyle = {
    // padding: `${p * baseSpacing}rem`,
    paddingTop: `${(pt ?? py ?? p) * baseSpacing}rem`,
    paddingBottom: `${(pb ?? py ?? p) * baseSpacing}rem`,
    paddingLeft: `${(pl ?? px ?? p) * baseSpacing}rem`,
    paddingRight: `${(pr ?? px ?? p) * baseSpacing}rem`,

    marginTop: `${(mt ?? m) * baseSpacing}rem`,
    marginBottom: `${(mb ?? m) * baseSpacing}rem`,
    marginLeft: `${(ml ?? m) * baseSpacing}rem`,
    marginRight: `${(mr ?? m) * baseSpacing}rem`
  }

  if (flex) {
    divStyle.display = "flex"
    divStyle.alignItems = "center"
  }

  if (dc) divStyle.flexDirection = "column"
  if (column) divStyle.flexDirection = "column"
  if (dr) divStyle.flexDirection = "row"
  if (sb) divStyle.justifyContent = "space-between"
  if (sa) divStyle.justifyContent = "space-around"
  if (hc) divStyle.justifyContent = "center"
  if (jfs) divStyle.justifyContent = "flex-start"
  if (stretch) divStyle.alignItems = "stretch"
  if (fs) divStyle.alignItems = "flex-start"
  if (fe) divStyle.alignItems = "flex-end"
  if (fc) divStyle.alignItems = "center"
  if (g) divStyle.gap = `${g * baseSpacing}rem`
  if (h) divStyle.height = `${h * baseSpacing}rem`
  if (h === 100) divStyle.height = `100%`
  if (w) divStyle.width = `${w * baseSpacing}rem`
  if (w === 100) divStyle.height = `100%`
  if (br) divStyle.borderRadius = `${br * baseSpacing * 16}px`

  let divProps = {
    style: divStyle
  }

  if (className) divProps.className = className
  if (onClick) divProps.onClick = onClick
  return <div data-test-id='W3fZ' {...divProps}>{props.children}</div>
}
