import React from "react"
import NextIcon from "../../assets/BreadCrumbs/NextIcon.svg"
import { Lang, lang, Translate, withLocalize } from "components/Lang/Lang"

//paths for lists, where is redundant to appear in breadcrumbs-click on it would
// load the page itself
const redundantUtils0 = ["/admin/order/list", "/admin/admin-lists/list"]
const redundantUtils1 = [
  "/admin/user/client/list",
  "/admin/user/role/list",
  "/admin/user/uni/list",
  "/admin/company/client/list",
  "/admin/company/accountrequests/list",
  "/admin/company/delivery/list",
  "/admin/company/towns/list",
  "/admin/product/b2b/list",
  "/admin/product/compano/list",
  "/admin/product/discount/list",
  "/admin/product/net/list",
  "/admin/product/b2brelations/list",
  "/admin/product/volume/list",
  "/admin/product/pack/list",
  "/admin/product/matrix/list",
  "/admin/product/badge/list",
  "/admin/product/qa/list",
  "/admin/product/review/list",
  "/admin/product/document/list",
  "/admin/product/warehouse/list",
  "/admin/product/stockrequest/list",
  "/admin/product/pricerequest/list",
  "/admin/order/invoice/list",
  "/admin/guest/news/list",
  "/admin/guest/tutorials/list",
  "/admin/guest/job/list",
  "/admin/guest/candidate/list",
  "/admin/guest/contact/list",
  "/admin/guest/location/list"
]
function BreadCrumbs({ className, translate }) {

  const windowPath = window?.location?.pathname
  const data = windowPath?.split?.("/")?.splice?.(2)
  
  
  const dictionary = {
    product: [
      translate?.("breadCrumbs.product"),
      translate?.("breadCrumbs.products")
    ],
    guest: ["", translate?.("adminmenu.guest")],
    accountRequests: ["", translate?.("breadCrumbs.applications")],
    discount: ["", translate?.("adminmenu.discount")],
    uniuser: ["", translate?.("adminmenu.users")],
    user: ["", translate?.("adminmenu.users")], //**********
    "admin-lists": ["", translate?.("adminmenu.predefinedListList")],
    order: ["", translate?.("adminmenu.orders")],
    invoice: ["", translate?.("dashboard.invoices")],
    location: ["", translate?.("dashboardselector.locationsUniprest")],
    offer: ["", translate?.("breadCrumbs.deals")],
    oferte: ["", translate?.("breadCrumbs.deals")],
    offers: ["", translate?.("breadCrumbs.offers")],
    project: ["", translate?.("breadCrumbs.project")],
    news: ["", translate?.("dashboardselector.news")],
    tutorials: ["", translate?.("adminmenu.tutorial")],
    company: ["", translate?.("adminmenu.organization")],
    job: ["", translate?.("adminmenu.announcesJob")],
    uniUsers: ["", translate?.("breadCrumbs.employee")],
    // accountRequests : ["", "Conturi"],
    accountrequests: ["", translate?.("breadCrumbs.demands")],
    addlistpref: ["", translate?.("breadCrumbs.favorites")],
    pack: ["", translate?.("adminmenu.packages")],
    qa: ["", translate?.("adminmenu.questions")],
    review: ["", translate?.("adminmenu.review")],
    document: ["", translate?.("adminmenu.productDocument")],
    "/admin/product/list": translate?.("breadCrumbs.productList"),
    "/admin/product/b2b/list": translate?.("productList.listaB2B"),
    "/admin/product/b2b/form": translate?.("productdetails.detailsProduct"),
    "/admin/product/form": translate?.("breadCrumbs.productDetails"),
    "/admin/product/discount": translate?.("grilleForm.discountGrila"),
    "/admin/product/discount/list": translate?.("discountList.discount"),
    "/admin/product/discount/form": translate?.("grilleForm.discount"),
    "/admin/product/compano": translate?.("breadCrumbs.companoList"),
    "/admin/product/pack/list": translate?.("adminmenu.packages"),
    "/admin/product/pack/form": translate?.("packTable.detailPack"),
    "/admin/product/qa/list": translate?.("adminmenu.questions"),
    "/admin/product/qa/form": translate?.("breadCrumbs.detailsQuestion"),
    "/admin/product/review/list": translate?.("adminmenu.review"),
    "/admin/product/review/form": translate?.("breadCrumbs.detailsReview"), //not exist yet
    "/admin/product/pricerequest/list": translate?.("adminmenu.priceless"),
    "/admin/product/pricerequest/form": translate?.("priceRequestForm.details"),
    "/admin/uniuser/list": translate?.("breadCrumbs.usersUniprest"),
    "/admin/accountRequests/list": translate?.("breadCrumbs.cont"),
    "/admin/user/list": translate?.("breadCrumbs.usersB2B"),
    "/admin/admin-lists/list": translate?.("adminmenu.predefinedLists"),
    "/admin/admin-lists/dashboard": translate?.("breadCrumbs.staticList"), //?
    "/admin/order/list": translate?.("orderList.order"),
    "/admin/order/view": translate?.("breadCrumbs.detailsOrder"), //lsta de comenzi + detalii document in design //?order/list/view?
    "/admin/order/invoice/list": translate?.("invoicesList.invoices"),
    "/admin/order/invoice/form": translate?.("breadCrumbs.detailsInvoices"), //page doesn't exist yet >> added
    "/admin/offer/list": translate?.("breadCrumbs.offertList"),
    "/admin/oferte/list": translate?.("oferteTable.list"),
    "/admin/project": translate?.("breadCrumbs.home"),
    "/admin/project/list": translate?.("breadCrumbs.projectList"),
    "/admin/guest/news/list": translate?.("dashboardselector.news"),
    "/admin/guest/tutorials/list": translate?.("adminmenu.tutorial"),
    "/admin/company/client/list": translate?.("adminmenu.companyList"),
    "/admin/company/client/form": translate?.("breadCrumbs.userDetails"), //?? BreadCrumbs Premium for now
    "/admin/company/delivery/list": translate?.("list.addressDelivery"),
    "/admin/company/delivery/form": translate?.(
      "breadCrumbs.detailsDeliveryAddress"
    ),
    "/admin/company/accountrequests/list": translate?.(
      "accountRequestList.UniprestCont"
    ), //Lista solicitari??
    "/admin/company/accountrequests/form": translate?.(
      "accountRequestForm.detailsCont"
    ),
    "/admin/guest/job/list": translate?.("avaliableJobs.job"),
    "/admin/guest/candidate/list": translate?.("candidate.applicants"),
    "/admin/guest/candidate/form": translate?.("breadCrumbs.profile"),
    "/admin/guest/candidate/addlistpref": translate?.("breadCrumbs.addFavorites"), //??? url ends with /form
    "/admin/user/uni/list": translate?.("adminmenu.employeeUniprest"),
    "/admin/user/uni/form": translate?.("breadCrumbs.employeeDetails"),
    "/admin/guest/contact/list": translate?.("adminmenu.contactRequest"), //'Lista Cereri Conturi',
    "/admin/guest/contact/form": translate?.("breadCrumbs.detailsContract"),
    "/admin/guest/location/form": translate?.("breadCrumbs.detailLocation"), //Breadcrumbs premium for now

    "/admin/guest/location/list": translate?.(
      "dashboardselector.locationsUniprest"
    ),
    //'/admin/user/client/form/edit' : 'Detalii Utilizator',
    "/admin/product/document/list": translate?.("adminmenu.productDocument"),
    "/admin/angajati/list": translate?.("breadCrumbs.employeeList"),
    "/admin/product/compano/list": translate?.("companoList.compano"),
    "/admin/product/compano/form": translate?.("productdetails.detailsProduct"),
    "/admin/product/volumeDiscount": translate?.("breadCrumbs.volumeDiscount"),
    "/admin/product/price/list": translate?.("breadCrumbs.productB2B"),
    "/admin/product/badge/list": translate?.("adminmenu.badge"),
    "/admin/product/document/form": translate?.("documentsForm.details"),
    "/admin/product/volume/list": translate?.("volumeForm.discounts"),
    "/admin/product/volume/form": translate?.("breadCrumbs.detailDiscount"),
    "/admin/product/net/list": translate?.("adminmenu.price"),
    "/admin/product/b2brelations/list": translate?.("relationList.relationB2B"),
    "/admin/product/net/form": translate?.("addList.detail"),
    "/admin/accountRequests/form": translate?.("breadCrumbs.home"),
    "/admin/user/role/form": translate?.("breadCrumbs.roleDetail"),
    "/admin/user/role/list": translate?.("adminmenu.rolList"),
    "/admin/user/client/list": translate?.("adminmenu.userList"),
    "/admin/user/client/form": translate?.("breadCrumbs.usersDetail"),
    "/admin/user/client/form/add": translate?.("userForm.addUser"),
    "/admin/job/applicant/form": translate?.("addList.detail"), //name dynamically?
    "/admin/guest/job/form": translate?.("addJob.jobDetails"),
    "/admin/company/form": translate?.("userForm.add"),
    //'/admin/angajati/list': 'Home',
    "/admin/delivery/list": translate?.("breadCrumbs.home"),
    "/admin/aplicanti/list": translate?.("breadCrumbs.applicantsList"),
    "/admin/product/matrix/list": translate?.("breadCrumbs.matix"),
    "/admin/product/badge/form": translate?.("badgeForm.detail"),
    "/admin/delivery/form": translate?.("breadCrumbs.home"),
    "/admin/product/warehouse/list": translate?.("adminmenu.listDeposit"),
    "/admin/product/stockrequest/list": translate?.("adminmenu.outOfStock"),
    "/admin/product/stockrequest/form": translate?.("stockRequestForm.details"),
    "/admin/product/warehouse/details": translate?.("breadCrumbs.depozit"),
    "/admin/user/role/form/add": translate?.("roleForm.addrole"),
    "/admin/user/uni/form/add": translate?.("breadCrumbs.addUsers"),
    "/admin/company/client/form/add": translate?.("companyForm.addCompany"),
    "/admin/admin-lists/form/add": translate?.("offerList.add"),
    "/admin/guest/news/form": translate?.("articleForm.newsDetails"),
    "/admin/guest/news/form/add": translate?.("articleForm.addNews"),
    "/admin/guest/tutorials/form": translate?.("breadCrumbs.detailTutoriale"),
    "/admin/guest/tutorials/form/add": translate?.("breadCrumbs.addTutoriale"),
    "/admin/guest/job/form/add": translate?.("avaliableJobs.addJob"),
    "/admin/guest/candidate/form/add": translate?.("breadCrumbs.addCandidate"),
    "/admin/guest/location/form/add": translate?.("locationForm.addLocation"),

    "/admin/dashboard": translate?.("breadCrumbs.statGlobale"),
    "/admin/product/dashboard": translate?.("dashboardselector.staticProduct"),
    "/admin/user/dashboard": translate?.("dashboardselector.staticUsers"), //**********
    "/admin/company/dashboard": translate?.("dashboardselector.staticCompany"),
    "/admin/guest/dashboard": translate?.("dashboardselector.staticGuest"),
    "/admin/order/dashboard": translate?.("breadCrumbs.statOrders"),
    "/admin/admin-lists/form": translate?.("addList.detail"),
    "/admin/company/towns/list": translate?.("deliveryLocationList.town"),
    "/admin/company/towns/form": translate?.("addList.detail"),
    documents: ["", translate?.("breadCrumbs.documents")],
    compano: ["", translate?.("adminmenu.companoProducts")],
    b2b: ["", translate?.("breadCrumbs.listB2B")],
    b2brelations: ["", translate?.("relationList.relationB2B")],
    price: ["", translate?.("netForm.price")],
    badge: ["", translate?.("adminmenu.badge")],
    volume: ["", translate?.("adminmenu.volumeDiscount")],
    net: ["", translate?.("adminmenu.price")],
    applicant: ["", translate?.("breadCrumbs.applicant")],
    candidate: ["", translate?.("adminmenu.applicantsJob")],
    pricerequest: ["", translate?.("adminmenu.priceless")],
    stockrequest: ["", translate?.("adminmenu.outOfStock")],
    warehouse: ["", translate?.("adminmenu.listDeposit")],
    matrix: ["", translate?.("adminmenu.matrix")],
    angajati: ["", translate?.("adminmenu.employeeUniprest")],
    delivery: ["", translate?.("adminmenu.address")],
    role: ["", translate?.("breadCrumbs.rols")],
    client: ["", translate?.("breadCrumbs.customers")], //**********
    uni: ["", translate?.("adminmenu.employeeUniprest")],
    contact: ["", translate?.("adminmenu.contactRequest")],
    towns: ["", translate?.("adminmenu.locations")],
    "/shop/client/users": translate?.("dashboard.usersNumberMulti"),
    "/shop/client/mycompany": translate?.("breadCrumbs.company"),
    "/shop/client/profile": translate?.("breadCrumbs.yourProfile"),
    "/shop/offers/details": translate?.("breadCrumbs.detailOffert")
  }
  return (
    <div 
      data-test-id='cmYn'
      className={`breadcrumb ${
        className ? className : ""
      } flex ptype-3 no-wrap`}
    >
      {data?.map?.((item, index) => {
        const lastOne = index + 1 === data?.length
        const rawPath = `/${windowPath
          ?.split?.("/")
          ?.splice?.(1, index + 2)
          ?.join?.("/")}`
        return !lastOne ? ( //item !== 'form' &&
          (index === 0 && redundantUtils0?.includes?.(windowPath)) ||
          (index === 1 &&
            redundantUtils1?.includes?.(windowPath)) ? null : item !==
            "form" ? (
            <React.Fragment key={index}>
              <a 
                data-test-id='VaQN'
                className="btn-txt-button-prime"
                style={{ textDecoration: "none" }}
                href={hrefDictionary?.[rawPath] ?? rawPath}
              >
                {dictionary?.[item]?.[1]}
              </a>
              <img 
                data-test-id='T7o9'
                onContextMenu={(e) => e?.preventDefault?.()}
                className="mx2"
                src={NextIcon}
                alt="next"
              />
            </React.Fragment>
          ) : null
        ) : (
          <div data-test-id='iXFT' key={"last" - index} className="text-light-01">
            {`${
              dictionary?.[
                windowPath?.split?.("/")?.splice?.(0, 6)?.join?.("/")
              ] ?? item
            }`}
          </div>
        )
      })}
      {!data?.length && (
        <div data-test-id='Abin' className="text-light-01">
          {`${dictionary?.[windowPath] ?? translate?.("breadCrumbs.statGlobale")}`}
        </div>
      )}
    </div>
  )
}

const hrefDictionary = {
  "/admin/order": "/admin/order/list",
  "/admin/order/invoice": "/admin/order/invoice/list",
  "/admin/admin-lists": "/admin/admin-lists/list",
  "/admin/product/warehouse": "/admin/product/warehouse/list",
  "/admin/product/stockrequest/list": "/admin/product/stockrequest/list",
  "/admin/oferte": "/admin/oferte/list",
  "/admin/offer": "/admin/offer/list",
  "/admin/project": "/admin/project/list",
  "/admin/company/client": "/admin/company/client/list",
  "/admin/company/delivery": "/admin/company/delivery/list",
  "/admin/company/accountrequests": "/admin/company/accountrequests/list",
  "/admin/guest/news": "/admin/guest/news/list",
  "/admin/guest/job": "/admin/guest/job/list",
  "/admin/guest/tutorials": "/admin/guest/tutorials/list",
  "/admin/guest/candidate": "/admin/guest/candidate/list",
  "/admin/guest/contact": "/admin/guest/contact/list",
  "/admin/guest/location": "/admin/guest/location/list",
  "/admin/product/b2b": "/admin/product/b2b/list",
  "/admin/product/compano": "/admin/product/compano/list",
  "/admin/product/discount": "/admin/product/discount/list",
  "/admin/product/net": "/admin/product/net/list",
  "/admin/product/b2brelations": "/admin/product/b2brelations/list",
  "/admin/product/volume": "/admin/product/volume/list",
  "/admin/product/pack": "/admin/product/pack/list",
  "/admin/product/matrix": "/admin/product/matrix/list",
  "/admin/product/badge": "/admin/product/badge/list",
  "/admin/product/qa": "/admin/product/qa/list",
  "/admin/product/review": "/admin/product/review/list",
  "/admin/product/document": "/admin/product/document/list",
  "/admin/product/pricerequest": "/admin/product/pricerequest/list",
  "/admin/user/client": "/admin/user/client/list",
  "/shop/client": "/shop/dashboard",
  "/admin/user/uni": "/admin/user/uni/list",
  "/admin/user/role": "/admin/user/role/list",
  "/admin/company/towns": "/admin/company/towns/list"
}

export default withLocalize(BreadCrumbs)
