
import { getValidDateOrNull } from "utils/dateUtils"


// TODO: This code is very similar to utils/myLazy.js
//       Consider refactoring to avoid duplication.



const APP_ERROR_REFRESHED_TIMESTAMP = 'appErrorRefreshedTimestamp'
const WAIT_MILLIS_BEFORE_ANOTHER_REFRESH = 15000 // 15 seconds
/**
 * Save the timestamp of the last app error refresh to the session storage.
 * Meant to be used to prevent an infinite loop of app error refreshes.
 */
export const saveAppErrorRefreshTimestamp = () => 
  window?.sessionStorage?.setItem?.(
    APP_ERROR_REFRESHED_TIMESTAMP, Date.now()
  )



/**
 * Check if the app error refresh is allowed yet.
 * If another app error refresh was done recently,
 * then it is not allowed yet to prevent an infinite loop 
 * of app error refreshes.
 */
export const isAppErrorRefreshAllowed = () => {

  const appErrorRefreshedTimestamp = window?.sessionStorage?.getItem?.(
    APP_ERROR_REFRESHED_TIMESTAMP
  )

  const lastRefreshDateObj = 
    getValidDateOrNull(appErrorRefreshedTimestamp) ||
    getValidDateOrNull(parseInt(appErrorRefreshedTimestamp))

  const lastRefreshTimestamp = lastRefreshDateObj?.getTime?.()
  
  if(!lastRefreshTimestamp) return true

  const now = Date.now()
  
  return now - lastRefreshTimestamp > WAIT_MILLIS_BEFORE_ANOTHER_REFRESH
  
}