import "./InfoIcon.scss"

function InfoIcon(props) {
  const {
    popLeft,
    popRight,
    popBottom, //string info to display
    color = "grey",
    showTip, //static displays the tool tip
    className,
    center, //horizonal center bottom tip
    // noFill
    onClick
  } = props
  let computedContainerClass = `info-icon-wrapper ${className ?? ""} ` + color
  if (popRight) computedContainerClass += " button-tool-tip-right"
  if (popLeft) computedContainerClass += " button-tool-tip-left"
  if (popBottom) computedContainerClass += " button-tool-tip-bottom"
  if (showTip) computedContainerClass += " show"
  if (center) computedContainerClass += " center"

  let buttonProps = {
    tooltip: popLeft ?? popRight ?? popBottom,
    className: computedContainerClass
  }
  if (onClick) buttonProps.onClick = onClick
  return <div data-test-id='FaMx' {...buttonProps}>{defaultIcon}</div>
}

export default InfoIcon

const defaultIcon = (
  <svg 
    data-test-id='whPM'
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeOpacity="0"
      d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM9 12H7V7H9V12ZM8 6C7.4 6 7 5.6 7 5C7 4.4 7.4 4 8 4C8.6 4 9 4.4 9 5C9 5.6 8.6 6 8 6Z"
    />
  </svg>
)
