const matriceaTableItem1 = {
  id: 1,
  code: "SO1",
  prodImages: [
    { id: 0, src: "", alt: "" },
    { id: 1, src: "", alt: "" },
    { id: 2, src: "", alt: "" },
    { id: 3, src: "", alt: "" },
    { id: 4, src: "", alt: "" },
    { id: 5, src: "", alt: "" },
    { id: 6, src: "", alt: "" },
    { id: 7, src: "", alt: "" },
    { id: 8, src: "", alt: "" }
  ],
  marimi: "6 Marimi",
  discountProcent: "1.21% - 2.41%"
}

const matriceaTableItem2 = {
  id: 2,
  code: "SO1",
  prodImages: [
    { id: 0, src: "", alt: "" },
    { id: 1, src: "", alt: "" },
    { id: 2, src: "", alt: "" },
    { id: 3, src: "", alt: "" },
    { id: 4, src: "", alt: "" },
    { id: 5, src: "", alt: "" },
    { id: 6, src: "", alt: "" },
    { id: 7, src: "", alt: "" },
    { id: 8, src: "", alt: "" }
  ],
  marimi: "6 Marimi",
  discountProcent: "1.21% - 2.41%"
}

const matriceaTableItem3 = {
  id: 3,
  code: "SO1",
  prodImages: [
    { id: 0, src: "", alt: "" },
    { id: 1, src: "", alt: "" },
    { id: 2, src: "", alt: "" },
    { id: 3, src: "", alt: "" },
    { id: 4, src: "", alt: "" },
    { id: 5, src: "", alt: "" },
    { id: 6, src: "", alt: "" },
    { id: 7, src: "", alt: "" },
    { id: 8, src: "", alt: "" }
  ],
  marimi: "6 Marimi",
  discountProcent: "1.21% - 2.41%"
}

const matriceaTableItem4 = {
  id: 4,
  code: "SO1",
  prodImages: [
    { id: 0, src: "", alt: "" },
    { id: 1, src: "", alt: "" },
    { id: 2, src: "", alt: "" },
    { id: 3, src: "", alt: "" },
    { id: 4, src: "", alt: "" },
    { id: 5, src: "", alt: "" },
    { id: 6, src: "", alt: "" },
    { id: 7, src: "", alt: "" },
    { id: 8, src: "", alt: "" }
  ],
  marimi: "6 Marimi",
  discountProcent: "1.21% - 2.41%"
}

const matriceaTableData = {
  header: {
    text1: "Codul Matricii",
    text2: "Produse",
    text3: "Marimi in matrice",
    text4: "Discount Procentual",
    text5: "Actiuni"
  },
  contents: [
    matriceaTableItem1,
    matriceaTableItem2,
    matriceaTableItem3,
    matriceaTableItem4,
    matriceaTableItem1,
    matriceaTableItem3,
    matriceaTableItem2
  ]
}

export default matriceaTableData
