import { createSelector } from "reselect"
import moment from "moment"
import { round } from "utils/serviceFunctions"
import { numberFormatter, searchItems } from "utils/stringUtils"
import { defaultCurrencyCode } from "utils/currencyUtils"
import { getTranslate } from "components/Lang/Lang"
import translate from "store/modules/translate"
import { getDepositNames } from "utils/stockUtils"

const language = (state) => state.user.language
const searchString = (state) => state?.dataView?.searchString
const selectedItems = (state) => state?.dataView?.selectedItems
const allItems = (state) => state?.dataView?.items
const clientType = (state) => state.company.type
const dictionaryBrandNames = (state) =>
  state.backData?.dictionary?.PRODUCT_BRAND
const backData = (state) => state.backData
const productCategory = (state) => state.backData?.productCategory
const basket = (state) => state.shop?.cart
const productLists = (state) => state.shop?.lists
const cartData = (state) => state.shop?.cartData
const oldCartData = (state) => state.shop?.oldCartData
const crtFormItem = (state) => state.page?.crtFormItem
const addressList = (state) => state.backData?.dictionary?.ORG_ADDRESS_LIST
const cartSearchString = (state) => state.shop?.cartSearchString
const selectedFilters = (state) => state?.dataView?.selectedFilters
const count = (state) => state?.dataView?.count
const controlPageViewId = (state) => state?.dataView?.controlPageViewId
const viewId = (state) => state?.dataView?.viewId


const mock_Product = {
  badgeTitle: "Badge Title",
  brandName: "Brand name",
  price: 130.33,
  fullPrice: "98.285,12 RON (cu TVA)",
  initialPrice: "98.285,12 RON",
  youSave: "-20%",
  title:
    "This is the product title with a hard limit of 70 (seventy) characters",
  stock: 2,
  stockMessage: "Raspundem in 2 ore",
  rating: 4.3,
  reviews: 120,
  clientType: "guest",
  priceRange: "288,21 - 323,32",
  quantity: 0,
  quantityUnit: "m2",
  totalPrice: "1.878.355,22 RON"
}

const mockDeals = [
  {
    id: "0001",
    quantity: 10,
    price: "8.355,22 RON",
    discount: "-2%",
    discountValue: 2,
    recomanded: false
  },
  {
    id: "0002",
    quantity: 20,
    price: "78.355,22 RON",
    discount: "-3%",
    discountValue: 3,
    recomanded: true
  },
  {
    id: "0003",
    quantity: 30,
    price: "878.355,22 RON",
    discount: "-5%",
    discountValue: 5,
    recomanded: false
  },
  {
    id: "0004",
    quantity: 40,
    price: "1.878.355,22 RON",
    discount: "-10%",
    discountValue: 1,
    recomanded: false
  }
]
const idKey = "id"

const stockInfo = {
  none: { icon: "checkbox-issues", className: "ptype-4 color-alert-red" },
  low: { icon: "checkbox-restricted", className: "ptype-4 color-alert-danger" },
  available: {
    icon: "checkbox-ok",
    className: "ptype-4-strong color-green-40 bold"
  }
}
const getNrInArray = (nr) => {
  if(nr) {
    return [nr?.toString?.()?.split?.(".")?.[0] || 0, nr?.toString?.()?.split?.(".")?.[1] || null]
  }
  return 0
}


export const mockStockInfo = ({ item, translate }) => {

  const depositNames = getDepositNames({ product: item, translate })

  const unitOfMeasure = item?.unitOfMeasureName || item?.unitOfMeasure

  return [
    {
      //0
      icon: "checkbox-restricted",
      className: "ptype-4 color-alert-red ",
      message: `Stoc depozit ${depositNames?.local} indisponibil`
    },
    {
      //1
      icon: "checkbox-issues",
      className: "ptype-4 color-alert-danger ",
      message: "Doar 25 de articole disponibile",
      infoMessage:
        `Livrarea se va face din depozitul ${depositNames?.local} în 24-48h, în limita stocului local.`
    },
    {
      //2 da
      icon: "checkbox-ok",
      className: "ptype-4-bold color-green-40",
      message: `${numberFormatter(getNrInArray(item?.localStockQuantity)?.[0], ".")}${getNrInArray(item?.localStockQuantity)?.[1] ? `,${getNrInArray(item?.localStockQuantity)?.[1]}` : ""} ${
        unitOfMeasure
      } în stoc depozit ${depositNames?.local}`
    },
    {
      //3
      icon: "checkbox-restricted",
      className: "ptype-4 color-alert-red ",
      message: `Stoc ${depositNames?.central} indisponibil`
    },
    {
      //4 da
      icon: "checkbox-ok",
      className: "ptype-4-bold color-green-40 ",
      message: `${numberFormatter(getNrInArray(item?.centralStockQuantity)?.[0], ".")}${getNrInArray(item?.centralStockQuantity)?.[1] ? `,${getNrInArray(item?.centralStockQuantity)?.[1]}` : ""} ${
        unitOfMeasure
      } în stoc depozit ${depositNames?.central}`
    },
    {
      //5 da
      icon: "checkbox-restricted",
      className: "ptype-4 color-alert-red ",
      message: "Stoc indisponibil"
    },
    {
      //6
      icon: "checkbox-ok",
      className: "ptype-4-bold color-green-40",
      message: `${numberFormatter(
        String(
          round(item?.localStockQuantity + (item?.centralStockQuantity ?? 0))
        )?.split?.(".")?.[0],
        "."
      )}${
        String(
          round(item?.localStockQuantity + item?.centralStockQuantity)
        )?.split?.(".")[1]
          ? `,${
              String(
                round(item?.localStockQuantity + item?.centralStockQuantity)
              )?.split?.(".")[1]
            }`
          : ""
      } ${unitOfMeasure} în stoc`
    },
    {
      icon: "checkboxgrey",
      className: "ptype-4 click-button-secondary",
      message: `${numberFormatter(getNrInArray(item?.localReceptionZoneQuantity)?.[0], ".")}${getNrInArray(item?.localReceptionZoneQuantity)?.[1] ? `,${getNrInArray(item?.localReceptionZoneQuantity)?.[1]}` : ""} ${
        unitOfMeasure
      } în stoc în zona de recepție depozitul ${depositNames?.local}`
    },
    {
      icon: "checkboxgrey",
      className: "ptype-4 click-button-secondary",
      message: `${numberFormatter(getNrInArray(item?.centralReceptionZoneQuantity)?.[0], ".")}${getNrInArray(item?.centralReceptionZoneQuantity)?.[1] ? `,${getNrInArray(item?.centralReceptionZoneQuantity)?.[1]}` : ""} ${
        unitOfMeasure
      } în stoc în zona de recepție depozitul  ${depositNames?.central}`
    },

  ]

}


const dateFormat = "DD MMM YYYY"



export const computeStock = ({ item, translate }) => {

  const stockInfoTypes = mockStockInfo({ item, translate }) || []

  const receptionStock = {
    local: item?.localReceptionZoneQuantity ? [stockInfoTypes?.[7]] : [],
    central: item?.centralReceptionZoneQuantity ? [stockInfoTypes?.[8]] : [],
  }
  
  
  // if (item?.localStockQuantity > 0) //no issues in local stock check
  //   return [stockInfoTypes?.[6]]
  // if (item?.centralStockQuantity)
  //   return [stockInfoTypes?.[0], stockInfoTypes?.[4]]
  if (item?.localStockQuantity > 0 && item?.centralStockQuantity > 0)
    return [stockInfoTypes?.[2], ...receptionStock.local, stockInfoTypes?.[4], ...receptionStock.central] 
  if (item?.localStockQuantity === 0 && item?.centralStockQuantity > 0)
    return [stockInfoTypes?.[0], ...receptionStock.local, stockInfoTypes?.[4], ...receptionStock.central] 
  if (item?.localStockQuantity === null && item?.centralStockQuantity > 0)
    return [stockInfoTypes?.[4], ...receptionStock.local, ...receptionStock.central]
  if (item?.localStockQuantity > 0 && item?.centralStockQuantity === 0)
    return [stockInfoTypes?.[2], ...receptionStock.local, stockInfoTypes?.[3], ...receptionStock.central] 
  if (item?.localStockQuantity > 0 && item?.centralStockQuantity === null)
    return [stockInfoTypes?.[2], ...receptionStock.local, ...receptionStock.central] 
  if (item?.localStockQuantity === 0 && item?.centralStockQuantity === 0)
    return [stockInfoTypes?.[0], ...receptionStock.local, stockInfoTypes?.[3], ...receptionStock.central]
  if (item?.localStockQuantity === 0 && item?.centralStockQuantity === null)
    return [stockInfoTypes?.[0], ...receptionStock.local, ...receptionStock.central]
  if (item?.localStockQuantity === null && item?.centralStockQuantity === 0)
    return [stockInfoTypes?.[3], ...receptionStock.local, ...receptionStock.central]
  return [stockInfoTypes?.[5], ...receptionStock.local, ...receptionStock.central]
}

export const selectListCardData = createSelector(
  productLists,
  (productLists) => {
    const lists = productLists?.map?.((item) => {
      const createDate = moment(item?.cDate).format("DD MMM YYYY")
      return {
        item,
        id: `{${item?.PK}-${item?.SK}}`,
        name: item?.name,
        products: item?.products?.length ?? 0 + " produse",
        date: `Creata pe ${createDate ?? "nu stim"} de ${
          item?.cByName ?? "necunoscut"
        }`
      }
    })
    return {
      lists
    }
  }
)

export const selectCartData = createSelector(cartData, (cartData) => {
  return {
    ...cartData
  }
})

export const selectDeliveryInfo = createSelector(cartData, (cartData) => {
  // const lists = productLists?.map?.(item => {
  //   const createDate = moment(item?.cDate).format('DD MMM YYYY')
  //   return {
  //     item,
  //     id: `{${item?.PK}-${item?.SK}}`,
  //     name: item?.name,
  //     products: item?.products?.length ?? 0 + " produse",
  //     date: `Creata pe ${createDate ?? 'nu stim'} de ${item?.cByName ?? "necunoscut"}`
  // }})
  return {
    fromWhere: "Livrarea din depozitul central",
    fromWhereInfo:
      "Lista de produse de mai jos este disponibila pentru livrare doar din Depozitul Central",
    deliverySecondOptionName: "Alege livrare cu FAN CURIER pt 50 RON",
    deliverySecondOptionId: "DEL-FAN0001",
    deliverySecondOptionDisabled: true,
    deliverySecondOptionInfo:
      "Cosul tau contine produse voluminoase ce nu pot fi livrate prin Curierat",
    summary: "21 de produse si 3 pachete",
    deliveryCostTitle: "Cost livrare",
    deliveryCost: "GRATUIT",
    deliveryCostColor: "green-40",
    deliveryFromTitle: "Livrat de",
    deliveryFrom: "Uniprest",
    deliveryFromColor: "blue-30",
    deliveryEstimate: "Livrare estimata 24-48h"
  }
})

//TODO first version if we need to check quantity in
//linelist directly without separate warehouses data
//we might need the second in case of needing error data on no stock in cart
export const getCartQuantity = (cartData, item) => {
  let q = 0
  cartData?.lineList?.forEach?.((product) => {
    // delivery.lineList?.forEach?.(product => {
    if (product.productId === item?.id) q += product.quantity
  })
  return q
}

// export const getCartQuantity = (cartData, item) => {
//   let q = 0
//   //I HAVE CHANGED BECAUSE OF NO STOCK CHECK - IT IS AVAILABLE IN DELIVERY LISTS ONLY
//   cartData?.deliveryList?.forEach?.((lineList) => {
//     lineList?.forEach?.(product => {
//     if (product.productId === item?.id) q += product.quantity
//     })
//   })
//   return q
// }

export const mapVolumeDiscounts = (data, priceNet, priceGross, currency) =>
  data?.map?.((item) => ({
    ...item,
    discount: `-${item?.discount} %`,
    discountValue: item?.discount,
    recomanded: false,
    price: `${round(
      (priceNet * item?.quantity * (100 - item?.discount ?? 0)) / 100
    )} ${currency}`,
    priceGross: `${round(
      (priceGross * item?.quantity * (100 - item?.discount ?? 0)) / 100
    )} ${currency} (cu TVA)`
  }))
export const selectProductListDataV2 = createSelector(
  searchString,
  selectedItems,
  clientType,
  allItems,
  dictionaryBrandNames,
  productCategory,
  basket,
  cartData,
  selectedFilters,
  count,
  viewId,
  controlPageViewId,
  (_, translate) => translate,

  (
    searchString,
    selectedItems,
    clientType,
    allItems,
    dictionaryBrandNames,
    productCategory,
    basket,
    cartData,
    selectedFilters,
    count,
    viewId,
    controlPageViewId, 
    translate,


  ) => {


    let mainPrice, secondaryPrice, price, listPrice, secondaryListPrice
    const isLoadable = viewId === controlPageViewId
    //console.log("VIEW IDS ", viewId, " * ", controlPageViewId, " LOADABLE: ", isLoadable)
    const products = isLoadable
      ?
      allItems?.map?.((item) => {
      const computedCurrency =
        " " +
        (item?.currencyCode?.toUpperCase?.() === "RON" ||
        item?.currencyCode?.toUpperCase?.() === "LEI"
          ? "LEI"
          : item?.currency)
      // if (clientType === 'LARGE') {
      mainPrice = round(item?.priceNet || 0) 
      secondaryPrice = round(item?.priceGross || 0) + computedCurrency + " (cu TVA)"//
      price = item?.priceNet || 0
      listPrice = round(item?.priceListNet || 0)
      secondaryListPrice =
        round(item?.priceListGross || 0) + computedCurrency + " (cu TVA)"
      // }
      // if (clientType !== 'LARGE') {
      //   mainPrice = round(item?.priceListGross) + computedCurrency
      //   secondaryPrice = round(item?.priceListNet) + computedCurrency + ' (fara TVA)'
      //   price = item?.priceGross
      // }
      const rebateMessage = `ECONOMISESTI PANA LA ${item?.discount}% DIN VOLUM`
      return {
        //...mock_Product,
        ...item,
        item,
        ratingAverage: round(item?.ratingAverage, 1),
        stockInfo: computeStock({ item, translate }),
        noStock: !item?.localStockQuantity && !item?.centralStockQuantity,
        nullStock:
          item?.localStockQuantity === null &&
          item?.centralStockQuantity === null,
        fastOptions:
          typeof item?.volumePickerList === "string"
            ? item?.volumePickerList?.split?.(",")?.map?.((e) => Number(e))
            : item?.volumePickerList,
        // minQuantity: item?.sellQuantityMinim, //this is the package content
        minQuantity: item?.sellQuantityIncrement,
        maxDiscount: item?.maxDiscount, //33,
        //precomputed keys
        mainPrice,
        secondaryPrice,
        clientType,
        rebateMessage,
        price,
        listPrice,
        secondaryListPrice,
        //mock stuff
        deals: mapVolumeDiscounts(
          item?.volumeDiscounts,
          item?.priceNet,
          item?.priceGross,
          computedCurrency
        ), //mockDeals,
        // quantityUnit: backData?.dictionary?.UNIT_OF_MEASURE?.find?.(u => u.code === item?.unitOfMeasure),
        //inline computations
        images: item?.products?.map?.((p) => p.primaryImage),
        quantity: getCartQuantity(cartData, item),
        bottomLeftPrice: round(item?.priceNet),
        youSave: item?.discount && `${Math.round(item?.discount)?.toFixed(0)} %`,
        youSaveValue: item?.discount?.toFixed(2),
        id: item?.[idKey],

        // rating: 4.44,
        // reviews: 99,
        stockProps: stockInfo?.[item?.stock ?? "low"],
        brandName: item?.brandName,
        title: item?.name,
        primaryImage: item?.mainImage?.reference ?? item?.mainImage,
        category:
          productCategory?.[0] &&
          productCategory?.find?.(
            (i) =>
              i.treeCode?.replace?.("#", "") ==
              item?.categoryTreeCode?.split?.("#")?.[1]
          )?.name,
        subCategory:
          productCategory?.[0] &&
          productCategory
            ?.find?.(
              (i) =>
                i.treeCode?.replace?.("#", "") ==
                item?.categoryTreeCode?.split?.("#")?.[1]
            )
            ?.childList?.find?.(
              (i) =>
                i.treeCode?.split?.("#")[2] ==
                item?.categoryTreeCode?.split?.("#")?.[2]
            )?.name,
        nrOfProductsInCart: cartData?.productCount || 0,
        priceGross: item?.priceGross || 0,
        priceListGross: item?.priceListGross || 0,
        priceListNet: item?.priceListNet || 0,
        priceNet: item?.priceNet || 0,

        // UNCOMMENT AND EDIT THESE IF custom url tags are needed:
        // customUrl: "mock-custom-url",
        // customTags: ["ctag1", "customtag-2"]
      }
    })
    : []
    // }})?.filter?.(item => searchStringFilter(item, searchString)); //with local search string processing
    if (
      (selectedFilters?.["FLT-sort00p1"]?.length > 0) &
      selectedFilters?.["FLT-sort00p1"]?.[0]?.includes?.("priceNet")
    ) {
      const isDecreasing = Boolean(
        selectedFilters?.["FLT-sort00p1"]?.[0]?.split?.("|")?.[1] === "true"
      )
      products?.sort?.((a, b) => {
        if (isDecreasing)
          return (a?.priceNet || 0) > (b?.priceNet || 0) ? -1 : 1
        if (!isDecreasing)
          return (a?.priceNet || 0) < (b?.priceNet || 0) ? -1 : 1
        return 0
      })
    }
    return {
      products,
      count,
      isLoadable
    }
  }
)

const oldVersionRequired = (item) => {
  return Boolean(item?.errorCode)
}
export const shoppingCartSelector = createSelector(
  searchString,
  clientType,
  dictionaryBrandNames,
  productCategory,
  basket,
  crtFormItem,
  selectCartData,
  oldCartData,
  selectDeliveryInfo,
  addressList,
  cartSearchString,
  (_, isBasketDetailsApi) => isBasketDetailsApi,
  (
    searchString,
    clientType,
    dictionaryBrandNames,
    productCategory,
    basket,
    crtFormItem,
    selectCartData,
    oldCartData,
    selectDeliveryInfo,
    addressList,
    cartSearchString, 
    isBasketDetailsApi = false,
  ) => {

    let products = []
    let showMainWarning = false
    // !!get the URL
    const inCart = window.location.pathname?.includes?.("cart")
    let actualData = inCart ? selectCartData : crtFormItem //we resuse this one in order view also

    // const isBasketDetailsApi = false

    const primaryArrayToIterate = isBasketDetailsApi
      ? actualData?.orderList // NEW
      : actualData?.deliveryList // ORIGINAL 


    //bring the list of items to the required format for the FE
    primaryArrayToIterate?.forEach?.((delivery, index) => {

      const mainDeliveryArray =
        delivery?.lineList?.length > 0
          ? delivery?.lineList
          : actualData?.lineList


      mainDeliveryArray?.forEach?.((deliveryItem) => {
        //enrich linelist item with date from root.LineList // with all the data about a specific product
        const mainItem = actualData?.lineList?.find?.(
          (e) => e?.productId === deliveryItem?.productId
        )
        const item = {
          ...mainItem,
          ...deliveryItem, //fresh info (price, Quantity) si in delivery list
          quantityForModification: mainItem?.quantity
        }


        //look for previous version of the item
        const oldDeliveryItem = oldCartData?.deliveryList?.[index]?.lineList?.find?.(p => p?.productId === mainItem?.productId)
        const oldLineItem = oldCartData?.lineList?.find?.(p => p?.productId === mainItem?.productId)
        const oldItem = {
          ...oldLineItem,
          ...oldDeliveryItem
        }
        //condition checking for error codes
        const priceChanged = oldItem
          && (oldItem?.errorCode === "PRICE_CHANGED")
          && item?.valueNet !== oldItem?.valueNet
          && item?.valueNet > 0
        const stockChanged = oldItem
          && (oldItem?.errorCode === "NOT_ENOUGH_STOCK")
          && oldItem?.quantity !== item?.quantity
          && item?.quantity > 0
        //console.log("PRICES ", item?.valueNet, oldItem?.valueNet, " QTTS ", oldItem?.quantity, item?.quantity)
        const zeroStock = oldItem//
          && (oldItem?.errorCode === "PRICE_CHANGED" || oldItem?.errorCode === "NOT_ENOUGH_STOCK")
          && item?.quantity === 0
        const zeroPrice = oldItem
          && (oldItem?.errorCode === "PRICE_CHANGED" || oldItem?.errorCode === "NOT_ENOUGH_STOCK")
          && item?.unitPriceNet === 0 
        const computedCurrency =
          " " +
          (item?.currencyCode?.toUpperCase?.() === "RON" ||
          item?.currencyCode?.toUpperCase?.() === "LEI"
            ? "LEI"
            : "LEI")//item?.currencyCode)
        const mainPrice = round(item?.valueNet) + computedCurrency
        const secondaryPrice =
          round(item?.valueGross) + computedCurrency + " (cu TVA)"
        //create the response item line
        if (!products[index]) products[index] = []
         //CASES WHEN NOT SHOW MAIN PROD - NO STOCK NO PRICE
        if(!(zeroStock || zeroPrice))
          products?.[index]?.push?.({
            //push current version
            item: { ...item, id: item?.productId },
            mainPrice,
            secondaryPrice,
            ...item,
            id: item?.productId,
            priceGross: item?.unitPriceGross,
            priceNet: item?.unitPriceNet,
            //mock stuff
            stockState: item?.quantity === 0 || item?.valueNet === 0 ? "low" : "available",
            noFan: index % 3 === 0,
            minQuantity: item?.sellQuantityIncrement,
            //inline computations
            images: item?.products?.map?.((p) => p.mainImage?.reference),
            quantity: item?.quantity,
            unit: item?.unitOfMeasure,
            secondaryPrice: secondaryPrice,
            primaryPrice: mainPrice,
            highLightNewPrices: priceChanged || zeroPrice,
            highlightNewQuantity: stockChanged || zeroStock, 
            totalJustValue: item?.valueNet,
            totalNoVatJustValue: item?.valueGross,
            bottomLeftPrice: round(item?.priceNet),
            youSave: item?.discount && `-${Math.round(item?.discount)} %`,
            brandName: item?.productBrandName,
            // brandName: dictionaryBrandNames?.find?.(e => e?.code === item?.goodsBrandName)?.value,
            title: item?.productName,
            primaryImage: item?.productMainImage
            // category: productCategory?.[0] && productCategory?.find?.(i => i.treeCode?.replace?.("#", "") == item?.categoryTreeCode?.split?.("#")?.[1])?.name,
            // subCategory: productCategory?.[0] && productCategory?.find?.(i => i.treeCode?.replace?.("#", "") == item?.categoryTreeCode?.split?.("#")?.[1])?.childList
            //   ?.find?.(i => i.treeCode?.split?.("#")[2] == item?.categoryTreeCode?.split?.("#")?.[2])?.name
          })
        
        // console.log("OLD ITEM ", oldItem," PRCH ", priceChanged," STKCHG ",stockChanged, " ZRsITC ",zeroStock, " ZRPRC ", zeroPrice )
        //look for and push previous version of the item
        const oldVersionCondition =
          (priceChanged || stockChanged || zeroStock || zeroPrice)
        if (oldVersionCondition) {
          showMainWarning = true
          //const oldItem = oldCartData?.deliveryList?.[index]?.lineList?.find?.(p => p?.productId === mainItem?.productId)
          if(oldItem) {
            products?.[index]?.push?.({
              // if (oldVersionRequired(mainItem)) products?.[index]?.push?.({
              ...mainItem,
              oldVersion: true,
              priceChanged,
              stockChanged,
              zeroStock,
              zeroPrice,
              quantity: oldItem?.quantity || 0,
              showDeleteButton: zeroPrice || zeroStock,
              highlightOldQuantity: stockChanged || zeroStock,
              primaryPrice:
                inCart ?
                  oldItem?.valueNet ?
                    round(oldItem?.valueNet) + computedCurrency
                    :
                    round(0) + computedCurrency
                  :
                  round(mainItem?.newPrice?.valueNet) + computedCurrency,
              highlightOldPrices: priceChanged || zeroPrice,
              secondaryPrice: 
                inCart ?
                  oldItem?.valueGross ?
                    round(oldItem?.valueGross)
                      +
                      computedCurrency +
                      " (cu TVA)"
                    :
                    round(0)
                      +
                      computedCurrency +
                      " (cu TVA)"
                  :
                  round(mainItem?.newPrice?.valueGross) +
                    computedCurrency +
                    " (cu TVA)",
              youSave: item?.discount && `-${item?.discount} %`,
              unit: item?.unitOfMeasure,
              //stockColor: 'all-good', //add some condition
              stockState: 
                mainItem?.centralStockQuantity + mainItem?.localStockQuantity >= 1
                  ? "available"
                  : "low",
              overLimitProductCount:
                item?.quantity -
                (item?.centralStockQuantity + item?.localStockQuantity),
              orderViewCheck: true
            })
          }
        }
      })
    })
    //filter based on cart search string
    products = products?.map?.((delivery) =>
      searchItems(cartSearchString, delivery)
    )
    const allPaymentTypes = Object?.keys?.(paymentTypeMapping || {})?.map?.(
      (key) => ({
        ...paymentTypeMapping?.[key],
        id: key,
        limitDescription: `Limita de credit disponibila: 132.748 EUR din 256.000 EUR`,
        limitAvailable: 0.6
        // limitTotal: 256,
      })
    )

    const deliveries = primaryArrayToIterate
      ?.map?.((delivery, index) => ({
        title: getDeliveryTitle(delivery, index),
        cardData: getDeliveryCardData(delivery, index),
        ...getDeliveryInfo({ delivery, isBasketDetailsApi }),
      })) || []


    return {
      orderSummary: orderSummary({ cartData: actualData, isBasketDetailsApi }),
      // deliverries: selectCartData?.deliveryList?.map?.((delivery, index) => ({
      //   products,
      //   title:  getDeliveryTitle(delivery, index),
      //   cardData: getDeliveryCardData(delivery, index),
      // })),
      deliveries,
      products,
      cartData: actualData,
      addressList,
      allPaymentTypes,
      isEligibleForInit: products?.length > 0,
      showMainWarning
    }
  }
)

export const getProductSuffix = (number) =>
  number === 1 ? "produs" : "produse"

const getDeliveryTitle = (delivery, index) =>
  `Livrarea ${index + 1}: 6 produse si 2 pachete`
const getDeliveryCardData = (delivery, index) => ({
  title: `Livrarea ${index}`,
  subtitle1:
    `${delivery.lineList?.length}` +
    getProductSuffix(delivery.lineList?.length) +
    `, din Depozit ${delivery.warehouseName}`,
  subtitle2: `Va fi livrat Miercuri 23 Sept 2021`,
  stock: `available`,
  stockIcon: ``,
  stockMessage: `Stoc disponibil`
})

const getDeliveryInfo = ({ delivery, isBasketDetailsApi = false}) => {

  // we must select the proper field depending on the api endpoint:
  const deliveryDate = isBasketDetailsApi 
    ? delivery?.deliveryDate // when fetched from /basket/details/id api
    : delivery?.orderDeliveryDate // when fetched from /basket/id api

  return {
    fromWhere: `Livrarea din ${delivery.warehouseName}`,
    fromWhereInfo: `Lista de produse de mai jos este disponibila pentru livrare doar din depozitul central`,
    deliverySecondOptionName: `Alege livrare cu FAN CURIER pt 50 RON`,
    deliverySecondOptionId: `DEL-FAN0001`,
    deliverySecondOptionDisabled: false,
    deliverySecondOptionInfo: `Cosul tau contine produse voluminoase ce nu pot fi livrate prin curierat`,
    summary: `${delivery.lineList?.length} ${getProductSuffix(
      delivery.lineList?.length
    )}`,
    deliveryCostTitle: `Cost livrare`,
    deliveryCost: `GRATUIT`,
    deliveryCostColor: `green-40`,
    deliveryFromTitle: `Livrat de`,
    deliveryFrom:
      delivery.deliveryMethodType === "EXTERN" ? "Fan Courier" : `Uniprest`,
    deliveryFromColor: `blue-30`,
    deliveryEstimate: deliveryDate
      ? `Livrare estimata: ${moment(deliveryDate)?.format(
          "DD MMM YYYY"
        )}`
      : `Livrare estimata ${delivery?.deliveryTimeType
          ?.replace?.("H", "")
          ?.replace?.("_", "-")}h`,
    showFanOption: false, //delivery.deliveryMethodType !== "EXTERN",
    orderCode: delivery?.orderCode, 
    orderExternalCode:
      delivery?.orderExternalCode && `ID LIVRARE: ${delivery?.orderExternalCode}`
  }
}


const orderTotal = 1000
const orderCurrency = 1000
const orderTotalNoVat = 1000


const getNrProductsFromOrderList = orderList => {
  
  const getValidProductIdsFromProducts = products => products
    ?.filter?.(product => product?.errorCode !== "NOT_ENOUGH_STOCK")
    ?.map?.(product => product?.productId)

  const nonUniqueProductIds = orderList?.map?.(delivery => 
    getValidProductIdsFromProducts(delivery?.lineList)
  )?.flat?.()

  const uniqueProductIds = [...new Set(nonUniqueProductIds)]

  return uniqueProductIds?.length || 0

}

const orderSummary = ({ cartData, isBasketDetailsApi = false}) => {

  const nrTotalProducts = isBasketDetailsApi 
    // when fetched from /basket/details/id api:
    ? getNrProductsFromOrderList(cartData?.orderList)
    : cartData?.lineList?.filter?.(product => product?.errorCode !== "NOT_ENOUGH_STOCK")?.length



  //const totalProducts = cartData?.deliveryList ? cartData?.deliveryList
  // ?.map?.(delivery => delivery.lineList?.map?.(e => e?.quantity)?.reduce?.((a, b) => a + b, 0))
  //?.reduce?.((a, b) => (a?.lineList?.length ?? 0) + (b?.lineList?.length ?? 0), 0) : 0;
  // ?.reduce?.((a, b) => a + b, 0) : 0;
  // const expDate = new Date(cartData?.expDate) //basket expired
  // const eDate = new Date(cartData?.eDate) //last edit
  // const expired = new Date() > expDate


  return {
    summary: `${nrTotalProducts} ${getProductSuffix(nrTotalProducts)}`,
    deliveriesNo: `${cartData?.deliveryList ?? 0} livrari`,
    total: `Total comanda ${cartData?.totalValueNet ?? 0} ${
      cartData?.currency ?? ""
    }`,
    totalJustValue: cartData?.totalValueNet ?? 0,
    totalNoVat: `Total comanda ${cartData?.totalValueGross ?? 0} ${
      cartData?.currency ?? ""
    } (cu TVA)`,
    totalNoVatJustValue: cartData?.totalValueGross ?? 0,
    payment: paymentTypeMapping?.[cartData?.paymentMethodType]?.paymentName,
    reservedFor: 33000, //in seconds,
    canBeReserved: true,
    totalValue: cartData?.totalValueGross,
    currencyCode: cartData?.currencyCode || defaultCurrencyCode,
    expDate: cartData?.expDate,
    eDate: cartData?.eDate,
    ...paymentTypeMapping?.[cartData?.paymentMethodType]
  }
}

const paymentTypeMapping = {
  BANK_CARD: {
    paymentName: "Plata prin card bancar",
    paymentDescription:
      "Platesiti in siguranta prin Stripe, stocul rezervat instant, iar comanda este procesata in cateva minute.",
    paymentBottomText: "MasterCard sau Visa",
    paymentBottomIcon: "paybycard"
  },
  BANK_TRANSFER: {
    paymentName: "Plata prin transfer bancar",
    paymentDescription:
      "Platesiti in siguranta prin transfer bancar, stocul e rezervat acum, iar comanda este procesata cand incasam suma in cont.",
    paymentBottomText: "Transfer bancar. Detalii pe urmatorul ecran.",
    paymentBottomIcon: "paybycard"
  },
  CREDIT_LIMIT: {
    paymentName: "Plata la termen",
    paymentDescription:
      "Platesiti in siguranta prin transfer bancar, stocul e rezervat acum, iar comanda este procesata cand incasam suma in cont."
  }
}
// const mockDeliveryInfo = {
//   fromWhere: 'Livrarea din depozitul central',
//   fromWhereInfo: 'Lista de produse de mai jos este disponibila pentru livrare doar din Depozitul Central',
//   deliverySecondOptionName: 'Alege livrare cu FAN CURIER pt 50 RON',
//   deliverySecondOptionId: 'DEL-FAN0001',
//   deliverySecondOptionDisabled: true,
//   deliverySecondOptionInfo: 'Cosul tau contine produse voluminoase ce nu pot fi livrate prin Curierat',
//   summary: '21 de produse si 3 pachete',
//   deliveryCostTitle: 'Cost livrare',
//   deliveryCost: 'GRATUIT',
//   deliveryCostColor: 'green-40',
//   deliveryFromTitle: 'Livrat de',
//   deliveryFrom: 'Uniprest',
//   deliveryFromColor: 'blue-30',
//   deliveryEstimate: 'Livrare estimata 24-48h',
// }
// const deliveryTitle = "Livrarea 1: 6 produse si 2 pachete"

// const deliveryCardInfo = {
//   title: "Livrarea 1",
//   subtitle1: "21 de produse, din Depozit Tragu Mures",
//   subtitle2: "Va fi livrat Miercuri 23 Sept 2021",
//   stock: "available",
//   stockIcon: "",
//   stockMessage: "Stoc Disponibil",
// }
// const deliveryCardInfo2 = {
//   title: "Livrarea 2",
//   subtitle1: "21 de produse, din Depozit Tragu Mures",
//   subtitle2: "Va fi livrat Miercuri 23 Sept 2021",
//   stock: "available",
//   stockIcon: "",
//   stockMessage: "Stoc Disponibil",
// }
// filter(item => searchStringFilter(item, searchString)).

const mockProps = {
  mainImage: {
    reference:
      "https://uniprest.compano.com/Data/Environments/000001/Attachment/Bijlage/Overzicht%20Product%20classifications/VS0440M05PRN.JPG"
  },
  brandName: "Brand Name",
  title:
    "This is the product title with a hard limit of 70 (seventy) characters",
  // discount: "-12%",
  price: "1.878.355,22",
  vatPrice: "2.478.355,22",
  quantity: "53",
  unit: "m2",
  id: "PRD-12345678",
  stockSvg: "done",
  stockState: "available",
  priceUnit: "LEI"
}

const products = [
  mockProps,
  mockProps,
  mockProps,
  mockProps,
  mockProps,
  mockProps,
  { ...mockProps, stockState: "low" },
  mockProps
]
const mockPack = {
  id: "mockpack-1",
  // primaryImage: "",
  brandName: "Brand Name",
  name: "Nume pachet simplu",
  discount: "12",
  priceListNet: 229.73,
  priceListGross: 273.3787,
  priceNet: 163.1083,
  priceGross: 194.098877,
  quantity: 53,
  unit: "buc",
  id: "PRD-12345678",
  stockSvg: "done",
  stockState: "limited",
  priceUnit: "LEI",
  // images: products?.map?.(p => p.primaryImage),

  products
}

// const products = [...basket]?.map?.((item, index) => {
//   const computedCurrency = ' ' + (item?.currency ?? 'RON')
//   // if (clientType === 'LARGE') {
//   //   mainPrice = round(item?.priceListNet) + computedCurrency
//   //   secondaryPrice= round(item?.priceListGross) + computedCurrency + ' (cu TVA)'
//   //   price = item?.priceListNet
//   // }
//   // if (clientType !== 'LARGE') {
//   //   mainPrice = round(item?.priceListGross) + computedCurrency
//   //   secondaryPrice= round(item?.priceListNet) + computedCurrency + ' (fara TVA)'
//   //   price = item?.priceGross
//   // }
//   // const rebateMessage = `ECONOMISESTI PANA LA ${item?.discount}% DIN VOLUM`
//   return {
//   //...mock_Product,
//   ...item, item,
//   //precomputed keys

//   //mock stuff
//   stockState: "available",
//   noFan: index % 3 === 0,
//   //inline computations
//   images: item?.products?.map?.(p => p.mainImage?.reference),
//   quantity: [...basket, mockPack]?.find?.(p => p.id === item?.id)?.quantity,
//   secondaryPrice: round(item?.priceListNet * item?.quantity) + computedCurrency + ' (fara TVA)',
//   primaryPrice : round(item?.priceListGross * item?.quantity) + computedCurrency,
//   bottomLeftPrice: round(item?.priceNet),
//   youSave: item?.discount && `-${item?.discount} %`,
//   id: item?.[idKey],
//   rating: 4.44,
//   reviews: 99,
//   //TODO:SE: brandName from BE is not a code value
//   brandName: item?.brandName, //dictionaryBrandNames?.find?.(i => i?.code === item?.brandName)?.value,
//   title: item?.name,
//   primaryImage: item?.mainImage?.reference,
//   category: productCategory?.[0] && productCategory?.find?.(i => i.treeCode?.replace?.("#","") == item?.categoryTreeCode?.split?.("#")?.[1])?.name,
//   subCategory: productCategory?.[0] && productCategory?.find?.(i => i.treeCode?.replace?.("#","") == item?.categoryTreeCode?.split?.("#")?.[1])?.childList
//     ?.find?.(i => i.treeCode?.split?.("#")[2] == item?.categoryTreeCode?.split?.("#")?.[2])?.name
// }});
// }})?.filter?.(item => searchStringFilter(item, searchString)); //with local search string processing
