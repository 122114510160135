import { lazy } from "react"
import { getValidDateOrNull } from "utils/dateUtils"


const CHUNK_ERROR_REFRESHED_TIMESTAMP = 'chunkErrorRefreshedTimestamp'
const WAIT_MILLIS_BEFORE_ANOTHER_REFRESH = 10000 // 10 seconds
/**
 * Save the timestamp of the last chunk error refresh to the session storage.
 * Meant to be used to prevent an infinite loop of chunk error refreshes.
 */
const saveChunkErrorRefreshTimestamp = () => 
  window?.sessionStorage?.setItem?.(
    CHUNK_ERROR_REFRESHED_TIMESTAMP, Date.now()
  )



/**
 * Check if the chunk error refresh is allowed yet.
 * If another chunk error refresh was done recently,
 * then it is not allowed yet to prevent an infinite loop 
 * of chunk error refreshes.
 */
const isChunkErrorRefreshAllowed = () => {

  const chunkErrorRefreshedTimestamp = window?.sessionStorage?.getItem?.(
    CHUNK_ERROR_REFRESHED_TIMESTAMP
  )

  console.log(
    'isChunkErrorRefreshAllowed() chunkErrorRefreshedTimestamp', 
    chunkErrorRefreshedTimestamp
  )

  const lastRefreshDateObj = 
    getValidDateOrNull(chunkErrorRefreshedTimestamp) ||
    getValidDateOrNull(parseInt(chunkErrorRefreshedTimestamp))

  console.log(
    'isChunkErrorRefreshAllowed() lastRefreshDateObj',
    lastRefreshDateObj
  )

  const lastRefreshTimestamp = lastRefreshDateObj?.getTime?.()
  console.log(
    'isChunkErrorRefreshAllowed() lastRefreshTimestamp',
    lastRefreshTimestamp
  )

  
  if(!lastRefreshTimestamp) return true

  const now = Date.now()
  console.log('isChunkErrorRefreshAllowed() now', now)
  
  return now - lastRefreshTimestamp > WAIT_MILLIS_BEFORE_ANOTHER_REFRESH
  
}

/**
 * Acts like React.lazy() but handles ChunkLoadError by refreshing the page.
 * Prevents an infinite loop of chunk error refreshes.
 */
export const myLazy = importerFunction => {
  return lazy(() => getLazyImporterPromise(importerFunction))
}


/**
 * Refresh the page if the error is a ChunkLoadError. 
 * Meant to be used with React.lazy() to prevent FatalError 
 * and WhiteScreenOfDeath caused by ChunkLoadError.
 * Prevents an infinite loop of these refreshes.
 */
export const getLazyImporterPromise = importerFunction => {
  return new Promise((resolve, reject) => {
    
    // try to import the component
    importerFunction()
      .then(resolve)
      .catch(error => {

        console.log('getLazyImporterPromise() error', error)

        if (error?.name === 'ChunkLoadError' || error?.code === 'CSS_CHUNK_LOAD_FAILED') {

          if(isChunkErrorRefreshAllowed()){
            console.log('getLazyImporterPromise() Refreshing because of ChunkLoadError: ', error)
            saveChunkErrorRefreshTimestamp()
            return window?.location?.reload(true)
          }else{
            console.log(`
              getLazyImporterPromise() Preventing infinite refresh loop 
              (Not refreshing because there was a refresh 
              already in the last ${WAIT_MILLIS_BEFORE_ANOTHER_REFRESH} 
              milliseconds because ChunkLoadError): `, error
            )
          }

        }

        reject(error) // Default error behavior as already tried refresh
      
      })
  })
}
