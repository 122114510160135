// import { Redirect } from "react-router";

import { myLazy } from "utils/myLazy";

export const shopDevRoutes = [
  // =============== GENERAL ===================

  {
    exact: true,
    path: "/shop/dev/wizzard",
    component: myLazy(() => import("views/dev/wizzardForm/wizzardForm"))
  },

  {
    exact: true,
    path: "/shop/dev/pricematrix",
    component: myLazy(() => import("views/dev/sorin/index3"))
  },

  {
    exact: true,
    path: "/shop/dev/select",
    component: myLazy(() => import("views/dev/select"))
  },

  {
    exact: true,
    path: "/shop/dev/filepicker",
    component: myLazy(() => import("views/dev/FilePicker"))
  },
  {
    exact: false,
    path: "/shop/dev/grid",
    component: myLazy(() => import("views/admin/index"))
  },

  {
    exact: true,
    path: "/shop/dev/apicalls",
    component: myLazy(() => import("views/dev/ApiCalls"))
  },

  {
    exact: true,
    path: "/shop/dev/icons",
    component: myLazy(() => import("views/dev/IconsPage"))
  },

  {
    exact: true,
    path: "/shop/dev/fields",
    component: myLazy(() => import("views/dev/fields"))
  },

  {
    exact: false,
    path: "/shop/dev/articleeditor",
    component: myLazy(() => import("views/dev/articleEditor.jsx"))
  },

  {
    exact: false,
    path: "/shop/dev/form",
    component: myLazy(() => import("views/dev/form"))
  },

  {
    exact: true,
    path: "/shop/dev/buttons",
    component: myLazy(() => import("views/dev/buttons"))
  },

  {
    exact: true,
    path: "/shop/dev/stiriTable",
    component: myLazy(() => import("views/stiriTable/stiriTable"))
  },

  {
    exact: true,
    path: "/shop/dev/scroll-on-back",
    component: myLazy(() => import("views/dev/scrollOnBack/scrollOnBack.jsx"))
  },

  // =============== TEAM ===================
  {
    exact: true,
    path: "/shop/dev/sorin3",
    component: myLazy(() => import("views/dev/sorin/index3"))
  },

  {
    exact: false,
    path: "/shop/dev/sorin2",
    component: myLazy(() => import("views/dev/sorin/index2"))
  },

  {
    exact: true,
    path: "/shop/dev/sorin5",
    component: myLazy(() => import("views/dev/sorin/index5"))
  },
  {
    exact: true,
    path: "/shop/dev/sorin6",
    component: myLazy(() => import("views/dev/sorin/index6"))
  },
  {
    exact: false,
    path: "/shop/dev/sorin8",
    component: myLazy(() => import("views/dev/sorin/index8"))
  },

  {
    exact: true,
    path: "/shop/dev/norbert",
    component: myLazy(() => import("views/dev/norbert"))
  },

  // {
  //   exact: false,
  //   path: "/shop/dev/norbert/wizzard",
  //   component: myLazy(() => import("views/dev/norbert/wizzards/AdaugaPachet/wizzardForm.jsx")),
  // },
  {
    exact: false,
    path: "/shop/dev/norbert/docs",
    component: myLazy(() => import("views/dev/norbert/docsComponent"))
  },

  {
    exact: true,
    path: "/shop/dev/tamas",
    component: myLazy(() => import("views/dev/tamas"))
  },

  {
    exact: true,
    path: "/shop/dev/gyula",
    component: myLazy(() => import("views/dev/gyula"))
  },

  {
    exact: true,
    path: "/shop/dev/gyula",
    component: myLazy(() => import("views/dev/gyula"))
  },

  {
    exact: true,
    path: "/shop/dev/cristi",
    component: myLazy(() => import("views/dev/cristi"))
  }
]
