import React from "react"
import PropTypes from "prop-types"
import { useState } from "react"
import MagicButton from "components/MagicButton/MagicButton"

export default function MultiStepBar(props) {
  const { labels, steps, selectedIndex, callBack } = props
  const [selectedStep, setSelectedStep] = useState()

  React.useEffect(() => {
    if (steps) {
      //Hint: initial selectedIndex prop overRules the list props
      if (
        selectedIndex !== undefined &&
        selectedIndex >= 0 &&
        selectedIndex <= steps?.length - 1
      ) {
        steps?.map?.((step, index) => {
          step.selected = index === selectedIndex
          return step
        })
        setSelectedStep(selectedIndex)

        //Hint: the selectedStep will be searched in the given list
      } else {
        setSelectedStep(getSelectedIndex(steps))
      }
    }
  }, [steps, selectedIndex])

  function btnClickHandler() {
    nextStepHandler(selectedStep, steps, setSelectedStep)
    if (callBack) callBack()
  }

  return (
    <div data-test-id='Au2Y' className="multistep">
      <div data-test-id='YfHE' className="row-container">
        {steps?.map?.((step, index) => {
          return (
            <div data-test-id='ExOK' key={index} className="step-container">
              <div data-test-id='oC3K' className="step-label">
                {labels?.current && index === selectedStep && labels.current}
              </div>
              <div 
                data-test-id='qq26'
                className={`bar ${step.done ? "done" : ""} ${
                  step.selected || index === selectedStep ? "selected" : ""
                }`}
              />
            </div>
          )
        })}
        <div data-test-id='K0gs' className="button-container">
          <MagicButton
            type={"grey"}
            svg={"infoK3"}
            onClick={() => btnClickHandler()}
            label={labels?.button && labels.button}
            h={3.5}
            pt={0.25}
            pr={0.25}
            pb={0.25}
            pl={0.25}
          />
        </div>
      </div>
    </div>
  )
}

function nextStepHandler(selectedStep, steps, setSelectedStepFN) {
  if (selectedStep + 1 < steps?.length) {
    steps[selectedStep].selected = false
    steps[selectedStep].done = true
    steps[selectedStep + 1].selected = true
    setSelectedStepFN(selectedStep + 1)
  }
}

function getSelectedIndex(steps) {
  return steps?.findIndex?.((i) => i.selected)
}

MultiStepBar.propTypes = {
  callBack: PropTypes.func,
  selectedIndex: PropTypes.number,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      selected: PropTypes.bool,
      done: PropTypes.bool
    })
  ),
  labels: PropTypes.shape({
    current: PropTypes.string,
    button: PropTypes.string
  })
}
