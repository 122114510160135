import React, { useState } from "react"
import "./index.scss"
import SvgIcon from "components/SvgIcon/SvgIcon"
import { useDispatch } from "react-redux"
import useQueryStringFilters from "utils/useQueryStringFilters"
import MyLink from "components/MyLink"
import { noBrandFilter } from "utils/filterUtils"

const noCaseStringSearch = (inputString, searchTerm) =>
  typeof inputString === "string"
    ? inputString?.toLowerCase?.()?.includes?.(searchTerm?.toLowerCase?.())
    : undefined

export default function MultiSelectFilter(props) {
  const {
    id,
    search,
    loadMore,
    stars,
    data,
    selection: propsSelection,
    withQs
  } = props
  const dispatch = useDispatch()
  const { filters, setFilter, getUrlWithNewBrand, getUrlWithNewJobFilter } = useQueryStringFilters()
  const [searchValue, setSearchValue] = useState("")
  const loadMoreAction = () => {
    console.log("load more")
  }

  const selection = withQs ? filters?.[id] : propsSelection

  const getNewSelection = optionId => {
    if(selection) return selection?.includes?.(optionId)
      ? selection?.filter?.((e) => e !== optionId)
      : [...selection || [], optionId]

    return [optionId]
  }

  /**
   * Fara brand can can not be selected with other brands. If Fara brand is selected, all other brands are deselected.
   **/
  const getNewBrandSelection = optionId => {
    const isBecomingSelected = !selection?.includes?.(optionId)

    if(optionId === noBrandFilter) return isBecomingSelected ? [optionId] : []
    if(!selection?.includes?.(noBrandFilter)) return getNewSelection(optionId)
    
    return isBecomingSelected
      ? getNewSelection(optionId)?.filter?.(someBrand => someBrand !== noBrandFilter)
      : []
  }

  const getOptionHref = optionId => {
    
    if(id === "FLT-SMaaaa00") {
      const newBrandSelection = getNewBrandSelection(optionId)
      return getUrlWithNewBrand(newBrandSelection, selection?.includes?.(optionId))
    }
    
    const newSelection = getNewSelection(optionId)
    if(id === "FLT-M0ctyjob") return getUrlWithNewJobFilter(newSelection)
  }


  const isSelected = (optionId) => selection?.includes?.(optionId)

  return (
    <>
      {search ? (
        <input 
          data-test-id='5Dzp'
          className="filterSearch ptype-3"
          placeholder="Cauta"
          value={searchValue}
          onChange={(e) => setSearchValue(e?.target?.value)}
        />
      ) : (
        <div data-test-id='Fkku' className="msf-top-space" />
      )}
      {data
        ?.filter?.(
          (item) => !searchValue || noCaseStringSearch(item?.label, searchValue)
        )
        ?.map?.((item) => {
          return (
            <MyLink
              key={item?.id}
              className="multi-filter-pill"
              to={getOptionHref(item?.id)}
            >
              <SvgIcon
                mr={2}
                name={
                  isSelected(item?.id) ? "Checkbox_active" : "Checkbox_inactive"
                }
              />
              {stars ? (
                [1, 2, 3, 4, 5]?.map?.((star) => (
                  <SvgIcon
                    name={
                      item?.id >= star ? "Review_star_full" : "reviewstarempty"
                    }
                  />
                ))
              ) : (
                <div data-test-id='ZV7r' className="ptype-4 text-light-1">{item?.label}</div>
              )}
              {item?.info && (
                <div data-test-id='opb7' className="ptype-4 grey-20 info">{`(${item?.info})`}</div>
              )}
            </MyLink>
          )
        })}
      {loadMore && (
        <div 
          data-test-id='uTO5'
          className="filter-load-more ptype-2"
          onClick={() => loadMoreAction()}
        >
          {loadMore}
        </div>
      )}
    </>
  )
}
