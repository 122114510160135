import { createBrowserHistory } from "history"
// import { useLocation } from 'react-router-dom';
import queryString from "query-string"

// export const history = createBrowserHistory();
export const history = createBrowserHistory({})

export const createUrlPrefix = ({ customUrl, customTags }) =>
  `${customUrl}`
    ?.concat?.(customTags?.length ? "-" : "")
    ?.concat?.(customTags?.join?.("-"))

export const getProductDetailHref = ({
  id,
  customTags = [],
  customUrl = ""
  // UNCOMMENT AND EDIT THESE IF default custom url tags are needed:
  // customTags = ["mocktag1", "mocktag2"],
  // customUrl = "customUrl",
}) => {
  if (!id) {
    console.error("No id provided for getProductDetailHref()")
    return "/404"
  }

  return `/shop/details/${createUrlPrefix({ customTags, customUrl })}?id=${id}`
}

export const goToProductDetails = (id, customTags, customUrl) => {
  const productDetailUrl = getProductDetailHref({ id, customTags, customUrl })
  if (!productDetailUrl) {
    return
  }
  history?.push?.(productDetailUrl)
}

export const getUrlParam = (param) =>
  window.location.search
    ?.split?.("?")[1]
    ?.split?.("&")
    ?.find?.((item) => item?.includes?.(`${param}=`))
    ?.split?.("=")[1]

export const setQsParams = (object) => {
  const crtQs = queryString.parse(window.location.search)
  const newQs = { ...crtQs, ...object }
  const url = `${window.location.pathname}?${queryString.stringify(newQs)}`
  // debugger
  window.history.replaceState(null, null, url)
  // history?.push?.( url )
}

export const getQsParams = () => queryString.parse(window.location.search)
