import React from "react"
import { rightMenuUnauthenticated, rightOpenMenu } from "mockData/menus"
import LogOut from "assets/MenuIcon/Atom/Icons/LogOut.svg"
import SvgIcon from "components/SvgIcon/SvgIcon"
// import MB from "components/MB";
import { history } from "utils/history"
import { useSelector } from "react-redux"
import { logoutUser } from "store/modules/auth"
import { useDispatch } from "react-redux"
import { removeJavaToken } from "store/justActions/javaAuth"
import {
  Lang,
  lang,
  Translate,
  withLocalize,
  getTranslate
} from "components/Lang/Lang"
import { setOnBehalfIdInState } from "store/modules/page"
import useWindowSize from "utils/useWindowsSize"


export default withLocalize(function RightOpenMenu({
  visible,
  className,
  translate
}) {
  let containerClass = visible ? "menuOpen right" : "menuOpen right hidden"
  const dispatch = useDispatch()
  const windowSize = useWindowSize()
  const authenticatedState = useSelector((state) => state.auth?.authenticated)
  const companyType = useSelector((state) => state.company?.type)
  const userFullName = useSelector((state) => state?.user?.name)
  const authenticated = useSelector((state) => state.auth?.authenticated)
  const userPermissions = useSelector(
    (state) => state.auth?.userData?.role?.permissions
  )
  const isHrAdmin = userPermissions?.includes?.("WEBSHOP_HR_ADMIN")
  const isMarketingManagerAdmin = userPermissions?.includes?.("WEBSHOP_MARKETING_ADMIN")
  const restrictedAdminRole = isHrAdmin || isMarketingManagerAdmin
  const parsedRightOpenMenu = rightOpenMenu?.top?.filter?.(menuItem => {
    if(menuItem?.id === "leftEntriesExcel"
      && (!authenticated || windowSize?.width < 768))
        return false
    else if (menuItem?.id === "leftEntriesOfferts"
      && (!authenticated ||
          windowSize.width < 1340 ||
          !userPermissions?.includes?.("CUSTOMER_OFFER_READ")))
            return false
    else if (menuItem?.id === "others" 
      && windowSize?.width < 768)
        return false
    else return true
  })
  const loggedInFragment = (text) => (
    <>
      <span data-test-id='q3S3' className="ptype-5 color-text-light-01 mr2">{text}</span>
      <a 
        data-test-id='7JZv'
        className="ptype-5-bold btn-txt-button-prime bold"
        href="/shop/client/profile"
      >
        {userFullName}
      </a>
    </>
  )

  const menuButtonFragment = (item, index, type = "") => {
    if (item?.name === "menus.rightLogout") {
      return (
        <div 
          data-test-id='qqaC'
          key={index}
          className={`entry button ${type}`}
          onClick={() => {
            dispatch(logoutUser())
            dispatch(setOnBehalfIdInState())
            // dispatch(removeJavaToken()) check root reducer for changes
          }}
        >
          {item?.icon && (
            <img 
              data-test-id='OY9Y'
              onContextMenu={(e) => e?.preventDefault?.()}
              className=""
              alt="logo"
              src={item?.icon}
            />
          )}
          {item?.svg && <SvgIcon name={item?.svg} />}
          <div data-test-id='La7h' className="text">{translate?.(item?.name)}</div>
        </div>
      )
    } else {
      return (
        <a data-test-id='sft4' key={index} className={`entry button ${type}`} href={item?.url}>
          {item?.icon && (
            <img 
              data-test-id='bJBx'
              onContextMenu={(e) => e?.preventDefault?.()}
              className=""
              alt="logo"
              src={item?.icon}
            />
          )}
          {item?.svg && <SvgIcon name={item?.svg} />}
          <div data-test-id='aylg' className="text">{translate?.(item?.name)}</div>
        </a>
      )
    }
  }

  const authGuestRightMenu = restrictedAdminRole ?
    <div data-test-id='L0mE' className="entry">
      {loggedInFragment(parsedRightOpenMenu?.find?.(i => i?.id === "loggedInAs")?.loggedInAs || "")}
    </div>
    :
    parsedRightOpenMenu?.map?.((item, index) => {
      if (
        item?.name === "menus.rightInvoices" &&
        !userPermissions?.includes?.("CLIENT_INVOICE_READ")
      ) {
        return <></>
      } else {
        return "sectionName" in item ? (
          <div data-test-id='XpBs' key={index} className="entry category ptype-5-strong">
            {item?.sectionName}
          </div>
        ) : "loggedInAs" in item ? (
          <div data-test-id='XyMv' key={index} className="entry">
            {loggedInFragment(item?.loggedInAs)}
          </div>
        ) : (
          menuButtonFragment(item, index)
        )
      }
    })

  const unauthGuestRightMenu = rightMenuUnauthenticated?.topItems?.map?.(
    (item, index) => menuButtonFragment(item, index)
  )

  return (
    <>
      <nav 
        data-test-id='Wzrb'
        className={`${containerClass} ${
          className !== undefined ? className : ""
        }`}
      >
        <div data-test-id='26UG' className="entryContainer stroke-text-light-01">
          {companyType !== "GUEST" ? authGuestRightMenu : unauthGuestRightMenu}
        </div>

        {companyType !== "GUEST" && (
          <div data-test-id='u3gZ' className="entryContainer">
            {rightOpenMenu.bottom?.map?.((item, index) => {
              if(restrictedAdminRole && item?.id === "menus.rightGoToCompany")
                return null
              return menuButtonFragment(item, index, "grey")
            })}
          </div>
        )}
      </nav>
    </>
  )
})
