import CategoryFilter from "components/CategoryFilter"
import SvgIcon from "components/SvgIcon/SvgIcon"
// import moment from "moment"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setFilterSelection } from "store/modules/dataView"
import useShowModalOutsideClick from "utils/useShowModalOutsideClick"
import "./DatePicker.scss"

// const allCategoryFilter = {
//   id: "FLT-CATEG001",
//   fb : 200,
//   value: "String",
//   type: "category",
//   placeholder: undefined,
//   label: "Categorie",
//   search: true,
//   // loadMore: "Incarca mai multe...",
// };

export const VerticalCategoryFilter = (props) => {
  const [value, setValue] = useState()
  const { ref, showModal, setShowModal } = useShowModalOutsideClick()

  let className = "cdt-button no-label stroke-text-light-01 "
  if (showModal) className += "open "
  if (!showModal) className += "close "
  if (value) className += " selected"

  // let label = value?.[0] ? "selectata" : "Select Date"
  return (
    <div data-test-id='mWpk' ref={ref}>
      <button data-test-id='tuBk' {...{ className }} onClick={() => setShowModal((p) => !p)}>
        {/* {`Category${value?.length > 0 ? ` (${value?.length})` : ''}`}
        only one can be selected at any point in time */}
        {`Categorie`}
        {/* <SvgIcon name="visibility" /> */}
      </button>
      {showModal && (
        <SortFilterModal {...{ ...props, value, setValue, setShowModal }} />
      )}
    </div>
  )
}

const SortFilterModal = (props) => {
  const { value, setValue, setShowModal, filter, id } = props
  const dispatch = useDispatch()
  return (
    <div 
      data-test-id='Ln1b'
      className={
        true ? "cdt-main relative-div" : "cdt-main standalone relative-div"
      }
    >
      <div data-test-id='bfHa'>
        <div data-test-id='mtS8' className="bg-white p3 mb6">
          <CategoryFilter {...props} noTopMargin />
        </div>

        <div data-test-id='1trL' className="filter-buttons">
          <button 
            data-test-id='OxJ7'
            onClick={() => {
              dispatch(setFilterSelection(id, null))
              setValue()
              setShowModal(false)
            }}
          >
            Anuleaza
          </button>
          <button 
            data-test-id='mg0J'
            onClick={() => {
              dispatch(setFilterSelection(id, value))
              setShowModal(false)
            }}
          >
            Salveaza
          </button>
        </div>
      </div>
    </div>
  )
}
export default VerticalCategoryFilter
