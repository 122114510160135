import { sanitizeAnythingForRedux } from "utils/preventReactInRedux"

const SET_FIREBASE_REALTIME_DB_FIELDS = "firebaseRealtimeDb/SET_FIREBASE_REALTIME_DB_FIELDS"


const initialState = {
  changingThisForcesRefresh: null,
  isMaintenanceModeOn: null, 
}

export default function (state = initialState, unsanitizedAction) {

  const action = sanitizeAnythingForRedux(unsanitizedAction)

  switch (action.type) {

    case SET_FIREBASE_REALTIME_DB_FIELDS:
      return {
        ...state,
        changingThisForcesRefresh: action?.payload?.changingThisForcesRefresh,
        isMaintenanceModeOn: action?.payload?.isMaintenanceModeOn,
      }

    default:
      return state
  }
}


export const setFirebaseRealtimeDbFields = data => ({
  type: SET_FIREBASE_REALTIME_DB_FIELDS,
  payload: data
})
