import { apiAction, reDispatch } from "store/middleware/api"
import {
  setCrtList,
  SET_CRT_LIST_ITEMS,
  ADD_CRT_LIST_ITEMS
} from "store/modules/page"
import { printError } from "utils/printError"
import store from "store/index"
import { loadOrganizationLists } from "store/justActions/lists"

const directMapping = [
  "uniprest/user",
  "product/goods/shop",
  "product/goods",
  "organization/webshop-client"
]
export const loadCrtListItems = (type, reload) =>
  reDispatch((dispatch) => {
    if (type === "mockCompanies") dispatch(setCrtList(mockCompanies))
    if (type === "mockProducts") dispatch(setCrtList(mockProducts))
    if (type === "mockUsers") dispatch(setCrtList(mockUsers))
    if (type === "dynamoComapnyLists")
      dispatch(loadOrganizationLists({ crtList: true }))
    if (type === "companies")
      dispatch(searchListStuff({ type: "organization/webshop-client", reload }))

    if (directMapping?.includes?.(type))
      dispatch(searchListStuff({ type, reload }))
    return { type: "a" }
  })

const img =
  "https://uniprest.compano.com/Data/Environments/000001/Attachment/Bijlage/Overzicht%20Product%20classifications/light%2087gr.jpg"

const mockUsers = {
  items: [
    { id: 1, name: "Andrei George", subtitle: "Manager Comapnie" },
    { id: 2, name: "Andrei Iorga", subtitle: "Manager Comapnie" },
    { id: 3, name: "Andrei Alexandru", subtitle: "Manager Comapnie" },
    { id: 11, name: "Georgescu George", subtitle: "Manager Comapnie" },
    { id: 12, name: "Georgescu Iorga", subtitle: "Manager Comapnie" },
    { id: 13, name: "Georgescu Alexandru", subtitle: "Manager Comapnie" },
    { id: 1, name: "Andrei George", subtitle: "Manager Comapnie" },
    { id: 2, name: "Andrei Iorga", subtitle: "Manager Comapnie" },
    { id: 3, name: "Andrei Alexandru", subtitle: "Manager Comapnie" },
    { id: 11, name: "Georgescu George", subtitle: "Manager Comapnie" },
    { id: 12, name: "Georgescu Iorga", subtitle: "Manager Comapnie" },
    { id: 13, name: "Georgescu Alexandru", subtitle: "Manager Comapnie" },
    { id: 1, name: "Andrei George", subtitle: "Manager Comapnie" },
    { id: 2, name: "Andrei Iorga", subtitle: "Manager Comapnie" },
    { id: 3, name: "Andrei Alexandru", subtitle: "Manager Comapnie" },
    { id: 11, name: "Georgescu George", subtitle: "Manager Comapnie" },
    { id: 12, name: "Georgescu Iorga", subtitle: "Manager Comapnie" },
    { id: 13, name: "Georgescu Alexandru", subtitle: "Manager Comapnie" }
  ]
}

const mockCompanies = {
  items: [
    {
      id: 1,
      name: "Company name 1",
      type: "Large",
      cui: "RO 111001",
      tags: ["TagA", "Custom1", "Tag-B"]
    },
    {
      id: 2,
      name: "Company name 2",
      type: "Large",
      cui: "RO 222002",
      tags: ["TagA", "Custom2", "Tag-B"]
    },
    {
      id: 3,
      name: "Company name 3",
      type: "Large",
      cui: "RO 333003",
      tags: ["TagA", "Custom3", "Tag-B"]
    },
    {
      id: 11,
      name: "Company name 11",
      type: "Reseller",
      cui: "RO A11001",
      tags: ["TagA", "Custom1", "Tag-B"]
    },
    {
      id: 12,
      name: "Company name 12",
      type: "Reseller",
      cui: "RO A22002",
      tags: ["TagA", "Custom2", "Tag-B"]
    },
    {
      id: 13,
      name: "Company name 13",
      type: "Reseller",
      cui: "RO A33003",
      tags: ["TagA", "Custom3", "Tag-B"]
    }
  ]
}
const mockProducts = {
  items: [
    {
      id: 1,
      img,
      name: "Product Name 1",
      type: "Moment Adezivi",
      cui: "RO 111001",
      tags: ["TagA", "Custom1", "Tag-B"]
    },
    {
      id: 2,
      img,
      name: "Product Name 2",
      type: "Moment Adezivi",
      cui: "RO 222002",
      tags: ["TagA", "Custom2", "Tag-B"]
    },
    {
      id: 3,
      img,
      name: "Product Name 3",
      type: "Moment Adezivi",
      cui: "RO 333003",
      tags: ["TagA", "Custom3", "Tag-B"]
    },
    {
      id: 11,
      img,
      name: "Product Name 11",
      type: "Velux",
      cui: "RO A11001",
      tags: ["TagA", "Custom1", "Tag-B"]
    },
    {
      id: 12,
      img,
      name: "Product Name 12",
      type: "Velux",
      cui: "RO A22002",
      tags: ["TagA", "Custom2", "Tag-B"]
    },
    {
      id: 13,
      img,
      name: "Product Name 13",
      type: "Velux",
      cui: "RO A33003",
      tags: ["TagA", "Custom3", "Tag-B"]
    }
  ]
}

export const searchListStuff = ({
  type = "product",
  sortAsc = true,
  sortField,
  firstRow = 0,
  pageSize = 25,
  searchTerm,
  reload
}) => {
  let state = store.getState()

  return apiAction({
    endPoint: `/${type}/search`,
    method: "POST",
    data: {
      pageSize,
      firstRow: reload ? 0 : state?.page?.crtList?.lastItemHint ?? 0,
      searchTerm: searchTerm ?? state?.page?.crtList?.searchString
    },
    onSuccess: (data, dispatch) => {
      // dispatch( setApiResponse(data))
      return {
        type: reload ? SET_CRT_LIST_ITEMS : ADD_CRT_LIST_ITEMS,
        payload: {
          items: data?.items,
          lastItemHint:
            data?.items?.length < pageSize
              ? null
              : Number(state?.page?.crtList?.lastItemHint) + data?.items?.length
        }
      }
    },
    onFailure: (error) => {
      printError("searchListStuff()", error)
      return {
        type: "a"
      }
    }
  })
}
