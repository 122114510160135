import SvgIcon from "components/SvgIcon/SvgIcon"
import { useEffect, useRef, useState } from "react"
import Select, { components, NonceProvider } from "react-select"
import AsyncSelect from "react-select/async"
import { withTheme } from "styled-components"
import CreatableSelect from "react-select/creatable"
import "./index.scss"
// import NewItemForm from 'views/dev/sorin/newItemForm'
import { useController } from "react-hook-form"
import MagicButton from "components/MagicButton/MagicButtonV2"
import { useDispatch, useSelector } from "react-redux"
import { setLoadingIndicator } from "store/modules/page"
import { deleteCustomerClient } from "store/justActions/miscApiInteractions"

import { Lang, lang, Translate, withLocalize } from "components/Lang/Lang"
export default function CustomSelect(props) {
  const { control } = props
  if (control) return <CustomSelectIntegrated {...props} />

  return <CustomSelectBase {...props} />
}

export const CustomCreatableSelect = (props) => {
  const { options, control } = props

  const processedOptions = options?.map?.((option) => ({
    value: option?.label,
    label: option?.label,
    originalValue: option?.value
  }))

  const propsWithProcessedOptions = {
    ...props,
    options: processedOptions,
    allowCustomOption: true
  }

  if (control) return <CustomSelectIntegrated {...propsWithProcessedOptions} />
  return <CustomSelectBase {...propsWithProcessedOptions} />
}

function CustomSelectIntegrated(props) {
  const { control, name } = props
  const {
    field: { value, onChange, onBlur }
  } = useController({
    name,
    control,
    defaultValue: ""
  })
  return <CustomSelectBase {...{ ...props, value, onChange, onBlur }} />
}

function CustomSelectBase(props) {
  const {
    inForm,
    className,
    control,
    data,
    disabled,
    error,
    fb,
    filter,
    icon,
    iconName,
    isClearable,
    isMulti,
    label,
    name,
    onChange,
    options,

    placeholder: inputPlaceholder,
    selected,
    type,
    value,

    onBlur,
    w,
    gridArea,
    onInputChange,
    inputValue,
    isAsync,
    getStuffForSelect,
    allLocationCase = false,
    allowCustomOption = false,
    onDeleteOption,
    //defaultOptions,
    ...rest
  } = props

  const defaultPlaceholder = <Lang value="customselect.select" />

  const placeholder =
    typeof inputPlaceholder === "string" && inputPlaceholder !== "null"
      ? inputPlaceholder
      : defaultPlaceholder

  
  const [addedCustomOption, setAddedCustomOption] = useState() 

  let actualOptions = (data ?? options) || []
  
  const optionsWithOneAdded = addedCustomOption
    ? [addedCustomOption, ...(actualOptions || [])]
    : actualOptions
    

  const getInitialAddedCustomOption = () => {
    if(!allowCustomOption) return null 
    if(!value) return null

    const isValueInOptions = actualOptions?.some?.(
      someOption => someOption?.value === value
    )
    if(isValueInOptions) return null
    return {value, label: value}
  }

  const initialAddedCustomOption = getInitialAddedCustomOption()

  useEffect(() => {
    if(initialAddedCustomOption) 
      setAddedCustomOption(initialAddedCustomOption)
  }, [value])


  const onCreateOption = (newOptionString) => {
    if (!newOptionString) setAddedCustomOption(null)

    const newOptionObj = {
      value: newOptionString,
      label: newOptionString
    }

    setAddedCustomOption(newOptionObj)
    onChange?.(newOptionString)
  }

  actualOptions =
    optionsWithOneAdded?.map?.((o) => ({
      value: o.id ?? o?.value,
      label: o.label
    })) || []

  const mainClass = className
    ? `${className} custom-select-flex`
    : `custom-select-flex`
  const ss = useRef("")
  const filterStyle = {
    container: (provided, state) => ({
      ...provided,
      width: w ?? (fb ? "auto" : 200),
      flexBasis: fb ?? 0
    }),
    control: (provided, state) => {
      let result = {
        ...provided
        // none of react-select's styles are passed to <Control />
      }
      if (state.hasValue && filter) {
        result.backgroundColor = "#EDF5FF"
        result.borderColor = "#D0E2FF"
      }
      return result
    },
    menuList: (provided, state) => ({
      ...provided,
      border: "1px solid #E0E0E0",
      borderRadius: 0,
      padding: 0,
      backgroundColor: "white"
    }),

    menu: (provided, state) => ({
      ...provided,
      left: filter ? 0 : 32,
      width: filter ? "100%" : "calc(100% - 32px)",
      boxShadow: "none",
      marginTop: filter ? 0 : 4,
      padding: filter ? 24 : 0,
      backgroundColor: filter ? "#F4F4F4" : "none"
    }),
    option: (provided, state) => ({
      ...provided,
      padding: "8px 16px"

      // width: "calc(100% - 32px)",
      // boxShadow: "none",
    }),
    indicatorSeparator: (provided, state) => {
      const opacity = isClearable && state.hasValue ? 1 : 0
      const backgroundColor = "#E0E0E0"
      const margin = "4px 0"
      return { ...provided, backgroundColor, opacity, margin }
    }
  }

  const ValueContainer = (props) => {
    return (
      <components.ValueContainer {...props}>
        {/* <EmojiIcon primaryColor={colourOptions?.[2]?.color} /> */}
        {/* {props.children?.[0]?.key
        ? props.children
        : <>
        <div data-test-id='EN8k'>{`props.`}</div>
        {props.children?.[1]}
        </>
      } */}
        {filter && props.isMulti ? (
          props?.children?.[0]?.key ? (
            props.children
          ) : (
            <>
              <div data-test-id='rbrD'>{`${placeholder} (${props.getValue().length})`}</div>
              {props.children?.[1]}
            </>
          )
        ) : (
          props.children
        )}
      </components.ValueContainer>
    )
  }

  const Control = (props) => {
    return (
      <components.Control {...props}>
        <>
          {(icon || iconName) && (
            <SvgIcon name={icon ?? iconName ?? "search"} />
          )}
          {props.children}
        </>
      </components.Control>
    )
  }

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        {/* <EmojiIcon primaryColor={colourOptions?.[2]?.color} /> */}
        <div data-test-id='D0Aa' className="btn-stroke-text-light-01 flex">
          <SvgIcon name="select down" />
        </div>
      </components.DropdownIndicator>
    )
  }

  const ClearIndicator = (props) => {
    return (
      <components.ClearIndicator {...props}>
        {isClearable ? (
          <div data-test-id='73Sh' className="btn-stroke-text-light-01 flex">
            <SvgIcon name="close" />
          </div>
        ) : (
          <div data-test-id='db3T' />
        )}
      </components.ClearIndicator>
    )
  }

  const Menu = (props) => {
    const menuRef = useRef(null)
    const [scrollPosition, setScrollPosition] = useState(0)
    const [selectedVal, setSelectedVal] = useState(
      props.getValue()?.map?.((item) => item?.value)
    )

    // menuRef && menuRef.current.scrollTo(0, scrollPosition);
    const optionClick = (item) => {
      setSelectedVal((p) =>
        props.isMulti ? toggleInArray(p, item?.value) : [item?.value]
      )
      setScrollPosition(menuRef.current.scrollTop)
    }

    useEffect(() => {
      if (menuRef.current) menuRef.current.scrollTop = scrollPosition
    }, [scrollPosition])

    return (
      <>
        {!allLocationCase ? (
          <components.Menu {...props}>
            {/* <EmojiIcon primaryColor={colourOptions?.[2]?.color} /> */}
            {!filter && props.children}
            {filter && (
              <>
                <div data-test-id='JFW9' className="option-list" ref={menuRef}>
                  {stringInArray(props.options, "label", ss.current)?.map?.(
                    (item, index) => (
                      <div 
                        data-test-id='MyuR'
                        className={`single-option ${
                          selectedVal?.includes?.(item?.value) ? "selected" : ""
                        }`}
                        key={index}
                        onClick={() => optionClick(item)}
                      >
                        {props.isMulti && (
                          <SimpleCheckbox
                            value={selectedVal?.includes?.(item?.value)}
                          />
                        )}
                        <div data-test-id='6sUN' className="label">{item?.label}</div>
                      </div>
                    )
                  )}
                </div>
                <div data-test-id='rldf' className="filter-buttons">
                  <button 
                    data-test-id='0CxL'
                    onClick={() => {
                      setSelectedVal([])
                      props.setValue([])
                    }}
                  >
                    Anuleaza
                  </button>
                  <button 
                    data-test-id='fmqu'
                    onClick={() =>
                      props.setValue(
                        props.options?.filter?.((item) =>
                          selectedVal?.includes?.(item?.value)
                        )
                      )
                    }
                  >
                    Salveaza
                  </button>
                </div>
              </>
            )}
          </components.Menu>
        ) : ss?.current?.length >= 3 && allLocationCase ? (
          <components.Menu {...props}>
            {/* <EmojiIcon primaryColor={colourOptions?.[2]?.color} /> */}
            {!filter && props.children}
            {filter && (
              <>
                <div data-test-id='qM9c' className="option-list" ref={menuRef}>
                  {stringInArray(props.options, "label", ss.current)?.map?.(
                    (item, index) => (
                      <div 
                        data-test-id='Zzy7'
                        className={`single-option ${
                          selectedVal?.includes?.(item?.value) ? "selected" : ""
                        }`}
                        key={index}
                        onClick={() => optionClick(item)}
                      >
                        {props.isMulti && (
                          <SimpleCheckbox
                            value={selectedVal?.includes?.(item?.value)}
                          />
                        )}
                        <div data-test-id='0cam' className="label">{item?.label}</div>
                      </div>
                    )
                  )}
                </div>
                <div data-test-id='7FGE' className="filter-buttons">
                  <button 
                    data-test-id='nGaD'
                    onClick={() => {
                      setSelectedVal([])
                      props.setValue([])
                    }}
                  >
                    Anuleaza
                  </button>
                  <button 
                    data-test-id='YBAT'
                    onClick={() =>
                      props.setValue(
                        props.options?.filter?.((item) =>
                          selectedVal?.includes?.(item?.value)
                        )
                      )
                    }
                  >
                    Salveaza
                  </button>
                </div>
              </>
            )}
          </components.Menu>
        ) : (
          <div data-test-id='jiyc'></div>
        )}
      </>
    )
  }

  const customOptionProps = allowCustomOption ? { Option: CustomOption } : {}

  const selectComponentProps = {
    onCreateOption,
    onDeleteOption,
    addedCustomOption,
    clearAddedCustomOption: () => setAddedCustomOption(null),
    className:
      "select-overwrites-1 " +
      (type === "small" ? "custom-select-small" : "") +
      (disabled ? " disabled" : ""),
    classNamePrefix: "crs",
    styles: type === "filter" ? filterStyle : undefined,
    components: {
      DropdownIndicator,
      ValueContainer,
      ClearIndicator,
      Control,
      Menu,
      ...customOptionProps
    },
    formatCreateLabel: (input) => `Salvează "${input}"`,
    onInputChange: onInputChange
      ? (e) => {
          onInputChange(e)
          ss.current = e
        }
      : (e) => (ss.current = e),
    rest,
    isClearable,
    isMulti,
    placeholder,
    onBlur,
    value: adaptValue(value ?? selected, actualOptions),
    options: actualOptions,
    onChange: (e) =>
      onChange?.(allowCustomOption ? e?.label : getSelectValues(e)),
    isDisabled: disabled
  }

  const selectComponent = allowCustomOption ? (
    <CreatableSelect {...selectComponentProps} />
  ) : (
    <Select {...selectComponentProps} />
  )

  const asyncSelectComponent = (
    <AsyncSelect
      cacheOptions
      defaultOptions
      loadOptions={(e) => getStuffForSelect(e)}
      className={
        "select-overwrites-1 " +
        (type === "small" ? "custom-select-small" : "") +
        (disabled ? " disabled" : "")
      }
      classNamePrefix="crs"
      styles={type === "filter" ? filterStyle : undefined}
      components={{
        // IndicatorSeparator: undefined,
        // DropdownIndicator: <SvgIcon name="down"/>
        DropdownIndicator,
        ValueContainer,
        ClearIndicator,
        Control,
        Menu
      }}
      onInputChange={
        onInputChange
          ? (e) => {
              onInputChange(e)
              ss.current = e
            }
          : (e) => (ss.current = e)
      }
      inputValue={inputValue ? inputValue : undefined}
      {...{ rest, isClearable, isMulti, placeholder, onBlur }}
      // defaultValue={[{ value: "ID4", label: "Option 4" }]}
      //value={adaptValue(value ?? selected, actualOptions)}
      //options={actualOptions}
      onChange={(e) =>
        onChange
          ? onChange(getSelectValues(e))
          : console.info("On change not defined; ", e)
      }
      //isDisabled={disabled}
    />
  )
  const title = error ? (
    <div data-test-id='aWTV' className="mb2 ptype-3 color-alert-red">{error}</div>
  ) : (
    <div data-test-id='wacy' className="mb2 ptype-3">{label}</div>
  )

  const mainDivStyle = {
    gridArea: gridArea ? (gridArea === true ? name : gridArea) : undefined
  }
  return (
    <>
      {inForm ? (
        <div 
          data-test-id='KTzd'
          className={`custom-select-wrapper input-${name} ${className ?? ""}`}
          style={mainDivStyle}
        >
          {title}
          {isAsync ? asyncSelectComponent : selectComponent}
        </div>
      ) : (
        selectComponent
      )}
    </>
  )
}

const getSelectValues = (e) => {
  if (!e) return undefined
  if (e?.value) return e?.value
  else return e?.map?.((item) => item?.value)
}
const toggleInArray = (array, value) =>
  array?.includes?.(value)
    ? array?.filter?.((item) => item !== value)
    : [...array || [], value]

const stringInArray = (array, keyName, string) =>
  array?.filter?.((item) => {
    if (!string) return true
    if (string?.length === 0) return true
    if (
      item?.[keyName]
        ?.toLowerCase?.()
        ?.replace?.(" ", "")
        ?.includes?.(string?.toLowerCase?.())
    )
      return true
    return false
  })
const SimpleCheckbox = (props) => {
  const iconName =
    props?.value === true ? "Checkbox_active" : "Checkbox_inactive"
  const style = {
    marginRight: 8,
    display: "flex",
    alignItems: "center"
  }
  return (
    <div data-test-id='zvye' style={style}>
      <SvgIcon name={iconName} />
    </div>
  )
}

const adaptValue = (value, options) => {
  // if (typeof value !== "object") return options
  return options?.filter?.((item) => value == item?.value)
}

const CustomOption = (props) => {
  const { innerProps, isDisabled, children, value } = props

  const dispatch = useDispatch()
  const [wasDeletePressed, setWasDeletePressed] = useState(false)

  const loadingId = `${value}-${innerProps?.id}`

  /**
   * Once the delete button is pressed, it enters into a loading
   * state (disabled). It never comes back to its' original state.
   * Once the delete API finishes and the Select option-list is
   * re-fetched and re-populated, this option should not exist anymore.
   */
  const isDeleteLoading = useSelector(
    (state) => state.page?.loadingIndicators?.[loadingId]
  )

  const onDeleteClicked = () => {
    setWasDeletePressed(true)

    // If the custom-added option is deleted:
    if (value && props?.selectProps?.addedCustomOption?.value === value) {
      props?.selectProps?.clearAddedCustomOption?.()
    } else {
      // If any other option is deleted:
      dispatch(setLoadingIndicator(loadingId, true))
      props?.selectProps?.onDeleteOption?.(value)
    }
  }

  if (isDisabled) return null
  if (wasDeletePressed) return null

  return (
    <div data-test-id='IhIw' className="custom-select-option-wrapper">
      <div data-test-id='zeKH' {...innerProps} className="custom-select-option-label">
        {children}
      </div>

      <MagicButton
        svg="Deletegrey"
        clear
        disabled={isDeleteLoading}
        loadingId={loadingId}
        onClick={onDeleteClicked}
      />
    </div>
  )
}
