import React, { useState } from "react"
import PropTypes from "prop-types"

export default function TabBar(props) {
  const { tabs, defaultTabIndex } = props
  const [selectedTab, setSelectedTab] = useState(
    defaultTabIndex ? defaultTabIndex : 0
  )
  const [content, setContent] = useState()

  React.useEffect(() => {
    if (
      selectedTab !== undefined &&
      selectedTab >= 0 &&
      selectedTab <= tabs?.length
    ) {
      setContent(tabs?.[selectedTab]?.content)
    }
  }, [tabs, selectedTab])

  function onTabClick(index) {
    setSelectedTab(index)
  }

  return (
    <div data-test-id='fpqf' className="tab-bar">
      <div data-test-id='DTzf' className="tabs">
        {tabs?.map?.((tab, index) => {
          return (
            <div 
              data-test-id='H25K'
              key={index}
              className={`tab ptype0 ${
                index === selectedTab ? "selected" : ""
              }`}
              onClick={() => onTabClick(index)}
              tabIndex={index + 1}
            >
              {tab.title}
            </div>
          )
        })}
      </div>
      {content !== undefined && content}
    </div>
  )
}

TabBar.propTypes = {
  callBack: PropTypes.func,
  defaulTabIndex: PropTypes.number,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired
    })
  )
}
