export const adminLeftMenu = [
  {
    name: "adminmenu.dashboard", //object object
    //svg: 'homepage',
    svg: "dashboard1",
    url: "/admin/dashboard",
    rejectedGroups: ["BO Sales", "Sales Manager", "Manager Region"]
  },

  {
    name: "adminmenu.users",
    svg: "manageusers",
    url: "/admin/user/dashboard",
    rejectedGroups: ["BO Sales", "Sales Manager", "Manager Region"],
    children: [
      {
        name: "adminmenu.dashboard",
        svg: "homepage",
        url: "/admin/user/dashboard",
        rejectedGroups: ["BO Sales", "Sales Manager", "Manager Region"]
      },
      {
        name: "adminmenu.userList", //'Conturi Clienti',
        svg: "menulist",
        url: "/admin/user/client/list",
        rejectedGroups: ["BO Sales", "Sales Manager", "Manager Region"]
      },
      {
        name: "adminmenu.rolList", //'Roles',
        svg: "resetpass",
        url: "/admin/user/role/list",
        rejectedGroups: ["BO Sales", "Sales Manager", "Manager Region"]
      },
      {
        name: "adminmenu.employeeUniprest",
        svg: "unipreststaff",
        url: "/admin/user/uni/list",
        rejectedGroups: ["BO Sales", "Sales Manager", "BO Sales"]
      }
    ]
  },

  {
    name: "adminmenu.organization",
    svg: "Business", //taken from design
    url: "/admin/company/dashboard", //???business
    children: [
      {
        name: "adminmenu.dashboard",
        svg: "homepage",
        url: "/admin/company/dashboard",
        rejectedGroups: ["BO Sales", "Sales Manager", "Manager Region"]
      },
      {
        name: "adminmenu.companyList", //'Toate Companiile',
        //svg: 'invoice',
        svg: "list1", //
        url: "/admin/company/client/list"
      },
      {
        name: "adminmenu.uniprestCont", //'Adrese Livrare',
        svg: "uniprestplus", //taken from design
        url: "/admin/company/accountrequests/list",
        rejectedGroups: ["BO Sales", "Sales Manager", "Manager Region"]
        //url: '/admin/company/delivery/list' //???
      },
      {
        name: "adminmenu.address",
        svg: "delivery",
        //url: '/admin/company/accountrequests/list'
        url: "/admin/company/delivery/list"
      },
      {
        name: "adminmenu.locations",
        svg: "maps",
        //url: '/admin/company/accountrequests/list'
        url: "/admin/company/towns/list",
        rejectedGroups: ["BO Sales", "Sales Manager", "Manager Region"]
      }
    ]
  },
  {
    name: "adminmenu.product",
    svg: "product",
    // icon: ManageUsers,
    url: "/admin/product/dashboard",
    children: [
      {
        name: "adminmenu.dashboard",
        svg: "homepage",
        url: "/admin/product/dashboard",
        rejectedGroups: ["BO Sales", "Sales Manager", "Manager Region"]
      },
      {
        name: "adminmenu.productList", //'B2B Items',
        //svg: 'invoice',
        svg: "uniprestglobalproducts",
        url: "/admin/product/b2b/list",
        rejectedGroups: ["BO Sales", "Sales Manager", "Manager Region"]
      },
      {
        name: "adminmenu.productRelations", //'B2B Items',
        //svg: 'invoice',
        svg: "adminlink",
        url: "/admin/product/b2brelations/list",
        rejectedGroups: ["BO Sales", "Sales Manager", "Manager Region"]
      },
      {
        name: "adminmenu.companoProducts", //'Compano',
        svg: "compano-products",
        url: "/admin/product/compano/list",
        rejectedGroups: ["BO Sales", "Sales Manager", "Manager Region"]
      },
      {
        name: "adminmenu.discount", //'Grila de discounturi',
        svg: "grila-discount",
        url: "/admin/product/discount/list",
        roForm: true,
        rejectedGroups: ["BO Sales", "Sales Manager", "Manager Region"]
      },
      {
        name: "adminmenu.price", //'Net nete',
        svg: "nete-nete",
        url: "/admin/product/net/list",
        roForm: true,
        rejectedGroups: ["BO Sales", "Sales Manager", "Manager Region"]
      },
      {
        name: "adminmenu.volumeDiscount", //'Discount Volum',
        svg: "volume-discount",
        url: "/admin/product/volume/list",
        rejectedGroups: ["BO Sales", "Sales Manager", "Manager Region"]
      },
      {
        name: "adminmenu.packages",
        svg: "discount-pachete",
        url: "/admin/product/pack/list",
        rejectedGroups: ["BO Sales", "Sales Manager", "Manager Region"]
      },
      {
        name: "adminmenu.matrix", //'Matrice Produse',
        svg: "kanban",
        url: "/admin/product/matrix/list",
        rejectedGroups: ["BO Sales", "Sales Manager", "Manager Region"]
      },
      {
        name: "adminmenu.badge", //'Badges',
        svg: "badge",
        url: "/admin/product/badge/list",
        rejectedGroups: ["BO Sales", "Sales Manager", "Manager Region"]
      },
      {
        name: "adminmenu.questions",
        svg: "airecommendations",
        url: "/admin/product/qa/list",
        rejectedGroups: ["BO Sales", "Sales Manager", "Manager Region"]
      },

      {
        name: "adminmenu.review",
        svg: "reviewstar",
        url: "/admin/product/review/list",
        rejectedGroups: ["BO Sales", "Sales Manager", "Manager Region"]
      },
      // {
      //   name: 'Star',
      //   svg: 'review-star',
      //   url: '/admin/product/review/list'
      // },
      // {
      //   name: 'WareHouse',
      //   svg: 'warehouse',
      //   url: '/admin/product/review/list'
      // },
      {
        name: "adminmenu.productDocument", //'Documente',
        svg: "product-document",
        url: "/admin/product/document/list",
        rejectedGroups: ["BO Sales", "Sales Manager", "Manager Region"]
      },
      {
        name: "adminmenu.listDeposit",
        svg: "Warehouse", //taken from design
        url: "/admin/product/warehouse/list", //???
        rejectedGroups: ["BO Sales", "Sales Manager", "Manager Region"]
      },
      {
        name: "adminmenu.outOfStock",
        svg: "Warning",
        url: "/admin/product/stockrequest/list"
      },
      {
        name: "adminmenu.priceless",
        svg: "Warning",
        url: "/admin/product/pricerequest/list"
      }
    ]
  },

  {
    name: "adminmenu.orders",
    svg: "invoicedollar", //taken from design
    url: "/admin/order/dashboard", //???
    children: [
      {
        name: "adminmenu.dashboard",
        svg: "homepage", //taken from design
        url: "/admin/order/dashboard", //???
        rejectedGroups: ["BO Sales", "Sales Manager", "Manager Region"]
      },
      {
        name: "adminmenu.orderList",
        svg: "list1", //taken from design
        url: "/admin/order/list" //???
      },
      {
        name: "adminmenu.invoices",
        svg: "invoice", //taken from design
        url: "/admin/order/invoice/list" //???
      }
    ]
  },

  {
    name: "adminmenu.predefinedLists",
    svg: "listmodels", //taken from design
    url: "/admin/admin-lists/dashboard", //???
    rejectedGroups: ["BO Sales", "Sales Manager", "Manager Region"],
    children: [
      {
        name: "adminmenu.dashboard",
        svg: "homepage", //taken from design
        url: "/admin/admin-lists/dashboard", //???
        rejectedGroups: ["BO Sales", "Sales Manager", "Manager Region"]
      },
      {
        name: "adminmenu.predefinedListList",
        svg: "list1", //taken from design
        url: "/admin/admin-lists/list", //???
        rejectedGroups: ["BO Sales", "Sales Manager", "Manager Region"]
      }
    ]
  },

  {
    name: "adminmenu.guest",
    svg: "webpage", //taken from design
    url: "/admin/guest/dashboard",
    rejectedGroups: ["BO Sales", "Sales Manager", "Manager Region"],
    children: [
      {
        name: "adminmenu.dashboard",
        svg: "homepage", //taken from design
        url: "/admin/guest/dashboard", //???
        rejectedGroups: ["BO Sales", "Sales Manager", "Manager Region"]
      },
      {
        name: "adminmenu.news",
        svg: "Documents3", //taken from design
        url: "/admin/guest/news/list", //???
        rejectedGroups: ["BO Sales", "Sales Manager", "Manager Region"]
      },
      {
        name: "adminmenu.tutorial",
        svg: "openbook", //taken from design
        url: "/admin/guest/tutorials/list", //???
        rejectedGroups: ["BO Sales", "Sales Manager", "Manager Region"]
      },
      {
        name: "adminmenu.announcesJob", //'Anunturi angajari',
        svg: "Jobs2", //taken from design
        url: "/admin/guest/job/list", //???
        rejectedGroups: ["BO Sales", "Sales Manager", "Manager Region"]
      },
      {
        name: "adminmenu.applicantsJob",
        svg: "jobs1", //taken from design
        url: "/admin/guest/candidate/list", //???
        rejectedGroups: ["BO Sales", "Sales Manager", "Manager Region"]
      },
      {
        name: "adminmenu.contactRequest", //'Cereri Contact',
        //svg: 'email', //taken from design
        svg: "sendemail",
        url: "/admin/guest/contact/list", //???
        rejectedGroups: ["BO Sales", "Sales Manager", "Manager Region"]
      },
      {
        name: "adminmenu.locationUniprest",
        svg: "maps", //taken from design
        url: "/admin/guest/location/list", //???
        rejectedGroups: ["BO Sales", "Sales Manager", "Manager Region"]
      }
    ]
  },

  // {
  //   name: 'Depozite',
  //   svg: 'Warehouse', //taken from design
  //   url: '/admin/warehouse/list', //???
  //   children: [
  //     {
  //       name: 'Lista Depozite',
  //       svg: 'Warehouse', //taken from design
  //       url: '/admin/warehouse/list' //???
  //     }
  //   ]
  // }

  // {
  //   name: "Cereri de oferta",
  //   subMenu: true,
  //   svg: "RFPList", //taken from design
  //   url: "/admin/offer/list", //??? RFPList
  // },
  // {
  //   name: "Proiecte",    subMenu: true,
  //   svg: "Projects", //taken from design
  //   url: "/admin/project/list", //??? RFPList
  // },
  // {
  //   name: "Stiri",
  //   svg: "Documents3", //taken from design
  //   url: "/admin/news/list",
  //   subMenu: true,
  //   rightTopButtons: [
  //     {
  //       name: "Dashboard",
  //       svg: "homepage",
  //       url: "/admin/news",
  //     },
  //     {
  //       name: "All Items",
  //       svg: "invoice",
  //       url: "/admin/news/list",
  //     },
  //   ]
  // },
  // {
  //   name: "Joburi",
  //   subMenu: true,

  //   svg: "Jobs2", //taken from design
  //   url: "list", //???
  // },

  // {
  //   name: "Pagini de prezentare",
  //   svg: "WebPage", //taken from design
  //   url: "/admin/pages", //???
  // },
  // {
  //   name: "Promotii",
  //   svg: "Promo", //taken from design
  //   url: "/admin/promo", //???
  // },
]

export const adminBottomMenu = [
  // {
  //   name: "The other thing",
  //   svg: "stats",
  //   url: "/admin/settings",
  // },
  // {
  //   name: 'Settings',
  //   svg: 'settings',
  //   url: '/admin/settings'
  // }
]

export const adminHRLeftMenu = [
  {
    name: "adminmenu.dashboard", //object object
    //svg: 'homepage',
    svg: "dashboard1",
    url: "/admin/dashboard",
    rejectedGroups: ["BO Sales", "Sales Manager", "Manager Region"]
  },

  {
    name: "adminmenu.guest",
    svg: "webpage", //taken from design
    url: "/admin/guest/dashboard",
    rejectedGroups: ["BO Sales", "Sales Manager", "Manager Region"],
    children: [
      {
        name: "adminmenu.announcesJob", //'Anunturi angajari',
        svg: "Jobs2", //taken from design
        url: "/admin/guest/job/list", //???
        rejectedGroups: ["BO Sales", "Sales Manager", "Manager Region"]
      },
      {
        name: "adminmenu.applicantsJob",
        svg: "jobs1", //taken from design
        url: "/admin/guest/candidate/list", //???
        rejectedGroups: ["BO Sales", "Sales Manager", "Manager Region"]
      },
    ]
  }
]

export const adminMarketingManagerLeftMenu = [
  {
    name: "adminmenu.dashboard", //object object
    //svg: 'homepage',
    svg: "dashboard1",
    url: "/admin/dashboard",
    rejectedGroups: ["BO Sales", "Sales Manager", "Manager Region"]
  },
  {
    name: "adminmenu.guest",
    svg: "webpage", //taken from design
    url: "/admin/guest/dashboard",
    rejectedGroups: ["BO Sales", "Sales Manager", "Manager Region"],
    children: [
      {
        name: "adminmenu.news",
        svg: "Documents3", //taken from design
        url: "/admin/guest/news/list", //???
        rejectedGroups: ["BO Sales", "Sales Manager", "Manager Region"]
      },
    ]
  }
]
