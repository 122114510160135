import { Redirect } from "react-router"
import { myLazy } from "utils/myLazy"

export const adminUserRoutes = [
  // {
  //   exact: true,
  //   path: "/admin/user/list", // API failure
  //   component: myLazy(() => import("../views/admin/user/userList")), // next || progress ===============================================
  // },

  {
    exact: true,
    path: "/admin/user/client/list",
    component: myLazy(() => import("../views/admin/user/userList")) //Localization done Balazs
  },

  {
    exact: false,
    path: "/admin/user/client/form",
    component: myLazy(() => import("../views/admin/user/userForm")) //Localization done Balazs
  },
  {
    exact: true,
    path: "/admin/user/uni/list",
    component: myLazy(() => import("../views/admin/angajati/List")) // DONE
  },
  //missing uniprest form
  {
    exact: false, //added for missing uniuser form => branch feature/istvan/admin/uniuser/form
    path: "/admin/user/uni/form",
    component: myLazy(() => import("views/admin/angajati/uniUserForm.jsx"))
  },
  {
    exact: false,
    path: "/admin/user/role/list",
    component: myLazy(() => import("../views/admin/rolesTable/rolesForm"))
  },

  {
    exact: false,
    path: "/admin/user/role/form",
    component: myLazy(() => import("../views/admin/user/roleForm"))
  },

  {
    exact: true,
    path: "/admin/user/dashboard",
    component: myLazy(() => import("../views/admin/unifiedDashboard")) //Localization done Balazs
  },

  {
    exact: false,
    path: "/admin/user",
    component: () => <Redirect to="/admin/user/dashboard" />
  }
]
