import PropTypes from "prop-types"
import { numberFormatter } from "utils/stringUtils"

export default function Price(props) {
  const { currency, prefixCurrency, value, trendPercent, label, row } = props

  return (
    <div data-test-id='Hq7f' className="flex column">
      {row.isSmallScreen && (
        <div data-test-id='Wmp2' className="teable-header default">{label}</div>
      )}
      <div data-test-id='6VCv' className={`comp-price-container`}>
        {currency && prefixCurrency && (
          <div data-test-id='Mmh2' className={`currency-container prefix`}>{currency}</div>
        )}
        {typeof value && (
          <div data-test-id='XU5B' className={`value-container`}>{numberFormatter(value)}</div>
        )}
        {currency && !prefixCurrency && (
          <div data-test-id='QFU9' className={`currency-container`}>{currency}</div>
        )}
        {trendPercent && (
          <div data-test-id='enda' className="trend-container">{`${trendPercent}%`}</div>
        )}
      </div>
    </div>
  )
}

Price.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currency: PropTypes.string,
  prefixCurrency: PropTypes.bool,
  trendPercent: PropTypes.number,
  label: PropTypes.string,
  row: PropTypes.object
}
