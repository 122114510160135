import { createSelector } from "reselect"
import {
  baseDataViewSelector,
  headerProcessor
} from "store/selectors/dataView/baseDataView.selector"
import { Lang, lang, Translate, withLocalize } from "components/Lang/Lang"
import { formatDateWTime } from "utils/dateUtils"
const templateHeader = [
  {
    type: "select"
  },
  {
    label: <Lang value="accountRequest.name" />,
    type: "default",
    urlPrefix: "/admin/company/accountrequests/form?id=",
    keys: {
      title: "name",
      tags: "roleTags"
    },
    staticProps: {
      tempSkinnyTitle: true,
      avatarBgColor: "red-1"
    }
  },
  {
    label: <Lang value="accountRequest.CUI" />,
    type: "default",
    urlPrefix: "/admin/company/accountrequests/form?id=",
    keys: {
      title: "companyName",
      tags: "nameTags"
    }
  },
  {
    label: <Lang value="accountRequest.creationDate" />,
    type: "default",
    keys: {
      title: "creationDate"
    },
    staticProps: {
      tempSkinnyTitle: true
    }
  },
  {
    label: <Lang value="jobappliactionform.adressMail" />,
    type: "default",
    keys: {
      title: "email"
    },
    staticProps: {
      tempSkinnyTitle: true
    }
  },
  {
    label: <Lang value="accountRequest.numberPhone" />,
    type: "default",
    keys: {
      title: "phone"
    },
    staticProps: {
      tempSkinnyTitle: true
    }
  },
  {
    label: <Lang value="articleForm.status" />,
    type: "pill",
    keys: {
      value: "statusValue",
      color: "statusColour"
    },
    staticProps: {
      type: "filled",

      bold: true
    }
  },
  {
    type: "moreActions",
    label: "Actiuni",

    actions: {
      primary: [
        { action: "edit", icon: "edit" },
        { action: "details", icon: "details" }
      ],
      secondary: [
        {
          label: <Lang value="accountRequest.delet" />,
          action: "dynamoDelete",
          icon: "trash"
        }
      ]
    }
  }
]

export const specificTableSelector = createSelector(
  baseDataViewSelector,
  (prevSelector) => {
    const rows = prevSelector.rows?.map?.((item) => {
      return {
        ...item,
        ...item?.values,
        nameTags: [
          {
            fontColor: "text-light-01",
            backgroundColor: "primary-5",
            value: `CUI: ${item?.values?.cui ?? ""}`
          }
        ],
        roleTags: [
          {
            fontColor: "text-light-01",
            backgroundColor: "base-5",
            value:
              item?.values?.role === "ID1" ? (
                <Lang value="accountRequest.admin" />
              ) : (
                <Lang value="accountRequest.edit" />
              )
          }
        ],
        creationDate: item?.cDate ? formatDateWTime(item?.cDate) : "-",
        
        statusValue:
          item?.values?.status === 3 ? (
            <Lang value="accountRequest.completed" />
          ) : item?.values?.status === 2 ? (
            <Lang value="accountRequest.work" />
          ) : (
            <Lang value="accountRequest.new" />
          ),
        statusColour:
          item?.values?.status === 3
            ? "grey-40"
            : item?.values?.status === 2
            ? "red-1"
            : "green-40"
      }
    })
    ?.sort?.((a, b) => {
      return (a?.cDate || 0) > (b?.cDate || 0) ? -1 : 1
    })

    const header = headerProcessor({ ...prevSelector, templateHeader })
    return { ...prevSelector, templateHeader, rows, header }
  }
)

export default specificTableSelector
