import React, { useContext } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import ThemeContext from "../../theme/themeProvider"
import SvgIcon from "components/SvgIcon/SvgIcon"
import ButtonLoadingStateInner from "components/MagicButton/ButtonLoadingStateInner"
import "./index.scss"
import MyLink from "components/MyLink"

export default function MagicButton(props) {
  const {
    label,
    active,
    selected,
    onClick,
    icon,
    svg,
    className,
    h,
    w,
    disabled = false,
    outline,
    p = 1,
    m = 0,
    mt,
    mb,
    badge,
    ml,
    mr,
    pl,
    pr,
    pt,
    pb,
    pa,
    popRight,
    popLeft, //used for toolTip text
    type = "primary",
    wrapText,
    tl,
    noFlex, //@TODO: required if it's in a felx:coll for height...
    url, //used if you want to go to a link
    loadingId
  } = props

  const theme = useContext(ThemeContext)
  const baseSpacing = theme.baseSpacing ?? 0.5 // if it's not in theme
  const loadingState = useSelector(
    (state) => state.page?.loadingIndicators?.[loadingId ?? "none"]
  )

  const computedLabel = label ?? (icon ? label : "") // escaped if there is no label or icon provided
  let containerStyle = {
    // padding: `${p * baseSpacing}rem`,
    marginTop: mt === "a" ? `auto` : `${(mt ?? m) * baseSpacing}rem`,
    marginBottom: mb === "a" ? `auto` : `${(mb ?? m) * baseSpacing}rem`,
    marginLeft: ml === "a" ? `auto` : `${(ml ?? m) * baseSpacing}rem`,
    marginRight: mr === "a" ? `auto` : `${(mr ?? m) * baseSpacing}rem`,
    padding: `${pa * baseSpacing}rem`,
    paddingTop: `${pt * baseSpacing}rem`,
    paddingBottop: `${pb * baseSpacing}rem`,
    paddingLeft: `${pl * baseSpacing}rem`,
    paddingRight: `${pr * baseSpacing}rem`,
    height: `${h * baseSpacing}rem`,
    width: computedLabel ? "auto" : `${h * baseSpacing}rem`
  }

  let computedContainerClass = "buttonContainerMainCTA"
  if (type === "secondary") computedContainerClass = "buttonContainerSecondary"
  if (type === "grey") computedContainerClass = "buttonContainerGrey"
  if (type === "grey-blue" || loadingState)
    computedContainerClass = "buttonContainerGrey blue"
  if (type === "green") computedContainerClass = "buttonContainerGreen"

  if (disabled) computedContainerClass += " disabled"

  if (outline) {
    computedContainerClass = "buttonContainerOutline"
    if (type === "grey") computedContainerClass += " grey"
    containerStyle.height = `calc(${h * baseSpacing}rem - 4px)`
  }
  if (type === "simple") computedContainerClass = "buttonSimple"
  if (type === "clear-green")
    computedContainerClass = "buttonSimple active-green"
  if (popRight) computedContainerClass += " button-tool-tip-right"
  if (popLeft) computedContainerClass += " button-tool-tip-left"

  if (selected || window.location.pathname === url || active)
    computedContainerClass += " selected"

  if (!computedLabel) containerStyle.flex = `0 0 ${h * baseSpacing}rem`
  if (computedLabel) containerStyle.flex = `1`
  if (!wrapText) containerStyle.whiteSpace = "break-spaces"
  if (tl) containerStyle.textAlign = "left"
  if (w) containerStyle.width = `${w * baseSpacing}rem`
  const imgStyle = {
    display: "flex",
    flex: `0 0 ${(h - 2 * p) * baseSpacing}rem`,
    height: `${(h - 2 * p) * baseSpacing}rem`,
    width: `${(h - 2 * p) * baseSpacing}rem`
  }
  if (noFlex) containerStyle.flex = undefined
  let computedOnClick = undefined
  if (url && window.location.pathname !== url && !active)
    computedOnClick = undefined

  if (onClick) computedOnClick = onClick

  let divProps = {
    "data-test-id": "4Om8",
    tooltip: popLeft ?? popRight,
    style: containerStyle,
    className: `${computedContainerClass} ${className ?? ""}`,
    //onClick: !disabled ? computedOnClick : undefined
    onClick:
      disabled || loadingState || !computedOnClick
        ? undefined
        : (e) => {
            computedOnClick()
            e?.stopPropagation?.()
          }
  }

  const buttonContent = (
    <>
      {icon && (
        <img 
          data-test-id='ttHh'
          onContextMenu={(e) => e?.preventDefault?.()}
          style={imgStyle}
          src={icon}
          alt="icon"
        />
      )}
      {svg && <SvgIcon name={svg} />}
      {computedLabel && <div data-test-id='I8f6' className="label">{computedLabel}</div>}
      {badge && <div data-test-id='YWXj' className="badge">{badge}</div>}
      {loadingState && <ButtonLoadingStateInner />}
    </>
  )

  if (url)
    return (url?.startsWith?.('http') || url?.startsWith?.('www'))
      ? <MyLink {...divProps} to={url}>{buttonContent}</MyLink>
      : <Link {...divProps} to={url}>{buttonContent}</Link>

  return <div data-test-id='U0Yw' {...divProps}>{buttonContent}</div>
}
