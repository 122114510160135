import { toastr } from "react-redux-toastr"
import SvgIcon from "components/SvgIcon/SvgIcon"

export const showMagicToaster = ({
  title,
  subtitle,
  type = "success",
  timeOut = 2500
}) => {


  const toastrOptions = {

    /**
     * Do not include any React component here, it goes through 
     * the redux save and restore process and it causes a fatal error when 
     * React tries to render it again later. 
     */

    timeOut, // by setting to 0 it will prevent the auto close
    
    // icon: (<SvgIcon name="Done" />), // You can add any component you want but note that the width and height are 70px ;)
    // onShowComplete: () => console.log('SHOW: animation is done'),
    // onHideComplete: () => console.log('HIDE: animation is done'),
    // onCloseButtonClick: () => console.log('Close button was clicked'),
    // onToastrClick: () => console.log('Toastr was clicked'),
    showCloseButton: true, // false by default
    closeOnToastrClick: true, // false by default, this will close the toastr when user clicks on it
    // this option will give you a func 'remove' as props

  }


  if (type === "success") toastr.success(title, subtitle, toastrOptions)
  if (type === "error") toastr.error(title, subtitle, toastrOptions)
  if (type === "warning") toastr.warning(title, subtitle, toastrOptions)
  if (type === "info") toastr.info(title, subtitle, toastrOptions)

}
