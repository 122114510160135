import { useSelector } from "react-redux"
import { Translate, withLocalize } from "react-localize-redux"
import { useEffect } from "react"
import useTranslations from "components/Lang/useTranslations"

const defaultLanguage = "RO"

export default withLocalize(function Localization(props) {
  const translations = useTranslations()
  const currentLanguage = useSelector(
    (state) => state?.auth?.userData?.role?.user?.language
  )

  //TODO PUT BACK LOADME LANGUAGE

  /** Until the localization API has responded, all translations
   * are considered missing. To avoid logging every missing translation
   * at such an early moment, disable the logging until the API has
   * actually responded. */
  const shouldLogMissingTranslations = translations?.isSuccess

  const onMissingTranslation = ({ translationId }) => {
    if (shouldLogMissingTranslations)
      console.log(`Missing translation for '${translationId}'`)

    return translationId
  }

  useEffect(() => {
    props.initialize({
      languages: [
        { name: "English", code: "EN" },
        { name: "Romanian", code: "RO" }
      ],
      options: {
        renderToStaticMarkup: false,
        renderInnerHtml: true,
        onMissingTranslation,
        defaultLanguage: defaultLanguage
      }
    })
  }, [])

  useEffect(() => {
    if (translations?.response) {
      const languageKeys = Object?.keys?.(translations?.response || {})
      // Add every language separately to react-localize-redux:
      languageKeys?.forEach?.((languageKey) =>
        props.addTranslationForLanguage(
          translations?.response?.[languageKey],
          languageKey
        )
      )
    }
  }, [translations])

  useEffect(() => {
    props.setActiveLanguage(currentLanguage ?? defaultLanguage)
  }, [currentLanguage])

  return null
})

export const lang = (value, props) => {
  //loc

  return <Translate id={value} data={props} />
}

export const Lang = ({ value, ...data }) => {
  //Loc

  return lang(value, data)
}

export { Translate } from "react-localize-redux"
export { withLocalize } from "react-localize-redux"
export { getTranslate } from "react-localize-redux"
