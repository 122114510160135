import React from 'react'
import { printError } from 'utils/printError'
import { isAppErrorRefreshAllowed, saveAppErrorRefreshTimestamp } from 'layouts/appErrorRefreshLogic'
import * as Sentry from "@sentry/react"

// TODO: (maybe) clear local storage? Or just redux?
// TODO: show a nicer UI
// TODO: (maybe) generate a unique random ID for each error, 
// and show it to the user while sending it to sentry

/**
 * Meant to be used as a wrapper around the entire app.
 * If there is an error, it will catch it, and try to refresh the page.
 * Such a refresh, however, is prevented if it was done recently 
 * (to avoid an infinite loop of refreshes).
 */
class AppErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { 
      hasError: false,
      randomErrorId: (Math.random() + 1)?.toString?.(36)?.substring?.(2)?.toUpperCase?.(),
    }
  }

  // Update state so the next render will show the fallback UI.
  static getDerivedStateFromError = error => {


    return { 
      ...this.state,
      hasError: true,
    }

  }
  

  componentDidCatch(error, errorInfo) {

    printError('AppErrorBoundary componentDidCatch() error: ', error)
    console.log(`AppErrorBoundary componentDidCatch() randomErrorId: ${this.state.randomErrorId}`)

    // Send the error to Sentry:
    Sentry.captureException(error)
    Sentry.captureMessage(`randomErrorId: ${this.state.randomErrorId}`)

    // Try to also send the errorInfo to Sentry:
    try{ Sentry.captureMessage(`AppErrorBoundary componentDidCatch() 
      errorInfo: ${JSON.stringify(errorInfo)}`) 
    }
    catch(_){} // we don't care if this fails
    
    if(isAppErrorRefreshAllowed()){
      
      logBeforeRefreshing(this.state.randomErrorId)
      saveAppErrorRefreshTimestamp()
      window?.location?.reload(true)

    }else{
      logPreventedRefresh({ 
        error, 
        errorInfo, 
        randomErrorId: this.state.randomErrorId 
      })
    }
  }


  render() {
    
    if (this.state.hasError) return <div data-test-id='Sxau' style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      maxHeight: '100vh',
      minHeight: '90vh',
      width: '100%',
      // fontSize: '1.5rem',
    }}>
      <span data-test-id='2CH6'>
        A apărut o eroare: 
        {' '}
        {this.state.randomErrorId}
      </span>
      
      <br data-test-id='2wFn' />
      Te rugăm să reîncarci pagina.
    </div>

    return this.props.children 
  
  }

}

const logBeforeRefreshing = randomErrorId => {

  const message = `AppErrorBoundary componentDidCatch() app 
    error ${randomErrorId} detected, refreshing the page to try to recover.`

  console.log(message)
  Sentry.captureMessage(message)

}

const logPreventedRefresh = ({ error, errorInfo, randomErrorId }) => {

  const message = `
    AppErrorBoundary componentDidCatch() ${randomErrorId} Preventing infinite 
    refresh loop (Not refreshing now because there was a refresh 
    already recently because appError). 
  `

  console.log(message, error, errorInfo)
  Sentry.captureMessage(message)

}


export default AppErrorBoundary
