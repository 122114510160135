import PillCell from "components/MagicTable/components/PillCell"
import TagCell from "components/MagicTable/components/TagCell"
import { titleClick } from "components/MagicTable/tableActions"
import SvgIcon from "components/SvgIcon/SvgIcon"
import { getInitialsFromName } from "utils/stringUtils"
import { setControlDVString, setDataViewId } from "store/modules/dataView"
import { useDispatch } from "store"
import { attachSizeQueryParamsXXS } from "utils/attachSizeQueryParams"

const DefaultCell = (props) => {
  const {
    title,
    subtitle,
    bgColor,
    svg,
    img,
    svgColor,
    svgClass,
    urlPrefix,
    twoLines,
    topTags,
    bottomTags,
    avatarBgColor,
    pill,
    tags,
    tempSkinnyTitle,
    uppercase,
    justAvatar,
    row,
    fontColor,
    pills,
    isSmallScreen,
    label
  } = props
  let topCell, bottomCell
  const dispatch = useDispatch()
  if (pill && title) bottomCell = <PillCell {...pill} />
  if (pill && subtitle) topCell = <PillCell {...pill} />

  if (tags) {
    const tagCells = (
      <div data-test-id='VT2v' className="tag-keeper">
        {tags?.map?.((item, index) => (
          <TagCell {...{ ...item, key: index }} />
        ))}
      </div>
    )
    if (title) bottomCell = tagCells
    else if (subtitle) topCell = tagCells
    else {
      topCell = (
        <div data-test-id='eNBZ'>
          <TagCell {...tags?.[0]} />
        </div>
      )
      bottomCell = (
        <div data-test-id='wiSQ'>
          <TagCell {...tags?.[1]} />
        </div>
      )
    }
  }
  const goToDetails = () => {
    dispatch(setControlDVString())
    dispatch(setDataViewId())
    setTimeout(() => titleClick(urlPrefix, row?.id, row?.SK, row?.sourceType), 100)
  }

  if (title && title !== "empty")
    topCell = (
      <>
        {isSmallScreen && <div data-test-id='tPq4' className="teable-header">{label}</div>}
        <div data-test-id='ZHHd' //remove this empty crap workaround
          className={`title ${tempSkinnyTitle ? "tempSkinnyTitle" : ""} ${
            twoLines ? "twoLines" : ""
          } ${uppercase ? "uppercase" : ""} ${urlPrefix ? "clickable" : ""}`}
          onClick={() => (urlPrefix ? goToDetails() : undefined)}
        >
          {title}
        </div>
      </>
    )

  if (subtitle)
    bottomCell = (
      <div 
        data-test-id='ahSC'
        className={`subtitle ${urlPrefix ? "clickable" : ""}`}
        onClick={() => (urlPrefix ? goToDetails() : undefined)}
      >
        {subtitle}
      </div>
    )

  if (topTags)
    topCell = (
      <div data-test-id='FYk3' className="tag-keeper">
        {topTags?.map?.((item, i) => (
          <TagCell {...{ ...item, key: `top-${i}` }} />
        ))}
      </div>
    )
  if (bottomTags)
    bottomCell = (
      <div data-test-id='PfLp' className="tag-keeper">
        {bottomTags?.map?.((item, i) => (
          <TagCell {...{ ...item, key: `bottom-${i}` }} />
        ))}
      </div>
    )
  return (
    <div 
      data-test-id='Vkbe'
      className={`table-default-cell ${
        bgColor ? "px4 center bg-" + bgColor : ""
      } ${fontColor ? "color-" + fontColor : "text-light-01"}`}
    >
      {avatarBgColor && (
        <div data-test-id='Cvay' className={`avatar bg-${avatarBgColor}`}>
          {getInitialsFromName(title)}
        </div>
      )}
      {svg && (
        <div 
          data-test-id='E2BP'
          className={`${svgClass ?? ""} mt1 mr2 ${
            svgColor ? "stroke-" + svgColor : "stroke-text-light-01"
          }`}
        >
          {" "}
          <SvgIcon name={svg} />{" "}
        </div>
      )}
      {img && (
        <div data-test-id='Usgh' className="imgwrap">
          <img 
            data-test-id='k5xd'
            onContextMenu={(e) => e?.preventDefault?.()}
            src={attachSizeQueryParamsXXS(img)}
            alt={`image-thumbnail`}
          />
        </div>
      )}
      {!justAvatar && (
        <div data-test-id='EVL4'>
          {row.isSmallScreen && (
            <div data-test-id='yoGx' className="teable-header default">{label}</div>
          )}
          {topCell}
          {bottomCell}
        </div>
      )}
    </div>
  )
}

export default DefaultCell
