import styled from "styled-components"
import { ImageChain } from "components/MagicTable/ImageChain/ImageChain"
import ReportProblem from "components/MagicTable/ReportProblem"
import SvgIcon from "components/SvgIcon/SvgIcon"
import { useDispatch } from "react-redux"
import {
  toggleSelectedItem,
  toggleSelectAllItems,
  toggleSort,
  setControlDVString
} from "store/modules/dataView"
import MoreActions from "components/MagicTable/components/MoreActions"
import { MultiActionsModal } from "components/MagicTable/components/MultiActionsModal"
import { useSelector } from "store"
import ProgressBar from "componentsK3/ProgressBar"
import Deadline from "componentsK3/Deadline"
import Pill from "componentsK3/Pill"
import CircleAvatarsCell from "components/MagicTable/cells/CircleAvatarsCell"
import Price from "componentsK3/Price"
import TagCell from "components/MagicTable/components/TagCell"
import { CellListItems } from "componentsK3"
import DefaultCell from "components/MagicTable/components/DefaultCell"
import { useEffect } from "react"
import { loadViewData } from "store/modules/dataView/dataView-dataLoader"
import Rating5StarsCell from "components/MagicTable/cells/Rating5StarsCell"
import MagicButton from "components/MagicButton/MagicButtonV2"
import { CartProductDetailsCell } from "views/shoppingCart/cartProductCard"
import CartProductCard from "views/shoppingCart/cartProductCard"
// import uniqueID from "utils/uniqueId";
// import MagicButton from "components/MagicButtonV2";
import useWindowSize from "utils/useWindowsSize"
import MagicInput from "components/MagicInput/MagicInpuv2"
import { Lang, lang, Translate, withLocalize } from "components/Lang/Lang"

const Container = styled.div`
  /* display: grid; //moved to CSS (magicTable/max-inline-size.scss)
  grid-template-columns: ${(props) => `repeat(${props.headerLength}, auto)`}; */
  grid-row-gap: ${(props) => `${props.rowsGap}rem`};
  overflow-x: ${(props) => (props.overflowX ? "scroll" : "hidden")};
  overflow-y: hidden;
  > div {
    //:nth-child(n+2)
    /* background-color: white; // @TODO: put this in parameters some how.. */
    > div:nth-last-child(${(props) => `-n+${props.repeatCollumns}`}) {
      min-height: ${(props) => `${props.rowHeight}rem`}; //was height
      border-bottom: 1px solid #ccc;
    }
    /* display: flex; */
    /* align-items: center; */
  }
  > div:nth-child(${(props) => `${props.headerLength}n+1`}) {
    padding-left: ${(props) =>
      `${(props.pr ?? props.px) * 0.5 ?? props.cellPadding}rem`};
  }
  > div:nth-child(${(props) =>
        `${props.headerLength}n+${props.headerLength}`}) {
    padding-right: ${(props) =>
      `${(props.pr ?? props.px) * 0.5 ?? props.cellPadding}rem`};
  }
  > div {
    border-bottom: 1px solid #ccc;
    padding-right: ${(props) => `${props.cellPadding}rem`};
  }
  > div:nth-last-child(${(props) => `-n+${props.headerLength}`}) {
    border-bottom: none;
  }
`

const cellTypes = {
  select: (headerSection, row, compKey, isSmallScreen) => (
    <CheckBoxSwitch
      key={compKey}
      {...getCellProps(headerSection, row, isSmallScreen)}
    />
  ),
  moreActions: (headerSection, row, compKey, isSmallScreen) => (
    <MoreActions
      key={compKey}
      {...getCellProps(headerSection, row, isSmallScreen)}
    />
  ),
  default: (headerSection, row, compKey, isSmallScreen) => (
    <DefaultCell
      key={compKey}
      {...getCellProps(headerSection, row, isSmallScreen)}
    />
  ),
  customComponent: (headerSection, row, compKey, isSmallScreen) => {
    const CustomComponent = headerSection?.customComponent
    return (
      <CustomComponent
        key={compKey}
        {...getCellProps(headerSection, row, isSmallScreen)}
      />
    )
  },
  imageChain: (headerSection, row, compKey, isSmallScreen) => (
    <ImageChain
      key={compKey}
      {...getCellProps(headerSection, row, isSmallScreen)}
    />
  ),
  progressBar: (headerSection, row, compKey, isSmallScreen) => (
    <ProgressBar
      key={compKey}
      {...getCellProps(headerSection, row, isSmallScreen)}
    />
  ),
  deadLine: (headerSection, row, compKey, isSmallScreen) => (
    <Deadline
      key={compKey}
      {...getCellProps(headerSection, row, isSmallScreen)}
    />
  ),
  pill: (headerSection, row, compKey, isSmallScreen) => (
    <Pill key={compKey} {...getCellProps(headerSection, row, isSmallScreen)} />
  ),
  avatars: (headerSection, row, compKey, isSmallScreen) => (
    <CircleAvatarsCell
      key={compKey}
      {...getCellProps(headerSection, row, isSmallScreen)}
    />
  ),
  price: (headerSection, row, compKey, isSmallScreen) => (
    <Price key={compKey} {...getCellProps(headerSection, row, isSmallScreen)} />
  ),
  tag: (headerSection, row, compKey, isSmallScreen) => (
    <TagCell
      key={compKey}
      {...getCellProps(headerSection, row, isSmallScreen)}
    />
  ),
  listItems: (headerSection, row, compKey, isSmallScreen) => (
    <CellListItems
      key={compKey}
      {...getCellProps(headerSection, row, isSmallScreen)}
      limit={2}
    />
  ),
  stars: (headerSection, row, compKey, isSmallScreen) => (
    <Rating5StarsCell
      key={compKey}
      {...getCellProps(headerSection, row, isSmallScreen)}
      limit={2}
    />
  ),
  greenSqare: (headerSection, row, compKey, isSmallScreen) => (
    <SquareColorButton
      key={compKey}
      {...getCellProps(headerSection, row, isSmallScreen)}
      className="btn-green"
    />
  ),
  greySqare: (headerSection, row, compKey, isSmallScreen) => (
    <SquareColorButton
      key={compKey}
      {...getCellProps(headerSection, row, isSmallScreen)}
      className="btn-grey"
    />
  ),
  reportProblem: (headerSection, row, compKey, isSmallScreen) => (
    <ReportProblem
      key={compKey}
      {...getCellProps(headerSection, row, isSmallScreen)}
    />
  ),
  cartProductCard: (headerSection, row, compKey, isSmallScreen) => (
    <CartProductCard
      key={compKey}
      {...getCellProps(headerSection, row, isSmallScreen)}
    />
  ),
  cartProductDetails: (headerSection, row, compKey, isSmallScreen) => (
    <CartProductDetailsCell
      key={compKey}
      {...getCellProps(headerSection, row, isSmallScreen)}
    />
  ),
  currencyUnitMeasureBox: (headerSection, row, compKey, isSmallScreen) => (
    <CurrencyUnitMeasureBox
      key={compKey}
      {...getCellProps(headerSection, row, isSmallScreen)}
    />
  )
}

// format={'ddd, DD MMM YYYY, h:mm'}
// const dateString = moment(props.date).format(props.format) // move this into selector

const getCellProps = (headerSection, row, isSmallScreen) => {
  let props = {}
  for (const key in headerSection?.keys) {
    props[key] = row?.[headerSection?.keys?.[key]]
  }
  if(headerSection?.passAllKeys && headerSection?.clickNotAllowed) props = { ...props, ...row, clickNotAllowed: true}
  if (headerSection?.passAllKeys) props = { ...props, ...row }
  props.row = { ...row, label: headerSection.label, isSmallScreen }
  // props.urlPrefix = headerSection?.urlPrefix
  return { ...props, ...headerSection, ...headerSection?.staticProps }
}

export const MagicTable = (props) => {
  const rowLength = props.rows?.length
  const {
    header,
    rows,
    className,
    miltiSelectLength,
    lastItemHint, //from selector
    noLoadMore,
    noHeader,
    cellPadding = 1.5,
    rowHeight = 4,
    rowsGap = 0,
    emptyComponent, //displayed when rows is empty
    noCall
  } = props
  const dispatch = useDispatch()
  const viewId = useSelector((state) => state?.dataView?.viewId)
  const sort = useSelector((state) => state?.dataView?.sort)
  const selectedFilters = useSelector(
    (state) => state?.dataView?.selectedFilters
  )
  const searchString = useSelector((state) => state?.dataView?.searchString)

  const url = window.location.pathname //console.log("URL ", url)
  const guestSideUrls = [
    "/shop/lists/details",
    "/shop/lists",
    "/shop/invoices",
    "/shop/orders"
  ]
  const windowSize = useWindowSize()
  useEffect(() => {
    if (!noCall) {
      dispatch(setControlDVString("not-shop"))
      dispatch(loadViewData({ reload: true }))
    }
  }, [sort, noCall, searchString, selectedFilters])
  let repeatCollumns = header?.length
  if (guestSideUrls?.includes?.(url)) {
    if (windowSize.width < 570 && url === "/shop/orders") {
      if (header?.length % 2 === 0)
        repeatCollumns = Math.floor(header?.length / 4)
      else repeatCollumns = Math.floor(header?.length / 4) + 1
    } else if (windowSize.width <= 930) {
      if (header?.length % 2 === 0)
        repeatCollumns = Math.floor(header?.length / 2)
      else repeatCollumns = Math.floor(header?.length / 2) + 1
    }
  }
  const containerProps = {
    cellPadding,
    rowHeight,
    rowsGap: guestSideUrls?.includes?.(url) ? 1 : rowsGap,
    repeatCollumns,
    headerLength: header?.length,
    overflowX: true, //!(guestSideUrls?.includes?.(url) && url),
    className: "magic-table-wrapper",
    style: {},
    ...props
  }
  const isSmallScreen =
    guestSideUrls?.includes?.(url) && windowSize.width <= 930
  if (
    isSmallScreen &&
    guestSideUrls?.includes?.(url) &&
    header?.length % 2 !== 0
  ) {
    if (url === "/shop/lists/details") header?.splice?.(2, 0, {})
    else header?.push?.({})
  }

  if (header?.find?.((item) => item?.type === "select")) {
    containerProps.className += " with-select"
    repeatCollumns--
  }
  if (header?.find?.((item) => item?.type === "moreActions")) {
    if (containerProps.className?.includes?.("with-select"))
      containerProps.className += " with-select-more-actions"
    else containerProps.className += " with-more-actions"
    repeatCollumns--
  }
  containerProps.style["--repeat-collums"] = repeatCollumns
  //containerProps.style["--responsive-repeat-border"] = "2"//+${repeatCollumns}`

  const createCellInRow = (row, headerItem, colIndex, rowIndex) => {
    const computedCellType =
      headerItem?.type ?? row?.[headerItem?.keys?.cellType] ?? "default"
    return (
      // <div data-test-id='7JUj' key={row.id + colIndex} className={`line-${row?.id} cell-${colIndex}`}>
      <div 
        data-test-id='GRgq'
        key={`row-${rowIndex}_col-${colIndex}`}
        className={`line-${row?.id} cell-${colIndex}`}
      >
        {cellTypes?.[computedCellType](
          headerItem,
          row,
          `${row.id}_${colIndex}`,
          isSmallScreen
        )}
      </div>
    )
  }

  return (
    <>
      <div 
        data-test-id='A2EG'
        className={`table-wrapper rows-${rowLength} ${
          className ? className : ""
        }`}
      >
        <Container {...containerProps}>
          {!noHeader && !isSmallScreen && (
            <div data-test-id='T3K4' className="header">
              {header?.map?.((headerItem, index) => (
                <HeaderCell key={`header-${index}`} {...headerItem} />
              ))}
            </div>
          )}
          {rows?.map?.((row, rowIndex) => (
            <div 
              data-test-id='Ru8s'
              key={`row-${rowIndex}`}
              className={`row ${
                guestSideUrls?.includes?.(url) ? "guest" : ""
              } ${row.selected ? "selected" : ""}`}
            >
              {header?.map?.((headerItem, colIndex) =>
                createCellInRow(row, headerItem, colIndex, rowIndex)
              )}
            </div>
          ))}
          {(!rows || rows?.length === 0) && emptyComponent}
        </Container>
        {miltiSelectLength > 0 && <MultiActionsModal {...props} />}
      </div>
      {(lastItemHint && !noLoadMore) 
        ? (
          <MagicButton
            mt={3}
            outline
            w={100}
            label={<Lang value="magictable.loadMore" />}
            onClick={() => dispatch(loadViewData())}
          />
        )
        : null
      }
    </>
  )
}

export const HeaderCell = ({ type, selected, label, sort }) => {
  const dispatch = useDispatch()
  const sortState = useSelector((state) => state?.dataView?.sort)
  // const moreActionsCell = <div data-test-id='z6hD' className="more-actions">{label}</div>
  const sortCell = (
    <>
      <div data-test-id='SaZI'>{label}</div>
      <div data-test-id='zYDR' className="sort-part">
        <div 
          data-test-id='WWOz'
          className={
            sortState?.[0] === sort && sortState?.[1] === true
              ? "up active"
              : "up"
          }
          onClick={() => dispatch(toggleSort([sort, true]))}
        >
          <SvgIcon name="smallup" />
        </div>
        <div 
          data-test-id='1aTt'
          className={
            sortState?.[0] === sort && sortState?.[1] === false
              ? "down active"
              : "down"
          }
          onClick={() => dispatch(toggleSort([sort, false]))}
        >
          <SvgIcon name="smalldown" />
        </div>
      </div>
    </>
  )
  return (
    <div data-test-id='gUse' className={`teable-header ${type}`}>
      {type === "select" ? (
        <CheckBoxSwitch header selected={selected} />
      ) : sort ? (
        sortCell
      ) : (
        label
      )}
    </div>
  )
}

export const SquareColorButton = ({ label, className }) => {
  return (
    <div data-test-id='HzjC' className="status">
      <button data-test-id='xUih' className={`btn ${className}`}>{label}</button>
    </div>
  )
}
export const CurrencyUnitMeasureBox = ({
  right,
  value,
  narrow,
  isEdit,
  onItemChange
}) => {
  return (
    <div data-test-id='pSAZ' className="flex alignCenter">
      <MagicInput
        tableRendered={true}
        narrow={narrow}
        className="svg-nostroke"
        value={value}
        outline
        disabled={!isEdit}
        onChange={onItemChange}
        rightComponent={<RightBox label={right} />}
      />
    </div>
  )
}
const RightBox = (props) => {
  const { label } = props
  return <div data-test-id='7ZMS' className="right-box">{label}</div>
}
const CheckBoxSwitch = (props) => {
  const { row, header, selected } = props
  const dispatch = useDispatch()
  const iconCondition = header ? selected : row.selected
  const iconName =
    iconCondition === false
      ? "Checkbox_inactive"
      : iconCondition === null
      ? "CheckboxMultiDeselect"
      : "Checkbox_active"
  return (
    <div 
      data-test-id='f4PY'
      onClick={() =>
        dispatch(header ? toggleSelectAllItems() : toggleSelectedItem(row.id))
      }
    >
      <SvgIcon
        className={`check-box-wrapper ${
          iconName !== "Checkbox_inactive" ? "selected" : ""
        }`}
        name={iconName}
      />
    </div>
  )
}

export default MagicTable
