const loginErrorToastMessages = {
  FAILURE: 'Utilizatorul sau parola introduse sunt incorecte. Te rugăm să verifici și să încerci din nou.',
  PW_INVALID: 'Utilizatorul sau parola introduse sunt incorecte. Te rugăm să verifici și să încerci din nou.',
  LOCKED: 'Contul tău a fost blocat din motive de securitate, deoarece ai introdus parola greșit de mai multe ori. Te rugăm să ne contactezi la digital@uniprest.ro pentru a debloca contul și pentru asistență suplimentară.', 
  'error.LOCKED': 'Contul tău a fost blocat din motive de securitate, deoarece ai introdus parola greșit de mai multe ori. Te rugăm să ne contactezi la digital@uniprest.ro pentru a debloca contul și pentru asistență suplimentară.', 
  'error.SUSPENDED': 'Contul tău este momentan suspendat. Te rugăm să ne contactezi la digital@uniprest.ro pentru mai multe informații sau pentru a rezolva această situație.', 
  'error.NO_ROLES': 'Rolul tău nu este setat. Te rugăm să ne contactezi la digital@uniprest.ro pentru asistență suplimentară.', 
}

export const getLoginErrorToastMessage = (error) => {
  const responseBody = error?.response?.data
  const responseCode = responseBody?.result || responseBody?.localizationCode
  return loginErrorToastMessages?.[responseCode] || 'A apărut o eroare. Te rugăm să încerci din nou.'
}
