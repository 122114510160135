import MagicInput from "components/MagicInput/MagicInput"
// import ColumnsReorderDragAndDrop from "views/dev/select/ReorderColumnsDragDrop/ColumnsReorderDragAndDrop"
import SvgIcon from "components/SvgIcon/SvgIcon"
import { useRef, useState } from "react"
import useWindowSize from "utils/useWindowsSize"
import CustomSelectFilter from "views/dev/select/CustomSelectFilter"
import { DatePickerFilter } from "views/dev/select/DatePicker"
// import HorizontalFilterWrapper from "views/dev/select/HorizontalFilterWrapper"
import { useSelector } from "store"
import { selectComputedFilters } from "store/selectors/dataView/finalFilters.selector"

import "./HorizontalFilterUniprest.scss"
import { ColumnSortFilter } from "views/dev/select/ColumnSortFilter"
// import filter from "views/invoices/InvoiceComponents/FilterSection/Filterdata"
import { useDispatch } from "react-redux"
import { setDVsearchString, setLastAddedToCart, setMultipleFilterSelection } from "store/modules/dataView"
import { toggleViewType } from "store/modules/shop"
import MagicButton from "components/MagicButton/MagicButton"
import VerticalCategoryFilter from "views/dev/select/VerticalCategoryFilter"
import { useEffect } from "react"
import { loadViewData } from "store/modules/dataView/dataView-dataLoader"
import MegaCategoryFilter from "components/MegaCategoryFilter"
import {
  setControlDVString,
  setCheckFilters,
  setCheckSearchString,
  setFilterSelection
} from "store/modules/dataView"
import { setScrollPostion } from "store/modules/page"
import useQueryStringFilters, { sortByToUrlFriendly } from "utils/useQueryStringFilters"
import { withLocalize } from "components/Lang/Lang"
import { hideMobileKeyboard } from "utils/hideKeyboardOnMobile"

// import  {Lang, lang, Translate, withLocalize } from "components/Lang/Lang"

// const data = [
//   { id: 'c', label: 'Chocolate' },
//   { id: 's', label: 'Strawberry' },
//   { id: 'v', label: 'Vanilla' },
//   { id: 'c1', label: 'Chocolate' },
//   { id: 's1', label: 'Strawberry' },
//   { id: 'v1', label: 'Vanilla' },
//   { id: 'c2', label: 'Chocolate' },
//   { id: 's2', label: 'Strawberry' },
//   { id: 'v2', label: 'Vanilla' },
//   { id: 'c3', label: 'Chocolate' },
//   { id: 's3', label: 'Strawberry' },
//   { id: 'v3', label: 'Vanilla' },
//   { id: 'c4', label: 'Chocolate' },
//   { id: 's4', label: 'Strawberry' },
//   { id: 'v4', label: 'Vanilla' },
//   { id: 'c5', label: 'Chocolate' },
//   { id: 's5', label: 'Strawberry' },
//   { id: 'v5', label: 'Vanilla' },
// ]
//   cosnt userInfo = useSelector(state => state.user.info)

// const filters = [
//   { fb: 200, data, placeholder: 'Brand ... ' },
//   { fb: 200, data, placeholder: 'Brand ... ', isMulti: true },
//   { fb: 200, data, placeholder: 'Brand ... ', isMulti: true, isClearable: true },
//   { fb: 200, data, placeholder: 'Brand ... ', selected: 'c4' },
//   { fb: 200, data, placeholder: 'Brand ... ' },
//   { fb: 200, data, placeholder: 'Brand ... ' },
// ]
const HorizontalFilterUniprest = (props) => {
  const {
    title,
    filterIds,
    showSelectedFilters,
    showSavedFilters,
    templateHeader,
    className,
    noMore, //disables more filters button
    wideSearch, //makes the seatch fullwidth
    noMargins,
    cardButton, //display list toggle button
    translate,
    noCallOnSearchString = false
  } = props
  const {
    searchString,
    page, 
    filters: qsFilters,
    sortBy,
    setSearchString,
    setPage,
    setQsSortBy,
  } = useQueryStringFilters()


  const nrProductsPerPage = useSelector(state => state?.dataView?.items?.pageSize || 25)

  const [showMoare, setShowMoare] = useState(Boolean(noMore))
  const [typingTimer, setTypingTimer] = useState(null)

  useEffect(() => {
    if(noMore) setShowMoare(true)
  },[noMore])

  const previousQueryStringRef = useRef()

  useEffect(() => {

    if (!previousQueryStringRef.current && searchString) {
      // When the first letter is typed into the text-based search, we switch to sort by relevance.
      dispatch(setFilterSelection("FLT-sort00p1", ["relevance|false"]))
    }
    if (previousQueryStringRef.current && !searchString) {
      // When the text-based search-term is cleared, we switch back to the default sort by.
      dispatch(setFilterSelection("FLT-sort00p1", ["ranking|true"]))
    }
    previousQueryStringRef.current = searchString

  }, [searchString])


  const ref = useRef()
  const inputRef = useRef()
  const size = useWindowSize(ref)
  const dispatch = useDispatch()
  //const firstRender = useRef(1);
  const { filters, appliedFilters } = useSelector((state) =>
    selectComputedFilters(state)
  )
  // const searchString = useSelector(state => state?.dataView?.searchString)
  const listType = useSelector((state) => state?.shop?.viewType)
  const viewId = useSelector((state) => state?.dataView?.viewId)
  const sort = useSelector((state) => state?.dataView?.sort)
  const searchTerm = searchString
  const lastItemHint = useSelector((state) => state?.dataView?.lastItemHint)
  const controlPageViewId = useSelector(
    (state) => state?.dataView?.controlPageViewId
  )
  const selectedFilters = useSelector(
    (state) => state?.dataView?.selectedFilters
  )
  const checkFilters = useSelector((state) => state?.dataView?.checkFilters)
  const checkSearchString = useSelector(
    (state) => state?.dataView?.checkSearchString
  )
  // useEffect(() => {
  //   dispatch(loadViewData({qsFilters, searchTerm, reload:true}))
  // }, [appliedFilters, dispatch])
  const delayConfig = 500
  //log each render

  const selectedSortBy = selectedFilters?.["FLT-sort00p1"]

  useEffect(() => {
    // Set the URL to reflect selected sortBy:
    setQsSortBy(sortByToUrlFriendly(selectedSortBy));
  }, [selectedSortBy])
  

  useEffect(() => {
    //timer reset, in case it was started before
    if (typingTimer) {
      clearTimeout(typingTimer)
      setTypingTimer(null)
    }
    //starts the timer, and after a preconfigured delay it will trigger the search dispatch
    const timer = setTypingTimer(
      setTimeout(() => {
        // if(viewId !== "java-product-client") {//console.log("CHECK ADMIN RENDER")
        //   dispatch(loadViewData({qsFilters, searchTerm, reload:true}))
        //   dispatch(setControlDVString(viewId))
        //   dispatch(setCheckFilters(selectedFilters ? selectedFilters : {}))
        //   dispatch(setCheckSearchString(searchString ? searchString : ""))
        //   //dispatch(setFilterSelection())
        //   dispatch(setScrollPostion(0))
        //   dispatch(setLastAddedToCart())
        // }
        // TODO test if viewId !== controlPageViewId comdition resolves loading stuff for onbehalf
        const filtersCheckCondition =
          JSON.stringify(checkFilters) !== JSON.stringify(selectedFilters) ||
          //force it to make dispatch
          qsFilters?.["FLT-CATEG001"]?.[0] ||
          qsFilters?.["FLT-SMaaaa00"]?.[0]

        if(!noCallOnSearchString)
          dispatch(loadViewData({ 
            qsFilters, 
            searchTerm, 
            reload: true, 
            page, 
          }))
          dispatch(setControlDVString(viewId))
          // dispatch(setCheckFilters(selectedFilters ? selectedFilters : {}))//*** */
          //THE LINE BELOW IS IMPORTANT, EXCEL IMPORT AND DYNAMO RELATED LISTS USES THIS FOR FILTERING PRODUCTS
          dispatch(setCheckSearchString(searchString ? searchString : ""))
          //dispatch(setFilterSelection())
          // dispatch(setScrollPostion(0))//*** */
          // dispatch(setLastAddedToCart())//*** */


      }, delayConfig)
    )
    return () => {
      clearTimeout(timer)
    }
  }, [
    dispatch,
    searchString,
    appliedFilters,
    qsFilters,
    selectedFilters,
    viewId,
    sort,
    page,

    /**
     * COMMENTED OUT BECAUSE it ruined the Load More functionality.
     * Now, however, when navigating away from the page and back,
     * the items are cleared (unfortunately) and the loading starts
     * from the beginning, with only 25 items.
     */
    // lastItemHint,


    //controlPageViewId
  ])
  //console.log( "SS ", searchString, " CS ", checkSearchString, " SF ", selectedFilters, " CSF ", checkFilters)

  let displayFilters = filterIds
    ? filters?.filter?.((item) => filterIds?.includes?.(item?.id))
    : filters
  if (size?.width < 450)
    displayFilters = displayFilters?.filter?.((i) => i.type !== "mega-category")
  if (cardButton) displayFilters?.push?.({ type: "cardButton", fb: 50 })
  let width = 0 // the filter container padding and the search and the more button
  let maxFilterIndex = 0
  displayFilters?.forEach?.((item, index) => {
    width += item.fb ?? 200 //16 is the padding item.type = "mega-category" && size < 440 ? 0 :
    if (width < size?.width) maxFilterIndex = index //if unpitrest show in one row.
  })
  // const title = 'Tabel title comes here'

  const searchInputProps = {
    value: searchString || '',
    onChange: (e) => {
      setSearchString(e?.target?.value || '');
    },
    onBlur: () => size?.width < 650 && hideMobileKeyboard(inputRef),
    // onChange: e => dispatch(setDVsearchString(e?.target?.value)),
    placeholder: translate?.("volumeForm.search")
  }

  const textSearchFilter = (
    <div data-test-id='u1TM' key="search" className={`search ${wideSearch ? "wideSearch" : ""}`}>
      <MagicInput {...searchInputProps} outline h={10} ref={inputRef}/>
    </div>
  )

  const moreFiltersButton = (
    <div data-test-id='CphD' key="more" className={"btn-holder"}>
      <button 
        data-test-id='bbxI'
        className={`btn-dashed ${showMoare ? "less" : "more"}`}
        onClick={() => setShowMoare((p) => !p)}
      >
        {/* {showMoare ? "Hide" : "More"}
        {" filters"} */}
         {showMoare ? "Ascunde" : "Mai multe"}
        {" filtre"}
        <SvgIcon name={"arrowdown"} />
      </button>
    </div>
  )

  const cardButtonComp = (
    <div data-test-id='QItJ' key="card-button" className="border-grey-40">
      <MagicButton
        h={6}
        mt=""
        mr=""
        type="simple"
        svg={listType === "cards" ? "ListView" : "CardsView"}
        onClick={() => dispatch(toggleViewType())}
      />
    </div>
  )

  return (
    <>
      {title && <div data-test-id='uYFi' className="tabel-title">{title}</div>}
      <div 
        data-test-id='85Zx'
        className={`bg-white filter-row ${className ?? ""} ${
          showMoare && !noMore ? "filter-active" : ""
        } ${noMargins ? "no-margins" : ""}`}
      >
        <div data-test-id='mK0A' className="hfsv2" ref={ref}>
          <div data-test-id='bCNI' className="filter-wrapper">
            {displayFilters?.map?.(
              (filter, index) =>
                (index <= maxFilterIndex || showMoare) &&
                (filter?.type === "textSearch" ? (
                  textSearchFilter
                ) : filter?.type === "columnSort" ? (
                  <ColumnSortFilter
                    {...{ ...filter, templateHeader, key: index }}
                  />
                ) : filter?.type === "dateOne" ? (
                  <DatePickerFilter
                    {...{ ...filter, key: index }}
                    iconName={"calendar-today-outlined"}
                  />
                ) : filter?.type === "multiSelect" ? (
                  <CustomSelectFilter
                    {...{ ...filter, key: index }}
                    isMulti
                    type="filter"
                    filter
                  />
                ) : filter?.type === "selectOne" ? (
                  <CustomSelectFilter
                    {...{ ...filter, key: index, resetPage: filter?.id === "FLT-sort00p1" }}
                    type="filter"
                    filter
                  />
                ) : filter?.type === "selectOneDate" ? (
                  <CustomSelectFilter
                    {...{ ...filter, key: index }}
                    type="filter"
                    filter
                    onChange={(e) => dispatch(setFilterSelection(filter.id, e))}
                  />
                ) : filter?.type === "category" ? (
                  <VerticalCategoryFilter {...{ ...filter, key: index }} />
                ) : filter?.type === "mega-category" ? (
                  <MegaCategoryFilter {...{ ...filter, key: index }} />
                ) : filter?.type === "applicability" ? (
                  <CustomSelectFilter
                    {...{ ...filter, key: index, label: filter?.placeholder }}
                    type="filter"
                    filter
                  />
                ) : filter?.type === "status" ? (
                  <CustomSelectFilter
                    {...{ ...filter, key: index, label: filter?.placeholder }}
                    type="filter"
                    filter
                  />
                ) : filter?.type === "permissionName" ? (
                  <CustomSelectFilter
                    {...{ ...filter, key: index, label: filter?.placeholder }}
                    isMulti
                    type="filter"
                    filter
                  />
                ) : filter?.type === "cardButton" ? (
                  cardButtonComp
                ) : null)
            )}
            {/* {cardButton && cardButtonComp} */}
          </div>

          {displayFilters?.length - 1 !== maxFilterIndex &&
            !noMore &&
            moreFiltersButton}
        </div>
      </div>
    </>
  )
}

export default withLocalize(HorizontalFilterUniprest)

// const reorderColumns = [
//   {
//     id: 1,
//     title: "Cel mai lung nume de coloana A",
//     disabled: false,
//   },
//   {
//     id: 2,
//     title: "Cel mai lung nume de coloana B",
//     disabled: true,
//   },
//   {
//     id: 3,
//     title: "Cel mai lung nume de coloana C",
//     disabled: false,
//   },
//   {
//     id: 4,
//     title: "Cel mai lung nume de coloana D",
//     disabled: false,
//   },
//   {
//     id: 5,
//     title: "Cel mai lung nume de coloana E",
//     disabled: false,
//   }
// ]
