import React from "react"

// import Menu from "assets/MenuIcon/Atom/Icons/Menu.svg";
// import User from ".assets/MenuIcon/Atom/Icons/User.svg";
// import CartEmpty from ".assets/MenuIcon/Atom/Icons/CartEmpty.svg";
// import ALERT from ".assets/MenuIcon/Atom/Icons/ALERT.svg";

import LeftOpenMenu from "components/LeftOpenMenu"
import RightOpenMenu from "components/RightOpenMenu"
import { useState } from "react"
import MagicButton from "components/MagicButton"
import SideMenuClosed from "components/SideMenuClosed/SideMenuClosedV2"
import Footer from "components/Footer/Footer"
import MenuSearch from "views/dev/gyula/MenuSearch"
import { useHistory } from "react-router"
import { useSelector, useDispatch } from "store"
// import { useSelector } from '.store';

import { isIOS } from "react-device-detect"
import { selectTotalItems } from "store/selectors/shopPage.selector"
// import { shoppingCartSelector } from "store/selectors/dataView/dataView.productsListFinal.selector";
import { useUserInfo } from "utils/useUserInfo"
import { getInitialsFromName } from "utils/stringUtils"
import { setScrollPostion } from "store/modules/page"
import HomePageLogoButton from "components/customButtons/HomePageLogoButton"
import { Lang, lang, Translate, withLocalize } from "components/Lang/Lang"
import { Link } from "react-router-dom"
import SvgIcon from "components/SvgIcon/SvgIcon.jsx"
import useIsLogged from "utils/useIsLogged"
import { campaignConfig, isCampaignPartiallyActive } from "views/shoppingCart/useCampaignInfo"
import MyLink from "components/MyLink"
import { isCampaignActive } from "utils/dateUtils"
import ShopLayoutFloatingButton from "layouts/shop/ShopLayoutFloatingButton.jsx"


function ShopLayout({ children, translate }) {
  const [showLeftMenu, setShowLeftMenu] = useState(false)
  const [showRightMenu, setShowRightMenu] = useState(false)
  const [showHamburger, setShowHamburger] = useState(false)
  // const shoppingCart = useSelector(state => state.shop.cart)
  const { totalCartItems } = useSelector((state) => selectTotalItems(state))
  // const authenticatedState = useSelector(state => state.auth?.authenticated)
  // const companyType = useSelector(state => state.company?.type)
  // const { products } = useSelector((state) => shoppingCartSelector(state))
  const { groupId, isPublic, companyType, name } = useUserInfo()
  // const productsInCart = useSelector((state) => state.shop?.cartData?.lineList)
  const productsInCart = useSelector((state) => state.shop?.cartData?.productCount)
  const userFullName = useSelector((state) =>
    state?.user?.name?.split?.(" ")?.slice?.(-1)?.join?.(" ")
  )
  // const storedScrollPosition = useSelector(state => state?.page?.setScrollPosition)
  const yPageScrollPos = useSelector((state) => state?.page?.yPageScrollPos)
  const userPermissions = useSelector(
    (state) => state.auth?.userData?.role?.permissions
  )

  const history = useHistory()
  // const cart = useSelector(state => state.shop.cart)
  const dispatch = useDispatch()
  const isCampaignPeriod = isCampaignPartiallyActive()
  const isLogged = useIsLogged()

  const leftHandler = (hide) => {
    setShowLeftMenu((e) => hide ? false : !e)
    setShowRightMenu(false)
    setShowHamburger((e) => hide ? false : !e)
  }

  const rightHandler = (hide) => {
    setShowRightMenu((e) => hide ? false : !e)
    setShowLeftMenu(false)
    setShowHamburger(false)
  }

  const cartButtonClick = () => {
    dispatch(setScrollPostion(yPageScrollPos))
    setTimeout(() => history?.push?.("/shop/cart"), 20)
  }
  return (
    <div data-test-id='RNsT' className={`app theme-default ${isIOS ? "ios" : ""}`}>
      <header data-test-id='v6Bw' className="topMenu">
        <div data-test-id='xA8q' className="left">
          <button 
            data-test-id='n5r1'
            type="button"
            name="hamburger"
            className={`hamburger ${showHamburger ? "active" : ""}`}
            onClick={() => leftHandler()}
          >
            <span data-test-id='hiit' />
          </button>
          <HomePageLogoButton {...{ companyType }} />
        </div>
        {companyType !== "GUEST" && (
          <MenuSearch
            palceholder={`${translate?.(
              "menusearch.placeholder"
            )} ${userFullName}`}
          />
        )}
        <nav data-test-id='fZFT' className="right">
          {companyType !== "GUEST" && (
            <MagicButton
              h={10}
              // p={3}
              svg={totalCartItems ? "cart not empty" : "cartEmpty"}
              // badge={productsInCart?.length ? productsInCart?.length : "0"}
              badge={productsInCart || "0"}
              type="clear-green"
              //url="/shop/cart"
              activeColor="green"
              //className="hideOnSmall"
              onClick={cartButtonClick}
              disabled={!userPermissions?.includes?.("BASKET_WRITE")}
            />
          )}
          {companyType !== "GUEST" && (
            null

            /**
             * This entire bell icon button (aka alert or notification)  
             * has been deactivated as per the request of the client.
             */
            
            /*
            <MagicButton
              h={10}
              svg={"bell"}
              type="simple"
              url="/shop/alerts"
              className="hideOnSmall"
            />
            */
          )}
          {companyType !== "GUEST" && (
            <MagicButton
              h={10}
              svg={"openbook"}
              type="simple"
              url="/shop/tutorials"
              className="hideOnSmall"
            />
          )}
          {companyType === "GUEST" && (
            <MagicButton
              className={`${showRightMenu ? "close-icon" : ""}`}
              h={10}
              // p={3}
              svg={`${showRightMenu ? "close" : "User"}`}
              type="grey"
              onClick={() => rightHandler()}
            />
          )}
          {companyType !== "GUEST" && (
            <div data-test-id='tGJV' className="logged-in-button" onClick={() => rightHandler()}>
              <div data-test-id='UO1S' className="inner-circle">{getInitialsFromName(name)}</div>
            </div>
          )}
        </nav>
      </header>
      <SideMenuClosed left leftHandler={leftHandler} rightHandler={rightHandler}/>
      <SideMenuClosed right leftHandler={leftHandler} rightHandler={rightHandler}/>
      <LeftOpenMenu visible={showLeftMenu} leftHandler={leftHandler} className={"shop"} />
      <RightOpenMenu visible={showRightMenu} className={"shop"} />
      

      {/* Please do not change the class-name pageContent as 
      it is needed in by the pagination (in the shop) to 
      scroll to the top when navigating to another page. */}

      <div data-test-id='rLoL' className="pageContent page-flex-column">
        
        <ShopLayoutFloatingButton />
        <main data-test-id='ndQ5' className="">{children}</main>
        <Footer />
        {/* <main data-test-id='NM5t'>{children}</main>
        <Footer /> */}
      </div>
    </div>
  )
}

export default withLocalize(ShopLayout)
