import { createSelector } from "reselect"

const productCategory = (state) => state.backData?.productCategory
const selectedFilters = (state) => state?.dataView?.selectedFilters

export const filtersCategoriesSelector = createSelector(
  selectedFilters,
  productCategory,
  (selectedFilters, productCategory) => {
    const selectedCategory = [
      selectedFilters?.["FLT-PCATEG00"],
      selectedFilters?.["FLT-PCATEG01"],
      selectedFilters?.["FLT-PCATEG02"]
    ]
    const category0 = productCategory?.map?.((item) => ({
      ...item,
      id: item?.treeCode,
      label: item?.name,
      childList: undefined
    }))
    const category1 = []?.concat
      .apply(
        [],
        productCategory
          ?.filter?.((item) => selectedCategory?.[0]?.includes?.(item?.treeCode))
          ?.map?.((item) => {
            return [...item?.childList]
          })
      )
      ?.map?.((item) => ({ ...item, id: item?.treeCode, label: item?.name }))
    const category2 = []?.concat
      .apply(
        [],
        category1
          ?.filter?.((item) => selectedCategory?.[1]?.includes?.(item?.treeCode))
          ?.map?.((item) => [...item?.childList])
      )
      ?.map?.((item) => ({ ...item, id: item?.treeCode, label: item?.name }))
    return {
      "FLT-PCATEG00": category0,
      "FLT-PCATEG01": category1,
      "FLT-PCATEG02": category2
    }
  }
)
