export const FILE_TYPE = {
  IMAGE: "IMAGE",
  OTHER: "OTHER",
  AVATAR: "AVATAR",
  VIDEO: "VIDEO",
  DOCUMENT: "DOCUMENT"
}

export const FILE_ACTION = {
  ADD: "ADD",
  REMOVE: "REMOVE"
}

export const PERMISSION = {
  READ: "read",
  WRITE: "write",
  DELETE: "delete"
}

export const GENERAL_STATUS = {
  ACTIVE: {
    title: "Activ",
    backgroundColor: "all-good"
  },
  INACTIVE: {
    title: "Inactiv",
    backgroundColor: "button-inactive"
  }
}
