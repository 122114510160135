// import React, { Suspense } from 'react';
import React from "react"

// import { ReactComponent as Add } from 'assets/icons/add.svg'
// import { ReactComponent as Close } from 'assets/general/Close.svg'
// import { ReactComponent as Edit } from 'assets/icons/Checkbox_multi-deselect.svg'
// import { ReactComponent as Expand } from 'assets/general/Expand.svg'
// import { ReactComponent as List } from 'assets/MenuIcon/SideIcons/List.svg'
// import { ReactComponent as Profile1 } from 'assets/icons/Profile1.svg'
// import { ReactComponent as ProfilePic } from 'assets/iconsK3/profile_pic.svg'
// import { ReactComponent as Right } from 'assets/dashboard/Icons/Right.svg'
// import {ReactComponent as Business } from 'assets/MenuIcon/Atom/Icons/Business.svg'
// import {ReactComponent as Cartempty } from 'assets/MenuIcon/Atom/Icons/CartEmpty.svg'
// import {ReactComponent as Listmodels } from 'assets/MenuIcon/Atom/Icons/Listmodels.svg'
// import {ReactComponent as Support } from 'assets/MenuIcon/Atom/Icons/Support.svg'
// import {ReactComponent as Warehouse } from 'assets/MenuIcon/Atom/Icons/Warehouse.svg'

import { ReactComponent as Collapse } from "assets/icons/Collapse.svg"
import { ReactComponent as GreenCheckbox } from "assets/icons/green-checkbox.svg"
import { ReactComponent as StockYellow } from "assets/icons/stock-yellow.svg"
import { ReactComponent as StockRed } from "assets/icons/stock-red.svg"
import { ReactComponent as Add } from "assets/MenuIcon/newIcons/Add.svg"
import { ReactComponent as Addtolist } from "assets/MenuIcon/SideIcons/Add_to_list.svg"
import { ReactComponent as AI } from "assets/icons/AI.svg"
import { ReactComponent as Airecommendations } from "assets/MenuIcon/SideIcons/AI_recommendations.svg"
import { ReactComponent as Alert } from "assets/dashboard/Icons/Alert.svg"
import { ReactComponent as Alert1 } from "assets/icons/Alert.svg"
import { ReactComponent as AlertTriangle } from "assets/general/Alert.svg"
import { ReactComponent as Apps } from "assets/icons/Apps.svg"
import { ReactComponent as ArrowDown } from "assets/general/ArrowDown.svg"
import { ReactComponent as ArrowDownBlue } from "assets/general/ArrowDownBlue.svg"
import { ReactComponent as ArrowRight } from "assets/dashboard/Icons/Right-1.svg"
import { ReactComponent as BackArrow } from "assets/general/BackArrow.svg"
import { ReactComponent as Badge } from "assets/icons/Badge.svg"
import { ReactComponent as Bell } from "assets/MenuIcon/Atom/Icons/ALERT.svg"
import { ReactComponent as Block } from "assets/icons/Block.svg"
import { ReactComponent as Business } from "assets/MenuIcon/SideIcons/Business.svg"
import { ReactComponent as Calendar } from "assets/icons/Calendar.svg"
import { ReactComponent as CalendarTodayOutlined } from "assets/general/CalendarTodayOutlined.svg"
import { ReactComponent as Call } from "assets/dashboard/Icons/Call.svg"
import { ReactComponent as CardsView } from "assets/ProductsList/CardsView.svg"
import { ReactComponent as CardsViewWhite } from "assets/ProductsList/CardsViewWhite.svg"
import { ReactComponent as Career } from "assets/MenuIcon/Atom/Icons/Career.svg"
import { ReactComponent as CartEmpty } from "assets/MenuIcon/Atom/Icons/CartEmpty.svg"
import { ReactComponent as CartNotEmpty } from "assets/general/CartNotEmpty.svg"
import { ReactComponent as Cash } from "assets/icons/Cash.svg"
import { ReactComponent as CheckboxActive } from "../../assets/general/Checkbox_active.svg"
import { ReactComponent as CheckboxActiveK3 } from "assets/iconsK3/CheckboxActive.svg"
import { ReactComponent as CheckboxOk } from "../../assets/icons/CheckboxOk.svg"
import { ReactComponent as CheckboxGrey } from "../../assets/icons/CheckboxGrey.svg"
import { ReactComponent as CheckboxInactive } from "../../assets/general/Checkbox_inactive.svg"
import { ReactComponent as CheckboxInactiveK3 } from "assets/iconsK3/CheckboxInactive.svg"
import { ReactComponent as CheckboxInactiveSolid } from "assets/icons/CheckboxInactiveSolid.svg"
import { ReactComponent as CheckboxIssues } from "assets/dashboard/Icons/Checkbox_issues.svg"
import { ReactComponent as CheckboxIssuesSolid } from "assets/icons/CheckboxIssuesSolid.svg"
import { ReactComponent as CheckboxIssuesWarning } from "assets/icons/CheckboxIssuesWarning.svg"
import { ReactComponent as CheckboxRestricted } from "assets/icons/CheckboxRestricted.svg"
import { ReactComponent as CheckboxMultiDeselect } from "assets/icons/Checkbox_multi-deselect.svg"
import { ReactComponent as CheckboxMultiDeselectK3 } from "assets/iconsK3/CheckboxMultiDeselect.svg"
import { ReactComponent as Close } from "assets/general/Close-big.svg"
import { ReactComponent as CloseK3 } from "assets/iconsK3/Close.svg"
import { ReactComponent as CloseRound } from "assets/general/CloseRound.svg"
import { ReactComponent as CloseWhite } from "assets/icons/CloseWhite.svg"
import { ReactComponent as CompanoProducts } from "assets/icons/CompanoProducts.svg"
import { ReactComponent as Copy } from "assets/icons/Copy.svg"
import { ReactComponent as Dashboard1 } from "assets/MenuIcon/newIcons/dashboard1.svg"
import { ReactComponent as Deals } from "assets/MenuIcon/Atom/Icons/Deals.svg"
import { ReactComponent as Deals2 } from "assets/MenuIcon/SideIcons/Deals2.svg"
import { ReactComponent as Delete } from "assets/general/Delete.svg"
import { ReactComponent as Cancel } from "assets/general/cancel.svg"
import { ReactComponent as Deletegrey } from "assets/general/Deletegrey.svg"
import { ReactComponent as Delivery } from "assets/shop/delivery.svg"
import { ReactComponent as Details } from "assets/icons/Details.svg"
import { ReactComponent as DiscountPachete } from "assets/icons/DiscountPachete.svg"
import { ReactComponent as DocumentCopy } from "assets/iconsK3/DocumentCopy.svg"
import { ReactComponent as Documents } from "assets/MenuIcon/Atom/Icons/Documents.svg"
import { ReactComponent as Documents2 } from "assets/MenuIcon/Atom/Icons/Documents2.svg"
import { ReactComponent as Documents3 } from "assets/MenuIcon/Atom/Icons/Documents3.svg"
import { ReactComponent as Documents4 } from "assets/icons/Documents3.svg"
import { ReactComponent as Done } from "assets/dashboard/Icons/Done.svg"
import { ReactComponent as Done2 } from "assets/icons/Done2.svg"
import { ReactComponent as Down } from "assets/dashboard/Icons/Down.svg"
import { ReactComponent as Download } from "assets/dashboard/Icons/Download.svg"
import { ReactComponent as EditClear } from "assets/icons/EditClear.svg"
import { ReactComponent as Edit } from "assets/MenuIcon/Atom/Icons/Edit.svg"
import { ReactComponent as Email } from "assets/dashboard/Icons/Email.svg"
import { ReactComponent as Expand } from "assets/MenuIcon/Atom/Icons/Expand.svg"
import { ReactComponent as ExpandClear } from "assets/MenuIcon/Atom/Icons/ExpandClear.svg"
import { ReactComponent as Expand1 } from "assets/icons/expand.svg"
import { ReactComponent as Flame } from "assets/icons/flame.svg"
import { ReactComponent as Ventilation } from "assets/icons/Ventilatie.svg"
import { ReactComponent as Apa } from "assets/icons/Apa.svg"
import { ReactComponent as GreenEnergy } from "assets/icons/GreenEnergy.svg"
import { ReactComponent as Gaz } from "assets/icons/Gaz.svg"
import { ReactComponent as Lichidare } from "assets/icons/lichidare.svg"
import { ReactComponent as ListBack } from "assets/icons/listback.svg"
import { ReactComponent as Sanitare } from "assets/icons/Sanitare.svg"
import { ReactComponent as Termice } from "assets/icons/Termice.svg"
import { ReactComponent as GreenOk } from "assets/iconsK3/greenOk.svg"
import { ReactComponent as GreyCloseK3 } from "assets/iconsK3/GreyClose.svg"
import { ReactComponent as GrilaDiscount } from "assets/icons/GrilaDiscount.svg"
import { ReactComponent as Heart } from "assets/icons/heart.svg"
import { ReactComponent as Heartblue } from "assets/icons/heartblue.svg"
import { ReactComponent as Heartbluedark } from "assets/icons/heartbluedark.svg"
import { ReactComponent as HeroImage } from "assets/iconsK3/HeroImage.svg"
import { ReactComponent as Historyrecommendations } from "assets/MenuIcon/SideIcons/History_recommendations.svg"
import { ReactComponent as Homepage } from "assets/MenuIcon/Atom/Icons/HomePage.svg"
import { ReactComponent as HorizontalLine } from "assets/iconsK3/horizontalLine.svg"
import { ReactComponent as HyperLink } from "assets/icons/Link.svg"
import { ReactComponent as IcoAdd } from "assets/icons/ico_add.svg"
import { ReactComponent as Id } from "assets/MenuIcon/Atom/Icons/ID.svg"
import { ReactComponent as InfoK3 } from "assets/iconsK3/Info.svg"
import { ReactComponent as Invoice } from "assets/MenuIcon/Atom/Icons/Invoice.svg"
import { ReactComponent as InvoiceDollar } from "assets/MenuIcon/newIcons/InvoiceDollar.svg"
import { ReactComponent as InvoiceIcon } from "assets/general/invoiceIcon.svg"
import { ReactComponent as Jobs1 } from "assets/icons/Jobs1.svg"
import { ReactComponent as Jobs2 } from "assets/MenuIcon/newIcons/Jobs2.svg"
import { ReactComponent as KanBan } from "assets/icons/KanBan.svg"
import { ReactComponent as Level } from "assets/icons/Level.svg"
import { ReactComponent as Link } from "assets/MenuIcon/Atom/Icons/Link.svg"
import { ReactComponent as AdminLink } from "assets/icons/adminlink.svg"
import { ReactComponent as List } from "assets/general/List.svg"
import { ReactComponent as List1 } from "assets/icons/List.svg"
import { ReactComponent as List3White } from "assets/MenuIcon/newIcons/list3.svg"
import { ReactComponent as Listmodels } from "assets/MenuIcon/SideIcons/List_models.svg"
import { ReactComponent as Listmodels1 } from "assets/MenuIcon/SideIcons/List_models-1.svg"
import { ReactComponent as ListView } from "assets/ProductsList/ListView.svg"
import { ReactComponent as ListViewWhite } from "assets/ProductsList/ListViewWhite.svg"
import { ReactComponent as Location } from "assets/MenuIcon/SideIcons/Location.svg"
import { ReactComponent as Locationlocation } from "assets/MenuIcon/Atom/Icons/Locationlocation.svg"
import { ReactComponent as SignIn } from "assets//icons/SignIn.svg"
import { ReactComponent as Logout } from "assets/MenuIcon/Atom/Icons/LogOut.svg"
import { ReactComponent as MagicLink } from "assets/general/MagicLink.svg"
import { ReactComponent as ManageUsers } from "assets/dashboard/Icons/ManageUsers.svg"
import { ReactComponent as Manageusers } from "assets/MenuIcon/Atom/Icons/ManageUsers.svg"
import { ReactComponent as Maps } from "assets/MenuIcon/Atom/Icons/Maps.svg"
import { ReactComponent as Maximize } from "assets/icons/window-maximize.svg"
import { ReactComponent as MediaPlayer } from "assets/iconsK3/MediaPlayer.svg"
import { ReactComponent as Menu } from "assets/MenuIcon/Atom/Icons/Menu.svg"
import { ReactComponent as MenuList } from "assets/MenuIcon/Atom/Icons/List.svg"
import { ReactComponent as MenuTransparent } from "assets/MenuIcon/Atom/Icons/MenuTransparent.svg"
import { ReactComponent as MoneyCoins1 } from "assets/general/money-coins-1.svg"
import { ReactComponent as More } from "assets/icons/More.svg"
import { ReactComponent as MoreK3 } from "assets/iconsK3/More.svg"
import { ReactComponent as NeteNete } from "assets/icons/NeteNete.svg"
import { ReactComponent as NotesText } from "assets/iconsK3/NotesText.svg"
import { ReactComponent as NumbersIcon } from "assets/general/numbers-icon.svg"
import { ReactComponent as FastOptionsIcon } from "assets/general/new_fast_options.svg"
import { ReactComponent as OnlineStore } from "assets/MenuIcon/Atom/Icons/Online_store.svg"
import { ReactComponent as Onlinestore } from "assets/MenuIcon/SideIcons/Online_store.svg"
import { ReactComponent as OrangeIssueK3 } from "assets/iconsK3/orangeIssueK3.svg"
import { ReactComponent as OrdersIcon } from "assets/general/ordersIcon.svg"
import { ReactComponent as Password } from "assets/general/Password.svg"
import { ReactComponent as PaybyInvoice } from "assets/dashboard/Icons/Pay_by_invoice.svg"
import { ReactComponent as PaybyTransfer } from "assets/dashboard/Icons/Pay_by_transfer.svg"
import { ReactComponent as Privacy } from "assets/dashboard/Icons/Privacy.svg"
import { ReactComponent as Product } from "assets/MenuIcon/newIcons/Product.svg"
import { ReactComponent as ProductDocument } from "assets/icons/Documents.svg"
import { ReactComponent as Productmagazine } from "assets/MenuIcon/SideIcons/ProductMagazine.svg"
import { ReactComponent as ProductMock } from "assets/iconsK3/ProductMock.svg"
import { ReactComponent as Products } from "assets/icons/Products.svg"
import { ReactComponent as CheckOk } from "assets/iconsK3/checkOk.svg"
import { ReactComponent as RedBox } from "assets/iconsK3/redBox.svg"
import { ReactComponent as EmptyBox } from "assets/iconsK3/emptyBox.svg"
import { ReactComponent as CheckOkGreen } from "assets/iconsK3/checkOkGreen.svg"
import { ReactComponent as GreenOkay } from "assets/iconsK3/Green-Ok.svg"
import { ReactComponent as GreyX } from "assets/iconsK3/GreyX.svg"
import { ReactComponent as OrangeLoad } from "assets/iconsK3/OrangeLoad.svg"
import { ReactComponent as UniprestIcon } from "assets/iconsK3/Uniprest.svg"
import { ReactComponent as MapPins } from "assets/iconsK3/MapPins.svg"
import { ReactComponent as Adress } from "assets/iconsK3/Adress.svg"
import { ReactComponent as Timer } from "assets/iconsK3/Timer.svg"
import { ReactComponent as Pay } from "assets/iconsK3/Pay.svg"
import { ReactComponent as AlertIcon } from "assets/iconsK3/Alert.svg"
import { ReactComponent as RedAlert } from "assets/iconsK3/RedAlert.svg"
import { ReactComponent as Passw } from "assets/iconsK3/passw.svg"
import { ReactComponent as CartAdd } from "assets/icons/cart-add.svg"
import { ReactComponent as CartFull } from "assets/icons/CartFull.svg"
import { ReactComponent as CartAddClear } from "assets/icons/cart-add-clear.svg"
import { ReactComponent as Page404 } from "assets/iconsK3/404.svg"
import { ReactComponent as Safari } from "assets/iconsK3/Frame.svg"
import { ReactComponent as SearchNoResults } from "assets/iconsK3/SearchNoResults.svg"
import { ReactComponent as PasswordGood } from "assets/iconsK3/PasswordGood.svg"
import { ReactComponent as OpenBook } from "assets/icons/openbook.svg"
import { ReactComponent as Maintenance } from "assets/iconsK3/maintenance.svg"
import { ReactComponent as ImportFromExcel } from "assets/iconsK3/excelImportIcon.svg"

// import { ReactComponent as ImageProfile1 } from 'assets/icons/Image_Profile_1.svg'
// import { ReactComponent as Profile1 } from 'assets/general/Image_Profile_1.svg'
// import { ReactComponent as Profile2 } from 'assets/general/Image_Profile_2.svg'

import { ReactComponent as Projects } from "assets/MenuIcon/newIcons/Projects.svg"
import { ReactComponent as Promo } from "assets/icons/Promo.svg"
import { ReactComponent as Reorder } from "assets/icons/Reorder.svg"
import { ReactComponent as Rect } from "assets/iconsK3/rect.svg"
import { ReactComponent as RectRed } from "assets/iconsK3/RectRed.svg"
import { ReactComponent as Refresh } from "assets/icons/refresh.svg"
import { ReactComponent as GreenRefresh } from "assets/icons/GreenRefresh.svg"
import { ReactComponent as Resellpartners } from "assets/MenuIcon/Atom/Icons/Resellpartners.svg"
import { ReactComponent as ResetPass } from "assets/dashboard/Icons/ResetPass.svg"
import { ReactComponent as Revert } from "assets/general/Revert.svg"
import { ReactComponent as ReviewStar } from "assets/icons/Reviews.svg"
import { ReactComponent as ReviewStarEmpty } from "../../assets/general/Review_star_empty.svg"
import { ReactComponent as ReviewStarFull } from "../../assets/general/Review_star_full.svg"
import { ReactComponent as RFPList } from "assets/MenuIcon/newIcons/RFPList.svg"
import { ReactComponent as Right1 } from "assets/dashboard/Icons/Right-1.svg"
import { ReactComponent as RightBlue } from "assets/dashboard/Icons/Rightblue.svg"
import { ReactComponent as RightThick } from "assets/MenuIcon/Atom/Icons/Right.svg"
import { ReactComponent as RightWhite } from "assets/general/RightWhite.svg"
import { ReactComponent as SaveList } from "assets/MenuIcon/Atom/Icons/SaveList.svg"
import { ReactComponent as Search } from "assets/MenuIcon/Atom/Icons/Search.svg"
import { ReactComponent as Selectdown } from "assets/icons/selectdown.svg"
import { ReactComponent as Services } from "assets/MenuIcon/Atom/Icons/Services.svg"
import { ReactComponent as Settings } from "assets/dashboard/Icons/Settings.svg"
import { ReactComponent as Share } from "assets/icons/Share.svg"
import { ReactComponent as Simpledone } from "assets/icons/simpledone.svg"
import { ReactComponent as SmallDown } from "assets/icons/small-down.svg"
import { ReactComponent as SmallUp } from "assets/icons/small-up.svg"
import { ReactComponent as Stats } from "assets/MenuIcon/Atom/Icons/Stats.svg"
import { ReactComponent as SunFog } from "assets/dashboard/Icons/sun-fog.svg"
import { ReactComponent as Support } from "assets/MenuIcon/SideIcons/Support.svg"
import { ReactComponent as TaskListK3 } from "assets/iconsK3/TaskList.svg"
import { ReactComponent as TextAndImage } from "assets/iconsK3/TextAndImage.svg"
import { ReactComponent as Tweak } from "assets/icons/Tweak.svg"
import { ReactComponent as Undo } from "assets/MenuIcon/Atom/Icons/Undo.svg"
import { ReactComponent as Uniprest } from "assets/MenuIcon/Atom/Icons/Uniprest.svg"
import { ReactComponent as UniprestPlus } from "assets/icons/UniprestPlus.svg"
import { ReactComponent as SuccessBigSVGwand } from "assets/icons/SuccessBigSVGwand.svg"
import { ReactComponent as UniprestStaff } from "assets/MenuIcon/SideIcons/Unipreststaff.svg"
import { ReactComponent as User } from "assets/icons/User.svg"
import { ReactComponent as Vector } from "assets/MenuIcon/SideIcons/Vector.svg"
import { ReactComponent as Vectorr } from "assets/iconsK3/BKG.svg"
import { ReactComponent as Visibility24 } from "assets/icons/Visibility24.svg"
import { ReactComponent as Visibility } from "assets/icons/Visibility.svg"
import { ReactComponent as VisibilityBlack } from "assets/icons/VisibilityBlackLines.svg"
import { ReactComponent as VisibilityHidden } from "assets/icons/VisibilityHidden.svg"
import { ReactComponent as VolumeDiscount } from "assets/icons/VolumeDiscount.svg"
import { ReactComponent as Wallet } from "assets/shop/wallet.svg"
import { ReactComponent as WareHouse } from "assets/icons/Warehouse.svg"
import { ReactComponent as Warehouse } from "assets/MenuIcon/SideIcons/Warehouse.svg"
import { ReactComponent as Warning } from "assets/dashboard/Icons/Warning.svg"
import { ReactComponent as WhiteWarning } from "assets/icons/WhiteWarning.svg"
import { ReactComponent as WarningK3 } from "assets/iconsK3/Warning.svg"
import { ReactComponent as WebPage } from "assets/MenuIcon/newIcons/WebPage.svg"
import { ReactComponent as Weight } from "assets/icons/Weight.svg"
import { ReactComponent as WeightK3 } from "assets/iconsK3/Weight.svg"
import { ReactComponent as White } from "assets/MenuIcon/Atom/Icons/White.svg"
import { ReactComponent as WindowMax1 } from "assets/iconsK3/window-maximize-1.svg"
import { ReactComponent as WindowMaximize } from "assets/icons/window-maximize.svg"
import { ReactComponent as CheckNotOk } from "assets/iconsK3/CheckNotOk.svg"
import { ReactComponent as UniprestGlobalProducts } from "assets/MenuIcon/newIcons/UniprestGlobalProducts.svg"
import { ReactComponent as SendEmail } from "assets/MenuIcon/newIcons/SendEmail.svg"
import { ReactComponent as PayByCard } from "assets/icons/PayByCard.svg"
import { ReactComponent as OrderHeroIcon } from "assets/icons/orderHeroIcon.svg"
import { ReactComponent as InvoicesFrame } from "assets/general/InvoicesFrame.svg"
import { ReactComponent as IlluNoList } from "assets/_illustrations/illu_no_list.svg"
import { ReactComponent as EmptyList } from "assets/_illustrations/sheet.svg"
import { ReactComponent as DashboardNews } from "assets/_illustrations/newsIcon.svg"
import { ReactComponent as Facebook } from "assets/social/Facebook_Default.svg"
import { ReactComponent as Youtube } from "assets/social/YouTube_Default.svg"
import { ReactComponent as LinkedIn } from "assets/social/LinkedIn_Default.svg"
import { ReactComponent as DeliveryIcon } from "assets/icons/DeliveryIcon.svg"
import { ReactComponent as DownloadIcon } from "assets/iconsK3/download.svg"
import { ReactComponent as Print } from "assets/iconsK3/print.svg"
import { ReactComponent as RedTrash } from "assets/iconsK3/redtrash.svg"
import { ReactComponent as SimpleShopCart } from "assets/iconsK3/simpleshopcart.svg"
import { ReactComponent as GlobalConnectionError } from "assets/iconsK3/GlobalConnectionAlert.svg"
import { ReactComponent as Offert } from "assets/iconsK3/offert.svg"
import { ReactComponent as OffertIcon } from "assets/icons/offertIcon.svg"
import { ReactComponent as AddBlue } from "assets/iconsK3/addblue.svg"

const blankIcon = (
  <svg 
    data-test-id='SWx5'
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect data-test-id='17Bp' width="32" height="32" fill="white" />
  </svg>
)

export default function SvgIcon({
  mr,
  h,
  w,
  className,
  style,
  name = "download"
}) {
  const inlineStyle = { ...style }
  if (className) inlineStyle.display = "block"
  if (mr) inlineStyle.marginRight = mr * 4
  if (h) {
    inlineStyle.height = h * 4
    if (w) inlineStyle.width = w * 4
    else inlineStyle.width = h * 4
  }
  let findSvgByName = () => {
    switch (
      (typeof name === "string" ? name : "defaunt")
        ?.toLowerCase?.()
        ?.replaceAll?.("-", "")
        ?.replaceAll?.("_", "")
        ?.replaceAll?.(" ", "")
    ) {
      // case 'business' : return <Business style={inlineStyle}/>
      // case 'cartempty' : return <Cartempty style={inlineStyle}/>
      // case 'list' : return <List style={inlineStyle}/>
      // case 'listmodels' : return <Listmodels style={inlineStyle}/>
      // case 'right' : return <Right style={inlineStyle}/>
      // case 'support' : return <Support style={inlineStyle}/>
      // case 'warehouse' : return <Warehouse style={inlineStyle}/>
      case "collapse":
        return <Collapse style={inlineStyle} />
      case "greencheckbox":
        return <GreenCheckbox style={inlineStyle} />
      case "stockyellow":
        return <StockYellow style={inlineStyle} />
      case "stockyellow":
        return <StockYellow style={inlineStyle} />
      case "stockred":
        return <StockRed style={inlineStyle} />
      case "stockred":
        return <StockRed style={inlineStyle} />
      case "add":
        return <Add style={inlineStyle} />
      case "addtolist":
        return <Addtolist style={inlineStyle} />
      case "ai":
        return <AI style={inlineStyle} />
      case "airecommendations":
        return <Airecommendations style={inlineStyle} />
      case "alert":
        return <Alert style={inlineStyle} />
      case "alert1":
        return <Alert1 style={inlineStyle} />
      case "alerttrinagle":
        return <AlertTriangle style={inlineStyle} />
      case "apps":
        return <Apps style={inlineStyle} />
      case "arrowdown":
        return <ArrowDown style={inlineStyle} />
      case "arrowdownblue":
          return <ArrowDownBlue style={inlineStyle} />
      case "arrowright":
        return <ArrowRight style={inlineStyle} />
      case "back":
        return <BackArrow style={inlineStyle} />
      case "backarrow":
        return <BackArrow style={inlineStyle} />
      case "badge":
        return <Badge style={inlineStyle} />
      case "bell":
        return <Bell style={inlineStyle} />
      case "block":
        return <Block style={inlineStyle} />
      case "business":
        return <Business style={inlineStyle} />
      case "calendar":
        return <Calendar style={inlineStyle} />
      case "calendartodayoutlined":
        return <CalendarTodayOutlined style={inlineStyle} />
      case "call":
        return <Call style={inlineStyle} />
      case "phone":
        return <Call style={inlineStyle} /> //added for normal humans :)
      case "cardsview":
        return <CardsView style={inlineStyle} />
      case "cardsviewwhite":
        return <CardsViewWhite style={inlineStyle} />
      case "career":
        return <Career style={inlineStyle} />
      case "cartempty":
        return <CartEmpty style={inlineStyle} />
      case "cartnotempty":
        return <CartNotEmpty style={inlineStyle} />
      case "cash":
        return <Cash style={inlineStyle} />
      case "checkboxactive":
        return <CheckboxActive style={inlineStyle} />
      case "checkboxactivek3":
        return <CheckboxActiveK3 style={inlineStyle} />
      case "checkboxinactive":
        return <CheckboxInactive style={inlineStyle} />
      case "checkboxok":
        return <CheckboxOk style={inlineStyle} />
      case "checkboxgrey":
        return <CheckboxGrey style={inlineStyle} />
      case "checkboxinactivek3":
        return <CheckboxInactiveK3 style={inlineStyle} />
      case "checkboxinactivesolid":
        return <CheckboxInactiveSolid style={inlineStyle} />
      case "checkboxissues":
        return <CheckboxIssues style={inlineStyle} />
      case "checkboxissuessolid":
        return <CheckboxIssuesSolid style={inlineStyle} />
      case "checkboxissueswarning":
        return <CheckboxIssuesWarning style={inlineStyle} />
      case "checkboxrestricted":
        return <CheckboxRestricted style={inlineStyle} />
      case "checkboxmultideselect":
        return <CheckboxMultiDeselect style={inlineStyle} />
      case "checkboxmultideselectk3":
        return <CheckboxMultiDeselectK3 style={inlineStyle} />
      case "close":
        return <Close style={inlineStyle} />
      case "closek3":
        return <CloseK3 style={inlineStyle} />
      case "closeround":
        return <CloseRound style={inlineStyle} />
      case "closewhite":
        return <CloseWhite style={inlineStyle} />
      case "companoproducts":
        return <CompanoProducts style={inlineStyle} />
      case "copy":
        return <Copy style={inlineStyle} />
      case "dashboard1":
        return <Dashboard1 style={inlineStyle} />
      case "deals":
        return <Deals style={inlineStyle} />
      case "deals2":
        return <Deals2 style={inlineStyle} />
      case "delete":
        return <Delete style={inlineStyle} />
      case "cancel":
        return <Cancel style={inlineStyle} />
      case "deletegrey":
        return <Deletegrey style={inlineStyle} />
      case "delivery":
        return <Delivery style={inlineStyle} />
      case "details":
        return <Details style={inlineStyle} />
      case "discountpachete":
        return <DiscountPachete style={inlineStyle} />
      case "documentcopy":
        return <DocumentCopy style={inlineStyle} />
      case "documents":
        return <Documents style={inlineStyle} />
      case "documents2":
        return <Documents2 style={inlineStyle} />
      case "documents3":
        return <Documents3 style={inlineStyle} />
      case "documents4":
        return <Documents4 style={inlineStyle} />
      case "done":
        return <Done style={inlineStyle} />
      case "done":
        return <Done2 style={inlineStyle} />
      case "down":
        return <Down style={inlineStyle} />
      case "download":
        return <Download style={inlineStyle} />
      case "edit":
        return <Edit style={inlineStyle} />
      case "editclear":
        return <EditClear style={inlineStyle} />
      case "email":
        return <Email style={inlineStyle} />
      case "expand":
        return <Expand style={inlineStyle} />
      case "expand1":
        return <Expand1 style={inlineStyle} />
      case "flame":
        return <Flame style={inlineStyle} />
      case "ventilatie":
        return <Ventilation style={inlineStyle} />
      case "apa":
        return <Apa style={inlineStyle} />
      case "greenenergy":
        return <GreenEnergy style={inlineStyle} />
      case "gaz":
        return <Gaz style={inlineStyle} />
      case "lichidare":
        return <Lichidare style={inlineStyle} />
      case "listback":
        return <ListBack style={inlineStyle} />
      case "sanitare":
        return <Sanitare style={inlineStyle} />
      case "termice":
        return <Termice style={inlineStyle} />
      case "greenok":
        return <GreenOk style={inlineStyle} />
      case "greyclosek3":
        return <GreyCloseK3 style={inlineStyle} />
      case "griladiscount":
        return <GrilaDiscount style={inlineStyle} />
      case "heart":
        return <Heart style={inlineStyle} />
      case "heartblue":
        return <Heartblue style={inlineStyle} />
      case "heartbluedark":
        return <Heartbluedark style={inlineStyle} />
      case "heroimage":
        return <HeroImage style={inlineStyle} />
      case "historyrecommendations":
        return <Historyrecommendations style={inlineStyle} />
      case "homepage":
        return <Homepage style={inlineStyle} />
      case "horizontalline":
        return <HorizontalLine style={inlineStyle} />
      case "hyperlink":
        return <HyperLink style={inlineStyle} />
      case "icoadd":
        return <IcoAdd style={inlineStyle} />
      case "id":
        return <Id style={inlineStyle} />
      case "infok3":
        return <InfoK3 style={inlineStyle} />
      case "invoice":
        return <Invoice style={inlineStyle} />
      case "invoicedollar":
        return <InvoiceDollar style={inlineStyle} />
      case "invoiceicon":
        return <InvoiceIcon style={inlineStyle} />
      case "invoicesicon":
        return <InvoiceIcon style={inlineStyle} />
      case "jobs1":
        return <Jobs1 style={inlineStyle} />
      case "jobs2":
        return <Jobs2 style={inlineStyle} />
      case "kanban":
        return <KanBan style={inlineStyle} />
      case "level":
        return <Level style={inlineStyle} />
      case "link":
        return <Link style={inlineStyle} />
      case "adminlink":
        return <AdminLink style={inlineStyle} />
      case "list":
        return <List style={inlineStyle} />
      case "list1":
        return <List1 style={inlineStyle} />
      case "list3white":
        return <List3White style={inlineStyle} />
      case "listicon":
        return <List style={inlineStyle} />
      case "listmodels":
        return <Listmodels style={inlineStyle} />
      case "listmodels1":
        return <Listmodels1 style={inlineStyle} />
      case "listview":
        return <ListView style={inlineStyle} />
      case "listviewwhite":
        return <ListViewWhite style={inlineStyle} />
      case "location":
        return <Location style={inlineStyle} />
      case "locationlocation":
        return <Locationlocation style={inlineStyle} />
      case "logout":
        return <Logout style={inlineStyle} />
      case "signin":
        return <SignIn style={inlineStyle} />
      case "magiclink":
        return <MagicLink style={inlineStyle} />
      case "manageusers":
        return <Manageusers style={inlineStyle} />
      case "mangeusers":
        return <ManageUsers style={inlineStyle} />
      case "maps":
        return <Maps style={inlineStyle} />
      case "mediaplayer":
        return <MediaPlayer style={inlineStyle} />
      case "menu":
        return <Menu style={inlineStyle} />
      case "menulist":
        return <MenuList style={inlineStyle} />
      case "menutransparent":
        return <MenuTransparent style={inlineStyle} />
      case "moneycoins1":
        return <MoneyCoins1 style={inlineStyle} />
      case "more":
        return <More style={inlineStyle} />
      case "morek3":
        return <MoreK3 style={inlineStyle} />
      case "netenete":
        return <NeteNete style={inlineStyle} />
      case "notestext":
        return <NotesText style={inlineStyle} />
      case "numbersicon":
        return <NumbersIcon style={inlineStyle} />
      case "newfastoptions":
        return <FastOptionsIcon style={inlineStyle} />
      case "online_store":
        return <OnlineStore style={inlineStyle} />
      case "onlinestore":
        return <Onlinestore style={inlineStyle} />
      case "openbook":
        return <OpenBook style={inlineStyle} />
      case "orangeissuek3":
        return <OrangeIssueK3 style={inlineStyle} />
      case "ordericon":
        return <OrdersIcon style={inlineStyle} />
      case "ordersicon":
        return <OrdersIcon style={inlineStyle} />
      case "password":
        return <Password style={inlineStyle} />
      case "paybyinvoice":
        return <PaybyInvoice style={inlineStyle} />
      case "paybytransfer":
        return <PaybyTransfer style={inlineStyle} />
      case "privacy":
        return <Privacy style={inlineStyle} />
      case "product":
        return <Product style={inlineStyle} />
      case "product1":
        return <Products style={inlineStyle} />
      case "productdocument":
        return <ProductDocument style={inlineStyle} />
      case "productmagazine":
        return <Productmagazine style={inlineStyle} />

      // case 'imageprofile1': return <ImageProfile1 style={inlineStyle} />
      // case 'profile1': return <Profile1 style={inlineStyle} />
      // case 'profile2': return <Profile2 style={inlineStyle} />

      case "projects":
        return <Projects style={inlineStyle} />
      case "promo":
        return <Promo style={inlineStyle} />
      case "reorder":
        return <Reorder style={inlineStyle} />
      case "rect":
        return <Rect style={inlineStyle} />
      case "rectred":
        return <RectRed style={inlineStyle} />
      case "refresh":
        return <Refresh style={inlineStyle} />
      case "greenrefresh":
        return <GreenRefresh style={inlineStyle} />
      case "resellpartners":
        return <Resellpartners style={inlineStyle} />
      case "resetpass":
        return <ResetPass style={inlineStyle} />
      case "revert":
        return <Revert style={inlineStyle} />
      case "reviewstar":
        return <ReviewStar style={inlineStyle} />
      case "reviewstarempty":
        return <ReviewStarEmpty style={inlineStyle} />
      case "reviewstarfull":
        return <ReviewStarFull style={inlineStyle} />
      case "rfplist":
        return <RFPList style={inlineStyle} />
      case "right":
        return <Right1 style={inlineStyle} />
      case "right1":
        return <Right1 style={inlineStyle} />
      case "rightblue":
        return <RightBlue style={inlineStyle} />
      case "rightthick":
        return <RightThick style={inlineStyle} />
      case "rightwhite":
        return <RightWhite style={inlineStyle} />
      case "savelist":
        return <SaveList style={inlineStyle} />
      case "search":
        return <Search style={inlineStyle} />
      case "selectdown":
        return <Selectdown style={inlineStyle} />
      case "services":
        return <Services style={inlineStyle} />
      case "services38":
        return <Services style={inlineStyle} width={38} height={38} />
      case "settings":
        return <Settings style={inlineStyle} />
      case "share":
        return <Share style={inlineStyle} />
      case "simpledone":
        return <Simpledone style={inlineStyle} />
      case "smalldown":
        return <SmallDown style={inlineStyle} />
      case "smallup":
        return <SmallUp style={inlineStyle} />
      case "stats":
        return <Stats style={inlineStyle} />
      case "sunfog":
        return <SunFog style={inlineStyle} />
      case "support":
        return <Support style={inlineStyle} />
      case "tasklistk3":
        return <TaskListK3 style={inlineStyle} />
      case "textandimage":
        return <TextAndImage style={inlineStyle} />
      case "trash":
        return <Delete style={inlineStyle} />
      case "triangle":
        return <AlertTriangle style={inlineStyle} />
      case "tweak":
        return <Tweak style={inlineStyle} />
      case "undo":
        return <Undo style={inlineStyle} />
      case "uniprest":
        return <Uniprest style={inlineStyle} />
      case "uniprestplus":
        return <UniprestPlus style={inlineStyle} />
      case "successbigsvgwand":
        return <SuccessBigSVGwand style={inlineStyle} />
      case "unipreststaff":
        return <UniprestStaff style={inlineStyle} />
      case "user":
        return <User style={inlineStyle} />
      case "vector":
        return <Vector style={inlineStyle} />
      case "vectorr":
        return <Vectorr style={inlineStyle} />
      case "visibility":
        return <Visibility style={inlineStyle} />
      case "visibility24":
        return <Visibility24 style={inlineStyle} />
      case "visibilityblack":
        return <VisibilityBlack style={inlineStyle} />
      case "visibilityhidden":
        return <VisibilityHidden style={inlineStyle} />
      case "volumediscount":
        return <VolumeDiscount style={inlineStyle} />
      case "wallet":
        return <Wallet style={inlineStyle} />
      case "warehouse":
        return <Warehouse style={inlineStyle} />
      case "warning":
        return <Warning style={inlineStyle} />
      case "whitewarning":
        return <WhiteWarning style={inlineStyle} />
      case "warningk3":
        return <WarningK3 style={inlineStyle} />
      case "webpage":
        return <WebPage style={inlineStyle} />
      case "weight":
        return <Weight style={inlineStyle} />
      case "weightk3":
        return <WeightK3 style={inlineStyle} />
      case "white":
        return <White style={inlineStyle} />
      case "window-maximize":
        return <Maximize style={inlineStyle} />
      case "windowmax1":
        return <WindowMax1 style={inlineStyle} />
      case "windowmaximize":
        return <WindowMaximize style={inlineStyle} />
      case "checkok":
        return <CheckOk style={inlineStyle} />
      case "redbox":
        return <RedBox style={inlineStyle} />
      case "emptybox":
        return <EmptyBox style={inlineStyle} />
      case "checknotok":
        return <CheckNotOk style={inlineStyle} />
      case "expandclear":
        return <ExpandClear style={inlineStyle} />
      case "uniprestglobalproducts":
        return <UniprestGlobalProducts style={inlineStyle} />
      case "sendemail":
        return <SendEmail style={inlineStyle} />
      case "checkokgreen":
        return <CheckOkGreen style={inlineStyle} />
      case "greenokay":
        return <GreenOkay style={inlineStyle} />
      case "greyx":
        return <GreyX style={inlineStyle} />
      case "orangeload":
        return <OrangeLoad style={inlineStyle} />
      case "unipresticon":
        return <UniprestIcon style={inlineStyle} />
      case "mappins":
        return <MapPins style={inlineStyle} />
      case "adress":
        return <Adress style={inlineStyle} />
      case "paybycard":
        return <PayByCard style={inlineStyle} />
      case "orderheroicon":
        return <OrderHeroIcon style={inlineStyle} />

      case "timer":
        return <Timer style={inlineStyle} />
      case "pay":
        return <Pay style={inlineStyle} />
      case "alerticon":
        return <AlertIcon style={inlineStyle} />
      case "redalert":
        return <RedAlert style={inlineStyle} />
      case "invoicesframe":
        return <InvoicesFrame style={inlineStyle} />
      case "passw":
        return <Passw style={inlineStyle} />
      case "cartadd":
        return <CartAdd style={inlineStyle} />
      case "cartfull":
        return <CartFull style={inlineStyle} />
      case "cartaddclear":
        return <CartAddClear style={inlineStyle} />
      case "page404":
        return <Page404 style={inlineStyle} />
      case "safari":
        return <Safari style={inlineStyle} />
      case "searchnoresults":
        return <SearchNoResults style={inlineStyle} />
      case "illunolist":
        return <IlluNoList style={inlineStyle} />
      case "emptylist":
        return <EmptyList style={inlineStyle} />
      case "dashboardnews":
        return <DashboardNews style={inlineStyle} />

      case "passwordgood":
        return <PasswordGood style={inlineStyle} />
      case "facebook":
        return <Facebook style={inlineStyle} />
      case "youtube":
        return <Youtube style={inlineStyle} />
      case "linkedin":
        return <LinkedIn style={inlineStyle} />
      case "deliveryicon":
        return <DeliveryIcon style={inlineStyle} />
      case "maintenance":
        return <Maintenance style={inlineStyle} />
      case "importfromexcel":
        return <ImportFromExcel style={inlineStyle} />

      case "downloadicon":
        return <DownloadIcon style={inlineStyle} />
      case "print":
        return <Print style={inlineStyle} />
      case "redtrash":
        return <RedTrash style={inlineStyle} />
      case "simpleshopcart":
        return <SimpleShopCart style={inlineStyle} />
      case "globalconnectionerror":
        return <GlobalConnectionError style={inlineStyle} />
      case "offert":
        return <Offert style={inlineStyle} />
      case "offerticon":
        return <OffertIcon style={inlineStyle} />
      case "addblue":
        return <AddBlue style={inlineStyle} />

      default:
        return blankIcon
    }
  }

  return (
    <>
      {className ? (
        <div data-test-id='cpYE' className={className}>{findSvgByName()}</div>
      ) : (
        findSvgByName()
      )}
    </>
  )
}
