import M from "components/MagicButton/MagicButton"

export function getMonthDays(yearin, inMonth, startSunday = false) {
  const daysInMonth = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
  const year = Number.parseInt(yearin)
  const inputMonth = Number.parseInt(inMonth)
  // If the year is leap then February has 29 days
  if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
    daysInMonth[2] = 29
  } else {
    daysInMonth[2] = 28
  }
  //0 sunday - 6 saturday
  const firstDay = new Date(yearin, inMonth - 1, 1).getDay()
  //const lastDay = new Date(yearin, inMonth - 1, daysInMonth?.[inMonth]).getDay();
  let prev = [25, 26, 27, 28, 29, 30, 31],
    cur = [],
    next = [1, 2, 3, 4, 5, 6, 7],
    missFront = 0,
    missBack = 0

  for (let day = 1; day <= daysInMonth?.[inMonth]; day++) {
    cur?.push?.(day)
  }

  missFront = firstDay === 0 ? 6 : firstDay - 1
  //calculate the inputMounth last row missings
  missBack = 35 - (cur?.length + missFront)

  cur = [
    ...prev?.slice?.(prev?.length - missFront),
    ...cur,
    ...next?.slice?.(0, missBack)
  ]
  return cur
}
