
export const isPrimitiveType = (value) => {

  if(value === null) return true
  if(typeof value === 'undefined') return true
  if(typeof value === 'string') return true
  if(typeof value === 'number') return true
  if(typeof value === 'boolean') return true

  return false

}

