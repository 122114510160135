import React, { useContext } from "react"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { setActionToConfirm, setPageModal } from "store/modules/page"
import ThemeContext from "../../theme/themeProvider"
import SvgIcon from "components/SvgIcon/SvgIcon"
import "./MagicButtonV3.scss"

const propsToClass = [
  "primary",
  "secondary",
  "outline",
  "selected",
  "disabled",
  "clear",
  "simple"
]

const computePaddings = (props) => {
  let className = ""
  let multiplier = 2
  let localProps = { ...props }
  if (localProps.square) {
    if (localProps.w && !localProps.h) localProps.h = localProps.w
    if (!localProps.w && localProps.h) localProps.w = localProps.h
  }
  for (let key in localProps)
    if (typeof localProps?.[key] === "number") {
      if (key === "w") multiplier = 0.5
      if (key === "h") multiplier = 1
      className += ` ${key}${Math.round(localProps?.[key] * multiplier)}`
    }
  return className
}

const addDefaultPaddings = (className) => {
  if (!RegExp(/p[0-9]+/).test(className)) {
    if (!RegExp(/px[0-9]+/).test(className)) {
      if (!RegExp(/pl[0-9]+/).test(className)) className += " pl2"
      if (!RegExp(/pr[0-9]+/).test(className)) className += " pr2"
    }
    if (!RegExp(/py[0-9]+/).test(className)) {
      if (!RegExp(/pt[0-9]+/).test(className)) className += " pt1"
      if (!RegExp(/pb[0-9]+/).test(className)) className += " pb1"
    }
  }
  return className
  // else if (RegExp(/px[0-9]*/).test(className)){
  //   if (RegExp(/py[0-9]*/).test(className)) return className
  //   else {
  //     if (RegExp(/px[0-9]*/).test(className))
  //   }
  // }
}
const mapPropsToClass = (props) => {
  let className = ""
  propsToClass?.forEach?.((item) => {
    if (props?.[item]) className += ` ${item}`
  })
  return className
}

//const baseClassName = 'magic-button magic-button-wrapper-v3'
//
export default function MagicButtonV3(props) {
  const {
    url,
    dldUrl,
    href,
    targetBlank,
    onClick,
    className,
    label,
    primary,
    secondary,
    svg,
    square,
    popRight,
    popLeft,
    popBottom //used for toolTip text
  } = props
  let {
    disabled,
    selected,
    active, //backward compatib. same as selected
    menu
  } = props
  const baseClassName = menu
    ? `buttonSimple ${
        props?.popRight === "Import din Excel" ? "excel-import-button" : ""
      } ${
        window?.location?.pathname === "/shop/importfromexcel/details"
          ? "excel-import-selected"
          : ""
      }`
    : "magic-button magic-button-wrapper-v3"
  if (selected || window.location.pathname === url || active) {
    selected = true
    disabled = true
  }

  let containerClassName = ""
  containerClassName += computePaddings(props)
  containerClassName += ` ${baseClassName}`
  containerClassName += mapPropsToClass(props)
  if (className) containerClassName += ` ${className}`
  if (primary) containerClassName += ` primary`
  if (!label) containerClassName += ` no-label`
  if (secondary) containerClassName += ` outline`
  if (!className?.includes?.("etype") && !className?.includes?.("ptype"))
    containerClassName += ` etype-1`
  if (!menu && !href)
    containerClassName = addDefaultPaddings(containerClassName)

  if (popRight) containerClassName += " button-tool-tip-right"
  if (popLeft) containerClassName += " button-tool-tip-left"
  if (popBottom) containerClassName += " button-tool-tip-bottom"

  if (selected || window.location.pathname === url || active)
    containerClassName += " selected disabled"

  const containerProps = {
    disabled,
    className: containerClassName,
    tooltip: popLeft ?? popRight ?? popBottom
  }

  const content = (
    <>
      {svg && <SvgIcon name={svg} />}
      {label}
    </>
  )

  if (dldUrl && !disabled) {
    //a for downloading stuff
    containerProps.href = dldUrl
    return <a data-test-id='K8td' {...containerProps}>{content}</a>
    // return <a data-test-id='I9YL' className={baseClassName} href={dldUrl}><div data-test-id='4jl3'  {...containerProps}>{content}</div></a>
  } else if (url && !disabled) {
    //Link to user react router history
    containerProps.to = url
    containerProps.onClick = onClick
    return <Link {...containerProps}>{content}</Link>
  } else if (href && !disabled) {
    containerProps.href = href
    if (targetBlank) containerProps.target = "_blank"
    return <a data-test-id='tw7Q' {...containerProps}>{content}</a>
  } else {
    //plain button
    containerProps.onClick = onClick
    return <button data-test-id='WhhF' {...containerProps}>{content}</button>
  }
}

function MagicButtonOld(props) {
  const {
    label,
    active,
    rightSvg,
    strong, //for outline makes the text bold
    clear, //makes it transparent
    selected,
    onClick,
    icon,
    svg,
    className,
    h = 6,
    w = 0,
    disabled = false,
    outline,
    px = 2,
    m = 0,
    mt,
    mb,
    badge,
    ml,
    mr,
    pl,
    pr,
    pt,
    pb,
    popRight,
    popLeft,
    popBottom, //used for toolTip text
    type = "primary",
    wrapText,
    tl,
    p,
    noFlex, //@TODO: redo logic so we don't need this
    url, //used if you want to go to a link
    dldUrl,
    showModal,
    wc //with confirmation modal
  } = props

  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const baseSpacing = theme.baseSpacing ?? 0.5 // if it's not in theme

  const computedLabel = label ?? (icon ? label : "") // escaped if there is no label or icon provided

  const paddingLeft =
    pl || px || p ? `${(pl ?? px ?? p) * baseSpacing}rem` : undefined
  const paddingRight =
    pr || px || p ? `${(pl ?? px ?? p) * baseSpacing}rem` : undefined

  let containerStyle = {
    // padding: `${p * baseSpacing}rem`,
    marginTop: mt === "a" ? `auto` : `${(mt ?? m) * baseSpacing}rem`,
    marginBottom: mb === "a" ? `auto` : `${(mb ?? m) * baseSpacing}rem`,
    marginLeft: ml === "a" ? `auto` : `${(ml ?? m) * baseSpacing}rem`,
    marginRight: mr === "a" ? `auto` : `${(mr ?? m) * baseSpacing}rem`,
    paddingTop: `${pt * baseSpacing}rem`,
    paddingBottop: `${pb * baseSpacing}rem`,
    paddingLeft,
    paddingRight,
    height: `${h * baseSpacing}rem`,
    width: computedLabel ? "auto" : `${h * baseSpacing}rem`
    // flex: '0 0',
  }

  let computedContainerClass = "magic-button-container"
  computedContainerClass += " " + type

  if (!type) computedContainerClass += " buttonContainerMainCTA"
  if (type === "grey-blue") computedContainerClass += " grey blue"
  if (type === "clear-green") computedContainerClass += " simple active-green"
  if (type === "white") computedContainerClass += " white"
  if (type === "black") computedContainerClass += " black"
  if (type === "redAlert") computedContainerClass += " redAlert"

  // if (type === "secondary") computedContainerClass += " buttonContainerSecondary";
  if (type === "grey") computedContainerClass += " buttonContainerGrey"
  // if (type === "primary") computedContainerClass += " buttonContainerMainCTA";
  if (type === "green") computedContainerClass += " buttonContainerGreen"
  // if (type === "simple") computedContainerClass += " buttonSimple";

  if (disabled) computedContainerClass += " disabled"
  if (outline) computedContainerClass += " outline"
  if (strong) computedContainerClass += " strong"
  if (clear) computedContainerClass += " clear"

  if (popRight) computedContainerClass += " button-tool-tip-right"
  if (popLeft) computedContainerClass += " button-tool-tip-left"
  if (popBottom) computedContainerClass += " button-tool-tip-bottom"

  if (selected || window.location.pathname === url || active)
    computedContainerClass += " selected"

  // if (!computedLabel) containerStyle.flex = `0 0 ${h * baseSpacing}rem`;
  // if (computedLabel) containerStyle.flex = `1`;
  if (!wrapText) containerStyle.whiteSpace = "break-spaces"
  if (tl) containerStyle.textAlign = "left"
  if (w) containerStyle.width = `${w * baseSpacing}rem`

  if (w == 100) {
    containerStyle.flex = "1 0"
    containerStyle.width = "100%"
  }
  const imgStyle = {
    display: "flex",
    flex: `0 0 ${h * baseSpacing}rem`,
    height: `${h * baseSpacing}rem`,
    width: `${h * baseSpacing}rem`
  }
  if (noFlex) containerStyle.flex = undefined
  let computedOnClick = undefined
  let wcClick = undefined

  if (url && window.location.pathname !== url && !active)
    computedOnClick = undefined
  if (dldUrl) {
    const downloadFromUrl = (url, name) => {
      const link = document.createElement("a")
      link.download = name
      link.href = url
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
    computedOnClick = () => downloadFromUrl(dldUrl, "Test")
  }
  if (onClick) computedOnClick = onClick
  if (showModal) computedOnClick = () => dispatch(setPageModal(showModal))
  //this has to be the last one
  if (wc) wcClick = () => dispatch(setActionToConfirm(computedOnClick))
  //
  let buttonProps = {
    tooltip: popLeft ?? popRight ?? popBottom,
    style: containerStyle,
    className: `${computedContainerClass} ${className ?? ""}`,
    onClick: !disabled
      ? (e) => {
          wcClick ? wcClick() : computedOnClick()
          e?.stopPropagation?.()
        }
      : undefined
  }

  const buttonContent = (
    <>
      {icon && (
        <img 
          data-test-id='GCaI'
          onContextMenu={(e) => e?.preventDefault?.()}
          style={imgStyle}
          src={icon}
          alt="icon"
        />
      )}
      {svg && <SvgIcon name={svg} />}
      {computedLabel && <div data-test-id='Et11' className="label">{computedLabel}</div>}
      {rightSvg && <SvgIcon name={rightSvg} />}
      {badge && <div data-test-id='rDBH' className="badge">{badge}</div>}
    </>
  )

  if (url)
    return (
      <Link {...buttonProps} to={url}>
        {buttonContent}
      </Link>
    )
  return <button data-test-id='qE8L' {...buttonProps}>{buttonContent}</button>
}
