import PropTypes from "prop-types"
import { useState } from "react"
import SvgIcon from "components/SvgIcon/SvgIcon"

export default function Checkbox(props) {
  const { selected, header, onClick, disabled, color, children } = props
  const [iconState, setIconState] = useState(selected)

  //TODO: clarify header functionality
  const iconName = iconState
    ? header
      ? "CheckboxMultiDeselectK3"
      : "CheckboxActiveK3"
    : "CheckboxInactiveK3"

  function _onClick() {
    if (!disabled) {
      setIconState(!iconState)
      if (onClick) onClick(iconState)
    }
  }

  return (
    <div 
      data-test-id='SlFx'
      onClick={() => _onClick()}
      className={`checkbox-k3 ${disabled ? "checkbox-disabled" : ""}`}
    >
      <SvgIcon
        name={iconName}
        className={`checkbox-icon ${color ? "checkbox-icon-" + color : ""} `}
      />
      {children}
    </div>
  )
}

Checkbox.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  selected: PropTypes.bool
}
