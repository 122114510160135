import { Redirect } from "react-router"
import { myLazy } from "utils/myLazy"

export const adminProductRoutes = [
  {
    exact: true,
    path: "/admin/product/b2b/list",
    component: myLazy(() => import("views/admin/product/productList")) // DONE
  },
  {
    exact: false,
    path: "/admin/product/b2b/form",
    component: myLazy(() => import("views/admin/product/productForm/productForm")) // DONE
  },
  {
    exact: false,
    path: "/admin/product/b2brelations/list",
    component: myLazy(() =>
      import("views/admin/product/relationList/relationList")
    ) // DONE
  },
  {
    exact: true,
    path: "/admin/product/compano/list",
    component: myLazy(() => import("views/admin/product/companoList")) // DONE
  },
  {
    exact: false,
    path: "/admin/product/compano/form",
    component: myLazy(() =>
      import("views/admin/product/productCompanoForm/productCompanoForm")
    )
  },

  //===================================================================
  //4.3
  {
    exact: true,
    path: "/admin/product/discount/list",
    component: myLazy(() => import("views/admin/product/grilaDiscounts/List")) // DONE
  },
  {
    exact: false,
    path: "/admin/product/discount/form",
    component: myLazy(() =>
      import("views/admin/product/grilaDiscounts/GrilaForm")
    )
  },
  //===================================================================
  //4.4 - Preturi nete nete
  {
    exact: true,
    path: "/admin/product/net/list",
    component: myLazy(() => import("views/admin/product/price/List")) // DONE
  },

  {
    exact: false,
    path: "/admin/product/net/form",
    component: myLazy(() => import("views/admin/product/productNetForm")) // DONE
  },

  // 4.5 Volume
  {
    exact: true,
    path: "/admin/product/volume/list",
    component: myLazy(() =>
      import("views/admin/product/volumeDiscount/volumeDiscountList")
    ) // DONE
  },
  {
    exact: false,
    path: "/admin/product/volume/form",
    component: myLazy(() =>
      import("views/admin/product/volumeDiscount/productVolumeForm")
    ) // DONE
  },

  //===================================================================
  //4.6 Pachete
  {
    exact: true,
    path: "/admin/product/pack/list",
    component: myLazy(() => import("views/admin/product/pack/packTable"))
  },
  //   {
  //     exact: true,
  //     path: "/admin/product/pack/form/add",
  //     component: myLazy(() => import("views/dev/norbert/wizzards/AdaugaPachet/wizzardForm.jsx")),
  //   },
  {
    exact: false,
    path: "/admin/product/pack/form",
    component: myLazy(() => import("views/admin/product/pack/packForm"))
  },

  // 4.7 Matricea de produse
  {
    exact: true,
    path: "/admin/product/matrix/list",
    component: myLazy(() => import("views/admin/product/productMatrix"))
  },

  //===================================================================
  //4.8 Badges
  {
    exact: true,
    path: "/admin/product/badge/list",
    component: myLazy(() => import("views/admin/product/badge/badgeList")) // DONE
  },
  {
    exact: false,
    path: "/admin/product/badge/form",
    component: myLazy(() =>
      import("views/admin/product/badge/badgeForm/badgeForm")
    )
  },
  // { //moved to modal
  //     exact: true,
  //     path: "/admin/product/badge/form/add",
  //     component: myLazy(() => import("views/admin/product/badge/addBadgeWizzard/addBadgeWizzard")),
  // },

  //===================================================================
  //4.9 QA

  {
    exact: true,
    path: "/admin/product/qa/list",
    component: myLazy(() =>
      import("views/admin/intrebariSiRaspunsuri/intrebariSiRaspunsuri")
    )
  },
  {
    exact: false,
    path: "/admin/product/qa/form",
    component: myLazy(() =>
      import("views/admin/product/customer/qa/questionsAnswersForm")
    )
  },

  //===================================================================
  //4.10 Reviews
  {
    exact: true,
    path: "/admin/product/review/list",
    component: myLazy(() => import("views/admin/recenzii/recenzii"))
  },
  {
    exact: false,
    path: "/admin/product/review/form",
    component: myLazy(() => import("../views/admin/recenzii/form/RecenziiForm"))
  },

  //===================================================================
  //4.11 Doc Techince
  {
    exact: true,
    path: "/admin/product/document/list",
    component: myLazy(() => import("views/admin/documents/documentsList")) // DONE
  },
  {
    exact: false,
    path: "/admin/product/document/form",
    component: myLazy(() => import("views/admin/documents/documentsForm")) // DONE
  },
  //==============================================================
  // Warehouse
  {
    exact: true,
    path: "/admin/product/warehouse/list",
    component: myLazy(() =>
      import("../views/admin/depoziteUniprest/depoziteUniprest")
    )
  },
  {
    exact: false,
    path: "/admin/product/warehouse/details",
    component: myLazy(() => import("../views/admin/product/warehouseDetails"))
  },
  //==============================================================================
  //4.14 Interes produse fara pret
  {
    exact: true,
    path: "/admin/product/pricerequest/list",
    component: myLazy(() => import("views/admin/product/priceRequest/List"))
  },
  {
    exact: true,
    path: "/admin/product/pricerequest/form",
    component: myLazy(() =>
      import("views/admin/product/priceRequest/priceRequestForm")
    )
  },
  //===================================================================
  //4.13 Interes produse fara stoc
  {
    exact: true,
    path: "/admin/product/stockrequest/list",
    component: myLazy(() => import("views/admin/product/stockRequest/List"))
  },
  {
    exact: true,
    path: "/admin/product/stockrequest/form",
    component: myLazy(() =>
      import("views/admin/product/stockRequest/stockRequestForm")
    )
  },
  //===================================================================
  {
    exact: true,
    path: "/admin/product/dashboard",
    component: myLazy(() => import("views/admin/unifiedDashboard"))
  },
  {
    exact: false,
    path: "/admin/product",
    component: () => <Redirect to="/admin/product/dashboard" />
  }
]
