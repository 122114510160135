import React, { useState } from "react"
// import ExpandableSection from "components/VerticalFilters/ExpandableSection/ExpandableSection";
import Close from "assets/general/Close.svg"
import "./index.scss"
import { useDispatch, useSelector } from "react-redux"
import { activateSavedFilter, deleteSavedFilter } from "store/modules/dataView"

// let initialFilters = [
//   { id: "dsdsd", name: "Filtrul A" },
//   { id: "dsdsdff", name: "Filtrul B" },
//   { id: "dsdsdsd", name: "Filtrul C" }
// ];

let loadMore = "Incarca mai multe"
export default function SavedFilters() {
  const savedFilters = useSelector((state) => state?.dataView.savedFilters)
  const dispatch = useDispatch()
  const [search, setSearch] = useState("")
  const onLoadMore = () => {
    console.log("load more")
  }

  const onDelete = (e, filterId) => {
    dispatch(deleteSavedFilter(filterId))
    e?.stopPropagation?.()
  }
  return (
    <>
      <input 
        data-test-id='lscf'
        className="filterSearch ptype-3"
        placeholder="Cauta"
        value={search}
        onChange={(e) => setSearch(e?.target?.value)}
      />
      {savedFilters
        ?.filter?.((item) => !search || item?.name?.includes?.(search))
        ?.map?.((filter, index) => (
          <div 
            data-test-id='v1D2'
            key={filter.id}
            className="filter-pill"
            onClick={() => dispatch(activateSavedFilter(filter.id))}
          >
            <div data-test-id='Lcdi' className="ptype-3">{filter?.name}</div>
            <img 
              data-test-id='BFxu'
              onContextMenu={(e) => e?.preventDefault?.()}
              src={Close}
              className="filter-delete"
              alt="del"
              onClick={(e) => onDelete(e, filter.id)}
            />
          </div>
        ))}
      {loadMore && (
        <div data-test-id='NgAJ' className="filter-load-more ptype-2" onClick={() => onLoadMore()}>
          {loadMore}
        </div>
      )}
    </>
  )
}
