import SvgIcon from "components/SvgIcon/SvgIcon"
import "./index.scss"
import MagicButton from "components/MagicButton/MagicButtonV2"
import { useController } from "react-hook-form"
import { NumericFormat as NumberFormat } from "react-number-format"
import { useState } from "react"

const MagicInput = (props) => {
  const { control } = props
  if (control) return <MagicInputIntegrated {...props} />

  return <MagicInputBase {...props} />
}

const MagicInputIntegrated = (props) => {
  const { control, name } = props
  const {
    field: { value, onChange, onBlur }
    // fieldState, //{ invalid, isTouched, isDirty },
    // formState, // { touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    defaultValue: ""
  })

  return <MagicInputBase {...{ ...props, value, onChange, onBlur }} />
}
const MagicInputBase = (props) => {
  const {
    label,
    value: inputValue,
    onChange,
    onBlur,
    textArea,
    className,
    name,
    icon,
    error,
    grey,
    white,
    blue,
    outline,
    disabled,
    small,
    ptype5,
    actionClick,
    actionIcon,
    rightComponent,
    placeholder,
    svgProps,
    fake,
    showModal,
    gridArea,
    narrow,
    tableRendered,
    numberFormatter,
    onFocus,
    ...rest
  } = props
  const [isBeingFocused, setIsBeingFocused] = useState(false)
  const value = numberFormatter
    ? getFormattedValue(inputValue, isBeingFocused)
    : inputValue ?? ""
  const inputLabel = label ? (error && error?.length ? error : label) : undefined
  let inputLabelClass = "label"
  let mainClass = `magic-input input-${name} ${className ?? ""}`
  let inputContainer = "inputContainer"

  if (grey) {
    inputContainer += " grey"
  }
  if (blue) {
    inputContainer += " blue"
  }
  if (white) {
    inputContainer += " white"
  }
  if (disabled) inputContainer += " disabled"

  if (outline) {
    inputContainer += " outline"
  } else {
    inputContainer += " grey"
  }
  if (error) {
    // inputLabelClass += " error"
    mainClass += " error"
    inputContainer += " error"
  }

  const inputContainerStyle = {
    minHeight: "40px"
  }

  function _onClick() {
    if (actionClick) actionClick()
  }

  let inputExtraClass = ""
  if (small) inputExtraClass += " small"
  if (narrow) inputExtraClass += " narrow"
  if (tableRendered) inputExtraClass += " tableRendered"
  const mainDivStyle = {
    gridArea: gridArea ? (gridArea === true ? name : gridArea) : undefined
  }
  return (
    <div data-test-id='rzL9' className={mainClass} style={mainDivStyle}>
      {inputLabel && <div data-test-id='2ADJ' className={inputLabelClass}>{inputLabel}</div>}
      <div data-test-id='MMmp' style={inputContainerStyle} className={inputContainer}>
        {icon && <SvgIcon name={icon} {...svgProps} />}
        {numberFormatter && (
          <NumberFormat
            thousandSeparator="."
            decimalSeparator=","
            {...{
              name,
              value,
              onFocus: onFocus
                ? (e) => onFocus(e, setIsBeingFocused)
                : undefined,
              onBlur: (e) =>
                onBlur ? onBlur(e, setIsBeingFocused) : undefined,
              disabled
            }}
            onValueChange={(values, sourceInfo) => {
              //console.log("SOURCE= ", sourceInfo?.source);
              sourceInfo?.source === "event"
                ? setIsBeingFocused?.(true)
                : setIsBeingFocused?.(false)
              sourceInfo?.source === "event" &&
                onChange &&
                onChange({
                  target: {
                    name: name,
                    value: values?.value
                  },
                  sourceInfo
                })
            }}
            //isNumericString={true}
            fixedDecimalScale={isBeingFocused ? undefined : 2}
            decimalScale={isBeingFocused ? undefined : 2}
          />
        )}
        {textArea ? (
          <textarea 
            data-test-id='BUyQ'
            placeholder={placeholder ?? ""}
            className={inputExtraClass}
            {...{ name, value, onChange, onBlur, disabled, ...rest }}
          />
        ) : fake ? (
          <div data-test-id='2KRo' className={`fake-input ${inputExtraClass}`}>{value}</div>
        ) : !numberFormatter ? (
          <input 
            data-test-id='tIlC'
            placeholder={placeholder ?? ""}
            className={inputExtraClass}
            {...{ name, value, onChange, onBlur, disabled, ...rest }}
          />
        ) : null}
        {actionIcon && (
          <MagicButton
            outline
            showModal={showModal}
            onClick={_onClick}
            svg={actionIcon}
            className="actionIcon"
            px={0}
            w={8}
            h={4}
          />
        )}
        {rightComponent ?? rightComponent}
      </div>
    </div>
  )
}

export default MagicInput

const getFormattedValue = (inputValue, isBeingFocused) => {
  const floatValue = parseFloat(inputValue)
  return isBeingFocused ? floatValue : floatValue || 0
}
