const searchList = {
  categories: {
    GOODS: "IN PRODUSE",
    ORDER: "IN COMENZI EXTERNE",
    CLIENT_INVOICE: "IN FACTURI",
    BASKET: "IN COMENZI B2B"
    //liste:"IN LISTE",
    //articole:"IN ARTICOLE"
  },
  icons: {
    GOODS: "onlinestore",
    ORDER: "warehouse",
    CLIENT_INVOICE: "invoice",
    BASKET: "cartempty"
  },
  all: {
    url: {
      GOODS: "/shop/productlist?searchString=",
      ORDER: "/shop/orders?searchString=", //not sure yet CLIENT_ORDER
      CLIENT_INVOICE: "/shop/invoices?searchString=", //invoices
      BASKET: "/shop/orders?searchString=" //not sure yet CLIENT_BASKET
    },
    label: "vezi toate",
    uppercase: true,
    visible: true
  },
  dbList: [
    {
      id: 1,
      category: "produse",
      name: "Robinet Schneider A5441",
      url: "#",
      icon: "Onlinestore"
    },
    {
      id: 2,
      category: "produse",
      name: "Robinet Schneider A9954",
      url: "#",
      icon: "Onlinestore"
    },
    {
      id: 3,
      category: "produse",
      name: "Robinet Schneider A5441",
      url: "#",
      icon: "Onlinestore"
    },
    {
      id: 4,
      category: "produse",
      name: "Robinet Schneider A9954",
      url: "#",
      icon: "Onlinestore"
    },
    {
      id: 5,
      category: "articole",
      name: "Altceva",
      url: "#",
      icon: "Documents3"
    },
    {
      id: 6,
      category: "comenzi",
      name: "Comanda A12312312312412412 (Robinet Schneide A231)",
      url: "#",
      icon: "Warehouse"
    },
    {
      id: 7,
      category: "comenzi",
      name: "Comanda B12312312312412412 (Robinet Schneide A991)",
      url: "#",
      icon: "Warehouse"
    },
    {
      id: 8,
      category: "facturi",
      name: "Factura F123123123 (Robinet Schneide A991)",
      url: "#",
      icon: "InvoiceIcon"
    },
    {
      id: 9,
      category: "liste",
      name: "Lista Apartament Costel (Robinet Schneide A991)",
      url: "#",
      icon: "Listmodels"
    },
    {
      id: 10,
      category: "liste",
      name: "Lista Tot ce ai nevoie pentru o casa de pana in 200mp (Robinet Schneide A991)",
      url: "#",
      icon: "Uniprest"
    },
    {
      id: 11,
      category: "articole",
      name: "Brandul Xing a lansat un robinet de aur",
      url: "#",
      icon: "Documents3"
    }
  ],
  seeAll: {
    visible: true,
    text1: "Cauta ",
    text2: " in ",
    link: {
      url: "#",
      label: "Documente Tehnice",
      uppercase: false
    }
  }
}
export default searchList
