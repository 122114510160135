// import { Component } from 'react';
import { useController } from "react-hook-form"
import Picker from "react-mobile-picker"

import "./CuatomPicker.scss"

// const RollingSelectInput = (props) => {
//   const {control} = props;
//   if (control) return <RollingSelectInputIntegrated {...props}/>

//   return <RollingSelectInputBase {...props}/>
// }

// const RollingSelectInputIntegrated = (props) => {
//   const {control, name} = props;
//   const {
//     field: {value, onChange, onBlur},
//     // fieldState, //{ invalid, isTouched, isDirty },
//     // formState, // { touchedFields, dirtyFields }
//   } = useController({
//     name,
//     control,
//     defaultValue: '',
//   });

//   return <RollingSelectInputBase {...{...props, value, onChange, onBlur}}/>
// }

function RollingSelectInput(props) {
  const { options, unit, value, onChange } = props
  const handleChange = (name, value) => {
    onChange(value?.replace?.(` ${unit}`, ""))
  }
  const optionGroups = {
    quantity: options?.map?.((item) => `${item} ${unit}`) ?? []
  }
  const valueGroups = { quantity: `${value} ${unit}` }
  return (
    <Picker
      height={280}
      {...{ optionGroups, valueGroups }}
      onChange={handleChange}
    />
  )
}

export default RollingSelectInput

// class CustomPicker extends Component {

//   constructor(props) {
//     super(props);
//     this.state = {
//       valueGroups: { secondName: this.props.picker?.[0] },
//       optionGroups: { secondName: this.props.picker }
//     };
//   }

//   // Update the value in response to user picking event
//   handleChange = (name,value) => {
//     this.setState(({valueGroups}) => ({ valueGroups: { ...valueGroups,[name]: value} }));
//   };

//   render() {
//     const {optionGroups, valueGroups} = this.state;
//     return (
//       <Picker
//         height={280}
//         optionGroups={optionGroups}
//         valueGroups={valueGroups}
//         onChange={this.handleChange}
//       />
//     );
//   }
// }

// export default CustomPicker;
