import React from "react"
import MagicButton from "components/MagicButton/MagicButtonV2"
import GlobalConnectionError from "assets/iconsK3/GlobalConnectionAlert.svg"
import "./PriceChangedModal.scss"
import { setDictionary } from "store/modules/backData"
import { useDispatch, useSelector } from "react-redux"
import FormatComponent from "components/FormatComponent/FormatComponent"
import { addToBasket, modifyBasket } from "store/modules/shop"
import { searchStuff } from "store/modules/dataView.search"
import Close from "assets/general/Close.svg"

const PriceChangedModal = ({ location }) => {
  const dispatch = useDispatch()
  const dictionary = useSelector((state) => state?.backData?.dictionary)

  const firstText = `<strong data-test-id='B1Mw'>Pretul la produsul ${dictionary?.data?.name} s-a schimbat!</strong>`

  return (
    <>
      <div data-test-id='qo9Q' className="central-modal-overlay fixed-modal big-modal no-footer">
        <div data-test-id='YVux' className="list-modal-wrapper modal-content" style={{position: "relative"}}>
          <div data-test-id='4PTF' style={{ position: "absolute", right: "1rem", top: "1rem"}}>
            <img 
                data-test-id='3ZPC'
                onContextMenu={(e) => e?.preventDefault?.()}
                src={Close}
                className="close round-clear"
                alt="clear"
                onClick={() => dispatch(setDictionary({ priceChanged: false }))}
            />
          </div>
          <div data-test-id='wsZd' className="header">
            <div data-test-id='fToH'></div>
          </div>
          <div data-test-id='6LA1' className="modal-body">
            <body data-test-id='blMG' className="page-404-wrapper">
              <div data-test-id='8RyJ' className="wrapper" style={{ "max-width": "700px" }}>
                <div 
                  data-test-id='xKry'
                  className="inside-logo"
                  style={{
                    display: "flex",
                    "flex-direction": "column",
                    "align-items": "center"
                  }}
                >
                  <img 
                    data-test-id='XzRu'
                    style={{ "margin-left": "30px" }}
                    onContextMenu={(e) => e?.preventDefault?.()}
                    alt="logo"
                    src={GlobalConnectionError}
                  />
                  <p 
                    data-test-id='Mzob'
                    className="page-not-found-text mb4"
                    style={{ "text-align": "center" }}
                    dangerouslySetInnerHTML={{ __html: firstText }}
                  />
                  <span data-test-id='zIpu' style={{ display: "flex" }}>
                    <p 
                      data-test-id='00qq'
                      className="price ptype-5-strong text-light-01 page-not-found-text mb4"
                      style={{ "text-align": "center" }}
                    >
                      Pretul vechi:{" "}
                    </p>
                    <div data-test-id='NR9l' style={{ "margin-left": "5px", "margin-top": "11px" }}>
                      <FormatComponent
                        number={dictionary?.oldNetPrice}
                        formatStyle={"price ptype-5-strong text-light-01"}
                        suffixText={"RON"}
                      />
                    </div>
                  </span>
                  <span data-test-id='LBV2' style={{ display: "flex" }}>
                    <p 
                      data-test-id='96jc'
                      className="price ptype-5-strong text-light-01 page-not-found-text mb4"
                      style={{ "text-align": "center" }}
                    >
                      Pret nou:{" "}
                    </p>
                    <div data-test-id='t108' style={{ "margin-left": "5px", "margin-top": "11px" }}>
                      <FormatComponent
                        number={dictionary?.data?.priceNet}
                        formatStyle={"price ptype-5-strong text-light-01"}
                        suffixText={"RON"}
                      />
                    </div>
                  </span>
                  <MagicButton
                    className="mt5"
                    h={8}
                    mt={5}
                    p={1}
                    w={27}
                    disabled={false}
                    label={`Am inteles`}
                    onClick={() => {
                      dispatch(
                        addToBasket(
                          dictionary?.data?.id,
                          dictionary?.oldProductQuantity,
                          dictionary?.data?.priceNet
                        )
                      )
                      //dispatch(searchStuff({reload:true,type: "product/goods/shop", sortField: "ranking", sortAsc: false }))

                      setTimeout(() => {
                        dispatch(setDictionary({ priceChanged: false }))
                      }, 500)
                    }}
                  />
                </div>
              </div>
            </body>
          </div>
        </div>
      </div>
    </>
  )
}

export default PriceChangedModal
