import PropTypes from "prop-types"
import SvgIcon from "components/SvgIcon/SvgIcon"

export default function Tag(props) {
  const {
    label,
    onIconClick,
    backgroundColor,
    iconColor,
    isTiny,
    iconName,
    fontColor,
    disabled,
    className,
    labelClass
  } = props

  const _isTiny = isTiny || iconName === undefined
  const bgColorClass =
    !disabled && backgroundColor ? "tag-bg-" + backgroundColor : ""
  const fontColorClass = !_isTiny && fontColor ? "tag-color-" + fontColor : ""
  const iconColorClass = disabled
    ? "tag-disabled"
    : iconColor
    ? "tag-fill-" + iconColor
    : ""
  const sizeClass = onIconClick ? "medium" : "tiny"
  const tagIconName = iconName ?? "closek3"

  function iconClickHandler(data) {
    if (onIconClick && !disabled) onIconClick(data)
  }

  return (
    <div 
      data-test-id='87Zv'
      className={`tag-wrapper ${bgColorClass} ${sizeClass} ${className ?? ""}`}
    >
      {label && (
        <div data-test-id='OMNF' className={`${fontColorClass} ${labelClass ?? ""}`}>{label}</div>
      )}
      {!_isTiny && !disabled && (
        <div data-test-id='rKqP' className="icon-container" onClick={() => iconClickHandler(label)}>
          <SvgIcon
            name={tagIconName}
            className={`tag-icon ${iconColorClass}`}
          />
        </div>
      )}
    </div>
  )
}

Tag.propTypes = {
  label: PropTypes.string,
  backgroundColor: PropTypes.string,
  fontColor: PropTypes.string,
  iconColor: PropTypes.string,
  onClose: PropTypes.func,
  disabled: PropTypes.bool,
  isTiny: PropTypes.bool
}
