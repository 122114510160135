import { sanitizeAnythingForRedux } from "utils/preventReactInRedux"

const GET_INVOICES = "table/GET_INVOICES"
const SET_EDITMODE = "table/SET_EDITMODE"
const TOGGLE_PAGE_EDIT = "table/TOGGLE_PAGE_EDIT"

const invoiceList = [
  {
    title: "Facturi",
    type: "achitat",
    price: "15.200",
    date: "Vineri, 28 Aprilie",
    articole: null
  },

  {
    title: "Facturi",
    type: "recent",
    price: "24.871,22",
    date: "Marti, 02 Mai",
    articole: null
  },
  {
    title: "Comenzi",
    type: "next",
    date: "Martie 2021",
    articole: 1250
  },
  {
    title: "Comenzi",
    type: "active",
    price: "24.871,22",
    date: "Martie 2021"
  },

  {
    title: "Facturi",
    type: "restante",
    price: "24.871,22",
    date: "Marti, 02 Mai",
    articole: null
  },
  {
    title: "Facturi",
    type: "total",
    price: "24.871,22",
    date: "Marti, 02 Mai",
    articole: null
  }
]

const initialState = {
  invoices: [...invoiceList || []],
  isEdit: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, unsanitizedAction) {

  const action = sanitizeAnythingForRedux(unsanitizedAction)

  switch (action.type) {
    case GET_INVOICES:
      return {
        ...state,
        invoices: action.payload
      }
    case SET_EDITMODE:
      return {
        ...state,
        isEdit: action.payload
      }
    case TOGGLE_PAGE_EDIT:
      return {
        ...state,
        isEdit: !state?.isEdit
      }
    default:
      return state
  }
}

export const setInvoices = (data) => {
  return {
    payload: data,
    type: GET_INVOICES
  }
}

export const setEditmode = (data) => {
  return {
    payload: data,
    type: SET_EDITMODE
  }
}

export const toggleCmpPageEdit = () => {
  return {
    type: TOGGLE_PAGE_EDIT
  }
}
