import React, { useEffect, useState, useRef } from "react"
// import LongProductCard from "components/LongProductCard"
// import MagicButton from "components/MagicButton/MagicButtonV2"
import ProductCard from "components/ProductCard"
import "./index.scss"
import { useDispatch, useSelector } from "react-redux"
import {
  clearDataViewItems,
  deleteSavedFilter,
  // setDataItems,
  setDataViewId,
  // setDVsearchString,
  setFilterSelection
} from "store/modules/dataView"
import VerticalFilters from "components/VerticalFilters/VerticalFilters"
import { selectProductListDataV2 } from "store/selectors/dataView/dataView.productsListFinal.selector"
import { loadProductCategories } from "store/modules/backData"
// import { loadViewData } from "store/modules/dataView/dataView-dataLoader"
import HorizontalFilterUniprest from "views/dev/select/HorizontalFilterUniprest"
import { setScrollPostion, setPageYScrollPosition, setPageModal, clearAllLoadingIndicators } from "store/modules/page"
import { getCart } from "store/modules/shop"
import DeliveryNotificationCard from "views/productsList/DeliveryNotificationCard"
import { loadOrganizationLists } from "store/justActions/lists"
import { useUserInfo } from "utils/useUserInfo"
import AddToListModal from "components/ProductCard/AddToListModal"
import NoSearchResults from "views/productsList/NoSearchResults"
import ShopBreadCrumbs from "components/BreadCrumbs/ShopBreadCrumbs"
import { loadItem } from "store/justActions/loadApiIems"
import LoadingScreen from "components/LoadingScreen/LoadingScreen"
import useScrollPosition from "utils/useScrollPosition"
import PriceChangedModal from "components/PriceChangedModal/PriceChangedModal"
import { round } from "utils/serviceFunctions"
// import { numberFormatter } from "utils/stringUtils"
import { deleteCrtListItem } from "store/modules/page"
// import {
//   deleteProductFromList,
//   updateListQuantityAction
// } from "store/justActions/lists"
// import FormatComponent from "components/FormatComponent/FormatComponent"
// import { saveStockRequest, savePriceRequest } from "store/modules/guest.actions"
// import { setLastAddedToCart } from "store/modules/dataView"
import BigModal from "components/_modals/BigModal"
import CartProductCard from "views/shoppingCart/cartProductCard"
// import { loadAccessories } from "store/justActions/loadApiIems"
import { addArrayToBasket } from "store/modules/shop"
import SvgIcon from "components/SvgIcon/SvgIcon"
import useWindowSize from "utils/useWindowsSize"
import OnBehalf from "views/productsList/OnBehalf"
//import EmptyState from "views/guest/news/GuestCardsList"
import useMagicForm from "utils/useMagicForm"
import { Lang, lang, Translate, withLocalize } from "components/Lang/Lang"
import useCategoryUrl from "./useCategoryUrl"
import BasketErrorModal from "components/_modals/BasketErrorModal"
import Pagination from "components/Pagination/Pagination"
import useIsLogged from "utils/useIsLogged"
import { getCartDeliveryAddress } from "store/justActions/miscApiInteractions"
import useQueryStringFilters, { urlFriendlyToSortBy } from "utils/useQueryStringFilters"

// const clientTypes = ["guest", "small", "reseller", "large"]
// const badgeTitles = [
//   "PROMO",
//   "Deal of the day",
//   "Cel mai bun pret!",
//   "Recomandarea Expertului"
// ];

const filterIdsAuth = ["FLT-CATMEGA1", "FLT-SEARCH01", "FLT-sort00p1"]
// const filterIdsAuth = ["FLT-SEARCH01", "FLT-sort00p1","FLT-sort0prc", "FLT-tipClient" ]
const filterIdsGuest = ["FLT-CATMEGA1", "FLT-SEARCH01"]
const verticalFilterIds = ["FLT-CATEG001", "FLT-SMaaaa00"]
// const verticalFilterIds= [ "FLT-CATEG001","FLT-RGaaaa00", "FLT-SMaaaa11", 'FLT-SMaaaa00','FLT-5Saaaa00' ]


const ProductsList = (props) => {
  const customUrl = props?.match?.params?.customUrl
  const dispatch = useDispatch()
  const isLogged = useIsLogged()
  // const [listType, setListType] = useState("cards");
  const priceChanged = useSelector(
    (state) => state?.backData?.dictionary?.priceChanged
  )
  const selectedFilters = useSelector(
    (state) => state?.dataView?.selectedFilters
  )
  const listType = useSelector((state) => state?.shop?.viewType)
  // const lastItemHint = useSelector((state) => state?.dataView?.lastItemHint)
  // const inputSearchString = window.location.search
  //   ?.split?.("?")[1]
  //   ?.split?.("&")
  //   ?.find?.((item) => item?.includes?.("searchString="))
  //   ?.split?.("=")[1]
  // const filterId = window.location.search
  //   ?.split?.("?")[1]
  //   ?.split?.("&")
  //   ?.find?.((item) => item?.includes?.("filterId="))
  //   ?.split?.("=")[1]
  // const filterSelection =
  //   "#" +
  //   window.location.search
  //     ?.split?.("?")[1]
  //     ?.split?.("&")
  //     ?.find?.((item) => item?.includes?.("filterSelection="))
  //     ?.split?.("=")[1]
  //     ?.split?.("-")
  //     ?.join?.("#")
  // const brandId = window.location.search
  //   ?.split?.("?")[1]
  //   ?.split?.("&")
  //   ?.find?.((item) => item?.includes?.("brandId="))
  //   ?.split?.("=")[1]
  // const brandSelection = window.location.search
  //   ?.split?.("?")[1]
  //   ?.split?.("&")
  //   ?.find?.((item) => item?.includes?.("brandSelection="))
  //   ?.split?.("=")[1]
  //   ?.split?.("%20")
  //   ?.join?.(" ")

  // const [clientType, setClientType] = useState(0);

  const { products, count, isLoadable } = useSelector((state) =>//searchString
    selectProductListDataV2(state, props?.translate)
  )
  // const clientType = selectedFilters?.["FLT-tipClient"]
  const { companyType } = useUserInfo()
  useCategoryUrl({ customUrl })

  const showSavedFilters = false && companyType !== "GUEST" //disable untill ready
  const horizontalFilter =
    companyType !== "GUEST" ? filterIdsAuth : filterIdsGuest

  const windowSize = useWindowSize()
  const pageContainerRef = useRef()
  const scrollContainerRef = useRef()
  const ref = useRef(null)
  // const storedScrollPosition = useSelector(
  //   (state) => state?.page?.setScrollPosition
  // )
  const { page: activePage, filters, searchString, sortBy } = useQueryStringFilters()

  const userPermissions = useSelector(
    (state) => state.auth?.userData?.role?.permissions
  )
  const companyId = useSelector((state) => state?.user?.organizationId)
  const currentSearchString = useSelector(
    (state) => state?.dataView?.searchString
  )
  const accesoriesModalData = useSelector((state) => state?.page?.modalData)
  const crtList = useSelector((state) => state?.page?.crtList)
  // const [pageScroll, setPageScroll] = useState()
  // const [pageYPosition, setPageYPosition] = useState()
  const disableSave = companyType === "GUEST"


  useEffect(() => {
    const expectedDataViewId = isLogged ? "java-product-client" : "notLogged-java-product-client"
    dispatch(setDataViewId(expectedDataViewId))
  }, [isLogged])

  useEffect(() => {
    if(isLogged && companyId) {
      dispatch(loadItem( "organization/webshop-client-productdetails", companyId, true, true))
    }
  }, [isLogged, companyId])

  useEffect(() => {
    if(isLogged) {
      dispatch(getCart())
      dispatch(getCartDeliveryAddress())
      dispatch(loadOrganizationLists())
      dispatch(loadItem("organization/my-organization", "", true, true))
      
    }
    // dispatch(getCart())
    // dispatch(getCartDeliveryAddress())
    dispatch(deleteSavedFilter())
    // dispatch(setDataItems(mockGoods));
    //dispatch(loadProductCategories())
 
    // if (inputSearchString)
    //   dispatch(setDVsearchString(decodeURI(inputSearchString)))



    // Set the initial sortBy based on the url
    dispatch(setFilterSelection("FLT-sort00p1", [urlFriendlyToSortBy(sortBy || "ranking")]))
    
    
    
    
    // // if (filterId && filterSelection) {
    // //   dispatch(setFilterSelection(filterId, [filterSelection]))
    //   // setTimeout(() => {
    //   //   dispatch(loadViewData({reload:true}))
    //   // }, 200);
    // }
    // if (brandId && brandSelection) {
    //   dispatch(setFilterSelection(brandId, [brandSelection]))
    //   // setTimeout(() => {
    //   //   dispatch(loadViewData({reload:true}))
    //   // }, 200);
    // }
    // // dispatch(searchStuff({ type: "product/goods"}))
    // setTimeout(() => {
    //   dispatch(loadViewData({reload:true}))
    // }, 200);
  }, [isLogged])

  // useEffect(() => {
  //   // setTimeout needed because loading the products takes a little while, so we want to scroll to the stored redux position only after the products are loaded
  //   setTimeout(() => {
  //     pageContainerRef.current?.parentElement?.parentElement?.scroll({
  //       top: storedScrollPosition,
  //       behavior: "smooth"
  //     })
  //   }, 20)
  // }, [storedScrollPosition, selectedFilters, searchString])
  // useEffect(() => {
  //   dispatch(setPageYScrollPosition(pageYPosition))
  // }, [pageYPosition])
  useEffect(() => {
    return () => {
      dispatch(clearDataViewItems())
      dispatch(clearAllLoadingIndicators())
    }
  },[])
  const previousPage = useRef(1)
  useEffect(() => {
    // When the page changes, scroll to top
    if(windowSize.width > 800) {
      tryToScrollToTop()
      if(previousPage) previousPage.current = activePage
    } else {
      if(previousPage) {
        if(activePage !== previousPage.current) {
          tryToScrollToTop()
          previousPage.current = activePage
        }
      }
    }
  }, [activePage, filters, searchString, windowSize])
  const resultStringFormatter = (count) =>
    `${count} ${count === 1 ? "produs gasit" : "produse gasite"}`

  // useScrollPosition(
  //   ({ prevPos, currPos }) => {
  //     const classForVerticalFilter = prevPos?.y > currPos?.y ? "down" : "up"
  //     setPageScroll(classForVerticalFilter)
  //     setPageYPosition(
  //       pageContainerRef.current?.parentElement?.parentElement?.scrollTop
  //     )
  //   },
  //   [pageContainerRef.current],
  //   pageContainerRef?.current?.parentElement?.parentElement,
  //   scrollContainerRef?.current,
  //   false,
  //   300
  // )
  const addAllProductsToCart = () => {
    const mainProduct = [
      {
        goodsId: accesoriesModalData?.id,
        quantity: accesoriesModalData?.quantity
      }
    ]
    const accessoriesToAdd = crtList?.items?.map?.((p) => ({
      goodsId: p.id,
      quantity: p.quantity
    }))
    const itemsToAdd = [...mainProduct || [], ...accessoriesToAdd || []]
    if (itemsToAdd?.length > 0)
      dispatch(addArrayToBasket(itemsToAdd, "Accessories"))
  }
  // const thereIsConnectionErrorWithGlobal = false
  const onBehalfId = useSelector((state) => state?.page?.onBehalfId)

  const {
    // submitForm,
    // disableSubmit,
    getProps,
    // setEdit,
    // errors,
    watch,
    // reset,
    // cancelEdit,
    // isEdit
  } = useMagicForm({ formName: "OnBehalf", defaultValues: {allClientList: onBehalfId } })

  return (
    <>
      {!priceChanged ? (
        <div data-test-id='43Xp' ref={pageContainerRef}>
          {/*  */}
          {userPermissions?.includes?.("CLIENT_CHANGE_WRITE") && (
            <OnBehalf
              showLongText={windowSize.width > 1185}
              watch={watch}
              getProps={getProps}
            />
          )}
          {companyType !== "GUEST" && (
            <DeliveryNotificationCard
              showLongText={windowSize.width > 1185}
              watch={watch}
            />
          )}
          {companyType !== "GUEST" && false && (
            <div 
              data-test-id='OxeV'
              className={`delivery-notification-card-in-modal`}
              style={{ "background-color": "#24a148" }}
            >
              <div data-test-id='xrEM' className="mr4">
                <SvgIcon name={"warning"} />
              </div>
              <div data-test-id='jkjG' className={`content-container`}>
                <div data-test-id='9siD'>
                  <span data-test-id='WlhI'>
                    <Lang value="productlist.calculated" />
                  </span>
                </div>
              </div>
            </div>
          )}
          <div 
            data-test-id='IY7E'
            className="h-filter-container bg-white mb4 main-shop-filter"
            id="horizontal-filter-id"
          >
            <HorizontalFilterUniprest
              noMargins
              {...{ filterIds: horizontalFilter }}
              wideSearch
              cardButton
              noMore={windowSize.width < 800}
            />
            {count > 0 && (
              <div 
                data-test-id='77GH'
                className={"flex justifyEnd pr4 pb2 ptype-5-strong"}
              >{`${resultStringFormatter(count)}`}</div>
            )}
          </div>
          <div data-test-id='3dW8' className="productsListContainer">
            <div data-test-id='5b9K' className="pl-left">
              <VerticalFilters
                filterIds={verticalFilterIds}
                {...{ showSavedFilters, disableSave }}//, pageScroll
                showSelectedFilters
              />
            </div>
            <div data-test-id='19Tc' ref={ref} className={`pl-right ${!products?.length ? "empty" : ""}`}>
              <div 
                data-test-id='5cfG'
                ref={scrollContainerRef}
                className={
                  listType === "list"
                    ? "pl-main pl-listStyle"
                    : "pl-main pl-cardsStyle"
                }
              >
                <ShopBreadCrumbs />
                {(products?.length > 0 && isLoadable) ? (
                  products?.map?.((product) =>
                    listType === "list" ? (
                      <ProductCard
                        long
                        key={product.id}
                        {...product}
                        setScrollPosition={() =>
                          dispatch(
                            setScrollPostion(
                              pageContainerRef.current.parentElement
                                .parentElement.scrollTop
                            )
                          )
                        }
                      />
                    ) : (
                      <ProductCard
                        key={product.id}
                        {...product}
                        setScrollPosition={() =>
                          dispatch(
                            setScrollPostion(
                              pageContainerRef.current.parentElement
                                .parentElement.scrollTop
                            )
                          )
                        }
                      />
                    )
                  )
                ) : products?.length === 0 &&
                  currentSearchString === "" &&
                  selectedFilters?.["FLT-SMaaaa00"]?.length === undefined &&
                  selectedFilters?.["FLT-CATEG001"]?.length === undefined ? (
                  // <LoadingScreen />
                  <EmptyState
                    isOnBehalf={userPermissions?.includes?.(
                      "CLIENT_CHANGE_WRITE"
                    )}
                    hasAlreadySelectedOnBehalfCompany={onBehalfId}
                  />
                ) : (
                  <NoSearchResults />
                )}
              </div>

              <Pagination parentRef={ref}/>

            </div>
            <AddToListModal />
            <BasketErrorModal />
            <BigModal
              title={<Lang value="productlist.productAdded" />}
              name="add-related-products-to-basket"
              saveAction={addAllProductsToCart}
            >
              <div data-test-id='xtWY' className="flex alignCenter strokeIt mb2">
                <SvgIcon name="cartfull" />
                <div data-test-id='mZES' className="ptype-5-strong ml2">
                  <Lang value="productlist.productPurchased" />
                </div>
              </div>
              <CartProductCard
                noDelete
                noCancel
                {...{
                  ...accesoriesModalData,
                  id: accesoriesModalData?.id,
                  title: accesoriesModalData?.name,
                  productCode: accesoriesModalData?.code,
                  brandName: accesoriesModalData?.brandName,
                  primaryImage: accesoriesModalData?.mainImage,
                  primaryPrice: mainPriceCompute(accesoriesModalData), //item?.priceListNet,
                  secondaryPrice: secondaryPriceCompute(accesoriesModalData),
                  quantity: accesoriesModalData?.quantity,
                  unit: accesoriesModalData?.unitOfMeasure,
                  stockState:
                    accesoriesModalData?.quantity >
                    accesoriesModalData?.localStockQuantity +
                      accesoriesModalData?.centralStockQuantity
                      ? "low"
                      : "available",
                  key: `${accesoriesModalData?.id}-main`,
                  products: undefined, // needed to not display main product as pack
                  setPageModal
                }}
              />
              <div data-test-id='AdLq' className="flex alignCenter strokeIt mb2 mt6">
                <SvgIcon name="warning" />
                <div data-test-id='8HOr' className="ptype-5-strong ml2">
                  <Lang value="productdetails.productTechnical" />
                </div>
              </div>
              <div data-test-id='GyC7' style={{ padding: "1rem" }} className="bg-grey-10 ptype-5">
                <Lang value="productdetails.productAdding" />
              </div>
              {crtList?.items?.map?.((i) => (
                <CartProductCard
                  noDelete
                  {...{
                    ...i,
                    id: i?.id,
                    title: i?.name,
                    productCode: i?.code,
                    brandName: i?.brandName,
                    primaryImage: i?.attachmentList?.find?.(
                      (i) => i.type === "IMAGE"
                    )?.reference,
                    primaryPrice: mainPriceCompute(i),
                    secondaryPrice: secondaryPriceCompute(i),
                    quantity: accesoriesModalData?.quantity,
                    unit: i?.unitOfMeasure,
                    stockState:
                      i?.quantity >
                      i?.localStockQuantity + i?.centralStockQuantity
                        ? "low"
                        : "available",
                    deleteCrtListItem: () => dispatch(deleteCrtListItem(i?.id)),
                    key: `${i?.id}-accessories`,
                    setPageModal
                  }}
                />
              ))}
            </BigModal>
          </div>
        </div>
      ) : (
        <PriceChangedModal />
      )}
    </>
  )
}
const computedCurrency = (i) =>
  ` ${
    i?.currency?.toUpperCase?.() === "RON" ||
    i?.currency?.toUpperCase?.() === "LEI"
      ? "LEI"
      : i?.currency
  }`
const mainPriceCompute = (i) =>
  `${round(i?.priceNet * i?.quantity)}${computedCurrency(i)}`
const secondaryPriceCompute = (i) =>
  `${round(i?.priceGross * i?.quantity)} ${computedCurrency(i)} (cu TVA)`
export const EmptyState = (props) => {
  const { isOnBehalf, hasAlreadySelectedOnBehalfCompany } = props
  const [emptyComponent, setEmptyComponent] = useState(<LoadingScreen />)
  useEffect(() => {
    const timeout = setTimeout(
      () => setEmptyComponent(<NoSearchResults {...{isOnBehalf, hasAlreadySelectedOnBehalfCompany}}/>),
      3000
    )
    return () => clearTimeout(timeout)
  }, [])
  return emptyComponent
}

export const tryToScrollToTop = () => {
  const wrapperElement = document.querySelector('.pageContent')
  
  if(!wrapperElement){
    console.warn('Could not scroll to top because .pageContent is not found.')
    return 
  }

  wrapperElement?.scrollTo?.({
    top: 0,
    behavior: 'smooth'
  })
}



export default withLocalize(ProductsList)
