import SvgIcon from "components/SvgIcon/SvgIcon"
import React, { useRef } from "react"
import { useDispatch } from "react-redux"
import { useSelector } from "store"
import {
  setMoreOpenRowId,
  setControlDVString,
  setDataViewId
} from "store/modules/dataView"
import { dataViewActionClicked } from "store/modules/dataView/dataViewActions.actions"
import useWindowSize from "../../../utils/useWindowsSize"
// import useScrollPosition from "../../../utils/useScrollPosition";

const MoreActions = (props) => {
  const { row, actions } = props

  const dispatch = useDispatch()
  const modalRef = useRef(null)
  const moreOpenRowId = useSelector((state) => state?.dataView?.moreOpenRowId)
  // const rowRef = useRef(null);

  const onItemClick = (item, row, index) => {
    if (item?.onClick) {
      // for local actions defined in the header
      item?.onClick({ row, index })
      // we also need to close the row manually in this case
      dispatch(setMoreOpenRowId(null))
    }
    // used for actions that are dispatched to the big ass table click action
    else {
      dispatch(setControlDVString())
      dispatch(setDataViewId())
      setTimeout(
        () => {
          dispatch(dataViewActionClicked(row.id, item?.action, row))
        },
        100
      )
    }
  }

  const screenHeight = (useWindowSize().height / 3) * 2
  const pos = modalRef.current?.offsetTop

  let actionPosition = false
  if (pos >= screenHeight) actionPosition = true
  else actionPosition = false

  const moreModal = (
    <div data-test-id='XA8v' className="more-modal">
      <div data-test-id='44Ib' className="top">
        {actions?.primary &&
          actions.primary?.map?.((item, index) => (
            <div 
              data-test-id='4ntB'
              key={index}
              className="clickable"
              onClick={() => onItemClick(item, row, index)}
            >
              <SvgIcon name={item?.icon} />
            </div>
          ))}
      </div>
      <div data-test-id='ExSS' className="bottom">
        {actions?.secondary &&
          actions.secondary?.map?.((item, index) => (
            <div 
              data-test-id='WZoy'
              key={index}
              className="secondary-action"
              onClick={() => onItemClick(item, row, index)}
            >
              <div data-test-id='PhrS' className="clickable">
                <SvgIcon name={item?.icon} />
              </div>
              <p data-test-id='gQf4'>{item?.label}</p>
            </div>
          ))}
      </div>
    </div>
  )
  return (
    <div 
      data-test-id='fX4x'
      ref={modalRef}
      className={`cell-more-actions ${actionPosition ? "above" : ""}`}
    >
      <div 
        data-test-id='uZMk'
        className="clickable"
        onClick={() => dispatch(setMoreOpenRowId(props?.row?.id))}
      >
        <SvgIcon name={row.moreActions ? "close" : "more"} />
      </div>
      {row.id === moreOpenRowId && moreModal}
    </div>
  )
}

export default MoreActions
