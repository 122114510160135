import SvgIcon from "components/SvgIcon/SvgIcon"

export default function SimpleCheckBox(props) {
  const { checked, onClick, color="#333" } = props
  const iconName =
    checked === false
      ? "Checkbox_inactive"
      : checked === null
      ? "CheckboxMultiDeselect"
      : "Checkbox_active"
  return (
    <div data-test-id='B50s' onClick={onClick}>
      <SvgIcon style={{ fill: color }} name={iconName} />
    </div>
  )
}
