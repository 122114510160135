import Listmodels from "assets/MenuIcon/Atom/Icons/Listmodels.svg"
import { Lang, lang, Translate, loc, Loc } from "components/Lang/Lang"

export const leftMenu = {
  topItems: [
    {
      id: "leftEntriesShop",
      name: "menus.leftEntriesShop",
      //<Lang value="menus.leftEntriesShop"/>,//"Magazin online", // the name of the entry >> toolTip in small format
      // icon: Online_store, //the icon used in the menu
      url: "/shop/productlist", //we add this later
      svg: "onlineStore"
    },
    // {
    //   id: "leftEntriesExcel",
    //   name: "menus.leftEntriesExcel", //"Import din Excel",
    //   svg: "importfromexcel",
    //   url: "/shop/excelimport",
    // },
    // {
    //   id: "leftEntriesOfferts",
    //   name: "menus.leftEntriesOfferts", //"Oferte catre clienti",
    //   svg: "offert", //"offer2clients",
    //   url: "/shop/offers"
    // },
    {
      id: "leftEntriesPredefined",
      name: "menus.leftEntriesPredefined", //"Liste create de expertii Uniprest",
      svg: "Listmodels",
      url: "/shop/predefinedlists"
    },
    {
      id: "leftEntriesAboutUs",
      name: "menus.leftEntriesAboutUs", //"Despre noi",
      svg: "Uniprest",
      url: "/shop/about"
    },
    {
      id: "leftEntriesNews",
      name: "menus.leftEntriesNews", //"Știri și noutăți",
      svg: "Documents3",
      url: "/shop/news"
    },
    // {
    //   name: "Despre Uniprest",
    //   icon: Uniprest,
    //   url: "/"
    // },
    {
      id: "leftEntriesServices",
      name: "menus.leftEntriesServices", //"Servicii",
      svg: "Services",
      url: "/shop/services"
    },
    {
      id: "leftEntriesPartners",
      name: "menus.leftEntriesPartners", //"Parteneri",
      svg: "Resellpartners",
      url: "/shop/partners"
    },
    {
      id: "leftEntriesCareers",
      name: "menus.leftEntriesCareers", //"Cariere",
      svg: "Career",
      url: "/shop/careers"
    },
    {
      id: "leftEntriesMap",
      name: "menus.leftEntriesMap", //"Unde ne gasesti",
      svg: "Locationlocation",
      url: "/shop/map"
    },
    {
      id: "leftEntriesContact",
      name: "menus.leftEntriesContact", //"Contact",
      svg: "Support",
      url: "/shop/support"
    },
  ],
  bottomItems: [
    {
      name: "menus.leftEntriesTerms", //"Termeni si conditii",
      svg: "Documents",
      url: "/shop/terms",
      type: "grey"
    }
  ]
}

export const rightMenuUnauthenticated = {
  topItems: [
    {
      name: "menus.rightLogin", // the name of the entry >> toolTip in small format
      svg: "signin", //the icon used in the menu
      url: "/shop/signin" //we add this later
    },
    {
      name: "menus.rightAskAccount",
      svg: "uniprestplus",
      url: "/shop/signup"
    }
  ]
}

export const rightMenu = {
  topItems: [
    {
      id: "rightDashboard",
      name: "menus.rightDashboard", // the name of the entry >> toolTip in small format
      svg: "HomePage", //the icon used in the menu
      url: "/shop/dashboard" //we add this later
    },
    {
      id: "righOrders",
      name: "menus.righOrders",
      svg: "Warehouse",
      url: "/shop/orders"
    },
    {
      id: "rightInvoices",
      name: "menus.rightInvoices", // the name of the entry >> toolTip in small format
      svg: "Invoice", //the icon used in the menu
      url: "/shop/invoices" //we add this later
    },
    {
      id: "rightMyLists",
      name: "menus.rightMyLists", // the name of the entry >> toolTip in small format
      svg: "menu list", //the icon used in the menu
      url: "/shop/lists" //we add this later
    },
    // {
    //   name: "Listele recomandate", // the name of the entry >> toolTip in small format
    //   icon: Listmodels, //the icon used in the menu
    //   url: "/" //we add this later
    // },
    // {
    //   name: "Facturi lista", // the name of the entry >> toolTip in small format
    //   // icon: Business, //the icon used in the menu
    //   svg: "Business", //the icon used in the menu
    //   url: "/shop/invoices/facturi" //we add this later
    // },
    // {
    //   name: "Comenzi lista", // the name of the entry >> toolTip in small format
    //   // icon: ManageUsers, //the icon used in the menu
    //   svg: "ManageUsers", //the icon used in the menu
    //   url: "/shop/invoices/comenzi" //we add this later
    // },
    // {
    //   name: "Product Grid", // the name of the entry >> toolTip in small format
    //   svg: "ID", //the icon used in the menu
    //   // icon: ID, //the icon used in the menu
    //   url: "/shop/dev/pricematrix" //we add this later
    // }
    {
      id: "leftEntriesExcel",
      name: "menus.leftEntriesExcel", //"Import din Excel",
      svg: "importfromexcel",
      url: "/shop/excelimport",
    },
    {
      id: "leftEntriesOfferts",
      name: "menus.leftEntriesOfferts", //"Oferte catre clienti",
      svg: "offert", //"offer2clients",
      url: "/shop/offers"
    }
  ],
  bottomItems: [
    {
      name: "menus.rightGoToCompany",
      svg: "business",
      url: "/shop/client/mycompany",
      type: "grey",
    },
    {
      name: "menus.rightLogout", // the name of the entry >> toolTip in small format
      svg: "LogOut", //the icon used in the menu
      // icon: ID, //the icon used in the menu
      // onClick=() => dispatch({ type: "LOGOUT_USER" }) //we add this later
      type: "grey",
      action: "logoutUser"
    }
  ]
}

export const leftMenuEntries = {
  top: [
    {
      id: "leftEntriesShop",
      name: "menus.leftEntriesShop", //"Magazin Online", // the name of the entry >> toolTip in small format
      svg: "Online_store", //the icon used in the menu
      url: "/shop/productlist" //we add this later
    },
    // {
    //   id: "leftEntriesExcel",
    //   name: "menus.leftEntriesExcel", //"Import din Excel",
    //   url: "/shop/excelimport",
    //   svg: "importfromexcel"
    // },
    // {
    //   id: "leftEntriesOfferts",
    //   name: "menus.leftEntriesOfferts", //"Oferte catre clienti",
    //   svg: "offert", //"offer2clients",
    //   url: "/shop/offers"
    // },
    {
      id: "leftEntriesPredefined",
      name: "menus.leftEntriesPredefined", //"Liste create de expertii Uniprest",
      svg: "Listmodels",
      url: "/shop/predefinedlists"
    },
    {
      id: "leftEntriesAboutUs",
      name: "menus.leftEntriesAboutUs", //"Despre noi",
      svg: "Uniprest",
      url: "/shop/about"
    },
    {
      id: "leftEntriesNews",
      name: "menus.leftEntriesNews", //"Știri și noutăți",
      svg: "Documents3",
      url: "/shop/news"
    },
    {
      id: "leftEntriesServices",
      name: "menus.leftEntriesServices", //"Servicii",
      svg: "Services",
      url: "/shop/services"
    },
    {
      id: "leftEntriesPartners",
      name: "menus.leftEntriesPartners", //"Parteneri",
      svg: "Resellpartners",
      url: "/shop/partners"
    },
    {
      id: "leftEntriesCareers",
      name: "menus.leftEntriesCareers", //"Cariere",
      svg: "Career",
      url: "/shop/careers"
    },
    {
      id: "leftEntriesMap",
      name: "menus.leftEntriesMap", //"Unde ne gasesti",
      svg: "Locationlocation",
      url: "/shop/map"
    },
    {
      id: "leftEntriesContact",
      name: "menus.leftEntriesContact", //"Contact",
      svg: "Support",
      url: "/shop/support"
    }
  ],
  bottom: [
    {
      name: "menus.leftEntriesTerms", //"Termeni si conditi",
      svg: "Documents",
      url: "/shop/terms"
    }
  ]
}

export const rightOpenMenu = {
  top: [
    {
      sectionName: lang("menus.rightMyAccount")
    },
    {
      id: "loggedInAs",
      loggedInAs: lang("menus.rightLoggedAs") // the name of the entry >> toolTip in small format
      // url: "/" //we add this later
    },
    {
      name: "menus.rightDashboard", // the name of the entry >> toolTip in small format
      svg: "HomePage", //the icon used in the menu
      url: "/shop/dashboard" //we add this later
    },
    {
      name: "menus.righOrders",
      // icon: Warehouse,
      svg: "Warehouse",
      url: "/shop/orders"
    },
    {
      name: "menus.rightInvoices",
      svg: "Invoice",
      // icon: Invoice,
      url: "/shop/invoices"
    },
    {
      sectionName: lang("menus.rightLists")
    },
    {
      name: "menus.rightMyLists",
      icon: Listmodels,
      url: "/shop/lists"
    },
    // {
    //   name: "Listele recomandate",
    //   icon: Listmodels,
    //   url: "/shop/lists"
    // },

    {
      id: "others",
      sectionName: "Altele",
    },
    //     {
    //       name: "Listele tale",
    //       svg: "Business",
    //       // icon: Business,
    //       url: "/shop/lists"
    //     },
    //     {
    //       name: "Listele tale",
    //       svg: "ID",
    //       // icon: ID,
    //       url: "/shop/lists"
    //     },
    //     {
    //       name: "Listele tale",
    //       svg: "ManageUsers",
    //       // icon: ManageUsers,
    //       url: "/shop/lists"
    //     }
    {
      id: "leftEntriesExcel",
      name: "menus.leftEntriesExcel", //"Import din Excel",
      url: "/shop/excelimport",
      svg: "importfromexcel"
    },
    {
      id: "leftEntriesOfferts",
      name: "menus.leftEntriesOfferts", //"Oferte catre clienti",
      svg: "offert", //"offer2clients",
      url: "/shop/offers"
    },
  ],
  bottom: [
    {
      id: "menus.rightGoToCompany",
      name: "menus.rightGoToCompany",
      svg: "business",
      url: "/shop/client/mycompany",
      // type: "grey",
    },
    {
      name: "menus.rightLogout",
      svg: "ManageUsers",
      // icon: ManageUsers,
      url: "/"
    }
  ]
}

//add proper SVG and name to products
//based on this create the other sections we have in the Admin

export const adminTopMenu = [
  {
    name: "Dashboard",
    svg: "ManageUsers",
    // icon: ManageUsers,
    url: "/admin/dashboard"
  },
  // {
  //   name: "Dashboard",
  //   svg: "Dashboard_ometer",//taken from mdesign //TODO check names in svg component
  //   url: "/admin/dashboard", // a fost settings here
  // },
  {
    name: "Utilizatori",
    svg: "user",
    url: "/admin/user",
    subMenu: true,
    rightTopButtons: [
      {
        name: "Dashboard",
        svg: "homepage",
        url: "/admin/user"
      },
      {
        name: "All Items",
        svg: "invoice",
        url: "/admin/user/list"
      },
      {
        name: "Roles",
        svg: "invoice",
        url: "/admin/user/roles"
      }
    ]
  },
  // {
  //   name: "Utilizatori",
  //   svg: "ManageUsers", //taken from design
  //   url: "/admin/user", //taken from above ???
  // },
  {
    name: "Organizatii",
    svg: "Business", //taken from design
    url: "/admin/company/list", //???business
    subMenu: true,
    rightTopButtons: [
      {
        name: "Dashboard",
        svg: "homepage",
        url: "/admin/company"
      },
      {
        name: "All Items",
        svg: "invoice",
        url: "/admin/company/list"
      },
      {
        name: "Account requests",
        svg: "WebPage", //taken from design
        url: "/admin/accountRequests/list" //???
      },
      {
        name: "New",
        svg: "cardsview",
        url: "/admin/company/form"
      }
    ]
  },
  {
    name: "Produse",
    svg: "Online_store",
    // icon: ManageUsers,
    url: "/admin/product",
    subMenu: true,
    rightTopButtons: [
      {
        name: "Dashboard",
        svg: "homepage",
        url: "/admin/product"
      },
      {
        name: "B2B Items",
        svg: "invoice",
        url: "/admin/product/list"
      },
      {
        name: "Compano",
        svg: "cardsview",
        url: "/admin/product/compano"
      },
      {
        name: "Discount volum",
        svg: "cardsview",
        url: "/admin/product/volumeDiscount"
      },
      {
        name: "Grila de discounturi", //remove adauga,
        svg: "cardsview",
        url: "/admin/product/discountsTable"
      },
      {
        name: "Net nete", //remove adauga,
        svg: "cardsview",
        url: "/admin/product/price/list"
      },
      {
        name: "Documente", //remove adauga,
        svg: "cardsview",
        url: "/admin/product/documents/list"
      },
      {
        name: "Badges", //remove adauga,
        svg: "cardsview",
        url: "/admin/product/badge/list"
      },
      {
        name: "Intrebari si raspunsuri", //remove adauga,
        svg: "cardsview",
        url: "/admin/product/qa/list"
      },
      {
        name: "Recenzii", //remove adauga,
        svg: "cardsview",
        url: "/admin/product/review/list"
      },
      {
        name: "Matrice preturi", //remove adauga,
        svg: "cardsview",
        url: "/admin/product/matrix/list"
      },
      {
        name: "Depozite", //remove adauga,
        svg: "cardsview",
        url: "/admin/product/warehouse/list"
      },
      {
        name: "Detalii depozit", //remove adauga,
        svg: "cardsview",
        url: "/admin/product/warehouse/details"
      }
    ]
  },
  // {
  //   name: "Produse",
  //   svg: "Product", //taken from design
  //   url: "/admin/products", //?????
  // },
  {
    name: "Comenzi",
    subMenu: true,

    svg: "Warehouse", //taken from design
    url: "/admin/order/list" //???
  },
  {
    name: "Facturi",
    subMenu: true,

    svg: "Invoice", //taken from design
    url: "/admin/invoice/list" //??? facturi
  },
  {
    name: "Liste predefinite",
    subMenu: true,
    svg: "List_models", //taken from design
    url: "/admin/admin-lists/list" //??? facturi
  },
  {
    name: "Cereri de oferta",
    subMenu: true,
    svg: "RFPList", //taken from design
    url: "/admin/offer/list" //??? RFPList
  },
  {
    name: "Proiecte",
    subMenu: true,
    svg: "Projects", //taken from design
    url: "/admin/project/list" //??? RFPList
  },
  {
    name: "Stiri",
    svg: "Documents3", //taken from design
    url: "/admin/news/list",
    subMenu: true,
    rightTopButtons: [
      {
        name: "Dashboard",
        svg: "homepage",
        url: "/admin/news"
      },
      {
        name: "All Items",
        svg: "invoice",
        url: "/admin/news/list"
      }
    ]
  },
  {
    name: "Joburi",
    subMenu: true,

    svg: "Jobs2", //taken from design
    url: "list" //???
  }

  // {
  //   name: "Pagini de prezentare",
  //   svg: "WebPage", //taken from design
  //   url: "/admin/pages", //???
  // },
  // {
  //   name: "Promotii",
  //   svg: "Promo", //taken from design
  //   url: "/admin/promo", //???
  // },
]

export const adminBottomMenu = [
  {
    name: "The other thing",
    svg: "stats",
    url: "/admin/settings"
  },
  {
    name: "Settings",
    svg: "settings",
    url: "/admin/settings"
  }
]
