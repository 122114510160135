const articleLabelFilter = {
  id: "FLT-M0lblart",
  fb: 130,
  startExpanded: true,
  // value: "String",
  type: "multiSelect",
  // placeholder: undefined,
  label: "Etichete"
  // loadMore: "Incarca mai multe...",
}
const jobCityFilter = {
  id: "FLT-M0ctyjob",
  fb: 130,
  startExpanded: true,
  search: true,
  // value: "String",
  type: "jobCityFilter",
  // placeholder: undefined,
  label: "Oras"
  // loadMore: "Incarca mai multe...",
}
const tutorialAboutFilter = {
  id: "FLT-M0tutabout",
  fb: 130,
  startExpanded: true,
  // value: "String",
  type: "multiSelect",
  // placeholder: undefined,
  label: "Instrucţiuni despre",
  // loadMore: "Incarca mai multe...",
  data: [
    { id: "Shop", label: "Shop" },
    { id: "Produse", label: "Produse" },
    { id: "Checkout", label: "Checkout" },
    { id: "Livrare", label: "Livrare" },
    { id: "Comenzi", label: "Comenzi" },
    { id: "Facturi", label: "Facturi" },
    { id: "Contul meu", label: "Contul meu" },
    { id: "Utilizatori şi drepturi", label: "Utilizatori şi drepturi" },
    { id: "Toate", label: "Toate" }
  ]
}
const tutorialTypeFilter = {
  id: "FLT-M0tuttype",
  fb: 130,
  startExpanded: true,
  // value: "String",
  type: "multiSelect",
  // placeholder: undefined,
  label: "Tip conţinut",
  // loadMore: "Incarca mai multe...",
  data: [
    { id: "Articol scris", label: "Articol scris" },
    { id: "Video", label: "Video" },
    { id: "Mix", label: "Mix" },
    { id: "Toate", label: "Toate" }
  ]
}
const columnSortFilter = {
  id: "FLT-C0SORTER",
  fb: 70,
  value: "String",
  type: "columnSort",
  placeholder: undefined,
  label: "Categorie"
  // loadMore: "Incarca mai multe...",
}
const allCategoryFilter = {
  id: "FLT-CATEG001",
  fb: 120,
  value: "String",
  type: "category",
  placeholder: undefined,
  label: "Categorie",
  search: true,
  startExpanded: true
  // loadMore: "Incarca mai multe...",
}
const allCategoryMegaFilter = {
  id: "FLT-CATMEGA1",
  fb: 240,
  value: "String",
  type: "mega-category",
  // placeholder: undefined,
  label: "Gama de produse"
  // search: true,
  // loadMore: "Incarca mai multe...",
}
const topCategoryFilter = {
  id: "FLT-PCATEG00",
  fb: 200,
  value: "String",
  type: "multiSelect",
  placeholder: undefined,
  label: "Categorie",
  search: true
  // loadMore: "Incarca mai multe...",
}
const subCateg1filter = {
  id: "FLT-PCATEG01",
  fb: 220,
  value: "String",
  type: "multiSelect",
  placeholder: undefined,
  label: "Subcategorie1",
  search: true
  // loadMore: "Incarca mai multe...",
}
const subCateg2filter = {
  id: "FLT-PCATEG02",
  fb: 220,
  value: "String",
  type: "multiSelect",
  placeholder: undefined,
  label: "Subcategorie2",
  search: true
  // loadMore: "Incarca mai multe...",
}

const mockRangeFilter = {
  id: "FLT-RGaaaa00",
  fb: 200,
  value: "string",
  type: "range",
  placeholder: undefined,
  label: "Price",
  data: [0, 1000] //used to have a boundary on selection
}

const mockDateSingleFilter = {
  id: "FLT-DS00ONE0",
  fb: 100,
  value: "string",
  type: "dateOne",
  label: "Select Date"
}

const mockMultiSelectFilter = {
  id: "FLT-SMaaab00",
  fb: 200,
  value: "String",
  type: "multiSelect",
  placeholder: undefined,
  label: "Brand",
  search: true,
  loadMore: "Incarca mai multe...",
  startExpanded: true
  // dictionary: "PRODUCT_BRAND",
  // data: [
  //   { id: "1", label: "String1", info: "100" },
  //   { id: "2", label: "String2", info: "150" },
  //   { id: "3", label: "String3", info: "90" },
  //   { id: "STEELMET ROMANIA SA", label: "STEELMET ROMANIA SA", info: "3000" },
  // ],
}
const brandNameFilter = {
  id: "FLT-SMaaaa00",
  fb: 200,
  value: "String",
  type: "multiSelect",
  placeholder: undefined,
  label: "Brand",
  search: true,
  startExpanded: true,
  // loadMore: "Incarca mai multe...",
  dictionary: "PRODUCT_BRAND"
  // data: [
  //   { id: "1", label: "String1", info: "100" },
  //   { id: "2", label: "String2", info: "150" },
  //   { id: "3", label: "String3", info: "90" },
  //   { id: "STEELMET ROMANIA SA", label: "STEELMET ROMANIA SA", info: "3000" },
  // ],
}
const mockMultiSelectFilterLabels = {
  id: "FLT-SMlabel0",
  fb: 200,
  value: "String",
  type: "multiSelect",
  placeholder: undefined,
  label: "Categorie articol",
  search: true,
  loadMore: "Incarca mai multe...",
  data: [
    { id: "1", label: "Label A" },
    { id: "2", label: "Label B" },
    { id: "3", label: "Label C" },
    { id: "4", label: "Label D" }
  ]
}

const jobFilter = {
  id: "FLT-SMlabe20",
  fb: 200,
  value: "String",
  type: "multiSelect",
  placeholder: undefined,
  label: "Oras",
  search: true,
  loadMore: "Incarca mai multe...",
  data: [
    { id: "1", label: "Arad" },
    { id: "2", label: "Bucuresti" },
    { id: "3", label: "Craiova" },
    { id: "4", label: "Cluj-Napoca" },
    { id: "5", label: "Oradea" },
    { id: "6", label: "Tirgu Mures" }
  ]
}

const mock5starsFilter = {
  id: "FLT-5Saaaa00",
  fb: 200,
  value: "String",
  type: "5stars",
  placeholder: undefined,
  label: "Rating",
  data: [
    { id: "5", label: "5 Stars", info: "1900" },
    { id: "4", label: "4 Stars", info: "800" },
    { id: "3", label: "3 Stars", info: "90" },
    { id: "2", label: "2 Stars", info: "150" },
    { id: "1", label: "1 Star", info: "100" }
  ]
}
const textSearchFilter = {
  id: "FLT-SEARCH01",
  fb: 200,
  type: "textSearch",
  placeholder: "Type To Search"
}

const applicabilityFilter = {
  id: "FLT-Applicability",
  fb: 200,
  type: "applicability", //"userDirectory",
  placeholder: "Aplicabilitate",
  data: [
    { id: "EXTERN", label: "Extern" },
    { id: "INTERN", label: "Intern" }
  ]
}
const sourceFilter = {
  id: "FLT-Source",
  fb: 200,
  type: "applicability", //"userDirectory",
  placeholder: "Sursa",
  data: [
    { id: "EXTERNAL", label: "Extern" },
    { id: "INTERNAL", label: "Intern" }
  ]
}
const sourceClientFilter = {
  id: "FLT-Source-Client",
  fb: 200,
  type: "applicability", //"userDirectory",
  placeholder: "Sursa comenzi",
  data: [
    { id: "EXTERNAL", label: "Consilierii Uniprest Instal" },
    { id: "INTERNAL", label: "Compania mea" }
  ]
}
const userTypeFilter = {
  id: "FLT-UserType",
  fb: 200,
  type: "applicability", //"userDirectory",
  placeholder: "Tip utilizator",
  data: [
    { id: "EXTERNAL", label: "Extern" },
    { id: "INTERNAL", label: "Intern" }
  ]
}
const agentFilter = {
  id: "FLT-Agent",
  fb: 200,
  type: "multiSelect", // "applicability",
  //placeholder: "Selecteaza agent",
  label: "Agent",
  dictionary: "AGENTS"
  // data: [
  //   { id: "EXTERNAL", label: "Extern" },
  //   { id: "INTERNAL", label: "Intern" },
  // ]
}

const rankingFilter = {
  id: "FLT-Ranking",
  fb: 200,
  type: "applicability",
  placeholder: "Ranking",
  label: "Ranking",
  dictionary: "RANKINGS"
  // data: [
  //   { id: "EXTERNAL", label: "Extern" },
  //   { id: "INTERNAL", label: "Intern" },
  // ]
}

const invoiceStatusFilter = {
  id: "FLT-InvStatus",
  fb: 200,
  type: "applicability", //"userDirectory",
  placeholder: "Status",
  data: [
    { id: "FINAL", label: "Achitata" },
    { id: "NEW", label: "Achitata partial" },
    { id: "NEW", label: "Neachitata" }
  ]
}
const statusFilter = {
  id: "FLT-RoleStatus", //"FLT-Status",
  fb: 150,
  type: "status",
  placeholder: "Status",
  data: [
    { id: "ACTIVE", label: "Activ" },
    { id: "INACTIVE", label: "Inactiv" }
  ]
}
const userStatusFilter = {
  id: "FLT-GeneralStatus01",
  fb: 200,
  value: "String",
  type: "multiSelect",
  placeholder: "Status",
  label: "Status",
  data: [
    { id: "ACTIVE", label: "Activ" },
    { id: "INACTIVE", label: "Inactiv" },
    { id: "SUSPENDED", label: "Suspendat" }
  ]
}
const companyStatusFilter = {
  id: "FLT-CompanyStatus",
  fb: 200,
  value: "String",
  type: "multiSelect",
  placeholder: "Status",
  label: "Status",
  data: [
    { id: "Activ", label: "Activ" },
    { id: "Inactiv", label: "Inactiv" },
    { id: "Suspendat", label: "Suspendat" }
  ]
}
const orderStatusFilter = {
  id: "FLT-OrderStatus",
  fb: 200,
  value: "String",
  type: "multiSelect",
  placeholder: "Status",
  label: "Status",
  data: [
    { id: "ACTIVE", label: "ACTIVE" },
    { id: "PROCESSED", label: "PROCESSED" },
    { id: "PLACED", label: "PLACED" },
    { id: "PREPARATION", label: "PREPARATION" },
    { id: "INVOICED", label: "INVOICED" }
  ]
}

const b2bProductFilter = {
  id: "FLT-b2bProductStatus",
  fb: 200,
  value: "String",
  type: "applicability",
  placeholder: "Status",
  label: "Status",
  data: [
    { id: "ACTIVE", label: "ACTIV" },
    { id: "INACTIVE", label: "INACTIV" },
    { id: "INVALID", label: "INVALID" }
  ]
}
const clientFilter = {
  id: "FLT-CLIENT",
  fb: 300,
  value: "String",
  type: "applicability",
  placeholder: "Alege client",
  label: "Client",
  dictionary: "CLIENTS"
  // data: [
  //   { id: "INSTALDI SRL", label: "INSTALDI SRL" },
  //   { id: "FERRUM TECH SRL", label: "FERRUM TECH SRL" },
  //   //{ id: "INVALID", label: "INVALID" }
  // ],
}
const clientIdFilter = {
  id: "FLT-CLIENT-ID",
  fb: 300,
  value: "String",
  type: "multiSelect",
  placeholder: "Alege client",
  label: "Client",
  dictionary: "CLIENTS"
  // data: [
  //   { id: "INSTALDI SRL", label: "INSTALDI SRL" },
  //   { id: "FERRUM TECH SRL", label: "FERRUM TECH SRL" },
  //   //{ id: "INVALID", label: "INVALID" }
  // ],
}
const userPositionFilter = {
  id: "FLT-PersPosition",
  fb: 200,
  value: "String",
  type: "multiSelect",
  placeholder: undefined,
  label: "Functia",
  search: true,
  data: [
    { id: "07", label: "Manager audit" },
    { id: "08", label: "Auditor" },
    { id: "09", label: "Inspector bancar" }
  ]
}

const permissionNameFilter = {
  id: "FLT-PermissionName",
  fb: 200,
  type: "permissionName",
  placeholder: "Permisiuni",
  data: [
    { id: "1", label: "Citire" },
    { id: "2", label: "Scriere" },
    { id: "3", label: "Stergere" }
    //{ id: "4", label: "Permission name 4" },
  ]
}
//"permissionReadCount": 2,
//"permissionWriteCount": 1,
//"permissionDeleteCount": 1,
// const mockSavedFilters = [
//   {
//     id: "USF-0000aaaa",
//     favorite: true,
//     name: "My precious",
//     data: {
//       "FLT-SMaaaa00": ["2"],
//     },
//   },
//   {
//     id: "USF-0000aaab",
//     favorite: false,
//     name: "My filter",
//     data: {
//       "FLT-SMaaaa00": ["2"],
//       "FLT-RGaaaa00": [0, 530],
//     },
//   },
// ];

const sortFilters = [
  {
    id: "FLT-sortart",
    type: "selectOne",
    fb: 200,
    label: "Ordoneaza dupa",
    placeholder: "Selecteaza...",
    data: [
      { id: "cDate", label: "Cele mai recente" },
      { id: "cDateCresc", label: "Cele mai vechi" },
      // { id: "viewCount", label: "Cele mai multe accesări" }
      // { id: 'i', label: 'Autor' },
    ]
  },
  // {
  //   id:"FLT-sortOderDate",
  //   type: 'selectOneDate',
  //     fb : 200,
  //   label:"Ordoneaza dupa",
  //   placeholder: "Selecteaza...",
  //   data: [
  //     { id: 'createDate|false', label: "Cele mai recente"}, //'Pret (scumpe)' },
  //     { id: 'createDate|true', label: 'Cele mai vechi'}, //Pret (ieftine)' },
  //     // { id: 'i', label: 'Autor' },
  //   ],
  //   },
  {
    id: "FLT-sort00p1",
    type: "selectOne",
    fb: 200,
    label: "Ordonează după",
    placeholder: "Selectează...",
    data: [
      //!! id syntax is "sortKey|direction"
      // { id: 'r', label: 'Cele mai recente' },
      { id: "shopAccessCount|false", label: "Popularitate" },
      { id: "relevance|false", label: "Relevanță" },
      { id: "ranking|true", label: "Rating" },
      { id: "name|true", label: "ABC ↑" },
      { id: "name|false", label: "ABC ↓" },
    ]
  },
  {
    id: "FLT-sort0prc",
    type: "selectOne",
    fb: 200,
    label: "Vezi preturile",
    placeholder: "Selecteaza...",
    defaultValue: { id: "both", label: "AMBELE" },
    data: [
      { id: "both", label: "AMBELE" },
      { id: "wVat", label: "Cu TVA" },
      { id: "woVat", label: "Fara TVA" }
    ]
  },
  {
    id: "FLT-sort0cmp",
    type: "selectOne",
    fb: 200,
    label: "Compania",
    placeholder: "Selecteaza...",
    data: [
      { id: "c", label: "Alphabet LLC" },
      { id: "s", label: "Forte PArtners" },
      { id: "v", label: "Cool Inc." }
    ]
  },
  {
    id: "FLT-SWaaaa00",
    value: "string",
    type: "switch",
    fb: 200,
    placeholder: undefined,
    label: "Doar facturi de achitat",
    data: undefined,
    selection: true //can be true/false
  },
  {
    id: "FLT-SWaaaa01",
    value: "string",
    type: "switch",
    fb: 200,
    placeholder: undefined,
    label: "Doar comenzi active",
    data: undefined,
    selection: true //can be true/false
  },

  {
    id: "FLT-sort0dat",
    type: "selectOne",
    fb: 200,
    label: "Afiseaza",
    placeholder: "Selecteaza...",
    data: [
      { id: "c", label: "Ultima Luna" },
      { id: "s", label: "Ultimele 6 luni" },
      { id: "v", label: "Ultimul an" },
      { id: "t", label: "Toate" }
    ]
  },
  {
    id: "FLT-sort0ord",
    type: "selectOne",
    fb: 200,
    label: "Ordoneaza dupa",
    placeholder: "Selecteaza...",
    data: [
      { id: "c", label: "Cele mai recente" },
      { id: "s", label: "Data creere" },
      { id: "n", label: "Numar Produse" },
      { id: "v", label: "Valoare" }
    ]
  },
  {
    id: "FLT-sort0inv",
    type: "selectOne",
    fb: 200,
    label: "Ordoneaza dupa",
    placeholder: "Selecteaza...",
    data: [
      { id: "c", label: "Cele mai recente" },
      { id: "s", label: "Data Scadenta" },
      { id: "e", label: "Data Emitere" },
      { id: "v", label: "Valoare" }
    ]
  },
  {
    id: "FLT-tipClient",
    type: "selectOne",
    fb: 200,
    label: "Tip Client",
    placeholder: "Selecteaza...",
    data: [
      { id: "GUEST", label: "Guest" },
      { id: "SMALL", label: "Client Mic" },
      { id: "RESELLER", label: "Resseller" },
      { id: "LARGE", label: "Client Mare" }
    ]
  }
]

const templateFilterList = [
  allCategoryMegaFilter,
  textSearchFilter,
  mockDateSingleFilter,
  columnSortFilter,
  allCategoryFilter,
  topCategoryFilter,
  subCateg1filter,
  subCateg2filter,
  brandNameFilter,
  mockRangeFilter,
  mock5starsFilter,
  jobFilter,
  mockMultiSelectFilterLabels,
  statusFilter,
  userStatusFilter,
  orderStatusFilter,
  b2bProductFilter,
  userPositionFilter,
  //guest filters
  articleLabelFilter,
  jobCityFilter,
  applicabilityFilter,
  // statusFilter,
  permissionNameFilter,
  tutorialAboutFilter,
  tutorialTypeFilter,
  clientFilter,
  clientIdFilter,
  userTypeFilter,
  sourceFilter,
  sourceClientFilter,
  companyStatusFilter,
  invoiceStatusFilter,
  agentFilter,
  rankingFilter,
  ...sortFilters,
  { ...mockRangeFilter, id: 2 }
  // {...mockRangeFilter, id: 3, },
  // {...mockRangeFilter, id: 4, },
]

export default templateFilterList
