import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers"

export const awsClientConfig = {
  region: process.env.REACT_APP_USER_REGION,
  credentials: fromCognitoIdentityPool({
    clientConfig: { region: process.env.REACT_APP_USER_REGION }, // Configure the underlying CognitoIdentityClient.
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    logins: {
      // Optional tokens, used for authenticated login.
    }
  })
}
