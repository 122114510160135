import { Redirect } from "react-router"
import { myLazy } from "utils/myLazy"

export const adminGuestRoutes = [
  //===================================================================
  //7.1 News
  {
    exact: true,
    path: "/admin/guest/news/list",
    component: myLazy(() => import("views/admin/news/List"))
  },
  {
    exact: false,
    path: "/admin/guest/news/form",
    component: myLazy(() => import("views/admin/news/articleForm"))
  },
  //===================================================================
  //tutorials
  {
    exact: true,
    path: "/admin/guest/tutorials/list",
    component: myLazy(() => import("views/admin/tutorials/tutorialList"))
  },
  {
    exact: false,
    path: "/admin/guest/tutorials/form",
    component: myLazy(() => import("views/admin/tutorials/tutorialForm"))
  },
  //7.2 Jobs - list and form
  {
    exact: true,
    path: "/admin/guest/job/list",
    component: myLazy(() => import("views/admin/avaliableJobs/avaliableJobs")) // DONE
  },
  {
    exact: false,
    path: "/admin/guest/job/form",
    component: myLazy(() => import("views/admin/avaliableJobs/addJob"))
  },

  //===================================================================
  //7.3 Aplicanti
  {
    exact: true,
    path: "/admin/guest/candidate/list",
    component: myLazy(() => import("views/admin/aplicanti/List"))
  },
  {
    exact: false,
    path: "/admin/guest/candidate/form",
    component: myLazy(() => import("views/admin/aplicanti/applicantFormPage"))
  },
  //===================================================================
  //7.4 Contact - list and form
  {
    exact: true,
    path: "/admin/guest/contact/list",
    component: myLazy(() => import("views/admin/contactListAndForm/contactList")) // DONE
  },
  {
    exact: false,
    path: "/admin/guest/contact/form",
    component: myLazy(() => import("views/admin/contactListAndForm/contactForm")) // DONE
  },

  //===================================================================
  //7.5 Locatii uniprest
  {
    exact: true,
    path: "/admin/guest/location/list",
    component: myLazy(() => import("views/admin/location/List"))
  },
  {
    exact: false,
    path: "/admin/guest/location/form",
    component: myLazy(() => import("views/admin/location/LocationForm.jsx"))
  },

  //===================================================================
  {
    exact: true,
    path: "/admin/guest/dashboard",
    component: myLazy(() => import("views/admin/unifiedDashboard"))
  },
  {
    exact: false,
    path: "/admin/guest",
    component: () => <Redirect to="/admin/guest/dashboard" />
  }
]
