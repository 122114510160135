import { numberFormatterV2 } from "utils/stringUtils"

export default function FormatComponent(props) {
  const {
    number,
    formatStyle,
    prefixText,
    suffixText,
    formatStyleJustNumber,
    productCardRelated
  } = props

  const left = productCardRelated
    ? numberFormatterV2(number, true)?.slice?.(0, -2) ?? 0
    : numberFormatterV2(number)?.slice?.(0, -2) ?? 0
  const right = productCardRelated
    ? numberFormatterV2(number, true)?.slice?.(-2)
    : numberFormatterV2(number)?.slice?.(-2)

  return (
    <div data-test-id='3jam' className={formatStyle}>
      {prefixText}{" "}
      <span data-test-id='O4J5' className={formatStyleJustNumber}>
        {left}
        <sup data-test-id='3vCT'>{right}</sup>
      </span>{" "}
      <span data-test-id='vhto' className={formatStyleJustNumber ? "mt2" : ""}>{suffixText}</span>
    </div>
  )
}
