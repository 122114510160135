import BigModal from "components/_modals/BigModal"
import { ReactComponent as AlreadyInBasketError } from "assets/_illustrations/AlreadyInBasketError.svg"
import MagicButton from "components/MagicButton/MagicButtonV2"
import { useDispatch, useSelector } from "react-redux"
import { emptyBasket } from "store/modules/shop"
import { setPageModal } from "store/modules/page"

const BasketErrorModal = () => {
  const dispatch = useDispatch()
  const errorMessage = useSelector((state) => state?.page?.modal?.data)

  return (
    <BigModal
      noFooter
      name="basketErrorOfferShopConflictModal"
      title=""
      className="prodListOfferInBasket"
    >
      <div 
        data-test-id='79q8'
        className={'basket-error-card modal'}
        style={{ "justify-content": "unset" }}
      >
        <div data-test-id='aJvI' className={`content-container`}>
          <div 
            data-test-id='AStI'
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "6rem"
            }}
          >
            <AlreadyInBasketError />
          </div>
          <div 
            data-test-id='18Bq'
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "2rem"
            }}
          >
            <span data-test-id='8fxp' style={{ width: "50%" }} className="etype-2">
              {errorMessage}
              {/** prodlist
                    În coș se găsesc deja produs(e) adăugate dintr-o ofertă.
                    Finalizează comanda, sau golește coșul ca să poți adăuga produse din magazin. */}
              {/**offer details
                    În coș se găsesc produs(e) dintr-o altă ofertă sau din magazin.
                    Finalizează comanda, sau golește coșul ca să poți adăuga în coș produse din această ofertă. */}
            </span>
          </div>
          <div 
            data-test-id='JdxR'
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1.5rem"
            }}
          >
            <MagicButton
              outline
              label="Verifică coşul"
              className="thin ptype-5"
              url="/shop/cart"
            />
            <div data-test-id='yOTZ' className="ml4">
              <MagicButton
                label="Goleşte coşul"
                type="redAlert"
                className="thin ptype-5"
                onClick={() => {
                  dispatch(emptyBasket())
                  dispatch(setPageModal({}))
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </BigModal>
  )
}

export default BasketErrorModal
