import PropTypes from "prop-types"
import { useState } from "react"

export default function SmallSwitch(props) {
  const { selected, onClick, disabled } = props
  const [switchState, setSwitchState] = useState(selected)

  function _onClick() {
    if (!disabled) {
      setSwitchState(!switchState)
      if (onClick) onClick(!switchState)
    }
  }

  return (
    <div 
      data-test-id='fXxm'
      className={`switch-container-k3 ${
        switchState ? "sw-active" : "sw-inactive"
      } ${disabled ? "sw-disabled" : ""}`}
      onClick={() => _onClick()}
    >
      <div data-test-id='oDfM' className={"switch"} />
    </div>
  )
}

SmallSwitch.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  selected: PropTypes.bool
}
