import { Link } from "react-router-dom"
import NextIcon from "assets/BreadCrumbs/NextIcon.svg" //'/static/media/NextIcon.2076b4b0.svg';

const BreadCrumbsPremium = () => {
  let paths
  paths = window.location.pathname?.split?.("/")
  paths =
    paths?.[paths?.length - 1] === "" ? paths?.slice?.(0, paths?.length - 1) : paths
  paths = paths?.[1] === "" ? paths?.slice?.(1) : paths

  const breadcrumb = paths?.map?.((path, index) => {
    const arrow =
      index !== paths?.length - 1 ? (
        <img 
          data-test-id='ERER'
          onContextMenu={(e) => e?.preventDefault?.()}
          className={"mx2"}
          src={NextIcon}
          alt=""
        />
      ) : (
        " "
      )
    if (index === 0) {
      return (
        <li data-test-id='aj9h'>
          <Link key={index} to={"/"}>
            Home
          </Link>
          {arrow}
        </li>
      )
    }

    let url = paths?.slice?.(0, index + 1)?.join?.("/")
    if (url === "/shop") url = "/shop/productlist"
    return (
      <li data-test-id='vPkO'>
        <Link key={index} to={url}>
          {path}
        </Link>
        {arrow}
      </li>
    )
  })

  return (
    <div data-test-id='NJIE' className="bcp">
      <ul data-test-id='vR5Q'>{breadcrumb}</ul>
    </div>
  )
}

export default BreadCrumbsPremium
