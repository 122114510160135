import moment from "moment"
import { useState } from "react"

const ReportProblem = ({ problem, reported }) => {
  const [text, setText] = useState(problem)
  const [ico, setIco] = useState(false) //this can be eliminated by looking at the text value

  function clickHandler() {
    setText(reported + moment(new Date().toISOString()).format("DD MMM YYYY"))
    setIco(true)
  }

  return (
    <div data-test-id='9U6e' className="problems">
      {text && (
        <button 
          data-test-id='etOb'
          className="btn btn-transparent"
          onClick={(e) => clickHandler(e)}
        >
          <span data-test-id='awWa' className={`ico ${ico ? "ico-red" : ""}`} />
          {text}
        </button>
      )}
    </div>
  )
}
export default ReportProblem
