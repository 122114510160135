import "./imageChain3.scss"
const placeholderImage = "/images/default80.jpg"

const ImageChain3 = ({ images, imageLimit, size = 32, imgPerLine }) => {
  const extraImages = (images?.length ?? 0) - imageLimit
  const maxWidth = imgPerLine ? imgPerLine * (4 + size) : undefined
  const style = {
    width: size,
    height: size
  }
  const remainingImages = images?.slice?.(0, imageLimit)
  return (
    <div data-test-id='CJUS' className="image-chain3-wrapper" {...{ style: { maxWidth } }}>
      {remainingImages?.map?.((item) => (
        <img 
          data-test-id='X1aC'
          onContextMenu={(e) => e?.preventDefault?.()}
          {...{ style }}
          src={item ?? placeholderImage}
          className="small-image"
          alt="img"
        />
      ))}
      {extraImages > 0 && (
        <div data-test-id='WBYb' {...{ style }} className="extra-images">
          +{extraImages}
        </div>
      )}
    </div>
  )
}
export default ImageChain3
