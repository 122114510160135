import SimpleCheckBox from "components/SimpleCheckBox"
import moment from "moment"
import { useHistory } from "react-router"
import "./ModalListCard1.scss"
export default function ModalListCard1(props) {
  const {
    index,
    item: inputItem,
    toggleItem,
    apiCompany,
    listOfLists,
    single,
    apiProduct, //product list card
    noCheckBox, //hides the checkbox
    disabled, //no action on this puppy
    typeKey,
    associatedComapny,
    associatedProduct,
    relatedProduct,
    documentDetailView
  } = props

  let item = inputItem
  const history = useHistory()
  if (associatedComapny) item = parseAssociatedComapny(inputItem)
  if (associatedProduct) item = parseAssociatedProduct(inputItem)
  if (apiCompany) item = parseApiComapnyItem(inputItem)
  if (apiProduct) item = parseApiProductItem(inputItem)
  if (listOfLists) item = parseListOfListsItem(inputItem)
  if (relatedProduct) item = parseRelatedProductItem(inputItem)

  const goToDetails = (id) =>
    history?.push?.(`/admin/product/b2b/form?id=${id}`)
  return (
    <div 
      data-test-id='tdEs'
      key={`sub-${index}`}
      className={`list-modal-card-1-wrapper ${!typeKey ? "left" : ""} ${
        item?.selected ? "selected" : ""
      }`}
      onClick={
        documentDetailView
          ? () => goToDetails(item?.id)
          : disabled
          ? undefined
          : () => toggleItem(item)
      }
    >
      <div 
        data-test-id='ZJxI'
        className={"start-items"}
        style={{ cursor: documentDetailView ? "pointer" : "" }}
      >
        {!noCheckBox && !single && !disabled && (
          <SimpleCheckBox checked={item?.selected} />
        )}
        {!item?.noImage && (
          <div data-test-id='7o73' className="rectangle">
            {(item?.mainImage || item?.img) && (
              <img 
                data-test-id='QNWF'
                onContextMenu={(e) => e?.preventDefault?.()}
                src={item?.mainImage?.reference ?? item?.img ?? item?.mainImage}
                alt={"company"}
              />
            )}
          </div>
        )}{" "}
        {/* @TODO: I have a hunch, image comes here ??, => missing in API */}
        <div data-test-id='lHHA' className={"company"}>
          {item?.name && <strong data-test-id='Eudz'>{item?.name}</strong>}
          {item?.code && <span data-test-id='1TV1'>{item?.code}</span>}
        </div>
      </div>
      {item?.tags?.length && (
        <div data-test-id='sSKF' className={"tags"}>
          {item?.tags?.map?.((t, i) => (
            <div data-test-id='mYB8' key={`tag-${i}`} className={"tag"}>
              {t}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

const parseListOfListsItem = (item) => {
  const prodCount = item?.products?.length
  return {
    //to review
    ...item,
    noImage: true,
    subtitle: `din ${moment(item?.cDate).format("D MMM YY, h:mm")}, creata de ${
      item?.cByName
    }`,

    tags: [
      `${prodCount} ${prodCount === 1 ? "produs" : "produse"}`,
      `ultima actualizare ${moment(item?.eDate).format("D MMM YY, h:mm")}`
    ]
  }
}
const parseAssociatedProduct = (item) => ({
  ...item,
  subtitle: `${item?.code}`
})

const parseAssociatedComapny = (item, vatNr) => ({
  //to review
  ...item,
  subtitle: `RO ${item?.vatNr}`,
  tags: [item?.classify]
})

const parseApiComapnyItem = (item, vatNr) => ({
  //to review
  ...item,
  subtitle: `${item?.category?.name}`,
  tags: [item?.brandName, item?.code]?.filter?.((item) => item)
})

const parseApiProductItem = (item) => ({
  ...item,
  subtitle: `${item?.category?.name}`,
  img: item?.mainImage?.reference,
  tags: [item?.brandName, item?.code]?.filter?.((item) => item)
})
const parseRelatedProductItem = (item) => ({
  ...item,
  name: item?.relatedProductName,
  subtitle: `${item?.relatedProductCode}`,
  img: item?.relatedProductMainImage
  // tags: [
  //   item?.brandName,
  //   item?.code,
  // ]?.filter?.(item=> item)
})
