export  function hideMobileKeyboard(inputRef) {

    if(inputRef?.current)
      inputRef.current.disabled = true // Force keyboard to hide on textarea field.
  
    setTimeout(function() {
  
      if(inputRef?.current)
        inputRef.current.disabled = false // Force keyboard to hide on textarea field.
  
      document?.activeElement?.blur?.()

    }, 100)
}
