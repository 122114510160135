import PropTypes from "prop-types"

export default function Deadline(props) {
  const {
    min,
    max,
    value,
    dangerLimitPercent,
    criticalLimitPercent,
    reverseLimits,
    labels,
    labelTitle,
    noValueText,
    levelBarClass,
    labelClass,
    valueClass
  } = props

  const title = (labels?.title ?? labelTitle)?.replace?.("{days}", value)
  const minValue = min ? min : 0
  const maxValue = max ? max : 100
  const currentPercent = calculatePercent(minValue, maxValue, value).toFixed(1)
  const stageLevelIndex = getLevelStage(
    currentPercent,
    dangerLimitPercent,
    criticalLimitPercent,
    reverseLimits
  )
  const levelBarWidth = currentPercent > 0 ? currentPercent : 100

  return (
    <div data-test-id='2dtO' className="deadline">
      <div data-test-id='iZR6' className={`${labelClass ?? "title-container"}`}>{title}</div>
      <div data-test-id='1cvE' className="base">
        {currentPercent !== undefined && !noValueText && (
          <div data-test-id='vkG3' className={`label-container label-color-${stageLevelIndex}`}>
            <div data-test-id='T0SM' className={`label ${valueClass ?? ""}`}>{`${
              currentPercent + "%"
            }`}</div>
          </div>
        )}
        <div 
          data-test-id='ZT19'
          className={`level level-color-${stageLevelIndex} ${
            levelBarClass ?? ""
          } `}
          style={{ width: `${levelBarWidth}%` }}
        />
      </div>
    </div>
  )
}

function calculatePercent(min, max, value) {
  if (!value) return 0
  if (min >= max) return 100
  return Math.min(100, (value / (max - min)) * 100)
}

function getLevelStage(
  currentPercent,
  dangerLimit,
  criticalLimit,
  reverseLimits
) {
  if (currentPercent === "0.0") return -1 //TODO: resolve HARDCODE '0.0'
  if (reverseLimits) {
    return criticalLimit && criticalLimit > currentPercent
      ? 2
      : dangerLimit && dangerLimit > currentPercent
      ? 1
      : 0
  } else {
    return criticalLimit && criticalLimit < currentPercent
      ? 2
      : dangerLimit && dangerLimit < currentPercent
      ? 1
      : 0
  }
}

Deadline.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number,
  dangerLimitPercent: PropTypes.number, // percent
  criticalLimitPercent: PropTypes.number, // percent
  reverseLimits: PropTypes.bool,
  labels: PropTypes.shape({
    title: PropTypes.string
  })
}
