import { parseFloatOrDefault, parseFloatOrThrow, roundOrThrow } from "utils/numberUtils"
import { defaultCurrencyCode } from "./currencyUtils"
import { campaignConfig } from "views/shoppingCart/useCampaignInfo"


export const getComputedPriceInfo = (product, shouldIgnoreQuantity = false) => {

  const finalCurrency = product?.currency || product?.currencyCode || defaultCurrencyCode
  
  const prices = {
    primary: getPrimaryPriceString(product, shouldIgnoreQuantity), 
    secondary: getSecondaryPriceString(product, shouldIgnoreQuantity),
  }

  return {
    prices,

    suffixText: {
      primary: finalCurrency, 
      secondary: `${finalCurrency} (cu TVA)`,
    },
    finalCurrency,
    hasPrice: {
      primary: !isNaN(prices.primary),
      secondary: !isNaN(prices.secondary),
    }
  }

}


const getPrimaryPriceString = (product, shouldIgnoreQuantity = false) => {
  try { return getPrimaryPriceNumberOrThrow(product, shouldIgnoreQuantity) } 
  catch (error) { return '-' }
}

const getSecondaryPriceString = (product, shouldIgnoreQuantity = false) => {
  try { return getSecondaryPriceNumberOrThrow(product, shouldIgnoreQuantity) }
  catch (error) { return '-' }
}

const getPrimaryPriceNumberOrThrow = (product, shouldIgnoreQuantity = false) => {
  const priceNetFloat = parseFloatOrThrow(product?.unitPriceNetLocal || product?.priceNet)
  
  const quantityFloat = shouldIgnoreQuantity
    ? 1
    : parseFloatOrThrow(product?.quantity)

  return roundOrThrow(priceNetFloat * quantityFloat)
  
}

const getSecondaryPriceNumberOrThrow = (product, shouldIgnoreQuantity = false) => {

  const priceGrossFloat = parseFloatOrThrow(product?.unitPriceGrossLocal || product?.priceGross)
  
  const quantityFloat = shouldIgnoreQuantity
    ? 1
    : parseFloatOrThrow(product?.quantity)

  return roundOrThrow(priceGrossFloat * quantityFloat)
  
}

const forbiddenProductGroups = [
  'FERRO ACCESORII',
  'FERRO BATERII N',
  'FERRO BATERII Y',
  'FERRO CHIUVETE',
  'FERRO CUTII DISTRIBUITOR',
  'FERRO DISTRIBUITOARE',
  'FERRO DUSURI',
  'FERRO GAZ',
  'FERRO POMPE',
  'FERRO ROBINETI',
  'FERRO ROBINETI BILA F',
  'FERRO ROBINETI COLTARI',
  'FERRO TERMET',
  'PURMO CONVECTOARE',
  'PURMO PARDOSEALA',
  'PURMO SISTEM SANITAR',
  'PURMO CUTII SI DISTRIBUITOARE',
  'PURMO TEVI',
  'PURMO TRUSE SI ACCESORII',
  'PURMO ACCESORII SISTEM PARDOSEALA',
  'PURMO TERMOSTATE SI AUTOMATIZARI',
  'PURMO PLACI',
  'PURMO KIT POMPARE',
  'RADIATOARE BAIE PURMO',
  'RADIATOARE PURMO ATIPICE',
  'PURMO ACCESORII RADIATOR',
  'RADIATOARE PURMO C UZUALE',
  'RADIATOARE PURMO CV UZUALE',
  'RADIATOARE PURMO DECORATIVE',
  'RADIATOARE BAIE DECORATIVE PURMO',
  'PURMO ACCESORII DECORATIVE',
]


export const shouldHideDiscountPercentage = (productGroup) => {
  if (!productGroup) return false

  return forbiddenProductGroups?.includes?.(productGroup)
}

export const getIsBlackFridayProduct = (product) => {

  if(!campaignConfig.shouldDisplayNonLoggedPrices) return false

  return (
    product?.productCategoryName === 'BLACK FRIDAY' || 
    product?.category?.name === 'BLACK FRIDAY'
  )

}