import { createSelector } from "reselect"

const language = (state) => state.user.language
const searchString = (state) => state?.dataView.searchString
const moreOpenRowId = (state) => state?.dataView.moreOpenRowId
const selectedItems = (state) => state?.dataView.selectedItems
const lastItemHint = (state) => state?.dataView.lastItemHint
const allItems = (state) => state?.dataView?.items
// const selectedFilters = state => state?.dataView.selectedFilters;
// const productCategory = state => state?.dataView.productCategory;
// const allFilters = state => state?.dataView.allFilters;
// const savedFilters = state => state?.dataView.savedFilters;
// const sort = state => state?.dataView.sort;
const stateColumns = (state) => state?.dataView?.columns
// const dictonary = state => state.backData?.dictionary
const count = (state) => state?.dataView?.count
// / boilerplate above //////////

const searchedKeys = ["comanda"] // the DB Json keys searched by the string search

const baseItemFormatter = ({ item, selectedItems, moreOpenRowId }) => ({
  ...item,
  selected: selectedItems?.includes?.(item?.id ?? item?.PK),
  id: item?.id ?? item?.PK,
  moreActions: moreOpenRowId === item?.id || moreOpenRowId === item?.PK
})

export const baseDataViewSelector = createSelector(
  language,
  searchString,
  selectedItems,
  allItems,
  moreOpenRowId,
  stateColumns,
  lastItemHint,
  count,
  (
    language,
    searchString,
    selectedItems,
    allItems,
    moreOpenRowId,
    stateColumns,
    lastItemHint,
    count
  ) => {
    // const rows = allItems?.filter?.(item => searchStringFilter(item, searchString))?.map?.(item => baseItemFormatter({ item, selectedItems, moreOpenRowId }))
    //without client side search processing
    const rows = allItems?.map?.((item) =>
      baseItemFormatter({ item, selectedItems, moreOpenRowId })
    )
    const miltiSelectLength = selectedItems?.length
    const miltiSelectText = `Ati selectat ${selectedItems?.length}`

    return {
      stateColumns,
      selectedItems,
      allItems,
      rows,
      miltiSelectLength,
      miltiSelectText,
      lastItemHint,
      count
    }
  }
)

export const headerProcessor = ({
  templateHeader,
  stateColumns,
  selectedItems,
  allItems
}) => {
  return reorder(templateHeader, stateColumns)?.map?.((item) =>
    item?.type === "select"
      ? {
          type: "select",
          selected:
            selectedItems?.length === 0
              ? false
              : selectedItems?.length === allItems?.length
              ? true
              : null
        }
      : item
  )
}

// ==========================================
// THIS IS WHAT YOUR SELECTOR SHOULD HOLD
// ==========================================
// export const specificTableSelector = createSelector(
//   baseDataViewSelector,
//   (prevSelector) => {
//     //enrich rows as required by current table
//     const rows = prevSelector.rows?.map?.(item => ({
//       ...item,
//       deliveryTitle: `${item?.deliveryStatus?.delivered} ${moment(item?.deliveryStatus?.date).format(dateFormat)}`,
//       deliverySubtitle: item?.deliveryStatus?.address,
//       progressValue: 66,
//       itemsList: ["Item one", "Item two"],
//       // itemsList: ["Item one", "Item two",  "Item two",  "Item two"]
//       pillData: {
//         type: "outlined",
//         value: "Info Label",
//         color: "purple",
//         // iconName: "user"
//         // bold:true,
//       },
//       tagData,
//       col2type: "default",
//       // col2typeTemp: item?.id % 2 === 0 ? "stars" : "defualt",
//     }))

//     const header = headerProcessor({ ...prevSelector, templateHeader })
//     return { ...prevSelector, templateHeader, rows, header, multiActions, }
//   })

const reorder = (templateHeader, stateColumns) => {
  if (!stateColumns || stateColumns?.length === 0) return templateHeader
  return stateColumns
    ?.filter?.((item) => item?.visible ?? true)
    ?.map?.((item) => templateHeader?.[item] ?? templateHeader?.[item?.id])
}

const searchStringFilter = (item, searchString) => {
  if (!searchString) return true
  const normalizedSearch = searchString?.toLowerCase?.()
  for (const i in searchedKeys)
    if (
      item?.[searchedKeys?.[i]] &&
      item?.[searchedKeys?.[i]]?.toLowerCase?.()?.includes?.(normalizedSearch)
    )
      return true
  return false
}
