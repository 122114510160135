import { useState } from "react"

const useQuantityValidation = () => {

  const [isInProgress, setIsInProgress] = useState(false)

  return {
    isInProgress,
    start: () => setIsInProgress(true),
    stop: () => setIsInProgress(false)
  }

}

export default useQuantityValidation
