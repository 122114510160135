import SvgIcon from "components/SvgIcon"
import "./menusearch.scss"
import { useDispatch, useSelector } from "react-redux"
import {
  clearMenuSearchItems,
  setMenuSearchItems,
  setMenuSearchString
} from "store/modules/shop"
import MB from "components/MB"
import { useEffect, useState } from "react"
import { searchStuff } from "store/modules/dataView.search"
import searchDATA from "mockData/searchList"
import useShowModalOutsideClick from "utils/useShowModalOutsideClick"
import { goToProductDetails, createUrlPrefix } from "utils/history"
import { category } from "mockData/imgCategory"

const getEntityUrl = (entity, id) => {
  switch (entity) {
    case "GOODS":
      return `/shop/details?id=${id}`
    // UNCOMMENT AND EDIT THESE IF custom url tags are needed:
    // return `/shop/details/${createUrlPrefix({
    //   customTags: ["mocktag1", "mocktag2"],
    //   customUrl: "customUrl"
    // })}?id=${id}`
    case "ORDER":
      return `/shop/orders/details?id=${id}&source=EXTERNAL`
    case "CLIENT_INVOICE":
      return `/shop/invoice/details?id=${id}`
    case "BASKET":
      return `/shop/orders/details?id=${id}&source=INTERNAL`
    default:
      return `/shop/details/${createUrlPrefix({
        // UNCOMMENT AND EDIT THESE IF custom url tags are needed:
        // customTags: ["mocktag1", "mocktag2"],
        // customUrl: "customUrl"
      })}?id=${id}`
  }
}
const MenuSearch = (props) => {
  const { palceholder } = props

  const dispatch = useDispatch()
  const query = useSelector((state) => state.shop.menuSeachString)
  const items = useSelector((state) => state.shop.menuSearchItems)
  const { categories, all, seeAll, icons } = searchDATA

  const hideStuff = () => {
    dispatch(setMenuSearchString(""))
    dispatch(clearMenuSearchItems())
  }

  const { setShowModal, ref } = useShowModalOutsideClick({
    handleOutsideClick: hideStuff
  })

  const dbList =
    items?.length > 0
      ? items?.map?.((item) => ({
          ...item,
          category: item?.entity, //"produse",
          icon: icons?.[item?.entity], //"invoice",//fuckturi//"cartempty"//basket,//"warehouse"//conemzi,//"Onlinestore",//prod
          // image: item?.mainImage?.reference, //not used
          /* url: `/shop/details?id=${item?.id}`, */
          /*  (id, customTags =  ["mocktag1","mocktag2"], customUrl="customUrl") => */
          url: getEntityUrl(item?.entity, item?.id) //`${detailUrls?.[item?.entity]}${item?.id}`
          //goToProductDetails } from "utils/history";
          //     {
          //       "id": "ZvYb5r",
          //       "name": "PURMO MAURITIUS 1754/600",
          //       "code": "RELE15207965",
          //       "info": null,
          //       "entity": "GOODS"
          //   },
          //   {
          //     "id": "ZzzBnZ",
          //     "name": null,
          //     "code": "65079659",
          //     "info": null,
          //     "entity": "CLIENT_INVOICE"
          // }
        }))
      : []

  useEffect(() => {
    // if (query?.length > 2) dispatch(searchStuff({searchTerm:query, target: "menuSearch", reload:true, pageSize:10}))
    // else hideStuff()
  }, [query])
  useEffect(() => {
    return () => {
      hideStuff()
    }
  }, [])
  const showDropdown = (e) => {
    dispatch(setMenuSearchString(e?.target?.value))
    setShowModal(true)
    if (e?.target?.value?.length > 2) {
    //call api to get results
    dispatch(setMenuSearchItems(e?.target?.value))
    } else
    dispatch(clearMenuSearchItems());
  }
  const [typingTimer, setTypingTimer] = useState(null)
  const delayConfig = 1000
  useEffect(() => {
    //timer reset, in case it was started before
    if (typingTimer) {
      clearTimeout(typingTimer)
      setTypingTimer(null)
    }
    //starts the timer, and after a preconfigured delay it will trigger the search dispatch
    const timer = setTypingTimer(
      setTimeout(() => {
        if (query?.length > 2) {
              //call api to get results
              // dispatch(setMenuSearchItems())
              dispatch(
                searchStuff({
                  searchTerm: query,
                  type: "global",
                  target: "menuSearch",
                  reload: true,
                  pageSize: 10
                })
              )
            } else dispatch(clearMenuSearchItems())

      }, delayConfig)
    )
    return () => {
      clearTimeout(timer)
    }
  }, [query])

  /**
   * This entire Search component has been deactivated as per the request of the client.
   * (The global search and plus product search proved to be confusing for the users.)
   */
  return null

  return (
    <div data-test-id='73VV' className="menu-search-wrap" ref={ref}>
      <input 
        data-test-id='Brvl'
        className="menuSearch hideOnSmall"
        placeholder={palceholder}
        onChange={(e) => showDropdown(e)}
        value={query}
      />
      <div data-test-id='Gt8t' className={`drop-list ${dbList?.length > 0 ? "opened" : ""}`}>
        {dbList?.length &&
          Object?.keys?.(categories || {})?.map?.((categoryKey) => {
            let products = dbList?.filter?.(
              (item) => item?.category === categoryKey
            )
            let productsCount = products?.length

            if (!productsCount) return ""
            return (
              <div 
                data-test-id='WGvV'
                className="listblock"
                data-category={categoryKey}
                key={categoryKey}
              >
                <MB flex sb>
                  <h6 data-test-id='wspO' className="category">{categories?.[categoryKey]}</h6>
                  <a 
                    data-test-id='1eJJ'
                    href={all?.url?.[categoryKey] + query}
                    onClick={hideStuff}
                    className={`link link-products ${
                      all?.uppercase ? "uppercase" : ""
                    } `}
                  >
                    {all?.label}
                  </a>
                </MB>
                <ul data-test-id='aV3m'>
                  {products?.map?.((item, i) => {
                    let productName = item?.name ?? item?.code
                    let index = productName
                      ?.toLowerCase?.()
                      ?.indexOf?.(query?.toLowerCase?.())
                    if (index >= 0) {
                      productName =
                        productName?.substring?.(0, index) +
                        "<span data-test-id='k9nq' class='highlight'>" +
                        productName?.substring?.(index, index + query?.length) +
                        "</span>" +
                        productName?.substring?.(index + query?.length)
                    }

                    //const placeholder = '/images/new-placeholder.svg'
                    //const prodImage = <div data-test-id='LaRL' className={'prod-img'}><img data-test-id='1Awq' src={item?.mainImage ?? placeholder} alt={''} /></div>
                    return (
                      <li data-test-id='pdkl' key={`item-${categoryKey}-${i}`}>
                        <a data-test-id='oRUP' href={item?.url} onClick={hideStuff}>
                          {/*prodImage*/}
                          {item?.icon && (
                            <div data-test-id='m9BC' className={"prod-img"}>
                              <SvgIcon name={item?.icon} />
                            </div>
                          )}
                          <span 
                            data-test-id='xZ2o'
                            dangerouslySetInnerHTML={{ __html: productName }}
                          />
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </div>
            )
          })}

        {/* { dbList?.length > 0 && <div data-test-id='fzh4' className="bottom-linkholder">
          {seeAll.text1} <span data-test-id='OiWO'>{ query }</span> {seeAll.text2}
          <a data-test-id='dTj1' href={seeAll.link.url} className={`link last ${seeAll.link.uppercase ? 'uppercase':''}`}>
            {seeAll.link.label}
          </a>
        </div>} */}
      </div>
    </div>
  )
}

export default MenuSearch
