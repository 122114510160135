import PropTypes from "prop-types"

export default function CellListItems(props) {
  const { items, limit } = props
  if (!items || items?.length === 0) return <></>

  //small update so the limit dictates the number of lines
  let showItems =
    limit !== undefined && limit > 0 && limit !== items?.length
      ? limit - 1
      : items?.length
  const moreItemNumber = items?.length === limit ? 0 : items?.length - showItems

  return (
    <>
      {props.isSmallScreen && (
        <div data-test-id='d4NX' className="teable-header">{props.label}</div>
      )}
      <div data-test-id='dtJD' className="cell-list">
        {items?.map?.((item, index) => {
          if (index + 1 <= showItems) {
            return (
              <div data-test-id='EG4S' key={index} className="cell-item">
                {item}
              </div>
            )
          }
          return null
        })}
        {moreItemNumber > 0 && (
          <div data-test-id='Cc1L' className="more-item-counter">+{moreItemNumber}</div>
        )}
      </div>
    </>
  )
}

CellListItems.propTypes = {
  items: PropTypes.array,
  limit: PropTypes.number
}
