import { deleteDynamoItemByPK } from "store/justActions/dynamoOps"
import { reDispatch } from "store/middleware/api"
import { history } from "utils/history"
import store from "store/index"
import { ADD_SAVED_FILTER, RESET_ACTIONS, setDataItems } from "store/modules/dataView"
import {
  setActionToConfirm,
  setPageModal,
  setPageModalData
} from "store/modules/page"
import { changeStatusAPI } from "store/justActions/miscApiInteractions"
import { getCart } from "store/modules/shop"
import { deleteDataViewSelectedRowsFromDynamoDB, deleteProductsFromList } from "store/justActions/lists"
//import { loadDynamoFormItemByPK_v2, } from "store/modules/dataView.search"
import { setCrtFormItem } from "store/modules/page"
import { apiAction } from "store/middleware/api"
//import { ENRICH_DATAVIEW_ITEMS } from "store/modules/dataView";
import { SET_TEMP_PRODUCT_PREDEFINED_LIST_DETAILS } from "store/modules/page"
// import { ENRICH_DATAVIEW_ITEMS } from "store/modules/dataView";
import { savePriceRequest, saveStockRequest } from "store/modules/guest.actions"
import { saveSetup } from "views/admin/product/priceRequest/priceRequestForm"
import { toastr } from "react-redux-toastr"
import { saveApiItem } from "store/justActions/saveApiIems"
import { showMagicToaster } from "utils/MagicToasterCall"
import { printError } from "utils/printError"
import { parseFloatOrZero } from "utils/numberUtils"
import { checkIsLowStock } from "views/ImportFromExcel/tableSelector"
import { filterConditions } from "views/ImportFromExcel"

export const dataViewActionClicked = (id, action, row) =>
  reDispatch((dispatch) => {
    const pathname =
      window.location.pathname === "/admin/order/list"
        ? `${window.location.pathname
            ?.split?.("/")
            ?.slice?.(0, -1)
            ?.join?.("/")}/view`
        : `${window.location.pathname
            ?.split?.("/")
            ?.slice?.(0, -1)
            ?.join?.("/")}/form`

    if (action === "delete") {
      dispatch(
        setActionToConfirm(() =>
          dispatch(changeStatusAPI({ id, ...{ ...row, status: "DELETED" } }))
        )
      )
    }
    if (action === "changeStatus") {
      dispatch(
        setActionToConfirm(() =>
          dispatch(changeStatusAPI({ id, ...{ ...row.changeStatusProps } }))
        )
      )
    }
    if (action === "dynamoDelete") {
      dispatch(
        setActionToConfirm(() =>
          dispatch(deleteDynamoItemByPK({ PK: id, SK: row?.SK }))
        )
      )
    }
    if (action === "cancelRequest") {
      dispatch(
        setActionToConfirm(() =>
          dispatch(
            savePriceRequest(
              saveSetup(row, "CANCELLED"),
              row?.product?.selectedQuantity
            )
          )
        )
      )
    }
    if (action === "sendEmail") {
      dispatch(
        setActionToConfirm(() =>
          dispatch(
            savePriceRequest(
              saveSetup(row, "NOTIFIED"),
              row?.product?.selectedQuantity
            )
          )
        )
      )
    }
    if (action === "cancelStockRequest") {
      dispatch(
        setActionToConfirm(() =>
          dispatch(
            saveStockRequest(
              saveSetup(row, "CANCELLED"),
              row?.product?.selectedQuantity
            )
          )
        )
      )
    }
    if (action === "sendStockRequestEmail") {
      dispatch(
        setActionToConfirm(() =>
          dispatch(
            saveStockRequest(
              saveSetup(row, "NOTIFIED"),
              row?.product?.selectedQuantity
            )
          )
        )
      )
    }
    if (action === "details") history?.push?.(`${pathname}?id=${id}`)
    if (action === "edit")
      history?.push?.({
        pathname,
        search: `id=${id}`,
        state: { isEdit: true }
      })
    // `${pathname}/edit?id=${id}`
    return {
      type: RESET_ACTIONS
    }
  })

const setPageModals = ["associate-search-index"]
export const dataViewMultiActions = (action) =>
  reDispatch((dispatch) => {
    const selectedItems = store.getState()?.dataView?.selectedItems
    const item = store.getState()?.page?.crtFormItem
    const products = store.getState()?.dataView?.items

    if (setPageModals?.includes?.(action)) {
      dispatch(setPageModal(action))
      dispatch(setPageModalData(selectedItems))
    }

    if (action === "addToCart" || action === "addExcelToCart") {
      const products = store.getState()?.dataView?.items
      const selectedProducts = products?.filter?.((pr) =>
        selectedItems?.includes?.(pr.id)
      )
      if(action === "addExcelToCart" && products?.length === selectedProducts?.length) {
        let hasErrorLines = false
        let hasFrontZeroPrice = false
        let hasFrontLowStock = false
        //in loop
        selectedProducts?.forEach?.((i) => {
            const isLowStock = checkIsLowStock(i)
            if(hasErrorLines === false && i?.id?.includes?.("incorrect")) hasErrorLines = true  
            if(hasFrontZeroPrice === false && parseFloatOrZero(i?.priceNet) === 0) hasFrontZeroPrice = true  
            if(hasFrontLowStock === false && isLowStock) hasFrontLowStock = true 
        })
        if(hasFrontLowStock || hasFrontZeroPrice) 
            showMagicToaster({
              type: "warning",
              title: "Cel putin unul dintre produse nu are stoc sau preț",
              timeOut: 5000,
            })
        if(hasErrorLines && !hasFrontLowStock && !hasFrontZeroPrice)
            showMagicToaster({
              type: "warning",
              title: "Importul conține date incorecte",
              timeOut: 5000,
            })
            dispatch(
              saveApiItem({
                type: "basket/add-item-list",
                data: selectedProducts
                ?.filter?.(i => filterConditions(i))
                ?.map?.(i => ({goodsId: i?.id, quantity: i?.quantity})),
                onSuccess: () => {
                  dispatch(getCart())
                  showMagicToaster({
                    title: `${
                      selectedProducts?.length === 1 ? (
                        "Produsul a fost adăugat"
                      ) : (
                        "Produsele au fost adăugate"
                      )
                    } in cos!`,
                    timeOut: 5000,
                  })
                },
                onFailure: (error) => {
                  printError("addAllToCart()", error)
                  if (
                    error?.response?.data?.localizationCode === "error.offerInBasket"
                  )
                    dispatch(
                      setPageModal({
                        name: "basketErrorOfferShopConflictModal",
                        data: "În coș se găsesc deja produs(e) adăugate dintr-o ofertă. Finalizează comanda, sau golește coșul ca să poți adăuga produse din magazin."
                      })
                    )
                  else if(error?.response?.data?.localizationCode === "error.notEnoughStock")
                    showMagicToaster({
                      type: "error",
                      title: "Unul sau mai multe produse selectate nu au stoc suficient sau preț afișat!",
                      timeOut: 5000,
                    })
                  else if(error?.response?.data?.localizationCode === "error.productNoPrice")
                    showMagicToaster({
                      type: "error",
                      title: "Unul sau mai multe produse selectate nu au stoc suficient sau preț afișat!",
                      timeOut: 5000,
                    })
                  else
                    showMagicToaster({
                      type: "error",
                      title: "A intervenit o eroare!",
                      timeOut: 5000,
                    })
                  return {
                    type: "a"
                  }
                }
              })
            )
      
      }
      else {
        const finalProducts = []
        let error = false
  
        selectedProducts?.forEach?.((item) => {
          if (
            item?.quantity > 0 &&
            item?.quantity <=
              (item?.centralStockQuantity ?? 0) +
                (item?.localStockQuantity ?? 0) &&
            item?.priceGross !== 0 &&
            item?.priceGross !== null &&
            item?.priceGross !== undefined
          ) {
            finalProducts?.push?.({ goodsId: item?.id, quantity: item?.quantity })
          } else {
            error = true
          }
        })
  
        if (finalProducts?.length && !error) {
          dispatch(
            saveApiItem({
              type: "basket/add-item-list",
              data: finalProducts,
              onSuccess: () => {
                dispatch(getCart())
                showMagicToaster({
                  title: `${
                    finalProducts?.length === 1
                      ? "Produsul a fost adaugat"
                      : "Produsele au fost adaugate"
                  } in cos!`
                })
              },
              onFailure: (error) => {
                printError("dataViewMultiActions()", error)
                if (
                  error?.response?.data?.localizationCode ===
                  "error.offerInBasket"
                )
                  dispatch(
                    setPageModal({
                      name: "basketErrorOfferShopConflictModal",
                      data: "În coș se găsesc deja produs(e) adăugate dintr-o ofertă. Finalizează comanda, sau golește coșul ca să poți adăuga produse din magazin."
                    })
                  )
  
                return {
                  type: "a"
                }
              }
            })
          )
        } else {
          toastr.error(
            "Unul sau mai multe produse selectate nu au stoc suficient sau pret."
          )
        }
      }
    }
    if (action === "deleteFromList") {
      const filteredProducts = products?.filter?.(
        (product) => !selectedItems?.includes?.(product.id)
      )

      dispatch(deleteProductsFromList({ products: filteredProducts }))
      //dispatch(loadDynamoFormItemByPK_v2({ input: itemId, moreSteps: enrichListFromJava, setDvListProducts:true }))
      dispatch(setCrtFormItem({ ...item, products: filteredProducts }))
    }
    if (action === "deleteFromExcel") {
      //console.log("")
      const filteredProducts = products?.filter?.(
        (product) => !selectedItems?.includes?.(product.id)
      )

      dispatch(setDataItems({ items: filteredProducts }))
      //dispatch(loadDynamoFormItemByPK_v2({ input: itemId, moreSteps: enrichListFromJava, setDvListProducts:true }))
      //dispatch(setCrtFormItem({ ...item, products: filteredProducts }))
    }
    if (action === "deleteJobsFromAdminList") {
      dispatch(deleteDataViewSelectedRowsFromDynamoDB("JOB_RESPONSE", selectedItems))
    }
    if (action === "deleteApplicantFromAdminList") {
      dispatch(deleteDataViewSelectedRowsFromDynamoDB("APLICANT_RESPONSE", selectedItems))
    }
    return {
      type: RESET_ACTIONS
    }
  })

export const addSavedFilter = (name) => {
  let state = store.getState()
  return {
    type: ADD_SAVED_FILTER,
    payload: {
      id: "USF-0000a" + ((Math.random() * 1000) % 1000),
      favorite: false,
      name,
      data: state?.dataView.selectedFilters
    }
  }
}

export const checkStockForProducts = (tabledata) =>
  reDispatch((dispatch) => {
    tabledata?.allItems?.map?.((item) =>
      item?.values?.content?.forEach?.((element) => {
        if (element.type === "products") {
          element.products?.forEach?.((product) => {
            dispatch(fetchProdDetailsForDataViewItems(product.id))
          })
        }
      })
    )
    return { type: "a" }
  })

const fetchProdDetailsForDataViewItems = (id) =>
  apiAction({
    endPoint: `/public/product/goods/shop/` + id,
    onSuccess: (data, dispatch) => {
      //return { type: "a" }
      //return { type: ENRICH_DATAVIEW_ITEMS,
      //payload: data,
    },
    onFailure: (error) => {
      printError("fetchProdDetailsForDataViewItems()", error)
      return {
        type: "a"
      }
    }
  })

export const enrichPredefinedListProductsFromJava = (data, dispatch) => {
  const productsSection = data?.values?.content
    ?.filter?.((c) => c.type === "products")
    // ?.forEach?.((item) => {
    //   item?.products?.forEach?.((prod) =>
    //     dispatch(fetchProdDetailsForPredefinedList(prod.id))
    //   )
    // })
  
  const productsLists = productsSection?.map?.(section => section?.products)
  const products = productsLists?.[0]?.map?.(i => ({ "id": i?.id}))
  console.log("PRODUCTS ", products)
  dispatch(fetchShortDetailsByIdListJava(products, SET_TEMP_PRODUCT_PREDEFINED_LIST_DETAILS))

}

export const fetchShortDetailsByIdListJava = (idList, actionType) => 
  apiAction({
    endPoint: '/product/goods/shop/short-details-by-id-list',
    method: "POST",
    data: idList,
    idList: true,
    onSuccess: (data, dispatch) => {
      // console.log("FUFUFUFUF ", data)
      return { type: actionType, payload: data }
    },
    onFailure: (error) => {
      printError("fetchProdDetailsForPredefinedList()", error)
      return {
        type: "a"
      }
    }
  })

const fetchProdDetailsForPredefinedList = (id) =>
  apiAction({
    endPoint: `/product/goods/shop/` + id,
    onSuccess: (data, dispatch) => {
      return { type: SET_TEMP_PRODUCT_PREDEFINED_LIST_DETAILS, payload: data }
    },
    onFailure: (error) => {
      printError("fetchProdDetailsForPredefinedList()", error)
      return {
        type: "a"
      }
    }
  })