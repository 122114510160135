import React, { useState } from "react"

import { Range } from "rc-slider"
import "rc-slider/assets/index.css"
import "./DualRangeSlider.scss"
import { useDispatch } from "react-redux"
import { setFilterSelection } from "store/modules/dataView"

const DualRangeSlider = (props) => {
  const dispatch = useDispatch()
  const { min, max, title, defaultValue, id } = props
  let normalizedValues = [min, max]
  if (defaultValue?.length) {
    if (defaultValue[0]) normalizedValues[0] = defaultValue?.[0]
    if (defaultValue[1]) normalizedValues[1] = defaultValue?.[1]
  }
  // const [values, setValues] = useState(normalizedValues)
  const checker = (value) => {
    let FinalValue = value
    if (value > max) FinalValue = max
    if (value < min) FinalValue = min
    return FinalValue
  }

  return (
    <>
      <div data-test-id='xA2r' className="range-wrapper">
        {title && <div data-test-id='0eQL' className="heading-3">{title}</div>}
        <Range
          min={min}
          max={max}
          value={normalizedValues}
          onChange={(e) => dispatch(setFilterSelection(id, e))}
        />
        <div data-test-id='kRi6' className="range-nrs">
          {/* <input data-test-id='fgFa' className="range-value input" type="number" value={ values?.[0] } onChange={e => setValues(p => [ checker(e?.target?.value), p?.[1] ] )}/> */}
          <input 
            data-test-id='1Cbl'
            className="range-value input"
            type="number"
            value={normalizedValues?.[0]}
            onChange={(e) =>
              dispatch(
                setFilterSelection(id, [
                  checker(e?.target?.value),
                  normalizedValues?.[1]
                ])
              )
            }
          />
          <div data-test-id='Ch1Z' className="range-divider"> - </div>
          <input 
            data-test-id='3H6Z'
            className="range-value input"
            type="number"
            value={normalizedValues?.[1]}
            onChange={(e) =>
              dispatch(
                setFilterSelection(id, [
                  normalizedValues?.[0],
                  checker(e?.target?.value)
                ])
              )
            }
          />
        </div>
      </div>
    </>
  )
}
export default DualRangeSlider
