import React, { useEffect } from 'react'
import { useRef } from "react"
import "./Pagination.scss"
import useQueryStringFilters from "utils/useQueryStringFilters"
import { Link, useHistory } from "react-router-dom"
import useWindowSize from "utils/useWindowsSize"
import { useSelector } from "react-redux"

const setPageInUrl = (page) => {
  let href = window.location.href
  let url = new URL(href)
  url.searchParams.set("page", page)
  return url.href?.replace?.(url.origin, "")
}


const buttonSizeInPixels = 48 //expressed in pixels. EX: 48px

function Pagination({parentRef}) {

  const { page: activePage } = useQueryStringFilters()
  // const history = useHistory()
  const { width } = useWindowSize(parentRef)

  const totalNrResults = useSelector(state => state?.dataView?.count || 0)
  const nrProductsPerPage = useSelector(state => state?.dataView?.items?.pageSize || 25)
  const noOfPages = Math.ceil(totalNrResults / nrProductsPerPage)
  const maxButtons = Math.floor(width / buttonSizeInPixels) - 4


  // useEffect(() => {
  //   // When the page changes, scroll to top
  //   const unlisten = history.listen(tryToScrollToTop)
  //   return unlisten
  // }, [activePage])


  const pageItemProps = (page) => ({
    style: {
      width: buttonSizeInPixels,
      height: buttonSizeInPixels
    },
    className: `page-item ${page === activePage ? "active" : ""}`,
    to: setPageInUrl(page)
  })


  const fistButtons = [
    <Link {...pageItemProps(1)}>
      1
    </Link>,

    // UNCOMMENT THIS IF BUTTON FOR PAGE 2 IS ALSO NEEDED:
    // <Link  {...pageItemProps(2)}>
    //   2
    // </Link>,

    <div data-test-id='qXKN' className="page-item dots">{dotsSvg}</div>
  ]

  const lastButtons = [
    <div data-test-id='BzIp' className="page-item dots">{dotsSvg}</div>,
    
    // UNCOMMENT THIS IF BUTTON FOR PAGE (n-1) IS ALSO NEEDED:
    // <Link {...pageItemProps(noOfPages - 1)}>
    //   {noOfPages - 1}
    // </Link>,

    <Link {...pageItemProps(noOfPages)}>
      {noOfPages}
    </Link>
  ]

  let buttons

  const buttonsArrayGenerator = (startIndex, length) => {

    const validStartIndex = startIndex > 1 ? startIndex : 1

    return (
      Array.from({ length }, (_, k) => k + validStartIndex)?.map?.((page) => (
        <Link {...pageItemProps(page)}>
          {page}
        </Link>
      ))
    )
  }
  
  
  
    

  const doTheyAllFit = noOfPages <= maxButtons

  if (doTheyAllFit) {

    //   they all fit case:
    //     1 2 3 4 5 6 7 8 9 10 11 12 13 14 15 16 17 18 19 20
    const startIndex = 1
    const length = noOfPages
    buttons = buttonsArrayGenerator(startIndex, length)

  } else if (activePage <= maxButtons - 4) {
    
    // trim the end case:
    //             1 2 3 4 5 6 7 8 9 10 ... 20
    //     render    [0, maxButtons-2]      1 static
    const startIndex = 1
    const length = maxButtons - 2

    buttons = buttonsArrayGenerator(startIndex, length)
    buttons?.push?.(...lastButtons)

  } else if (activePage >= noOfPages - maxButtons + 4) {
    
    // trim the beginning case:
    //             1 2 ... 11 12 13 14 15 16 17 18 19 20
    //     render    1 static    [maxButtons-2, total]
    buttons = [...fistButtons || []]
    const startIndex = noOfPages - maxButtons + 3
    const length = maxButtons - 2
    buttons?.push?.(...buttonsArrayGenerator(startIndex, length))

  } else {
    
    // trim both sides case:
    //             1 2 ... 6 7 8 9 10 11 12 13 14 15 ... 19 20
    //     render    1 static       middleButtons    1 static
    buttons = [...fistButtons || []]
    const length = maxButtons - 2
    const startIndex = activePage - Math.floor((maxButtons - 2) / 2)
    buttons?.push?.(...buttonsArrayGenerator(startIndex, length))
    buttons?.push?.(...lastButtons)

  }


  const isCurrentPageFirst = activePage === 1
  const isCurrentPageLast = activePage === noOfPages
  const leftClass = `page-item ${isCurrentPageFirst ? "disabled" : ""}`
  const rightClass = `page-item ${isCurrentPageLast ? "disabled" : ""}`

  if(!totalNrResults) return null

  const wrapperClassName = `pagination-wrapper ${doTheyAllFit ? 'doTheyAllFit' : ''}`



  return (
    <div data-test-id='O4Vs' className='pagination-outer-wrapper'>
      <div data-test-id='dUqL'  className={wrapperClassName}>
        <Link {...pageItemProps(1)} className={leftClass}>
          {doubleLeftSvg}
        </Link>
        <Link {...pageItemProps(activePage - 1)} className={leftClass}>
          {leftSvg}
        </Link>

        {
          buttons?.map?.((button, index) => (
              <React.Fragment key={`finalNumericButtons-${index}`}>
                {button}
              </React.Fragment>
            )
          )
        }

        <Link {...pageItemProps(activePage + 1)} className={rightClass}>
          {leftSvg}
        </Link>
        <Link {...pageItemProps(noOfPages)} className={rightClass}>
          {doubleLeftSvg}
        </Link>
      </div>
    </div>
  )
}

export default Pagination


// const tryToScrollToTop = () => {
//   const wrapperElement = document.querySelector('.pageContent')
  
//   if(!wrapperElement){
//     console.warn('Could not scroll to top because .pageContent is not found.')
//     return 
//   }

//   wrapperElement?.scrollTo?.({
//     top: 0,
//     behavior: 'smooth'
//   })
// }



const doubleLeftSvg = (
  <svg 
    data-test-id='r47C'
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15 17.5605L6.96975 9.5302C6.6765 9.23695 6.6765 8.76295 6.96975 8.4697L15 0.439453L16.0605 1.49995L8.5605 8.99995L16.0605 16.5L15 17.5605Z" />
    <path d="M10.5 17.5605L2.46975 9.5302C2.1765 9.23695 2.1765 8.76295 2.46975 8.4697L10.5 0.439453L11.5605 1.49995L4.0605 8.99995L11.5605 16.5L10.5 17.5605Z" />
  </svg>
)

const leftSvg = (
  <svg 
    data-test-id='mhjw'
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15.7502 20.871L7.71994 12.8407C7.42669 12.5475 7.42669 12.0735 7.71994 11.7802L15.7502 3.75L16.8107 4.8105L9.31069 12.3105L16.8107 19.8105L15.7502 20.871Z" />
  </svg>
)

const dotsSvg = (
  <svg 
    data-test-id='jo1K'
    width="48"
    height="27"
    viewBox="0 0 48 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.7891 19.1719C18.7891 18.8229 18.8672 18.5599 19.0234 18.3828C19.1849 18.2005 19.4141 18.1094 19.7109 18.1094C20.013 18.1094 20.2474 18.2005 20.4141 18.3828C20.5859 18.5599 20.6719 18.8229 20.6719 19.1719C20.6719 19.5104 20.5859 19.7708 20.4141 19.9531C20.2422 20.1354 20.0078 20.2266 19.7109 20.2266C19.4453 20.2266 19.224 20.1458 19.0469 19.9844C18.875 19.8177 18.7891 19.5469 18.7891 19.1719ZM23.0547 19.1719C23.0547 18.8229 23.1328 18.5599 23.2891 18.3828C23.4505 18.2005 23.6797 18.1094 23.9766 18.1094C24.2786 18.1094 24.513 18.2005 24.6797 18.3828C24.8516 18.5599 24.9375 18.8229 24.9375 19.1719C24.9375 19.5104 24.8516 19.7708 24.6797 19.9531C24.5078 20.1354 24.2734 20.2266 23.9766 20.2266C23.7109 20.2266 23.4896 20.1458 23.3125 19.9844C23.1406 19.8177 23.0547 19.5469 23.0547 19.1719ZM27.3203 19.1719C27.3203 18.8229 27.3984 18.5599 27.5547 18.3828C27.7161 18.2005 27.9453 18.1094 28.2422 18.1094C28.5443 18.1094 28.7786 18.2005 28.9453 18.3828C29.1172 18.5599 29.2031 18.8229 29.2031 19.1719C29.2031 19.5104 29.1172 19.7708 28.9453 19.9531C28.7734 20.1354 28.5391 20.2266 28.2422 20.2266C27.9766 20.2266 27.7552 20.1458 27.5781 19.9844C27.4062 19.8177 27.3203 19.5469 27.3203 19.1719Z"
      fill="#B3B3B3"
    />
  </svg>
)
