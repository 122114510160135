export const permissionGroups = [
  {
    code: "ORGANIZATION",
    label: "Organization"
  },
  {
    code: "INVOICE",
    label: "Invoice"
  },
  {
    code: "LOCATION",
    label: "Location"
  },
  {
    code: "GROUP",
    label: "Group"
  },
  {
    code: "VEHICLE",
    label: "Vehicle"
  },
  {
    code: "BUDGET_CATEGORY",
    label: "Budget category"
  },
  {
    code: "AUDIT",
    label: "Audit"
  },
  {
    code: "ASSET_MODEL",
    label: "Asset model"
  },
  {
    code: "USER",
    label: "User"
  },
  {
    code: "DICTIONARY",
    label: "Dictionary"
  },
  {
    code: "MATERIAL",
    label: "Material"
  },
  {
    code: "SHIFT",
    label: "Shift"
  },
  {
    code: "VALUABLES_RATE",
    label: "Valuables rate"
  },
  {
    code: "VALUABLES",
    label: "Valuables"
  },
  {
    code: "REMINDER",
    label: "Reminder"
  },
  {
    code: "CONTRACT",
    label: "Contract"
  },
  {
    code: "NOTIFICATION",
    label: "Notification"
  },
  {
    code: "PRODUCT",
    label: "Product"
  }
]
