import store from "store"
import {
  apiAction,
  setIsSignedInStateInLocalStorage,
  setRefreshToken,
  // setSignedInStateInLocalStorage
} from "store/middleware/api"
import {
  // setApiResponse,
  SET_AUTHENTICATED,
  SET_USER,
  SET_AUTH_TIME_STAMP,
  LOGOUT_USER
} from "store/modules/auth"
import {
  loadDictionary,
  // loadProductCategories,
  setLoginCalls,
  getAllClients,
  getAllClientsOptions,
  getAllAgents,
  getRankingOptions
} from "store/modules/backData"
import { loadClientAddresses, setClientTypeGlobal } from "store/modules/company"
import { getCart } from "store/modules/shop"
import { toastr } from "react-redux-toastr"
import { setUserInfo } from "store/modules/user"
import { history } from "utils/history"
import { loadOrganizationLists } from "store/justActions/lists"
import { resetDV } from "store/modules/dataView"
// import { searchStuff } from "store/modules/dataView.search"
import { printError } from "utils/printError"
import { arrayPartialMatch } from "utils/arrayUtils"
import { getLoginErrorToastMessage } from "utils/toastUtils"
// import { setOnBehalfIdInState } from "store/modules/page"

export const loadMe = ({ auth = false }) =>
  apiAction({
    endPoint: "/me", ///uniprest/user
    method: "GET",
    onSuccess: (data, dispatch) => {
      dispatch(loadOrganizationLists())
      dispatch(
        setUserInfo({
          id: data?.id,
          userId: data?.user?.id,
          name: data?.name,
          organizationId: data?.organizationId,
          language: "EN" //data?.user?.language
        })
      )

      //debugger
      // auth && dispatch(loadClientAddresses(data?.user.organizationId))
      return { type: "a" }
    },
    onFailure: (err) => {
      printError("loadMe()", err)
      // showMagicToaster({title: err})

      return {
        type: "a"
      }
    }
  })

export const javaLogin = (loginName, password, noRedirect) =>
  apiAction({
    endPoint: "/public/sign-in",//login
    method: "POST",
    data: {
      loginName,
      password,
      client: "front"
    },
    onSuccess: (data, dispatch) => {
      setRefreshToken(data?.refreshToken)
      setIsSignedInStateInLocalStorage(true);//loginName !== "anonymous"
      dispatch(setUserInfo({ groupId: data?.role?.groupId }))
      dispatch(loadMe({ auth: true }))//loginName !== "anonymous"
      dispatch(setLoginCalls(0))
      dispatch(getCart())
     
      // dispatch( /***** moved to App.js for non logged users */
      //   searchStuff({
      //     type: "public/organization/workplace",
      //     dictionary: "LOCATION_RESPONSE",
      //     searchTerm: null,
      //     reload: true
      //   })
      // )

      // dispatch({ type: SET_AUTHENTICATED, payload: noRedirect ? null : true })
      // dispatch({ type: SET_AUTH_TIME_STAMP, payload: new Date() })
      // dispatch( setApiResponse(data));
      //app set-up
      // dispatch(loadEnums("product.ProductStockStatus"))
      // dispatch(loadEnums("user.UserStatus"))
      dispatch(
        setClientTypeGlobal("SMALL")//loginName === "anonymous" ? "GUEST" : 
      )
      // dispatch(setClientTypeGlobal("RESELLER"))
      // dispatch(setClientTypeGlobal("LARGE"))
      // dispatch(setClientTypeGlobal("ADMIN"))
      //dispatch(loadProductCategories())/***** moved to App.js for non logged users */
      // dispatch(loadDictionary("PRODUCT_BRAND"))/***** moved to App.js for non logged users */
      // dispatch(loadDictionary("PRODUCT_SUPPLIER"))/***** moved to App.js for non logged users */
      // dispatch(loadDictionary("USER_POSITION"))/***** moved to App.js for non logged users */
      // dispatch(loadDictionary("UNIT_OF_MEASURE"))/***** moved to App.js for non logged users */
      dispatch(loadDictionary("UNIT_OF_MEASURE_CUSTOMER_OFFER"))
      // dispatch(loadDictionary("COUNTY"))/***** moved to App.js for non logged users */
      dispatch(getAllClients())
      dispatch(getAllClientsOptions())
      dispatch(getAllAgents())
      dispatch(getRankingOptions())/***** moved to App.js for non logged users */
      dispatch(resetDV())
      // dispatch(setOnBehalfIdInState())/***** moved to App.js for non logged users */
      dispatch({ type: SET_AUTHENTICATED, payload: noRedirect ? null : true })
      dispatch({ type: SET_AUTH_TIME_STAMP, payload: new Date() })
      if (!noRedirect) {
        setTimeout(() => {
          history?.push?.(
            arrayPartialMatch(data?.role?.permissions,
              ["WEBSHOP_ADMIN", "WEBSHOP_HR_ADMIN", "WEBSHOP_MARKETING_ADMIN"])
            ? "/admin"
            : "/shop/dashboard"
          )
          //history?.push?.(data?.role?.groupName === "Sales Manager" || data?.role?.groupName === "BO Sales" ? '/admin' : '/shop/dashboard')
        }, 3000)
        // setInterval(() => {
        //     dispatch(refreshJavaToken())
        // }, 5000);
      }
      return {
        type: SET_USER,
        payload: data
      }
    },
    onFailure: (e) => {

      printError("javaLogin()", e)

      const errorMessage = getLoginErrorToastMessage(e)
      toastr.error(errorMessage)

      return {
        type: "a"
      }
    }
  })

export const resetPassword = (
  nPass,
  nPassAgain,
  passwKey,
  setSuccsessReset,
  setenterNewPasswordCard
) => {
  return apiAction({
    endPoint: "/public/password-reset",
    method: "POST",
    data: {
      newPassword: nPass,
      newPasswordAgain: nPassAgain,
      key: passwKey
    },
    onSuccess: (data, dispatch) => {
      setSuccsessReset(true)
      setenterNewPasswordCard(false)
      return {
        type: "a"
      }
    },
    onFailure: (error, dispatch) => {
      printError("resetPassword()", error)
      if (nPass !== nPassAgain) {
        toastr.error("Parolele nu se potrivesc!")
      } else {
        toastr.error(
          "Parola nouă nu se potrivește cerințelor minime de complexitate"
        )
      }
      return {
        type: "a"
      }
    }
  })
}

export const forgotPassword = (
  identity,
  setResetPasswordCard,
  setSentResetPasswordMessae,
  setLoginCard
) => {
  return apiAction({
    endPoint: "/public/password-forgot",
    method: "POST",
    data: {
      emailAddress: identity,
      client: "front"
    },
    onSuccess: (data, dispatch) => {
      setResetPasswordCard(false)
      setSentResetPasswordMessae(true)
      setLoginCard(false)
      return {
        type: "a"
      }
    },
    onFailure: (error, dispatch) => {
      printError("forgotPassword()", error)
      toastr.error("Utilizatorul nu exista!")
      return {
        type: "a"
      }
    }
  })
}

export const refreshJavaToken = () => {
  const rftok = store.getState().auth?.userData?.refreshToken
  return apiAction({
    endPoint: "/public/refresh-secure",
    method: "POST",
    data: {
      rftok
    },
    qs: true,
    onSuccess: (data, dispatch) => {
      return {
        type: "a"
        // payload: data
      }
    },
    onFailure: (error, dispatch) => {
      printError("refreshJavaToken()", error)
      // When refresh token fails, throw out the user and go to login page
      dispatch({ type: LOGOUT_USER })
      history?.push?.("/login")

      return {
        type: "a"
      }
    }
  })
}

// not used anymore, check clearCookieToken()
// 
//
// export const removeJavaToken = () => {
//   setIsSignedInStateInLocalStorage(false);
//   return apiAction({
//     endPoint: "/logout",
//     method: "POST",
//     onSuccess: (data) => {
//       setTimeout(() => {
//         history?.push?.("/")
//       }, 1000)
//       return {
//         type: "a"
//       }
//     },
//     onFailure: (error, dispatch) => {
//       printError("removeJavaToken()", error)
//       return {
//         type: "a"
//       }
//     }
//   })
// }
