import SvgIcon from "components/SvgIcon/SvgIcon"
import { useEffect, useRef, useState } from "react"
import Select, { components, NonceProvider } from "react-select"
import { withTheme } from "styled-components"
import "./index.scss"
import { Controller } from "react-hook-form"
import { useDispatch } from "react-redux"
import { setFilterSelection } from "store/modules/dataView"
import useQueryStringFilters from "utils/useQueryStringFilters"
// import NewItemForm from 'views/dev/sorin/newItemForm'

export default function CustomSelectFilter(props) {
  const {
    inForm,
    className,
    control,
    data,
    disabled,
    error,
    fb,
    filter,
    icon,
    isClearable,
    isMulti,
    label,
    name,
    onChange,
    options,
    placeholder = "Selecteaza...",
    selection,
    type,
    id,
    // value,
    w,
    resetPage,
    ...rest
  } = props
  const { setPage } = useQueryStringFilters()

  let actualOptions = data ?? options
  actualOptions = actualOptions
    ? actualOptions?.map?.((o) => {
        return { value: o.id ?? o?.value, label: o.label }
      })
    : []
  // const computedValue = actualOptions?.filter?.(item => value?.includes?.(item?.value) || selection?.includes?.(item?.value))
  // const computedValue = value
  const mainClass = className
    ? `${className} custom-select-flex`
    : `custom-select-flex`
  const ss = useRef("")
  const dispatch = useDispatch()

  const filterStyle = {
    container: (provided, state) => ({
      ...provided,
      width: w ?? (fb ? "auto" : 200),
      flexBasis: fb ?? 0
    }),
    control: (provided, state) => {
      let result = {
        ...provided
        // none of react-select's styles are passed to <Control />
      }
      if (state.hasValue && filter) {
        result.backgroundColor = "#EDF5FF"
        result.borderColor = "#D0E2FF"
      }
      return result
    },

    menuList: (provided, state) => ({
      ...provided,
      border: "1px solid #E0E0E0",
      // borderRadius: 4,
      padding: 0,
      backgroundColor: "white"
    }),

    menu: (provided, state) => ({
      ...provided,
      left: filter ? 0 : 32,
      width: filter ? "100%" : "calc(100% - 32px)",
      boxShadow: "none",
      marginTop: filter ? 0 : 4,
      padding: filter ? 24 : 0,
      backgroundColor: filter ? "#F4F4F4" : "none",
      border: "1px solid green"
    }),
    option: (provided, state) => ({
      ...provided,
      padding: "8px 16px"

      // width: "calc(100% - 32px)",
      // boxShadow: "none",
    }),
    indicatorSeparator: (provided, state) => {
      const opacity = isClearable && state.hasValue ? 1 : 0
      const backgroundColor = "#E0E0E0"
      const margin = "4px 0"
      return { ...provided, backgroundColor, opacity, margin }
    }
  }

  const ValueContainer = (props) => {
    
    const menuIsOpen = props?.children?.[1]?.props?.selectProps?.menuIsOpen
    const nrSelectedOptions = props?.getValue?.()?.length || 0

    const valueContainerLabel = menuIsOpen // rendering text-based search-input only when select is open
      ? props.children?.[1]
      : <div data-test-id='rXyd'>
        {label}
        {nrSelectedOptions ? ` (${nrSelectedOptions})` : null}
      </div>

    return (
      <components.ValueContainer {...props}>
        {filter && props.isMulti 
          ? (props?.children?.[0]?.key ? props.children : valueContainerLabel) 
          : props.children
        }
      </components.ValueContainer>
    )
  }

  const Control = (props) => {
    return (
      <components.Control {...props}>
        <>
          {icon && <SvgIcon name="search" />}
          {props.children}
        </>
      </components.Control>
    )
  }

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        {/* <EmojiIcon primaryColor={colourOptions?.[2]?.color} /> */}
        <div data-test-id='uS3w' className="btn-stroke-text-light-01 flex">
          <SvgIcon name="select down" />
        </div>
      </components.DropdownIndicator>
    )
  }

  const ClearIndicator = (props) => {
    return (
      <components.ClearIndicator {...props}>
        {isClearable ? (
          <div data-test-id='mvEU' className="btn-stroke-text-light-01 flex">
            <SvgIcon name="close" />
          </div>
        ) : (
          <div data-test-id='LCOk' />
        )}
      </components.ClearIndicator>
    )
  }

  const Menu = (props) => {
    const menuRef = useRef(null)
    const [scrollPosition, setScrollPosition] = useState(0)
    const [selectedVal, setSelectedVal] = useState(
      props.getValue()?.map?.((item) => item?.value)
    )

    // menuRef && menuRef.current.scrollTo(0, scrollPosition);
    const optionClick = (item) => {
      setSelectedVal((p) =>
        props.isMulti ? toggleInArray(p, item?.value) : [item?.value]
      )
      setScrollPosition(menuRef.current.scrollTop)
    }

    useEffect(() => {
      if (menuRef.current) menuRef.current.scrollTop = scrollPosition
    }, [scrollPosition])
    return (
      <components.Menu {...props}>
        {/* <EmojiIcon primaryColor={colourOptions?.[2]?.color} /> */}
        {!filter && props.children}
        {filter && (
          <>
            <div data-test-id='CmtI' className="option-list" ref={menuRef}>
              {stringInArray(props.options, "label", ss.current)?.map?.(
                (item, index) => (
                  <div 
                    data-test-id='sywz'
                    key={index}
                    className={`single-option ${
                      selectedVal?.includes?.(item?.value) ? "selected" : ""
                    }`}
                    onClick={() => optionClick(item)}
                  >
                    {props.isMulti && (
                      <SimpleCheckbox
                        value={selectedVal?.includes?.(item?.value)}
                      />
                    )}
                    <div data-test-id='7tY8' className="label">{item?.label}</div>
                  </div>
                )
              )}
            </div>
            <div data-test-id='vMLf' className="filter-buttons">
              <button 
                data-test-id='OmT0'
                onClick={() => {
                  setSelectedVal([])
                  props.setValue()
                }}
              >
                Anuleaza
              </button>
              <button 
                data-test-id='B57N'
                onClick={() => {
                  props.setValue(
                    props.options?.filter?.((item) =>
                      selectedVal?.includes?.(item?.value)
                    )
                  )
                  resetPage && setPage(1)
                }}
              >
                Salveaza
              </button>
              {/* <button data-test-id='w2z5' onClick={()=> props.setValue(props.options?.filter?.(item => selectedVal?.includes?.(item?.value)))}>Salveaza</button> */}
            </div>
          </>
        )}
      </components.Menu>
    )
  }

  const selectComponent = (onChangeLocal, onBlur, value) => (
    <Select
      className={"select-overwrites-1 " + (type ?? "custom-select-small")}
      classNamePrefix="crs"
      styles={type === "filter" ? filterStyle : undefined}
      components={{
        // IndicatorSeparator: undefined,
        // DropdownIndicator: <SvgIcon name="down"/>
        DropdownIndicator,
        ValueContainer,
        ClearIndicator,
        Control,
        Menu
      }}
      onInputChange={(e) => (ss.current = e)}
      {...rest}
      isClearable={isClearable}
      isMulti={isMulti}
      // defaultValue={[{ value: "ID4", label: "Option 4" }]}
      value={adaptValue(value ?? selection, actualOptions)}
      options={actualOptions}
      onChange={(e) =>
        dispatch(
          setFilterSelection(
            id,
            e?.map?.((i) => i?.value)
          )
        )
      }
      placeholder={label}
      isDisabled={disabled}
      onBlur={onBlur ?? undefined}
    />
  )

  const title = error ? (
    <div data-test-id='2knq' className="mb2 ptype-3 color-alert-red">{error}</div>
  ) : (
    <div data-test-id='0Q3e' className="mb2 ptype-3">{label}</div>
  )

  return (
    <>
      {inForm ? (
        <div data-test-id='C55s'>
          {title}
          <Controller
            control={control}
            name={name}
            defaultValue={undefined}
            render={(
              { onChange, onBlur, value, name, ref },
              { invalid, isTouched, isDirty }
            ) => selectComponent(onChange, onBlur, value)}
          />
        </div>
      ) : (
        selectComponent()
      )}
    </>
  )
}

const getSelectValues = (e) => {
  if (!e) return undefined
  if (e?.value) return e?.value
  else return e?.map?.((item) => item?.value)
}
const toggleInArray = (array, value) =>
  array?.includes?.(value)
    ? array?.filter?.((item) => item !== value)
    : [...array || [], value]

const stringInArray = (array, keyName, string) =>
  array?.filter?.((item) => {
    if (!string) return true
    if (string?.length === 0) return true
    if (
      item?.[keyName]
        ?.toLowerCase?.()
        ?.replace?.(" ", "")
        ?.includes?.(string?.toLowerCase?.())
    )
      return true
    return false
  })
const SimpleCheckbox = (props) => {
  const iconName =
    props?.value === true ? "Checkbox_active" : "Checkbox_inactive"
  const style = {
    marginRight: 8,
    display: "flex",
    alignItems: "center"
  }
  return (
    <div data-test-id='z7gU' style={style}>
      <SvgIcon name={iconName} />
    </div>
  )
}

const adaptValue = (value, options) => {
  // if (typeof value !== "object") return options
  const result = options?.filter?.(
    (item) => value == item?.value || value?.includes?.(item?.value)
  )
  return result
}
