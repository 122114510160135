import MagicButton from "components/MagicButton/MagicButton"
import MB from "components/MB/MB"
import { useDispatch } from "react-redux"

function SpecialPricesCard(props) {
  const {
    setCardState,
    setSelectedQuantity,
    unitOfMeasure,
    deals,
    totalStock,
    closeModal
  } = props
  const dispatch = useDispatch()

  return (
    <>
      <div data-test-id='ay9i'>
        <MagicButton
          h={6}
          ml={-1}
          mb={1}
          svg="BackArrow"
          type="simple"
          onClick={() => setCardState("addToCartCard")}
        />
        <div data-test-id='lbFu' className="ptype-6">Preturi</div>
        <div data-test-id='0dAB' className="ptype-6">Speciale</div>
        <MB mt={3} mb={1} className="ptype-4-price text-light-03">
          ALEGE CANTITATEA
        </MB>
        {deals?.map?.((item) => (
          <>
            {item?.recomanded && (
              <MB mb={0.5} className="ptype-4-price all-good">
                ALEGERE RECOMANDATA
              </MB>
            )}
            <div 
              data-test-id='OZGo'
              className={item?.recomanded ? "grey-option deal" : "grey-option"}
              onClick={() => {
                setSelectedQuantity(
                  item?.quantity > totalStock ? totalStock : item?.quantity
                )
                setCardState("addToCartCard")
              }}
            >
              <div data-test-id='zvXG'>{`${item?.quantity} ${unitOfMeasure}`}</div>
              <MB g={1} flex>
                <div data-test-id='jeXu'>{item?.price}</div>
                <div data-test-id='l4cI' className="redSmallCard">{item?.discount}</div>
              </MB>
            </div>
          </>
        ))}
      </div>
      <div data-test-id='hWGj' />
    </>
  )
}

export default SpecialPricesCard
