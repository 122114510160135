import React from "react"
import Close from "assets/general/Close.svg"
import closeGrey from "assets/general/close-grey.svg"
import blueHeart from "assets/general/blueHeart.svg"
import lightbulb from "assets/general/lightbulb.svg"
import "./index.scss"
import { useDispatch, useSelector } from "react-redux"
import { setFilterSelection } from "store/modules/dataView"
import { addSavedFilter } from "store/modules/dataView/dataViewActions.actions"
import useQueryStringFilters from "utils/useQueryStringFilters"
import { withLocalize } from "components/Lang/Lang"
import MyLink from "components/MyLink"

const SelectedFilters = (props) => {
  const { disableSave, filters, translate } = props
  // const {appliedFilters, disableSave} = props
  const { query, getUrlWithoutThisFilter } = useQueryStringFilters()
  const appliedFilters =
    Object?.entries?.(query.filters)?.map?.(([key, value]) => ({
      id: key,
      name: filters?.find?.((item) => item?.id === key)?.label
      //  value
    })) ?? []

  // const dispatch = useDispatch() //logic moved to useQueryStringFilters
    //console.log("SELECTED FILTERS APPLIED ", appliedFilters);


  const getTitle = () => {


    if(!appliedFilters?.length) return translate?.("filters.noFilter")
    if(appliedFilters?.length === 1) return translate?.("filters.filter")
    return translate?.("filters.filters", {Variable40: appliedFilters?.length || '0'})
    // return translate?.("filters.filters", {Variable40: appliedFilters?.length || '0'})
  }

  
  // code moved to useQueryStringFilters
  // const deleteFilter = (id) => {
  //   setQuery({filters:{[id]: undefined}})

  //   // dispatch(setFilterSelection(id, null))
  // }
  // const deleteAllFilters = () => {
  //   setQuery({filters:undefined})
  //   // dispatch({type:"TEMP_CLEAR_SHOP_FILTERS"})
  // }
  return (
    <div data-test-id='h26O' className="appliedFilters text-light-1">
      <div data-test-id='euKL' className="top">
        <img 
          data-test-id='O1rS'
          onContextMenu={(e) => e?.preventDefault?.()}
          src={lightbulb}
          alt="light"
        />
        <div data-test-id='3ACK' className="ptype-4-bold">{getTitle()}</div>
      </div>
      <div data-test-id='dnOI' className="selectContainer">
        {appliedFilters?.map?.((filter) => (
          <div data-test-id='mhyC' key={filter?.id} className="sf-pill">
            <div data-test-id='X6Ms' className="ptype-2">{filter?.name}</div>
            <MyLink to={getUrlWithoutThisFilter(filter?.id)} className='closeIconLink'>
              <img 
                data-test-id='WG4N'
                onContextMenu={(e) => e?.preventDefault?.()}
                src={closeGrey}
                alt="delete"
              />
            </MyLink>

            
          </div>
        ))}
        {appliedFilters?.length ? (
          <MyLink to='/shop/productlist' className='closeIconLink' >
            <img 
              data-test-id='Q1CU'
              src={Close}
              className="sf-clear"
              alt="clear"
            />
          </MyLink>
          
        ) : (
          ""
        )}
      </div>
      {/**{(appliedFilters?.length && !disableSave) ? <img data-test-id='FRs8' src={blueHeart} className="sf-add" alt="clear" onClick={()=> dispatch(addSavedFilter("New one"))}/> : ""} */}
    </div>
  )
}

export default withLocalize(SelectedFilters)
