import React, { useEffect } from "react"
import NextIcon from "../../assets/BreadCrumbs/NextIcon.svg"
import MB from "../MB/MB"
import { useSelector, useDispatch } from "react-redux"
import { shopBreadcrumbsSelector } from "./shopBreadCrumbsSelector"
import { setFilterSelection } from "store/modules/dataView"
import useQueryStringFilters from "utils/useQueryStringFilters"
import { defaultPageTitle } from "components/HelmetMetaData"
import { clearPageSeo, setPageSeo } from "store/modules/page"
import { toTitleCase } from "utils/stringUtils"
import { campaignConfig } from "views/shoppingCart/useCampaignInfo"
import useIsLogged from "utils/useIsLogged"
import { KnownCategoryCodes } from "components/CategoryFilter"
import Lang, { withLocalize } from "components/Lang/Lang"
//import { loadViewData } from "store/modules/dataView/dataView-dataLoader";

const categFilterId = "FLT-CATEG001"
const brandFilterKey = 'FLT-SMaaaa00'

//const mockData = ["Toate Produsele", "Categoria A", "Sub Categoria B"];
//const data = mockData;
function ShopBreadCrumbs({ translate }) {
  const cateogries = useSelector((state) => state.backData?.productCategory)
  
  const isLogged = useIsLogged()


  // const {selectedFilterID,
  //     categoryNames,
  //     //categoryTreeCode,
  //     treeCodeValues} = useSelector(state => shopBreadcrumbsSelector(state))
  //console.log("BreadCRBS ids names tree values", selectedFilterID, categoryNames, categoryTreeCode, treeCodeValues )
  const dispatch = useDispatch()
  const { filters, setFilter, clearFiler, searchString } = useQueryStringFilters()
  

  const activeBrandNames = filters?.[brandFilterKey]
  const qsSelection = filters?.[categFilterId]?.[0]
  const searchStringTitleCase = toTitleCase(searchString)



  //map the selected tree code to an array of selected category / sub category/ etc for display purpose
  // better to keep th original data structure then using 2 corelated arrays.
  const categoriesFinder = (treeCode) => {
    const result = []
    const numberOfCategories = treeCode?.split?.("#")?.length - 1
    let children = cateogries
    let category = children?.find?.((c) => treeCode?.includes?.(c.treeCode))
    // debugger
    if (category) {
      result?.push?.(category)
      children = category?.childList
      if (children?.length > 0 && numberOfCategories > 1) {
        category = children?.find?.((c) => treeCode?.includes?.(c.treeCode))
        if (category) result?.push?.(category)
        children = category?.childList
        if (children?.length > 0 && numberOfCategories > 2) {
          category = children?.find?.((c) => treeCode?.includes?.(c.treeCode))
          if (category) result?.push?.(category)
          children = category?.childList
          if (children?.length > 0 && numberOfCategories > 3) {
            category = children?.find?.((c) => treeCode?.includes?.(c.treeCode))
            if (category) result?.push?.(category)
          }
        }
      }
    }
    return result
  }


  const foundCategories = categoriesFinder(qsSelection)

  const categoriesData = [
    { name: "Toate produsele" },
    ...(foundCategories || []), 
  ]
  
  const shouldShowCampaignExplanation = (
    !isLogged &&
    campaignConfig.shouldShowBreadcrumbExplanation &&
    (
      qsSelection?.includes?.(KnownCategoryCodes.blackFriday) ||
      foundCategories?.some?.(category => category?.name === 'BLACK FRIDAY')
    )
  )
  

  useEffect(() => {
    /** Set page title and meta tags according to active filters 
     * (categories and brands) and the (text-based) search-term . */
    const activeCategoryNames = foundCategories?.map?.(category => category?.name)

    const titleParts = [
      ...(activeCategoryNames || []),
      ...(activeBrandNames || []),
      ...(searchStringTitleCase ? [searchStringTitleCase] : [])
    ]

    const jointTitle = titleParts?.join?.(' | ') || 'Toate produsele'

    const pageTitle = `${defaultPageTitle} - ${jointTitle}`

    dispatch(setPageSeo({ 
      pageTitle, 
      metaTags: {
        'og:title': pageTitle,
      },
    }))
    
    // Clear title and meta tags when leaving the page:
    return () => dispatch(clearPageSeo())

  }, [categoriesData])
  
  // if(!treeCodeValues?.length) return null
  if (!qsSelection) return null

  return (

    <>
    
    <div 
        data-test-id='F43o'
        className={`breadcrumb flex ptype-3 no-wrap`}
        style={{ flex: "0 0 100%", paddingRight: "0" }}
      >
        {categoriesData?.map?.((item, index) => {
          const lastOne = index + 1 === categoriesData?.length
          return !lastOne ? (
            <React.Fragment key={index}>
              <div 
                data-test-id='6x98'
                className="btn-txt-button-prime"
                onClick={(e) => {
                  if (index === 0) {
                    // dispatch(setFilterSelection())
                    clearFiler(categFilterId)
                    dispatch(setFilterSelection("FLT-sort00p1", ["ranking|true"]))
                    //loadViewData()
                  } else {
                    // const dispatchTreeCode = treeCodeValues?.slice?.(0,index)?.join?.("")
                    // dispatch(setFilterSelection(selectedFilterID, [dispatchTreeCode]))
                    setFilter(categFilterId, [item?.treeCode])
                  }
                }}
              >
                {item?.name}
              </div>
              <img 
                data-test-id='i9FS'
                onContextMenu={(e) => e?.preventDefault?.()}
                className="mx2"
                src={NextIcon}
                alt="next"
              />
            </React.Fragment>
          ) : (
            <div data-test-id='odAW' key={"last" - index} className="text-light-01">
              {item?.name}
            </div>
          )
        })}


      </div>
        {
          shouldShowCampaignExplanation 
            ? (
              <div style={{ flex: "0 0 100%", fontSize: "12px", marginTop: "-25px", }}>
                <p style={{maxWidth: '750px',}}>
                  {translate?.('breadCrumbs.blackFridayDescription')}
                </p>
              </div>
            )
            : null
        }
      </>

    
  )
}


export default withLocalize(ShopBreadCrumbs)
