// import orderTableData from "mockData/orderTableData";
import { reDispatch } from "store/middleware/api"
import { setQsParams } from "utils/history"
import { sanitizeAnythingForRedux } from "utils/preventReactInRedux"
// import store from "store/index";
// import { setClientTypeGlobal } from "store/modules/company";
// import { history } from "utils/history";
// import { deleteDynamoItemByPK } from "store/justActions/dynamoOps";
// import { reDispatch } from "store/middleware/api";

export const ADD_ITEMS = "dataView/ADD_ITEMS"
export const SET_ITEMS = "dataView/SET_ITEMS"
export const ADD_PK_ITEMS_WITHOUT_DUPLICATES = "dataView/ADD_PK_ITEMS_WITHOUT_DUPLICATES"
export const CLEAR_ITEMS = "dataView/CLEAR_ITEMS"
export const SET_DV_SELECTED_FILTERS = "dataView/SET_DV_SELECTED_FILTERS"
export const SET_DV_MULTIPLE_SELECTED_FILTERS =
  "dataView/SET_DV_MULTIPLE_SELECTED_FILTERS"
export const RESET_DV = "dataView/RESET_DV"
export const SET_DV_STRING = "dataView/SET_DV_STRING"
export const SET_DV_ITEMS = "dataView/SET_DV_ITEMS"
export const ADD_DV_ITEMS = "dataView/ADD_DV_ITEMS"
export const TOGGLE_SELECT_ITEM = "dataView/TOGGLE_SELECT_ITEM"
export const TOGGLE_SELECT_ALL_ITEMS = "dataView/TOGGLE_SELECT_ALL_ITEMS"
export const ADD_SAVED_FILTER = "dataView/ADD_SAVED_FILTER"
export const DELETE_SAVED_FILTER = "dataView/DELETE_SAVED_FILTER"
export const ACTIVATE_SAVED_FILTER = "dataView/ACTIVATE_SAVED_FILTER"
export const SET_MORE_ACTION_ROW_ID = "dataView/SET_MORE_ACTION_ROW_ID"
export const TOGGLE_SORT = "dataView/TOGGLE_SORT"
export const RESET_ACTIONS = "dataView/RESET_ACTIONS"
export const SET_DV_ID = "dataView/SET_DV_ID"
export const SET_COLUMS = "dataView/SET_COLUMS"
export const SET_VOLUME_DISCOUNT_QUNTITIES =
  "shop/SET_VOLUME_DISCOUNT_QUNTITIES"
export const ENRICH_DATAVIEW_ITEMS = "dataView/ENRICH_DATAVIEW_ITEMS"
export const SET_CONTROL_VIEW_ID = "dataView/SET_CONTROL_VIEW_ID"
export const SET_CHECK_SEARCH_STRING = "dataView/SET_CHECK_SEARCH_STRING"
export const SET_CHECK_FILTERS = "dataView/SET_CHECK_FILTERS"
export const SET_LAST_ADDED_TO_CART = "dataView/SET_LAST_ADDED_TO_CART"
export const DELETE_ITEM = "dataView/DELETE_ITEM"
export const EXCEL_QUANTITY_CHANGE = "dataView/EXCEL_QUANTITY_CHANGE"
export const SET_SELECTED_ITEMS_LIST = "dataView/SET_SELECTED_ITEMS_LIST"

const mockSavedFilters = [
  {
    id: "USF-0000aaaa",
    favorite: true,
    name: "My precious",
    data: {
      "FLT-SMaaaa00": ["2"]
    }
  },
  {
    id: "USF-0000aaab",
    favorite: false,
    name: "My filter",
    data: {
      "FLT-SMaaaa00": ["2"],
      "FLT-RGaaaa00": [0, 530]
    }
  }
]

const initialState = {
  viewId: "",
  items: [],
  selectedItems: [],
  searchString: "",
  columns: null,
  sort: [],
  moreOpenRowId: "",
  // allFilters: [],
  selectedFilters: {
    // 'FLT-sort00p1' : ["ranking|true"],
    // "FLT-sort0prc" : ["both"],
    // "FLT-PCATEG00" : ["#1", "#45"],
    // "FLT-PCATEG01" : ["#1#4", "#45#46"],
  },
  lastItemHint: 0,
  controlPageViewId: "",
  savedFilters: [...mockSavedFilters || []],
  checkFilters: undefined,
  checkSearchString: ""
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, unsanitizedAction) {

  const action = sanitizeAnythingForRedux(unsanitizedAction)

  switch (action.type) {
    case RESET_DV:
      return initialState
    case CLEAR_ITEMS:
      return {
        ...state,
        items: []
      }
    case SET_COLUMS:
      return {
        ...state,
        columns: action.payload
      }
    case SET_DV_ID:
      return state.viewId === action.payload
        ? state
        : {
            ...initialState,
            viewId: action.payload
          }
    case SET_ITEMS:
      return {
        ...state,
        items: action.payload?.items,
        lastItemHint: action.payload?.lastItemHint,
        count: action.payload?.count
      }
    case ADD_PK_ITEMS_WITHOUT_DUPLICATES: {
      const oldPKs = state?.items?.reduce(
        (prev, current) => {
            if(!current?.PK) return prev
            return {
                ...prev, 
                [current.PK]: true,
            }
          }, {}
        )

      const filteredNewItems = action?.payload?.items.filter(
        newItem => {
            return !(oldPKs[newItem.PK])
        }
      )
      return {
        ...state,
        items: [...state?.items || [], ...filteredNewItems || []],
      }
    }
    case DELETE_ITEM:
      return {
        ...state,
          items: state?.items?.filter?.(
            (i) => i.id !== action.payload
          ),
          //lastItemHint: state?.lastItemHint
      }
    case EXCEL_QUANTITY_CHANGE:
      return {
        ...state,
          items: state?.items?.map?.(i =>
              i?.id === action?.payload?.id ? {...i, quantity: action?.payload?.quantity} : i
          )
      }
    case ADD_ITEMS:
      return {
        ...state,
        items: [...(state?.items ?? []), ...action.payload?.items || []],
        lastItemHint: action.payload?.lastItemHint,
        count: action.payload?.count
      }
    case ENRICH_DATAVIEW_ITEMS:
      const products = state?.items?.map?.((oldProd) =>
        oldProd?.id === action.payload?.id ? action.payload : oldProd
      )
      return {
        ...state,
        items: [...(products ?? [])]
      }
    case SET_MORE_ACTION_ROW_ID:
      return {
        ...state,
        selectedItems: [], //closes more actions modal
        moreOpenRowId:
          action.payload === state.moreOpenRowId ? "" : action.payload
      }
    case TOGGLE_SORT:
      return {
        ...state,
        sort:
          state?.sort?.[0] === action.payload?.[0] &&
          state?.sort?.[1] === action.payload?.[1]
            ? []
            : action.payload
      }
    case SET_DV_STRING:
      const searchString = action.payload
      // setQsParams({searchString})
      return {
        ...state,
        searchString
      }
    case SET_LAST_ADDED_TO_CART:
      return {
        ...state,
        lastAddedToCart: action.payload
      }
    case SET_CONTROL_VIEW_ID:
      return {
        ...state,
        controlPageViewId: action.payload
      }
    case SET_CHECK_SEARCH_STRING:
      return {
        ...state,
        checkSearchString: action.payload || ""
      }
    case SET_CHECK_FILTERS:
      // setQsParams(action.payload)

      return {
        ...state,
        checkFilters: action.payload
      }
    case TOGGLE_SELECT_ITEM:
      return {
        ...state,
        moreOpenRowId: "", //closes any open action
        selectedItems: state.selectedItems?.includes?.(action.payload)
          ? state.selectedItems?.filter?.((item) => item != action.payload)
          : [...state.selectedItems || [], action.payload || []]
      }
    case RESET_ACTIONS:
      return {
        ...state,
        moreOpenRowId: "", //closes any open action
        selectedItems: []
      }
    case TOGGLE_SELECT_ALL_ITEMS:
      return {
        ...state,
        moreOpenRowId: "", //closes any open action
        selectedItems:
          state.selectedItems?.length === state?.items?.length
            ? []
            : state?.items?.map?.((item) => item?.id ?? item?.PK)
      }
    case SET_DV_ITEMS:
      return {
        ...state,
        rows: action.payload?.rows
      }
    case ADD_DV_ITEMS:
      return {
        ...state,
        rows: [...state.rows || [], ...action.payload?.rows || []],
        lastRowHint: action.payload?.lastRowHint
      }
    case ADD_SAVED_FILTER:
      return {
        ...state,
        savedFilters: [...state.savedFilters || [], action.payload || []]
      }
    case ACTIVATE_SAVED_FILTER:
      return {
        ...state,
        selectedFilters: state.savedFilters?.find?.(
          (e) => e?.id === action.payload
        ).data
      }
    case DELETE_SAVED_FILTER:
      return {
        ...state,
        savedFilters:
          state?.savedFilters &&
          state.savedFilters?.filter?.((item) => item?.id !== action.payload)
      }
    case SET_DV_SELECTED_FILTERS:
      // let prev = state.selectedFilters
      return {
        ...state,
        selectedFilters: action.payload?.id
          ? {
              ...state.selectedFilters,
              [action.payload?.id]: action.payload?.selection
            }
          : {}
      }
    case SET_DV_MULTIPLE_SELECTED_FILTERS:
      // payload = {
      //   filterId : filterSelection,
      //   filterId : filterSelection,
      // }
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          ...(action.payload ?? {})
        }
      }
    case "TEMP_CLEAR_SHOP_FILTERS":
      // let prev = state.selectedFilters
      return {
        ...state,
        selectedFilters: {
          "FLT-sort00p1": ["ranking|true"]
        }
      }
    case SET_VOLUME_DISCOUNT_QUNTITIES:
      // let prev = state.selectedFilters
      return {
        ...state,
        items: state?.items?.map?.((e) =>
          e?.id === action.payload?.productId
            ? { ...e, volumeDiscounts: action.payload?.data }
            : e
        )
      }
    case SET_SELECTED_ITEMS_LIST:
      return {
        ...state,
        selectedFilters: {
          ...state?.selectedFilters,
          ...action?.payload
        }
      }
    default:
      return state
  }
}

export const setColumns = (data) => {
  return {
    type: SET_COLUMS,
    payload: data
  }
}
export const setDataViewId = (id) => {
  return {
    type: SET_DV_ID,
    payload: id
  }
}
export const setDataItems = (items, lastItemHint, noUnpack) => {
  // debugger
  return {
    type: SET_ITEMS,
    payload: {
      items: noUnpack ? items : items?.items,
      lastItemHint
    }
  }
}
export const setImportedDataFromExcel = (data) => {
  // console.log("DATA IN STATE ", data)
  return({
    // type: SET_TEMP_PRODUCT_IMPORT_DETAILS,

    type: ADD_ITEMS,
    payload: {items: [data]}
  })
}
export const setExcelQuantity = (id, quantity) => {
    return({
      type: EXCEL_QUANTITY_CHANGE,
      payload: {id, quantity}
    })
}
export const addDataItems = (items, lastItemHint) => {
  return {
    type: ADD_ITEMS,
    payload: {
      items,
      lastItemHint
    }
  }
}
export const clearDataViewItems = () => {
  return {
    type: CLEAR_ITEMS
  }
}
export const deleteProductFromExcel = (data) => ({
  type: DELETE_ITEM,
  payload: data
})
export const toggleSelectedItem = (id) => {
  return {
    type: TOGGLE_SELECT_ITEM,
    payload: id
  }
}

export const toggleSort = (payload) => {
  return {
    type: TOGGLE_SORT,
    payload
  }
}

export const setMoreOpenRowId = (id) => {
  return {
    type: SET_MORE_ACTION_ROW_ID,
    payload: id
  }
}
export const toggleSelectAllItems = () => {
  return {
    type: TOGGLE_SELECT_ALL_ITEMS
  }
}

export const deleteSavedFilter = (id) => {
  return {
    type: DELETE_SAVED_FILTER,
    payload: id
  }
}

export const activateSavedFilter = (id) => {
  return {
    type: ACTIVATE_SAVED_FILTER,
    payload: id
  }
}

export const resetDV = () => {
  return {
    type: RESET_DV
  }
}

export const setDVsearchString = (string) => {
  return {
    payload: string,
    type: SET_DV_STRING
  }
}

export const setControlDVString = (string) => {
  return {
    payload: string,
    type: SET_CONTROL_VIEW_ID
  }
}

export const setDVitems = (items) => {
  return {
    payload: { items },
    type: SET_DV_ITEMS
  }
}

export const setCheckSearchString = (string) => {
  return {
    payload: string,
    type: SET_CHECK_SEARCH_STRING
  }
}

export const resetCheckSearchString = () => {
  return {
    payload: "",
    type: SET_CHECK_SEARCH_STRING
  }
}

export const setCheckFilters = (filters) => {
  return {
    payload: filters,
    type: SET_CHECK_FILTERS
  }
}
export const addDVitems = (items) => {
  return {
    payload: { items },
    type: ADD_DV_ITEMS
  }
}
export const setSelectedItemList = (data) => {
  return {
    payload: data,
    type: SET_SELECTED_ITEMS_LIST
  }
}
export const setFilterSelection = (id, selection) =>
  reDispatch((dispatch) => {
    // if (id==="FLT-tipClient") dispatch(setClientTypeGlobal(selection?.[0]))
    return {
      payload: { id, selection },
      type: SET_DV_SELECTED_FILTERS
    }
  })
export const setMultipleFilterSelection = (data) => ({
  payload: data,
  type: SET_DV_MULTIPLE_SELECTED_FILTERS
})

export const setLastAddedToCart = (id) => {
  return {
    type: SET_LAST_ADDED_TO_CART,
    payload: id
  }
}

// FLT-tipClient
