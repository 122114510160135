import SvgIcon from "components/SvgIcon/SvgIcon"
import moment from "moment"
import { useEffect, useRef, useState } from "react"
import { getMonthDays } from "views/dashboard/logic"
import { date } from "yup/lib/locale"
import "./DatePicker.scss"
import useShowModalOutsideClick from "utils/useShowModalOutsideClick"
import { useController } from "react-hook-form"

// const month = {
//   // overdueTotal,
//   // toPayTotal,
//   // overdueDays,
//   // toPayDays: []
//   monthTitle: "Noiembrie 2020",
//   year: 2020,
//   month: 11
// }

const fixedOptionsOne = [
  { label: "ieri", diff: [1] },
  { label: "acum 7 zile", diff: [7] },
  { label: "acum o luna", diff: [1], type: "months" }
]
const fixedOptionsInterval = [
  { label: "saptamana trecuta", diff: [2, 1], type: "week" },
  { label: "luna trecuta", diff: [2, 1], type: "months" }
]
const dateFormat = "DD/MM/YYYY"

export const DatePicker = (props) => {
  const { control } = props
  if (control) return <DatePickerIntegrated {...props} />

  return <DatePickerInput {...props} />
}

export const DatePickerIntegrated = (props) => {
  const { control, name, double } = props
  const {
    field: { value, onChange },
    fieldState, //{ invalid, isTouched, isDirty },
    formState // { touchedFields, dirtyFields }
  } = useController({
    name,
    control
    // defaultValue: [],
  })

  return <DatePickerInput {...{ ...props, value, onChange }} />
}

export const DatePickerInput = (props) => {
  const {
    value: inputValue,
    onChange: inputOnChange,
    iconName,
    icon,
    label,
    error,
    placeholder,
    className,
    double,
    disabled
  } = props
  const { ref, showModal, setShowModal } = useShowModalOutsideClick()
  let value, onChange
  if (typeof inputValue !== "Array") {
    value = [inputValue]
    onChange = (e) => inputOnChange(e?.[0])
  } else {
    value = inputValue
    onChange = inputOnChange
  }
  let buttonClassName = "cdt-button "
  if (showModal) buttonClassName += "open "
  if (!showModal) buttonClassName += "close "

  const title = error ? (
    <div data-test-id='LVun' className="mb2 ptype-3 color-alert-red">{error}</div>
  ) : (
    <div data-test-id='6plP' className="mb2 ptype-3">{label}</div>
  )

  const setValue = (value) => {
    onChange(value?.map?.((date) => moment(date).toISOString()))
  }
  const parsedValue = value?.map?.((date) => moment(date).toDate()) ?? [
    moment(date).toDate()
  ]
  return (
    <div 
      data-test-id='FAiw'
      ref={ref}
      className={`date-input ${disabled ? "disabled" : ""} ${className ?? ""}`}
    >
      {title}
      <button 
        data-test-id='cuO5'
        {...{ className: buttonClassName }}
        onClick={() => setShowModal((p) => !p)}
      >
        <div data-test-id='FwBr'>
          {(iconName || icon) && <SvgIcon name={icon ?? iconName} />}
          {value?.[0] ? moment(value?.[0])?.format("DD MMM YYYY") : placeholder}
          {/* {value?.[0] ? moment(value?.[0])?.format("DD MMM YYYY h:mm:ss a ") : placeholder} */}
        </div>
        {!disabled && <SvgIcon name={"selectdown"} />}
      </button>
      {showModal && (
        <DatePickerModal
          {...{ ...props, value: parsedValue, setValue, setShowModal }}
          simpleInput
        />
      )}
    </div>
  )
}

export const DatePickerFilter = (props) => {
  // const {value, onChange} = props

  const [value, setValue] = useState([])
  const { ref, showModal, setShowModal } = useShowModalOutsideClick()

  let className = "cdt-button "
  if (showModal) className += "open "
  if (!showModal) className += "close "
  if (value?.[0]) className += "selected"

  // let label = value?.[0] ? "selectata" : "Select Date"
  return (
    <div data-test-id='iFuf' ref={ref}>
      <button data-test-id='T1yH' {...{ className }} onClick={() => setShowModal((p) => !p)}>
        Date
        {props.iconName && <SvgIcon name={props.iconName} />}
      </button>
      {showModal && (
        <DatePickerModal {...{ value, setValue: setValue, setShowModal }} />
      )}
    </div>
  )
}

const DatePickerModal = (props) => {
  const {
    simpleInput,
    double,
    time,
    interval,
    value: extValue,
    setValue: extSetValue,
    setShowModal
  } = props
  const today = new Date()
  today.setMonth(today.getMonth() + 1)
  const [value, setValue] = useState([])
  const [leftMonth, setLeftMonth] = useState(new Date())
  const [rightMonth, setRightMonth] = useState(new Date(today))
  const [forceRefresh, setForceRefresh] = useState(false)
  const [moreHours, setMoreHours] = useState(false)
  const [fromHour, setFromHour] = useState("")
  const [toHour, setToHour] = useState("")
  const [toHourClicked, setToHourClicked] = useState(false)
  const fixedOptions = interval ? fixedOptionsInterval : fixedOptionsOne

  const setFixedOption = (item) => {
    // let today = new Date()
    const setOneDate = (index) => {
      let newDate = moment()
        .subtract(item?.diff?.[index], item?.type ?? "day")
        .toDate()

      return newDate
    }
    let newValue = [setOneDate(0)]
    if (item?.diff?.[1]) {
      // today = new Date()
      // today.setDate(new Date(today.getDate() - diff?.[1]))
      newValue?.push?.(setOneDate(1))
    }
    setValue(newValue)
    if (double) {
      setLeftMonth(newValue?.[0])
      setRightMonth(newValue?.[1])
    } else setLeftMonth(newValue?.[interval ? 1 : 0])
  }

  const switchMonth = (month, right) => {
    !right &&
      setLeftMonth((p) => {
        let newDate = new Date(p)
        newDate.setMonth(newDate.getMonth() + month)
        return newDate
      })

    right &&
      setRightMonth((p) => {
        let newDate = new Date(p)
        newDate.setMonth(newDate.getMonth() + month)
        return newDate
      })
  }

  const setTextDate = (value, field) => {
    // moment(value?.[0]).format(dateFormat)
    const df = moment(value).format(dateFormat)
    if (df !== "Invalid date") {
      setValue((p) => {
        p[field] = moment(value).toDate()
        return p
      })
      setForceRefresh((p) => !p)
    }
  }
  const moreHourClick = (hour, right) => {
    setMoreHours(false)
    if (!right) setFromHour(hour)
    if (right) setToHour(hour)
  }
  const monthLine = (right) => (
    <div data-test-id='i188' className="month-line">
      <div data-test-id='y7k9' onClick={() => switchMonth(-1, right)}>
        <SvgIcon name="back" />
      </div>
      <div data-test-id='eleo'>{moment(!right ? leftMonth : rightMonth).format("MMM YYYY")}</div>
      <div data-test-id='DdgH' onClick={() => switchMonth(+1, right)}>
        <SvgIcon name="right" />
      </div>
    </div>
  )

  const onCancel = () => {
    setValue([])
    if (setShowModal) setShowModal(false)
  }

  const onSave = () => {
    extSetValue ? extSetValue(value) : console.log(value)
    if (setShowModal) setShowModal(false)
  }

  const onChange = (value) => {
    const megaCheck = simpleInput && (!double || (value?.[0] && value?.[1]))
    if (megaCheck) {
      extSetValue ? extSetValue(value) : console.log(value)
      if (setShowModal) setShowModal(false)
    } else setValue(value)
  }
  return (
    <div data-test-id='WNWp' className={extSetValue ? "cdt-main" : "cdt-main standalone"}>
      <div data-test-id='lmg9'>
        {!simpleInput && (
          <div data-test-id='77SC' className="top-buttons">
            {fixedOptions?.map?.((item, index) => (
              <div data-test-id='bZmF' key={index} onClick={() => setFixedOption(item)}>
                {item?.label}
              </div>
            ))}
          </div>
        )}
        <div data-test-id='QzCI' className="month-line-top">
          {monthLine()}
          {double && monthLine(true)}
        </div>
        <div data-test-id='SkeW' className="line" />
        <div data-test-id='YUS6' className="planningCalendar-top">
          <CalendarMonth
            forceRefresh
            value={value}
            crtMonth={leftMonth}
            startSunday
            onChange={onChange}
          />
          {double && (
            <CalendarMonth
              forceRefresh
              value={value}
              crtMonth={rightMonth}
              startSunday
              onChange={onChange}
            />
          )}
        </div>
        {!simpleInput && (
          <>
            <div data-test-id='f0Pv' className="type-in">
              <input 
                data-test-id='2e5N'
                placeholder="De la"
                onBlur={(e) => setTextDate(e?.target?.value, 0)}
              />
              {interval && (
                <input 
                  data-test-id='fmxw'
                  placeholder="Pana la"
                  onBlur={(e) => setTextDate(e?.target?.value, 1)}
                />
              )}
            </div>
            <div data-test-id='fvPl' className="line" />
            {time && (
              <>
                <div data-test-id='t3uf' className="hour-section">
                  <div 
                    data-test-id='Elwv'
                    onClick={() => {
                      setToHourClicked(false)
                      setMoreHours(true)
                    }}
                  >
                    <label data-test-id='C0OS' htmlFor="fromHour">De la ora</label>
                    <input 
                      data-test-id='gOqs'
                      id="fromHour"
                      placeholder="__:__"
                      value={fromHour}
                      onBlur={(e) => console.info(e?.target?.value, 0)}
                    />
                    <SvgIcon name="close" />
                  </div>
                  {interval && (
                    <div 
                      data-test-id='rIbR'
                      onClick={() => {
                        setToHourClicked(true)
                        setMoreHours(true)
                      }}
                    >
                      <label data-test-id='GVTD' htmlFor="toHour">De la ora</label>
                      <input 
                        data-test-id='mttJ'
                        id="toHour"
                        placeholder="__:__"
                        value={toHour}
                        onBlur={(e) => console.info(e?.target?.value, 1)}
                      />
                      <SvgIcon name="close" />
                    </div>
                  )}
                </div>
                <div data-test-id='B9TA' className="line" />
              </>
            )}
            <div data-test-id='IL9b' className="filter-buttons">
              <button data-test-id='SHCx' onClick={() => onCancel()}>Anuleaza</button>
              <button data-test-id='PoTk' onClick={() => onSave()}>Salveaza</button>
            </div>
          </>
        )}
      </div>
      {moreHours && (
        <>
          <div data-test-id='JHNG' className="vertical-line"></div>
          <div data-test-id='O5T3' className="more-hours">
            <div data-test-id='z3Lv'>
              {generateMoreTime()?.map?.((section) => (
                <div data-test-id='XGCa' className="section">
                  <div data-test-id='Cxwz' className="section--label">{section?.label}</div>
                  <div data-test-id='DqMm' className="section--hours">
                    {section.hours?.map?.((item) => (
                      <button 
                        data-test-id='9NbK'
                        onClick={() => moreHourClick(item, toHourClicked)}
                      >
                        {item}
                      </button>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <div data-test-id='SJLa' onClick={() => setMoreHours(false)}>
              <SvgIcon name="closeround" />
            </div>
          </div>
        </>
      )}
    </div>
  )
}
export default DatePickerModal

function CalendarMonth(props) {
  const {
    startSunday,
    value,
    crtMonth,
    start,
    end,
    green = [],
    red = [11, 20],
    active = [],
    onChange
  } = props

  let startDate = value?.[0]
  let endDate = value?.[1]
  let now = new Date()

  const today = now.getDate()
  const year = now.getFullYear()
  const month = now.getMonth()

  // const days = [28, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 1, 2, 3, 4]
  const days = getMonthDays(year, month, startSunday)
  const cellStyle = {
    // lineHeight: '40px',
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between"
  }
  const topDays = startSunday
    ? ["D", "L", "M", "M", "J", "V", "S"]
    : ["L", "M", "M", "J", "V", "S", "D"]

  const dateClicked = (newDate) => {
    // const newDate = new Date(`${year}-${month}-${day}`)
    if (value?.[0] && !value?.[1])
      if (value?.[0] < newDate) onChange([value?.[0], newDate])
      else onChange([newDate, value?.[0]])
    else onChange([newDate, null])
  }

  const getDateClass = (day, mounth, year) => {
    if (!value?.[1]) {
      if (sameDay(value?.[0], day)) return " selected"
    } else {
      if (sameDay(value?.[0], day)) return " selected--start"
      if (sameDay(value?.[1], day)) return " selected--end"
      if (day >= value?.[0] && day <= value?.[1]) return " selected--mid"
    }
    return ""
  }

  const monthDays = generateDays({ crtMonth, startSunday })
  const monthNo = Math.ceil(monthDays?.length / 7)
  return (
    <div data-test-id='kZmD' className="planningCalendar" style={{ "--monthNo": monthNo + 1 }}>
      {topDays?.map?.((topDay, i) => (
        <div data-test-id='oIpk' key={`${topDay}-${i}`} className="topDay">
          {topDay}
        </div>
      ))}
      {monthDays?.map?.((day, index) => {
        let className = "calendarDay"
        let activeClass = ""
        let topClass = ""
        if (sameDay(day, now)) className += " today"
        if ([0, 6]?.includes?.(day.getDay())) className += " weekend"
        if (day.getMonth() !== crtMonth.getMonth()) className += " disabled"
        className += getDateClass(day)

        if (red?.includes?.(day)) className += " red"
        if (green?.includes?.(day)) className += " green"

        return (
          <div 
            data-test-id='IX5l'
            key={index}
            className={className}
            style={cellStyle}
            onClick={() => dateClicked(day)}
          >
            <div data-test-id='E1s9' className={topClass} />
            <div data-test-id='2GrS'>{day.getDate()}</div>
            <div data-test-id='rmxJ' className={activeClass} />
          </div>
        )
      })}
    </div>
  )
}

const generateDays = ({
  crtMonth,
  month = new Date().getMonth(),
  year = new Date().getFullYear(),
  startSunday
}) => {
  let crtDay = new Date(crtMonth)
  crtDay.setDate(1)
  let dates = []
  const fisrtDay = startSunday ? 0 : 1
  while (crtDay.getMonth() === crtMonth.getMonth()) {
    dates?.push?.(new Date(crtDay))
    crtDay.setDate(crtDay.getDate() + 1)
  }
  while (crtDay.getDay() !== fisrtDay) {
    dates?.push?.(new Date(crtDay))
    crtDay.setDate(crtDay.getDate() + 1)
  }
  crtDay = new Date(dates?.[0])
  while (crtDay.getDay() !== fisrtDay) {
    crtDay.setDate(crtDay.getDate() - 1)
    dates?.unshift?.(new Date(crtDay))
  }
  return dates
}

function sameDay(d1, d2) {
  try {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    )
  } catch {
    return false
  }
}

const generateMoreTime = () => {
  let sections = [
    { label: "Dimineata", range: [6, 7, 8, 9, 10, 11] },
    { label: "Pe zi", range: [12, 13, 14, 15, 16, 17] },
    { label: "Seara", range: [18, 19, 20, 21, 22, 23] },
    { label: "Noaptea", range: [0, 1, 2, 3, 4, 5] }
  ]
  return sections?.map?.((section) => {
    let items = []
    section.range?.forEach?.((item) => {
      items?.push?.(moment({ hour: item }).format("HH:mm"))
      items?.push?.(moment({ hour: item, minute: 30 }).format("HH:mm"))
    })
    return {
      ...section,
      hours: items
    }
  })
}
