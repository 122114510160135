
const attachSizeQueryParams = ({ urlString, width = 514, height = 293}) => {

  if(!urlString) return urlString;

  try {
    const url = new URL(urlString);
    
    if(url?.hostname !== 'uniprest.compano.com') return urlString;
    
    if(url?.searchParams?.get('W')) return urlString;
    if(url?.searchParams?.get('H')) return urlString;

    url?.searchParams?.append('W', width);
    url?.searchParams?.append('H', height);

    return url?.href

  } catch (error) {
    return urlString;
  }

}


/**
 * Why these numbers? Because we aimed for the product-detail page
 * where the images are typically rendered no larger than 541x304, but that was too 
 * blurry. For extra sharpness we added an additional 20% to the requested resolution.
 */
export const attachSizeQueryParamsSmall = (urlString) => 
  attachSizeQueryParams({ urlString, width: 649, height: 365})


/**
 * Why these numbers? Because we aimed for the shop (product-list) page in list view
 * where the images are typically rendered no larger than 96x96, but that was too 
 * blurry. For extra sharpness we added an additional 25% to the requested resolution.
 */
export const attachSizeQueryParamsXS = (urlString) => 
  attachSizeQueryParams({ urlString, width: 120, height: 120})


/**
 * Why these numbers? Because we aimed for the shop product-detail page "Produse asociate"
 * where the images are typically rendered no larger than 32x32, but that was too 
 * blurry. For extra sharpness we added an additional 25% to the requested resolution.
 */
export const attachSizeQueryParamsXXS = (urlString) => 
  attachSizeQueryParams({ urlString, width: 40, height: 40})

