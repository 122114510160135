import PropTypes from "prop-types"
import SvgIcon from "components/SvgIcon/SvgIcon"

export default function Pill(props) {
  const { type, label, bold, color, fontColor, iconColor, iconName, value } =
    props

  const classColor = fontColor
    ? `color-${fontColor}`
    : type === "outlined" && color
    ? `color-${color}`
    : "color-light-purewhite"
  const classColorBg =
    type === "filled" && color ? `bg-${color}` : "bg-light-purewhite"
  const classBorder = type === "outlined" && color ? `border-${color}` : ""
  const classSize = iconName ? "large" : bold ? "medium" : "small"
  const classStroke = iconColor
    ? `stroke-${iconColor}`
    : type === "outlined" && color
    ? `stroke-${color}`
    : "stroke-light-purewhite"

  const computedLabel = value ?? label
  return (
    <div data-test-id='2Ogp' className="pill">
      <div 
        data-test-id='rsOZ'
        className={`pill-content ${classBorder} ${classSize} ${classColorBg}`}
      >
        {iconName && (
          <SvgIcon
            w={8}
            h={8}
            name={iconName}
            className={`${classStroke} pill-icon`}
          />
        )}
        {computedLabel && (
          <div 
            data-test-id='FK2v'
            className={`${classColor} ${
              bold && !iconName ? "ptype-3-strong" : "ptype-3"
            }`}
          >
            {computedLabel}
          </div>
        )}
      </div>
    </div>
  )
}

Pill.propTypes = {
  type: PropTypes.oneOf(["outlined", "filled"]),
  label: PropTypes.string,
  value: PropTypes.string,
  bold: PropTypes.bool,
  color: PropTypes.string,
  fontColor: PropTypes.string,
  iconName: PropTypes.string
}
