import { useState } from "react"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import Handler from "views/dev/select/ReorderColumnsDragDrop/handler.svg"
import MutaBG from "views/dev/select/ReorderColumnsDragDrop/MutaAici.svg"
import { SmallSwitch } from "componentsK3"
import { useDispatch } from "store"
import { setColumns } from "store/modules/dataView"
import { useSelector } from "react-redux"

const ColumnsReorderDragAndDrop = (props) => {
  const { data, selection, datas, value, setValue } = props
  const stateDataList =
    useSelector((state) => state?.dataView?.columns) ??
    data?.map?.((item, i) => ({ id: i, visible: true }))
  const dataList = value ?? stateDataList
  const displayData = dataList?.map?.((item) => data?.[item?.id])
  const dispatch = useDispatch()
  const onDragEnd = (result) => {
    if (!result.destination) return
    const items = Array.from(dataList)
    const [reorderedItem] = items?.splice?.(result.source.index, 1)
    items?.splice?.(result.destination.index, 0, reorderedItem)
    setValue(items)
    // dispatch(setColumns(items))
  }

  const flipColumnVisibility = (id) => {
    const newDataList = dataList?.map?.((item) =>
      item?.id === id ? { ...item, visible: !item?.visible } : item
    )
    setValue(newDataList)

    // dispatch(setColumns(newDataList))
  }

  const dragItem = ({ list, snapshot, index }) => {
    return (
      <div 
        data-test-id='TKL2'
        className={`drag-item  ${snapshot.isDragging ? "dragging" : ""} ${
          list.disabled ? "disabled" : ""
        }`}
        key={`item-${list.id}`}
      >
        <div data-test-id='OMLt' className={"left-items"}>
          <div 
            data-test-id='1kR2'
            className={"drag-handler-premium"}
            style={{ backgroundImage: `url('${Handler}')` }}
          ></div>
          <div data-test-id='nk8G' className={"title"}>{list.label}</div>
        </div>
        <SmallSwitch
          selected={dataList?.find?.((i) => i.id === list?.id)?.visible}
          disabled={list?.disabled}
          onClick={() => flipColumnVisibility(list?.id)}
        />
      </div>
    )
  }
  // let actualIndex = 0

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div 
        data-test-id='cIiw'
        className="dvcs-draggable-column"
        style={{ backgroundImage: `url('${MutaBG}')` }}
      >
        <Droppable droppableId="droppable">
          {(provided) => (
            <div data-test-id='BL0x' ref={provided.innerRef} {...provided.droppableProps}>
              {displayData?.map?.((list, index) => {
                if (list?.label) {
                  return (
                    <Draggable
                      key={list?.id}
                      draggableId={`list-${list?.id}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div 
                          data-test-id='5GSy'
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {dragItem({ list, snapshot, index })}
                        </div>
                      )}
                    </Draggable>
                  )
                }
              })}

              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  )
}
export default ColumnsReorderDragAndDrop
