// import orderTableData from "mockData/orderTableData";
import { apiAction, reDispatch } from "store/middleware/api"
import { printError } from "utils/printError"
import store from "store/index"
import { setApiResponse } from "store/modules/auth"
import { loadDynamoItemsBySK, searchStuff } from "store/modules/dataView.search"
import { sanitizeAnythingForRedux } from "utils/preventReactInRedux"

export const SET_STATISTICS = "dataView/SET_STATISTICS"
export const CLEAR_STATISTICS = "dataView/CLEAR_STATISTICS"
export const SET_AUXILIARY_INVOICE = "dataView/SET_AUXILIARY_INVOICE"

const initialState = {
  statistics: {},
  auxiliaryInvoiceData: []
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, unsanitizedAction) {

  const action = sanitizeAnythingForRedux(unsanitizedAction)

  switch (action.type) {
    case SET_STATISTICS:
      return {
        ...state,
        statistics: { ...state.statistics, ...action.payload }
      }
    case SET_AUXILIARY_INVOICE:
      return { ...state, auxiliaryInvoiceData: [...action.payload] }
    case CLEAR_STATISTICS:
      return { ...initialState }
    default:
      return state
  }
}

export const clearStatistics = () => {
  return { type: CLEAR_STATISTICS }
}

export const loadStatistics = (type) =>
  apiAction({
    endPoint: `/uniprest/${type}/statistics`,
    onSuccess: (data, dispatch) => {
      let payload = {}
      payload[type] = data

      dispatch(setApiResponse(data))
      return {
        type: SET_STATISTICS,
        payload
      }
    },
    onFailure: (error) => {
      printError("loadStatistics()", error)
      return {
        type: "a"
      }
    }
  })

export const loadCompanyStatistics = () =>
  apiAction({
    endPoint: `/organization/webshop-client/statistics`,
    onSuccess: (data, dispatch) => {
      dispatch(setApiResponse(data))
      let payload = {}
      payload["companies"] = data
      return {
        type: SET_STATISTICS,
        payload
      }
    },
    onFailure: (error) => {
      printError("loadCompanyStatistics()", error)
      return {
        type: "a"
      }
    }
  })

export const loadProductStatistics = () =>
  apiAction({
    endPoint: `/product/goods/statistics`,
    onSuccess: (data, dispatch) => {
      dispatch(setApiResponse(data))
      let payload = {}
      payload["products"] = data
      return {
        type: SET_STATISTICS,
        payload
      }
    },
    onFailure: (error) => {
      printError("loadProductStatistics()", error)
      return {
        type: "a"
      }
    }
  })

export const loadOrderStatistics = () =>
  apiAction({
    endPoint: `/basket/statistics`,
    onSuccess: (data, dispatch) => {
      dispatch(setApiResponse(data))
      let payload = {}
      payload["orders"] = data
      return {
        type: SET_STATISTICS,
        payload
      }
    },
    onFailure: (error) => {
      printError("loadOrderStatistics()", error)
      return {
        type: "a"
      }
    }
  })

export const loadInvoiceStatistics = () =>
  apiAction({
    endPoint: `/client-invoice/statistics`, //not available yet, or I'm missing something
    onSuccess: (data, dispatch) => {
      dispatch(setApiResponse(data))
      return {
        type: SET_AUXILIARY_INVOICE,
        payload: data
      }
    },
    onFailure: (error) => {
      printError("loadInvoiceStatistics()", error)
      return {
        type: "a"
      }
    }
  })

//testing if switchcase adds correctly data in state
// export const loadInvoiceStatistics = () => {
//   return {
//     type: COMBINE_STATISTICS,
//     payload: [{1: "test1"}, {2: "test2"}],
//   }
// }
export const loadGuestStatistics = () =>
  reDispatch((dispatch) => {
    dispatch(
      loadDynamoItemsBySK({
        SK: "APLICANT_RESPONSE",
        dictionary: "APLICANT_STATS"
      })
    )
    dispatch(
      loadDynamoItemsBySK({ SK: "JOB_RESPONSE", dictionary: "JOBS_STATS" })
    )
    dispatch(
      loadDynamoItemsBySK({ SK: "GUEST_ARTICLE_V2", dictionary: "NEWS_STATS" })
    )
    dispatch(
      loadDynamoItemsBySK({
        SK: "CONTACT_RESPONSE",
        dictionary: "CONTACT_STATS"
      })
    )
    dispatch(
      searchStuff({
        type: "public/organization/workplace",
        sortField: null,
        dictionary: "LOCATION_RESPONSE",
        reload: true
      })
    )
    return { type: "a" }
  })
