const getErrorMessage = (error) => {
  const errorStringPart1 = `${error?.response?.status || ""} ${
    error?.response?.statusText || ""
  }`

  const errorStringPart2 = error?.response?.data
    ? JSON.stringify(error?.response?.data)
    : error?.message

  return `${errorStringPart1} ${errorStringPart2}`?.trim?.()
}

export const printError = (location, error) => {
  const errorString = getErrorMessage(error)
  console.log(`Error at ${location}: `, errorString)
}
