const download = {
  technice: {
    title: "Documente Tehnice",
    search: true,
    searchPlaceholder: "Cauta aici",
    items: [
      {
        id: 1,
        url: "#",
        title: "Carte Tehnica a centralei (.pdf)",
        udated: "2021-03-19T13:01:06.835Z",
        expire: "2021-03-19T13:01:06.835Z",
        expired: true
      },
      {
        id: 2,
        url: "#",
        title: "Carte Tehnica a centralei",
        udated: "2021-03-19T13:01:06.835Z",
        expire: "2021-03-19T13:01:06.835Z",
        expired: false
      }
    ]
  },
  garantie: {
    title: "Certificat de garantie",
    search: false,
    items: [
      {
        id: 1,
        url: "#",
        title: "Certificat de garantie (.pdf)",
        text: 'Conorm facturii <a data-test-id="sZzb" href="#">FF 2135532</a>'
      }
    ]
  }
}
export default download
