import { badgeColors } from "staticData/admin/products"
import { apiAction } from "store/middleware/api"
import { SET_API_RESPONSE } from "store/modules/auth"
import { setCrtFormItem } from "store/modules/page"
import store from "store/index"
import { loadItem } from "store/justActions/loadApiIems"
import { DATE_FORMAT_DAY_ONLY, formatISOMoment } from "utils/dateUtils"
import { toastr } from "react-redux-toastr"
import { getCart } from "store/modules/shop"
import { printError } from "utils/printError"

export const saveApiItem = ({ type, data, onSuccess, onFailure }) => {
  let apiData = data
  let endPoint
  if (type === "client-user-invite") {
    apiData = parseForApiClientUserData(data, "invite")
    endPoint = `/uniprest/user`
  }
  if (type === "client-user-edit") {
    apiData = parseForApiClientUserData(data, "edit")
    endPoint = `/uniprest/user`
  }
  if (type === "user") {
    //this might change, that's why the different func for admin parse
    apiData = parseForApiAdminUserData(data)
    endPoint = "/uniprest/user" //${type}`
  }
  if (type === "group") {
    apiData = parseForApiGroupData(data)
    endPoint = `/group`
  }
  if (type === "product-answer") {
    apiData = parseForApiProductAnswer(data)
    endPoint = `/product-answer`
  }
  if (type === "product-comment") {
    apiData = parseForApiProductComment(data)
    endPoint = `/product-answer`
  }
  if (type === "product-question") {
    apiData = parseForApiProductQuestion(data)
    endPoint = `/${type}`
  }
  if (type === "grilaDiscountForm") {
    apiData = parseForApiDiscountGrid(data)
    endPoint = `/product/goods/discount/grid`
  }
  if (type === "product/goods/discount/package") {
    apiData = parseForApiDiscountPackage(data)
  }
  if (type === "product/goods/discount/fix") {
    apiData = parseForApiDiscountFix(data)
  }
  if (type === "discountVolumeDetails") {
    apiData = parseForApiDiscountVolume(data)
    endPoint = "/product/goods/discount/volume"
  }
  if (type === "badgeDetails") {
    apiData = parseForApiProductGoodsBadge(data)
    endPoint = "/goods-badge"
  }
  if (type === "product-rating") {
    apiData = parseForApiProductRating(data)
  }
  if (type === "basket") {
    apiData = parseForApiBasket(data)
  }
  if (type === "organization/warehouse") {
    apiData = parseForApiWearhouse(data)
  }
  if (type === "productDetails") {
    apiData = parseForApiProductGoods(data)
    endPoint = "/product/goods"
  }
  if (type === "product-document") {
    apiData = parseForApiDocument(data)
  }
  if (type === "/organization/workplace") {
    apiData = parseForApiOrganizationWorkplace(data)
    endPoint = "/organization/workplace/"
  }
  if (type === "uniprest/user/my-user") {
    apiData = data
  }
  if (type === "location-delivery") {
    apiData = parseLocationDelivery(data)
  }
  if (type === "companyForm") {
    apiData = parseCompanyDataForApi(data)
    endPoint = "/organization/webshop-client"
  }
  if (type === "companyLocation") {
    apiData = parseAddressDataForApi(data)
    endPoint = "/organization/webshop-client/my-address" //address
  }
  if (type === "address") {
    apiData = parseAddressData(data)
  }
  if (type === "customer-offer") {
    apiData = parseCustomerOfferData(data)
  }
  if (type === "customer-offer-view") {
    apiData = parseCustomerOfferViewData(data)

    endPoint = "/customer-offer"
  }
  if (type === "offerDetailCompanies") {
    apiData = parseOfferDetailCompanies(data)
    endPoint = "/customer-offer/client-save/"
  }

  // return { type: 'dev' };
  //return {type: "a"};
  return apiAction({
    endPoint: endPoint ?? `/${type}`,
    method: "POST",
    data: apiData,
    onSuccess: (data, dispatch) => {
      dispatch({ type: SET_API_RESPONSE, payload: data }) // dev mode: used to display data on apicalls page
      if (type === "user")
        toastr.success(
          "Emailul de validare a fost trimis cu succes la adresa specificată."
        )

      if (type === "customer-offer") dispatch(getCart())

      if (!onSuccess) {
        //if API success also reset the form to crt values. Maybe not needded...
        //- we need this, but the loadApiParser functions are skipped with this
        //TODO:SE: @Sorin review
        // dispatch(setCrtFormItem(data))
        dispatch(loadItem(type, data?.id))
      } else onSuccess(data, dispatch)

      return { type: "a" }
    },
    onFailure: (err) => {
      printError("saveApiItem()", err)
      if (onFailure) onFailure(err)
      else {
        if (type === "user")
          toastr.error(
            "Eroare: Această adresă de email este deja folosită la un utilizator."
          )
      }
      return { type: "a" }
    }
  })
}
const parseOfferDetailCompanies = (data) => {
  const addressListCleaned = data?.addressList
  delete addressListCleaned.locationName

  const _data = {
    ...data,
    addressList: [addressListCleaned],
    attachmentList: [
      {
        id: data?.attachmentList?.id ? data?.attachmentList?.id : null,
        type: "LOGO",
        reference: data?.attachmentList?.url
      }
    ],
    contactInfo: {
      email: data?.contactInfo?.email,
      phone: data?.contactInfo?.phone,
      website: data?.contactInfo?.website
    }
  }
  return _data
}

const parseCustomerOfferViewData = (data) => {
  const parsedLineList = data?.lineList?.map?.((i) => ({
    ...i,
    basketQuantity: undefined,
    basketPrice: undefined
  }))
  const parsedExternalList = data?.externalProductList
    ?.filter?.((i) => i.type !== null)
    ?.map?.((i) => ({ ...i, unitOfMeasury: undefined }))

  const _data = {
    ...data,
    externalProductList: parsedExternalList,
    lineList: parsedLineList
  }
  delete _data?.products
  delete _data?.initialValue

  return _data
}
const parseCustomerOfferData = (data) => {
  //return {...data?.offerInitData, }
  const _data = data
  delete _data?.centralDeliveryDateOptions
  delete _data?.localDeliveryDateOptions
  delete _data?.products
  delete _data?.personalizationData
  delete _data?.relationList3

  return {
    id:
      _data?.offerInitData?.id === "" ||
      _data?.offerInitData?.id === null ||
      _data?.offerInitData?.id === undefined
        ? null
        : _data?.offerInitData?.id, //""
    code: _data?.offerInitData?.code ?? null, //""
    customerName: _data?.customerName ?? "",
    title: _data?.title ?? "",
    subtitle: _data?.subtitle ?? "",
    description: _data?.description ?? "",
    //pay attention it is lost somewhere
    status: _data?.status ?? "ACTIVE", //diff between the two CustommerOfferStatus
    globalMargin: _data?.offerInitData?.globalMargin ?? false,
    customerVatPayer: _data?.offerInitData?.customerVatPayer ?? false,
    marginPercent: _data?.offerInitData?.marginPercent
      ? Number(_data?.offerInitData?.marginPercent)
      : null,
    marginValue: _data?.offerInitData?.marginValue ?? null,

    totalValueNet: _data?.offerInitData?.totalValueNet ?? null,
    totalValueVat: _data?.offerInitData?.totalValueVat ?? null,
    totalValueGross: _data?.offerInitData?.totalValueGross ?? null,
    globalValue: _data?.offerInitData?.globalValue ?? false,
    globalValueNet: _data?.offerInitData?.globalValue
      ? _data?.offerInitData?.globalValueNet
      : _data?.offerInitData?.totalValueNet,
    globalValueVat: _data?.offerInitData?.globalValueVat ?? null,
    globalValueGross: _data?.offerInitData?.globalValueGross ?? null,
    currencyCode: _data?.offerInitData?.currencyCode ?? "RON",
    lineList: _data?.offerInitLineList ?? [],

    externalProductAndService: _data?.externalProductAndService ?? false,
    externalProductValueNet: _data?.externalProductValueNet
      ? Number(_data?.externalProductValueNet)
      : null,
    externalProductValueVat: _data?.externalProductValueVat ?? null,
    externalProductValueGross: _data?.externalProductValueGross ?? null,
    externalServiceValueNet: _data?.externalServiceValueNet
      ? Number(_data?.externalServiceValueNet)
      : null,
    externalServiceValueVat: _data?.externalServiceValueVat ?? null,
    externalServiceValueGross: _data?.externalServiceValueGross ?? null,
    externalProductList:
      _data?.externalProductList?.length === 1 &&
      _data?.externalProductList?.[0]?.type === ""
        ? []
        : _data?.externalProductList?.filter?.((i) => i?.type !== "") ?? [],
    externalProductGlobalValueNet: _data?.externalProductGlobalValueNet || null,
    externalProductGlobalValueVat: _data?.externalProductGlobalValueVat || null,
    externalProductGlobalValueGross:
      _data?.externalProductGlobalValueGross || null,
    externalServiceGlobalValueNet: _data?.externalServiceGlobalValueNet || null,
    externalServiceGlobalValueVat: _data?.externalServiceGlobalValueVat || null,
    externalServiceGlobalValueGross:
      _data?.externalServiceGlobalValueGross || null
  }
}

const parseAddressData = (data) => {
  let _data = {
    ...data,
    latitude: data?.map?.latitude,
    longitude: data?.map?.longitude
  }

  delete _data?.centralWarehouseEnabled
  delete _data?.local
  delete _data?.central
  delete _data?.map
  delete _data?.workplaceId
  delete _data?.township
  delete _data?.addressDelivery

  return _data
}

const parseAddressDataForApi = (data) => ({
  id: null,
  type: "TRANSPORT", //tempfix what to do, how to handle
  name: data?.name,
  county: data?.county,
  country: data?.country,
  street: data?.street,
  number: data?.number,
  zipCode: data?.zipCode,
  locationId: data?.locationId ?? "ZvoExl",
  displayAddress: data?.displayAddress ?? data?.name,
  contactInfo: {
    email: null,
    mobil: null,
    phone: null
  },
  info: data?.info ?? null,
  latitude: data?.map?.latitude ?? 34.34,
  longitude: data?.map?.longitude ?? 54.54,
  zoom: data?.map?.zoom ?? 3,
  status: "BaseEntityStatus.APPROVAL"
})

const parseForApiOrganizationWorkplace = (data) => {
  const addressList = [
    {
      ...data?.addressList,
      displayAddress: data?.addressShortName,
      street: data?.street,
      number: data?.number,
      name: data?.city,
      county: data?.county,
      country: data?.country,
      zipCode: data?.postalCode,
      modifyDate: formatISOMoment(new Date()),
      //"id": "2l5NmQ",
      //"type": "HEADQUARTER",
      //"status": "BaseEntityStatus.ACTIVE",
      //"name": null,
      //"modifyDate": "2021-09-27T11:12:08.789+0000",
      //"country": "RO",
      // "county": "HD",
      //"locationId": "mGx36r",
      //"street": "B-dul. 22 Decembrie",
      //"number": "Nr. 265",
      //"zipCode": null,
      //"region": null,
      //"displayAddress": "B-dul. 22 Decembrie Nr. 265",
      //"extern": false,
      longitude: data?.map?.longitude, //22.919204627265284,
      latitude: data?.map?.latitude, //45.86190319371234,
      zoom: data?.map?.zoom
      //"contactInfo": null,
      //"info": null
    }
  ]
  const contactInfo = {
    ...data?.contactInfo,
    email: data?.storeEmail
    // "email": "deva@uniprest.ro",
    //     "mobil": null,
    //     "phone": "0354-416.350",
    //     "facebook": null,
    //     "linkedIn": null,
    //     "note": null,
    //     "profileStrength": null
  }
  return {
    //...data,
    //from Postman
    id: data?.id, //*
    type: data?.type, //"WORKPLACE", //*
    name: data?.name, //*
    code: data?.code, //*
    alias: null, //*
    status: data?.status, //"BaseEntityStatus.ACTIVE", //*
    addressList: addressList,
    bankAccountList: [], //???
    contactList: [], //????
    contactInfo: contactInfo,
    description: null, //???
    presentationList: [], //???
    attachmentList: [], //???
    limitList: [], //???
    operatingHourList: [data?.schedule], //? it is gonna be an array/days?
    hasAccess: false //???
  }
}
const parseCompanyDataForApi = (data) => {
  const addressListReProcessed = data?.addressListProcessed?.map?.((i) => ({
    id: i.id ?? null,
    type: "HEADQUARTER", //"TRANSPORT",
    country: i.country || "ROU",
    county: i.county,
    locationId: i.locationId || null,
    street: i.street || null,
    number: i.number || null,
    zipCode: i.zipCode || null,
    region: i.county || null,
    displayAddress:
      i?.displayAddress ||
      i?.name ||
      null,
    isForeign: false,
    longitude: i.map.longitude,
    latitude: i.map.latitude
  }))


  return {
    id: data?.id ?? null,
    name: data?.name,
    addressList: addressListReProcessed ?? [],
    classify: data?.classify?.[0] ? data?.classify?.[0] : undefined,
    paymentMethod: null, //data?.paymentMethod, null for now, error from server if invoice, etc are given
    contactInfo: data?.contactInfo,
    userRelationList: undefined, // Removed from the payload as per the request of the PM. 
    isAutoSendInvoiceEnabled: data?.isAutoSendInvoiceEnabled,
  }
}

const parseLocationDelivery = (data) => {
  const ruleList = []

  data?.centralWarehouseEnabled &&
    ruleList?.push?.({
      workplaceId: "Lmnqln",
      workplaceName: "Depozit Central",
      priority: "0",
      deliveryTypeList: [
        {
          deliveryMethodType: "EXTERNAL",
          deliveryTimeType:
            data?.central?.EXTERNAL?.available !== "0"
              ? data?.central?.EXTERNAL?.deliveryTimeType
              : null,
          cost:
            data?.central?.EXTERNAL?.available !== "0"
              ? data?.central?.EXTERNAL?.cost
              : null,
          currency: "RON"
        },
        {
          deliveryMethodType: "ONSITE",
          deliveryTimeType:
            data?.central?.ONSITE?.available !== "0"
              ? data?.central?.ONSITE?.deliveryTimeType
              : null,
          cost:
            data?.central?.ONSITE?.available !== "0"
              ? data?.central?.ONSITE?.cost
              : null,
          currency: "RON"
        },
        {
          deliveryMethodType: "INTERNAL",
          deliveryTimeType:
            data?.central?.INTERNAL?.available !== "0"
              ? data?.central?.INTERNAL?.deliveryTimeType
              : null,
          cost:
            data?.central?.INTERNAL?.available !== "0"
              ? data?.central?.INTERNAL?.cost
              : null,
          currency: "RON"
        }
      ]
    })

  data?.local?.workplaceId &&
    ruleList?.push?.({
      workplaceId: data?.local?.workplaceId,
      workplaceName: data?.local?.workplaceName,
      priority: "1",
      deliveryTypeList: [
        {
          deliveryMethodType: "EXTERNAL",
          deliveryTimeType:
            data?.local?.EXTERNAL?.available !== "0"
              ? data?.local?.EXTERNAL?.deliveryTimeType
              : null,
          cost:
            data?.local?.EXTERNAL?.available !== "0"
              ? data?.local?.EXTERNAL?.cost
              : null,
          currency: "RON"
        },
        {
          deliveryMethodType: "ONSITE",
          deliveryTimeType:
            data?.local?.ONSITE?.available !== "0"
              ? data?.local?.ONSITE?.deliveryTimeType
              : null,
          cost:
            data?.local?.ONSITE?.available !== "0"
              ? data?.local?.ONSITE?.cost
              : null,
          currency: "RON"
        },
        {
          deliveryMethodType: "INTERNAL",
          deliveryTimeType:
            data?.local?.INTERNAL?.available !== "0"
              ? data?.local?.INTERNAL?.deliveryTimeType
              : null,
          cost:
            data?.local?.INTERNAL?.available !== "0"
              ? data?.local?.INTERNAL?.cost
              : null,
          currency: "RON"
        }
      ]
    })

  let _data = {
    locationId: data?.locationId,
    orderProcessMethod:
      data?.orderProcessMethod === undefined
        ? "AUTOMATIC"
        : data?.orderProcessMethod,
    ruleList: ruleList,
    addressId: data?.addressId,
    id: data?.beSideGeneratedId ? data?.beSideGeneratedId : null
  }

  // if(!data?.addressDelivery) {
  //   delete _data?.addressId
  // }

  if (!data?.locationDelivery) {
    delete _data?.locationId
  }

  delete _data?.centralWarehouseEnabled

  delete _data?.localWarehouseEnabled

  delete _data?.local

  delete _data?.central

  delete _data?.workplaceId

  delete _data?.id

  return _data
}

const parseForApiClientUserData = (data, type) => {
  if (type === "invite")
    return {
      name: data?.name,
      newLoginName: data?.newLoginName,
      //"loginName": data?.loginName,//*** */
      contactInfo: {
        phone: data?.phone
      },
      groupIds: [data?.groupIdsExternal],
      type: "EXTERNAL",
      loginMethods: {
        emailAndPassword: true
      },
      organizationId: data?.loggedOrganizationId
    }
  if (type === "edit")
    return {
      id: data?.id,
      name: data?.name,
      loginName: data?.loginName,
      avatar: null,
      contactInfo: {
        phone: data?.phone
      },
      groupIds: [data?.groupIdsExternal],
      type: "EXTERNAL",
      loginMethods: {
        emailAndPassword: true
      },
      organizationId: data?.organizationId
    }
}

// const saveUserData = {
//   "accessKeyList": [],
//   "id": null,
//   "language": "RO",
//   "loginName": null,
//   "name": "Uniprest user",
//   "newLoginName": "uniprest@test.com",
//   "attachmentList": [],
//   "avatar": null,
//   "contactInfo": null,
//   "department": "TRANSPORT",
//   "groupIds": ["XlDbrD"],
//   "organizationId": "0lYvRm",
//   "phone": null,
//   "position": "08",
//   "reminderList": [],
//   "status": "UserStatus.ACTIVE",
//   "userDirectory": "INTERN",
//   "workplace": null
// }

const parseForApiDocument = (data) => {
  return {
    // ...data, //mock, used for testing. replace with ...data
    id: data?.id,
    name: data?.name,
    description: data?.description,
    category: data?.category,
    attachment: data?.attachment,
    //TODO: hardcode
    status: data?.status ?? "BaseEntityStatus.ACTIVE",
    validity: data?.validity,
    //TODO: review: products with ids or products with objects
    productIdList:
      data?.products?.length > 0
        ? data?.products?.map?.((product) => product.id)
        : null
    // userId : values.userId,
    // userName : values.userName,
    // createTime : values.createTime,
  }
}
const parseForApiAdminUserData = (data) => {
  let _data = {
    ...data,
    groupIds: data?.groupIdsExternal
      ? [data?.groupIdsExternal]
      : data?.groupIdsInternal
      ? [data?.groupIdsInternal]
      : []
    //loginName: data?.login,
    //newLoginName: data?.newLoginName ?? undefined,
  }

  //fileds that gave error in lista utilizatori -> detail
  delete _data?.groupIdsExternal
  delete _data?.groupIdsInternal

  delete _data?.password
  delete _data?.repeatPassword
  delete _data?.name
  delete _data?.phone
  delete _data?.userDirectory
  delete _data?.department
  delete _data?.position
  delete _data?.positionPublic
  delete _data?.workplace
  delete _data?.language
  delete _data?.reminderList
  delete _data?.attachmentList
  delete _data?.accessKeyList
  delete _data?.companyCount
  delete _data?.departmentList
  delete _data?.hasAccount
  delete _data?.suspendReason
  delete _data?.userAccountType
  delete _data?.identityNr
  delete _data?.idCardNr
  delete _data?.code
  delete _data?.needsAuthentication
  delete _data?.bankAccountList
  delete _data?.products

  //uniuser errors temp from lista angajati uniprest -> detail
  delete _data?.workstation
  delete _data?.city
  delete _data?.county
  delete _data?.companiesList

  //to avoid unwanted org id errors, better make in not save yet
  //delete _data?.organizationId;
  return _data
}

const parseForApiGroupData = (data) => ({
  // ...data,
  // modifyTime: moment(data?.modifyTime),
  // createTime: moment(data?.createTime),
  code: data?.code,
  description: data?.description,
  id: data?.id,
  name: data?.name,
  status: data?.status,
  technicalName: data?.technicalName,
  type: data?.type,
  userCount: data?.userCount,
  userDirectory: data?.userDirectory,
  permissionList: data?.permissionList?.map?.((item) => {
    return {
      code: item?.code,
      read: data?.[item?.code] === "read" ? true : false,
      write: data?.[item?.code] === "write" ? true : false,
      delete: data?.[item?.code] === "delete" ? true : false
    }
  })
})

// const mockAnswerData = {
//   "id": "arBbr0",
//   "type": "ANSWER",
//   "answer": "My Answer Edit.",
//   "attachemntList": null,
//   "userName": "Uniprest user",
//   "organizationName": "UNIPREST",
//   "answerTime": "2021-06-22T12:13:18.692+0300",
//   "questionId": "5l7vZR",
// }
const parseForApiProductAnswer = (data) => {
  return {
    // ...data,
    //id: item?.id,

    answerTime: formatISOMoment(data?.date),
    answer: data?.value ?? data?.answer ?? null,
    questionId: data?.questionId ?? null,
    ratingId: data?.ratingId ?? null,
    //TODO: hardcode
    type: "ANSWER",
    attachmentList: data?.attachmentList
  }
}
const parseForApiProductComment = (data) => {
  return {
    // ...data,
    //id: item?.id,
    answerTime: formatISOMoment(data?.date),
    answer: data?.value ?? data?.answer ?? null,
    questionId: data?.questionId ?? null,
    ratingId: data?.ratingId ?? null,
    //TODO: hardcode
    type: "COMMENT",
    attachmentList: data?.attachmentList
  }
}
const parseForApiBasket = (data) => ({
  ...data
})

const parseForApiProductGoods = (data) => {
  // const attachmentList = [];
  // if (data?.imageList?.length > 0) {
  //   attachmentList?.join?.(...[
  //      data?.imageList?.map?.((item) => {
  //       return {
  //         ...item,
  //         url: item?.reference,
  //       }
  //     })]);
  // }
  // if (data?.videoList?.length > 0) {
  //   attachmentList?.join?.(...[
  //      data?.videoList?.map?.((item) => {
  //       return {
  //         ...item,
  //         url: item?.reference,
  //       }
  //     })]);
  //   }
  const relationList = data?.relationList?.map?.((item) => ({
    id: item?.new ? undefined : item?.id,
    relationType: item?.relationType,
    mainProductId: data?.id,
    relatedProductId: item?.relatedProductId
  }))

  const _data = {
    // ...data,
    //hint: only editable fields are sent to the API
    id: data?.id,
    tagList: data?.tagList,

    ranking: data?.ranking,
    // sortByRanking: data?.sortByRanking,
    // sortByAccessCount: data?.sortByAccessCount,

    categoryId: data?.category?.id, //category.id,
    relationList,

    nrOfDecimals: data?.nrOfDecimals,

    sellQuantityMinim: data?.sellQuantityMinim,
    sellQuantityIncrement: data?.sellQuantityIncrement,

    unitOfMeasure2: data?.unitOfMeasure2,

    shopMatrixCode: data?.shopMatrixCode,
    volumePickerOn: data?.volumePickerOn,
    volumePickerList: data?.volumePickerList?.join?.(","),

    deliveryMethodSettings: data?.deliveryMethodSettings,
    searchableName: data?.searchableName,
  }

  return _data
}

const parseForApiWearhouse = (data) => ({
  ...data
})

const parseForApiDiscountGrid = (data) => {
  const rawProductGroup = data?.productGroup?.split?.(" ")
  const rawClientCount = data?.clientCount?.split?.(" ")
  const rawProductCount = data?.rawProductCount?.split?.(" ")
  return {
    ...data, // below are commented what comes from form
    productGroup: rawProductGroup?.[rawProductGroup?.length - 1], //`Clasa de produs ${data?.productGroup}`,
    clientCount: rawClientCount?.[0], //`${data?.clientCount} Companii`,
    productCount: rawProductCount?.[0] //`${data?.productCount} Produse`,
  }
}

const parseForApiDiscountVolume = (data) => {
  let _data = {
    id: data?.id,

    //companyAplicability
    workplaceList:
      data?.companyApplicability === "category"
        ? data?.workplaceList ?? null
        : null,
    clientClassifyList:
      data?.companyApplicability === "category"
        ? data?.clientClassifyList
        : null,
    clientList:
      data?.companyApplicability === "individual"
        ? data?.clientList
          ? data?.clientList?.map?.((item) => item?.id ?? item)
          : null
        : null,
    productId:
      data?.productId ??
      (data?.products?.length > 0 ? data?.products?.[0]?.id : null),
    quantityList: data?.discountList
      ? data?.discountList?.map?.((item) => {
          return { discount: item?.discount, quantity: item?.quantity }
        })
      : null
  }
  return _data
}

const parseForApiDiscountPackage = (data) => {
  const _data = {
    //...data,
    id: data?.id,
    // status: data?.status,
    name: data?.name,
    quantityStock: data?.quantityStock,
    // quantitySold: data?.quantitySold,
    validityStart: data?.validityStart,
    validityEnd: data?.validityEnd,

    //productAplicability - NaN //TODO: validate spec and remove this commented code
    // productCategoryList: data?.productApplicability === "category" ? data?.productCategory : null,
    // productSupplierList: data?.productApplicability === "category" ? data?.productSupplier : null,
    // productBrandList: data?.productApplicability === "category" ? data?.brandName : null,
    goodsList: data?.products
      ? data?.products?.map?.((item) => ({
          //Hint: (TODO review) on saved products we get productId as goodId, on new products we get productId from id (due the source from dropdown option list)
          goodsId: item?.goodsId ?? item?.id,
          discount: item?.discount,
          quantity: item?.quantity
        }))
      : null,

    //companyAplicability
    workplaceList:
      data?.companyApplicability === "category"
        ? data?.workplaceList ?? null
        : null,
    clientClassifyList:
      data?.companyApplicability === "category"
        ? data?.clientClassifyList
        : null,
    clientList:
      data?.companyApplicability === "individual"
        ? data?.clientList
          ? data?.clientList?.map?.((item) => item?.id ?? item)
          : null
        : null
  }

  // delete _data?.workplaceList;
  // delete _data?.productCategory;
  // delete _data?.productSupplier;
  // delete _data?.brandName;
  // delete _data?.products;

  // delete _data?.companyApplicability;
  // // delete _data?.productApplicability;
  // delete _data?.affectedProducts;
  // delete _data?.affectedComapnies;
  // delete _data?.daysInTotal;

  // delete _data?.quantityAvailable;

  // delete _data?.discount; //TODO: ???

  return _data
}
const parseForApiDiscountFix = (data) => ({
  ...data
})
const parseForApiProductGoodsBadge = (data) => {
  let _data = {
    ...data,

    //productAplicability
    productCategoryList:
      data?.productApplicability === "category" ? data?.productCategory : null,
    productSupplierList:
      data?.productApplicability === "category" ? data?.productSupplier : null,
    productBrandList:
      data?.productApplicability === "category" ? data?.brandName : null,
    productList:
      data?.productApplicability === "individual"
        ? data?.products
          ? data?.products?.map?.((item) => item?.id)
          : null
        : null,

    //companyAplicability
    workplaceList:
      data?.companyApplicability === "category"
        ? data?.workplaceList ?? null
        : null,
    clientClassifyList:
      data?.companyApplicability === "category"
        ? data?.clientClassifyList
        : null,
    clientList:
      data?.companyApplicability === "individual"
        ? data?.clientList
          ? data?.clientList?.map?.((item) => item?.id)
          : null
        : null,

    color: badgeColors?.find?.((i) => i.id === data?.colorName)?.label
  }
  delete _data?.productCategory
  delete _data?.productSupplier
  delete _data?.brandName
  delete _data?.products

  delete _data?.companyApplicability
  delete _data?.productApplicability

  delete _data?.clientCount
  delete _data?.associatedProducts
  delete _data?.associatedCompanies

  return _data
}
const parseForApiProductRating = (data) => ({
  //...data,

  id: data?.id,
  productId: data?.productId,
  rating: data?.rating,
  review: data?.review,
  reviewTime: data?.reviewTime ?? null,
  title: data?.title,
  status: data?.status === "ProductRatingStatus.ACTIVE" ? "ACTIVE" : "ARCHIVE",

  answerList: data?.answers
    ? data?.answers?.map?.((item) => {
        return {
          id: item?.id,
          // TODO: from where?
          organizationName: data?.organizationName,
          answerTime: formatISOMoment(item?.date),
          answer: item?.value,
          questionId: data?.id ?? null,
          //TODO: hardcode
          type: "ANSWER",
          attachmentList: item?.attachmentList
        }
      })
    : []
})

const parseForApiProductQuestion = (data) => ({
  // ...data,
  id: data?.id ?? null,
  userId: data?.userId ?? null,
  userName: data?.userName,
  organizationId: data?.organizationId ?? null,
  question: data?.question,
  productId: data?.productId ?? null,
  organizationName: data?.organizationName ?? null,

  answerList: data?.answers
    ? data?.answers?.map?.((item) => {
        return {
          id: item?.id,
          answerTime: formatISOMoment(item?.date),
          answer: item?.value,
          questionId: data?.id ?? null,
          //TODO: hardcode
          type: "ANSWER",
          attachmentList: item?.attachmentList
        }
      })
    : []
})
