import React, { useEffect, useState } from "react"
import { useController } from "react-hook-form"
import { useSelector } from "react-redux"
import { useDispatch } from "store"
import {
  resetCrtList,
  setCrtListSearchString,
  setPageModal
} from "store/modules/page"
import { loadCrtListItems } from "store/justActions/crtListItems.actions"
import MagicInput from "components/MagicInput/MagicInpuv2"
import BigModal from "components/_modals/BigModal"
import "./list-modal.scss"
import { ListModalContent } from "components/_modals/ListModalContent"
import SimpleCheckBox from "components/SimpleCheckBox"
import { searchStringFilter } from "views/admin/accountRequests/tableSelector"
import MagicButton from "components/MagicButton/MagicButtonV2"
import { Lang, lang, Translate, withLocalize } from "components/Lang/Lang"

const ListModal = (props) => {
  const { control } = props
  if (control) return <ListModalIntegrated {...props} />

  return <ListModalBase {...props} />
}

export default ListModal

const ListModalIntegrated = (props) => {
  const { control, name } = props
  const {
    field: { value, onChange, onBlur }
    // fieldState, //{ invalid, isTouched, isDirty },
    // formState, // { touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    defaultValue: []
  })

  return <ListModalBase {...{ ...props, value, onChange, onBlur }} />
}

function ListModalBase(props) {
  const {
    value,
    onChange,
    openIndex,
    setOpenIndex,
    itemFormatter,
    name,
    itemsType,
    title,
    label,
    placeholder,
    // setShowModal, showModal, //no used anymore. moved to REDUX with the modal operation
    noResultCount, //hides the result count below search
    selectionLabel, //array of values (one or many) used for the selectionToggle
    noSelectionToggle, //hides the show selected row above the footer
    noSearch, //hides the search bar
    typeKey, //create categories based on this one. hides type categories if undefined
    noModal,
    staticList, // directly supply the list of rows to render in value
    header, // array of strings used for creating a table instead of list
    single, // only one item selected at any point
    ro, // read only modal: no select, no save
    formatAdapter //function to adapt the api values to what we have from the initial state
  } = props
  const dispatch = useDispatch()
  const pageModal = useSelector((state) => state.page?.modal)
  const [showSelection, setShowSelection] = useState(false)

  const crtListItemsFromSelector = useSelector(
    (state) => state.page?.crtList?.items
  )?.map?.((item) => (formatAdapter ? formatAdapter(item) : item))
  const searchString = useSelector((state) => state.page?.crtList?.searchString)
  const lastItemHint = useSelector(
    (state) => state?.page?.crtList?.lastItemHint
  )
  const [selected, setSelected] = useState(value ?? [])
  //comp load init
  const crtListItems = staticList ? value : crtListItemsFromSelector
  useEffect(() => {
    dispatch(resetCrtList())
    return () => {
      dispatch(resetCrtList())
    }
  }, [])
  useEffect(() => {
    setSelected(value)
  }, [value])

  useEffect(() => {
    if (noModal && onChange) onChange(selected)
  }, [selected])

  //component update list
  useEffect(() => {
    if (!staticList) {
      if (pageModal === name) dispatch(loadCrtListItems(itemsType, true))
      if (openIndex !== null && openIndex !== undefined)
        dispatch(loadCrtListItems(itemsType, true))
    }
  }, [pageModal, openIndex, searchString])

  const isSelected = (input) =>
    Boolean(selected?.find?.((item) => item?.id === input.id))
  const items = crtListItems?.map?.((item) => {
    return {
      ...item,
      selected: isSelected(item)
    }
  })
  const toggleItem = (input) => {
    if (!input) return
    if (single) setSelected([{ ...input, selected: true }])
    else if (isSelected(input))
      setSelected((p) => (p ? p?.filter?.((item) => item?.id !== input.id) : []))
    else setSelected((p) => [...(p ?? []), { ...input, selected: true }])
  }

  const onItemChange = (item) =>
    setSelected((p) => p?.map?.((i) => (i.id === item?.id ? item : i)))
  const toggleSelectAll = () =>
    selected?.length === crtListItems?.length
      ? setSelected([])
      : setSelected(crtListItems)

  const topFooter = noSelectionToggle ? null : (
    <div data-test-id='Qd8X' className="selection-indicator">
      <div data-test-id='6Yjb'>{`${selected?.length ?? 0} ${
        selected?.length === 1
          ? (selectionLabel ?? selectionLabelDefault)?.[0]
          : (selectionLabel ?? selectionLabelDefault)[1]
      }`}</div>
      <button 
        data-test-id='9VRY'
        className={"see-all"}
        onClick={() => setShowSelection((p) => !p)}
      >{`${
        !showSelection ? "arata elementele selectate" : "arata toate elementele"
      }`}</button>
    </div>
  )

  const topRow = (
    <div data-test-id='YqeL' className="top-row">
      <SimpleCheckBox
        checked={
          items?.length === selected?.length
            ? true
            : selected?.length === 0
            ? false
            : null
        }
        onClick={() => toggleSelectAll()}
      />
      {`${items?.length ?? 0} ${
        items?.length === 1 ? "rezultat" : "rezultate"
      }`}
    </div>
  )

  // if(openIndex === null || openIndex === undefined) return null
  const content = (
    <>
      {!noSearch && (
        <MagicInput
          outline
          {...{
            icon: "search",
            label,
            placeholder,
            className: `search ${noResultCount ? "mb6" : null}`,
            value: searchString,
            onChange: (e) => dispatch(setCrtListSearchString(e?.target?.value))
          }}
        />
      )}
      {!noResultCount && !showSelection && !ro && topRow}

      <ListModalContent
        {...{
          ...props,
          showSelection,
          toggleItem,
          onItemChange,
          header,
          items: (itemFormatter
            ? items?.map?.((i) => itemFormatter(i))
            : items
          )?.map?.((i) => ({
            ...i,
            [typeKey]:
              i?.[typeKey] === null
                ? `No ${typeKey?.toLowerCase?.()}`
                : i?.[typeKey]
          })),
          selected,
          typeKey,
          ro,
          single
        }}
      >
        {props.children}
      </ListModalContent>
      {lastItemHint > 0 && !showSelection && !staticList && (
        <MagicButton
          w={100}
          outline
          onClick={() => dispatch(loadCrtListItems(itemsType, false))}
          label={<Lang value="magictable.loadMore" />}
        />
      )}
      {!ro && topFooter}
    </>
  )

  return noModal ? (
    <div data-test-id='7WFV' className="modal-body modal-body-no-modal"> {content}</div>
  ) : (
    <BigModal
      title={title}
      name={name}
      // topFooter = {topFooter}
      saveAction={() => {
        onChange(selected)
        if (setOpenIndex) setOpenIndex(null)
      }}
      closeAction={() => {
        setSelected(value)
        if (setOpenIndex) setOpenIndex(null)
      }}
    >
      {" "}
      {content}
    </BigModal>
  )
}

const selectionLabelDefault = ["comapnie selectata", "companii selectate"]
