import Apa from "assets/AboutUniprest/productcategory/Apa.svg"
import Termice from "assets/AboutUniprest/productcategory/Termice.svg"
import Gaz from "assets/AboutUniprest/productcategory/Gaz.svg"
import EnergieVerde from "assets/AboutUniprest/productcategory/EnergieVerde.svg"
import Sanitare from "assets/AboutUniprest/productcategory/Sanitare.svg"
import Ventilatie from "assets/AboutUniprest/productcategory/Ventilatie.svg"
import { Lang, lang, Translate, withLocalize } from "components/Lang/Lang"

export const category = [
  {
    Title: <Lang value="aboutUs.gas" />,
    alt: <Lang value="aboutUs.categories" />,
    icon: Gaz,
    url: "/shop/productlist?filterId=FLT-CATEG001&filterSelection=27465"
  },
  {
    Title: <Lang value="aboutUs.thermos" />,
    alt: <Lang value="aboutUs.categories" />,
    icon: Termice,
    url: "/shop/productlist?filterId=FLT-CATEG001&filterSelection=27501"
  },
  {
    Title: <Lang value="aboutUs.water" />,
    alt: <Lang value="aboutUs.categories" />,
    icon: Apa,
    url: "/shop/productlist?filterId=FLT-CATEG001&filterSelection=27407"
  },
  {
    Title: <Lang value="aboutUs.energy" />,
    alt: <Lang value="aboutUs.categories" />,
    icon: EnergieVerde,
    url: "/shop/productlist?filterId=FLT-CATEG001&filterSelection=27448"
  },
  {
    Title: <Lang value="aboutUs.health" />,
    alt: <Lang value="aboutUs.categories" />,
    icon: Sanitare,
    url: "/shop/productlist?filterId=FLT-CATEG001&filterSelection=27472"
  },
  {
    Title: <Lang value="aboutUs.ventilation" />,
    alt: <Lang value="aboutUs.categories" />,
    icon: Ventilatie,
    url: "/shop/productlist?filterId=FLT-CATEG001&filterSelection=27398"
  }
]
