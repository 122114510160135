import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import { Tag } from "componentsK3"
import { useController } from "react-hook-form"

const InputTags = (props) => {
  const { control } = props
  if (control) return <InputTagsIntegrated {...props} />

  return <InputTagsBase {...props} />
}

export default InputTags

const InputTagsIntegrated = (props) => {
  const { control, name } = props
  const {
    field: { value, onChange, onBlur }
    // fieldState, //{ invalid, isTouched, isDirty },
    // formState, // { touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    defaultValue: []
  })

  return <InputTagsBase {...{ ...props, value, onChange, onBlur }} />
}

function InputTagsBase(props) {
  const {
    name,
    value,
    onChange,
    label,
    error,
    disabled,
    inputPlaceholder,
    className,
    withId,
    options //corner case when used as a display comp in aplicability forms
  } = props
  const [editMode, setEditMode] = useState(false)
  const inputRef = useRef()

  function _handleIconClick(itemIndex) {
    onChange(value?.filter?.((item, index) => itemIndex !== index))

    setEditMode(false)
  }

  function _handleOnBlur() {
    setEditMode(false)
  }

  function _handleKeyDown(e) {
    const newElement = withId ? { name: e?.target?.value } : e?.target?.value
    if (e?.key === "Enter") {
      if (e?.target?.value) onChange([...value || [], newElement])
      setEditMode(false)
    }
  }

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [editMode])

  const getLabel = (item) =>
    options?.length > 0
      ? options?.find?.((o) => (o.id ?? o?.value) === (item?.id ?? item))?.label
      : item?.name ?? item

  return (
    <div data-test-id='dKLv' className={`input-tags ${className ?? ""}`}>
      <div data-test-id='r2F9' className="top-label">
        {error ? (
          <div data-test-id='kIBh' className="tag-error-text">{error}</div>
        ) : label ? (
          label
        ) : (
          name
        )}
      </div>
      <div data-test-id='zeiQ' className={`tags-container ${error ? "tag-error" : ""}`}>
        {value &&
          value?.map?.((item, index) => {
            return (
              <Tag
                key={index}
                label={getLabel(item)}
                iconColor="blue-3"
                backgroundColor="bluebkg"
                disabled={disabled}
                onIconClick={() => _handleIconClick(index)}
                iconName="closek3"
              />
            )
          })}
        {!disabled &&
          (editMode ? (
            <input 
              data-test-id='Zw2E'
              placeholder={inputPlaceholder ? inputPlaceholder : ""}
              className="input-tag"
              onKeyDown={_handleKeyDown}
              onBlur={_handleOnBlur}
              ref={inputRef}
            />
          ) : (
            /* TODO: should be this one a button? */
            <div data-test-id='QV1O' className="add-button" onClick={() => setEditMode(true)}>
              +
            </div>
          ))}
      </div>
    </div>
  )
}

InputTagsIntegrated.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  error: PropTypes.string,
  // value: PropTypes.array,
  // onChange: PropTypes.func,
  disabled: PropTypes.bool
}
