import MagicButton from "components/MagicButton/MagicButtonV2"
import SvgIcon from "components/SvgIcon/SvgIcon"
import { useState } from "react"
import "./cartProductCard.scss"
import { useDispatch } from "react-redux"
import { modifyBasket } from "store/modules/shop"
import useShowModalOutsideClick from "utils/useShowModalOutsideClick"
import ProductCard from "components/ProductCard"
import imageChain3 from "components/_partials/ImageChain3"
import { goToProductDetails } from "utils/history"
import FormatComponent from "components/FormatComponent/FormatComponent"
import { numberFormatter } from "utils/stringUtils"
import { deleteProductFromList } from "store/justActions/lists"
import MagicInput from "components/MagicInput/MagicInpuv2"
import {
  setMainProductPriceAndQuantity,
  setAccessoryProductPriceAndQuantity,
} from "store/modules/page"
import { withLocalize } from "components/Lang/Lang"
import { deleteProductFromExcel, setMoreOpenRowId } from "store/modules/dataView"
import { getComputedPriceInfo } from "utils/productUtils"


const splitButtonLabel = "Imparte pachetul in produse individuale"
const splitMessage =
  "Atentie! Produsele individuale nu vor beneficia de discountul de Pachet. Odata impartit, pachetul nu poate fi recompus, insa poate fi adaugat din sectiunea Pachete "
const imageLimit = 4
function CartProductCard(props) {
  const {
    stockState,
    images,
    name,
    discount,
    price,
    products,
    vatPrice,
    quantity,
    unit,
    id,
    priceUnit,
    small, //renders a smaller card, for the pack expanded list
    youSave,
    orderView,
    oldVersion,
    renderedBy, // figure out where do we render this one
    onlyRight, //hides the left product details section
    cardModalState = "addToCartCard", //controlls where the edit modal opens.
    priceOrStockRequestForm,
    noDelete,
    noCancel,
    deleteCrtListItem,
    stockChanged,
    priceChanged,
    zeroStock,
    zeroPrice,
    highlightOldQuantity,
    highlightOldPrices,
    highLightNewPrices,
    highlightNewQuantity,
    offerId,
    showDeleteButton,
    secondaryPriceForModal,
    isBasketPage
  } = props


  let { product } = props

  const computed = getComputedPriceInfo(props)

  const translate = props.translate
  const [open, setOpen] = useState(false)
  const [editable, setEditable] = useState(false)
  const { ref, showModal, setShowModal } = useShowModalOutsideClick()
  let [changedQuantity, setChangedQuantity] = useState(quantity)
  const dispatch = useDispatch()
  const editAction = () => {
    if (!noDelete) {
      setShowModal(true)
    } else {
      if (editable) {
        if (noCancel) {
          //console.log("SET SHOW PRICE MAIN")
          dispatch(setMainProductPriceAndQuantity(changedQuantity))
        } //setShowPrices
        else {
          //console.log("SET SHOW PRICE AUX")
          dispatch(setAccessoryProductPriceAndQuantity({ id, changedQuantity }))
        } //setCrtListPriceAndQuantity
      }
      setEditable((p) => !p)
    }
  }

  const deleteFromCartAction = () => {
    if (renderedBy === "userList")
      dispatch(deleteProductFromList(null, id))
    else if (renderedBy === "importFromExcel") {
      dispatch(deleteProductFromExcel(id))
      dispatch(setMoreOpenRowId(null))
    }
    else dispatch(
            modifyBasket(id, 0, product?.unitPriceNet, `remove-button-${id}`)
          )
  }

  const stockSvg =
    stockState === "low"
      ? "stockred"
      : stockState === "limited"
      ? "stockyellow"
      : "greencheckbox"
  const pack = products?.length > 0
  let className = "cart-product-card-wrapper"
  if (small) className += " small"
  if (pack) className += " pack "
  if (!stockState) className += " no-stock"
  if (oldVersion) {
    className += " old-version"
  }

  // useEffect(() => {
  //   let expTimeout
  //   if (oldVersion)
  //     expTimeout = setTimeout(() => {
  //       dispatch(setExpiredBasket(true))
  //     }, 0)
  //   return () => {
  //     clearTimeout(expTimeout)
  //   }
  // }, [])

  // const images = products?.map?.(item => item?.primaryImage)

  const computePricesStyle = () => {
    if (oldVersion && highlightOldPrices) return "color-alert-red strike"
    if(highLightNewPrices) return "color-all-good"
    return "text-light-01"
  }
  const priceCollumn =
    priceOrStockRequestForm || id?.includes?.("incorrect") ? (// || oldVersion
      <div data-test-id='mazl' />
    ) : (
      <div data-test-id='nDVL' className="section section--price">
        {computed.hasPrice.primary &&
          <div data-test-id='FuuQ' className="top">
          <FormatComponent
            number={computed.prices.primary}
            formatStyle={`price ptype-5-strong ${computePricesStyle()}`}
            suffixText={computed.suffixText.primary}
          />
          <span data-test-id='OgSV' className="discount">{youSave}</span>
          </div>
        }
        {computed.hasPrice.secondary &&
          <FormatComponent
            number={computed.prices.secondary}
            formatStyle={`vat-price ptype-3  ${computePricesStyle()}`}
            suffixText={computed.suffixText.secondary}
          />
        }
      </div>
    )

  const removeButton = priceOrStockRequestForm ? (
    <div data-test-id='M5RC' />
  ) : (
    !small &&
    !orderView &&
    !oldVersion && (
      <MagicButton
        clear
        px={0}
        svg="Trash"
        onClick={() => deleteFromCartAction()}
        loadingId={`remove-button-${id}`}
      />
    )
  )
  const computeQuantityStyle = (quantity) => {
    if(oldVersion && highlightOldQuantity) return "color-alert-red strike mt3 mb3"
    if(highlightNewQuantity) return "color-all-good"
    if(oldVersion && !highlightOldQuantity) return "text-light-03 mt3 mb3" 
    if(quantity) return ""
    return "text-light-03"
  }
  const quantityStockEditSection = (id?.includes?.("incorrect") ) ? (//&& !id?.includes?.("warning")
    <div data-test-id='UAkl' />
  ) : (
    <>
      {noDelete ? (
        editable ? (
          <MagicInput
            className="quantity mr1 "
            value={changedQuantity}
            onChange={(e) =>
              setChangedQuantity((p) =>
                isNaN(e?.target?.value) ? p : e?.target?.value
              )
            }
          />
        ) : (
          <div data-test-id='LREW' className="quantity mr1">
            {numberFormatter(changedQuantity, ".")}
          </div>
        )
      ) : (
        <div data-test-id='5ztX' className={`quantity mr1 ${computeQuantityStyle(true)}`}>{numberFormatter(quantity, ".")}</div>
      )}
      {/* <div data-test-id='ECXt' className="quantity mr1">{numberFormatter(quantity,'.')}</div> */}
      <div data-test-id='UX3b' className={`unit mr4 ${computeQuantityStyle(false)} `}>
        {unit === "PCE" ? "buc" : unit === "MTR" ? "m" : unit}
      </div>
      {!small && !orderView && !oldVersion && (
        <MagicButton
          outline
          clear
          className="edit"
          px={0}
          h={5}
          w={editable ? 10 : 5}
          svg={editable ? "savelist" : "edit"}
          popBottom={
            noDelete
              ? editable
                ? translate?.("productcard.save")
                : translate?.("formPormPageHeader.edit")
              : ""
          }
          onClick={() => editAction()}
          disabled={offerId}
        />
      )}
      {/**  disabled={noDelete}*/}
      {noDelete && !noCancel && (
        <MagicButton
          outline
          clear
          className="cancel"
          ml={1.5}
          px={0}
          h={5}
          w={5}
          svg="cancel"
          onClick={deleteCrtListItem}
          popBottom={translate?.("documentsForm.delete")}
        />
      )}
      {!orderView && !priceOrStockRequestForm && !editable && !oldVersion && (
        <SvgIcon name={stockSvg} />
      )}
    </>
  )
  const getWarningMessages = () => {
    let dynamicText = ``
    if(stockChanged && priceChanged)
      dynamicText = `${translate?.("cartProductCard.stockChangedPriceChanged")}`//"Preţul şi stocul s-au modificat la acest produs"
    else  if(stockChanged && zeroPrice)
      dynamicText = `${translate?.("cartProductCard.stockChangedZeroPrice")}`//"Stocul s-a modificat la acest produs şi nu are preţ"
    else  if(zeroStock && priceChanged)
      dynamicText = `${translate?.("cartProductCard.zeroStockPriceChanged")}`//"Preţul s-a modificat la acest produs şi nu are stoc"
    else  if(zeroStock && zeroPrice)
      dynamicText = `${translate?.("cartProductCard.zeroStockZeroPrice")}`//"Produsul s-a epuizat şi nu are preţ"
    else if (stockChanged)
      dynamicText = `${translate?.("cartProductCard.stockChanged")}`
    else if(priceChanged)
      dynamicText = `${translate?.("cartProductCard.priceChanged")}`
    else if(zeroStock)
      dynamicText = `${translate?.("cartProductCard.zeroStock")}`//"Produsul s-a epuizat"
    else if (zeroPrice)
      dynamicText = `${translate?.("cartProductCard.zeroPrice")}`//"Produsul nu are preţ"
    else
      dynamicText = `${translate?.("cartProductCard.defaultStockPriceWarning")}`//"Preţul sau stocul s-au modificat la acest produs"
    // `${translate?.("cartProductCard.priceChanged")}: ${props.productCode} - ${props.productName}`
    // `${translate?.("cartProductCard.stockChanged")}: ${props.productCode} - ${props.productName}`
    return `${dynamicText}:`
  }
  const oldVersionContent = (
    <div data-test-id='NHrE' className="main-content ">
      <div data-test-id='4NkY'></div>
      {alertIcon}
      <div data-test-id='6DzP' className="ptype-5 color-alert-red">
        <div data-test-id='vL82'>{getWarningMessages()}</div>
        <div data-test-id='DWQI'>{`${props.productCode} - ${props.productName}`}</div>
      </div>
    </div>
  )
  const productCardContent = (
    <>
      {!onlyRight &&
        (oldVersion ? (
          oldVersionContent
        ) : (
          <CartProductDetailsCell {...props} />
        ))}
      {priceCollumn}
      <div 
        data-test-id='8xng'
        className={`section section--edit ptype-5-strong bg-grey-10 ${oldVersion && highlightOldQuantity ? "color-alert-red" : ""}`}
        // style={{ padding: "1rem" }}
      >
        {quantityStockEditSection}
      </div>
      {!noDelete && removeButton}
      { showDeleteButton &&
        <MagicButton
          clear
          px={0}
          svg="Trash"
          onClick={() => deleteFromCartAction()}
          loadingId={`remove-button-${id}`}
        />
      }
    </>
  )

  const splitCard = (
    <div data-test-id='zZqv' className="split-card">
      <MagicButton outline strong wrapText px={2} label={splitButtonLabel} />
      <SvgIcon name="warning" />
      <span data-test-id='dKGm'>{splitMessage}</span>
    </div>
  )

  const packCardContent = (
    <>
      <div 
        data-test-id='JxKu'
        className={`pack-top-row ${open ? "expanded" : ""}`}
        onClick={() => setOpen((p) => !p)}
      >
        <div data-test-id='vv8i' className="section section--details ">
          <div data-test-id='rILZ' className="title etype-1 mb2">{name}</div>
          {imageChain3({ images, imageLimit })}
        </div>
        {priceCollumn}
        <div data-test-id='t3lE' className="section section--edit ptype-5-strong ">
          {quantityStockEditSection}
        </div>
        {/* <MagicButton outline clear  px={0} h={5} svg="edit" onClick={() => editAction()}  /> */}
        {/* <SvgIcon name={stockSvg} /> */}
        {removeButton}
      </div>
      {open && products?.map?.((item) => <CartProductCard {...item} small />)}
      {open && splitCard}
    </>
  )

  return (
    <div data-test-id='GIN9' {...{ className }}>
      {pack ? packCardContent : productCardContent}
      {showModal && (
        <div data-test-id='g16q' {...{ ref }} className="central-modal-overlay">
          <ProductCard
            addState={cardModalState}
            closeModal={() => setShowModal(false)}
            product={props}
            {...{...props, quantity: props?.quantityForModification, 
              priceNet: props?.priceNet,
              secondaryPrice: secondaryPriceForModal || props?.secondaryPrice,
              isBasketPage
            }}
          />
        </div>
      )}
    </div>
  )
}

export default withLocalize(CartProductCard)

export const alertIcon = (
  <svg 
    data-test-id='1MFb'
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 26L16 7L26 26H6Z"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 15V19"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 23C16.5523 23 17 22.5523 17 22C17 21.4477 16.5523 21 16 21C15.4477 21 15 21.4477 15 22C15 22.5523 15.4477 23 16 23Z"
      stroke="none"
    />
  </svg>
)

export function CartProductDetailsCell(props) {
  const { id, brandName, primaryImage, title, setPageModal, clickNotAllowed = false, isWarning } = props
  const itemImage = primaryImage ?? "/images/new-placeholder.svg"
  const dispatch = useDispatch()
  const productCodeStyle = {
    backgroundColor: "#f3f3f3",
    borderRadius: "4px",
    padding: "2px 9px",
    marginRight: "8px"
  }

  return (
    <div data-test-id='Ge3i' className="cart-product-details-cell">
      <img 
        data-test-id='zLvB'
        onContextMenu={(e) => e?.preventDefault?.()}
        src={itemImage}
        className={`image ${id && "click"}`}
        alt="img"
        onClick={() => {
          if(clickNotAllowed) return 
          if(setPageModal && id) {
            dispatch(setPageModal?.());
            return goToProductDetails(id)
          }
          if(id) goToProductDetails(id)}
        }
      />
      <div 
        data-test-id='ozQI'
        className={`section section--title ${id && "click"} ${
          (id?.includes?.("incorrect") || isWarning) && "alert-red"
        }`}
        onClick={() => {
          if(id && !id?.includes?.("incorrect")) {
            if(clickNotAllowed) return 
            if(setPageModal) {
              dispatch(setPageModal?.());
              return goToProductDetails(id)
            }
            goToProductDetails(id)
          }
        }
      }
      >
        <div data-test-id='cAEl' className="mb1">
          {props?.productCode && (
            <span data-test-id='Ccqt' className="ptype-4" style={productCodeStyle}>
              {props?.productCode}
            </span>
          )}
          {brandName && (
            <span data-test-id='MGu0' className="brand ptype-4 ">Brand: {brandName}</span>
          )}
        </div>
        <div data-test-id='18XP' className="title ptype-4-bold">{title}</div>
      </div>
    </div>
  )
}
