const initialApiStatus = {
  response: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
  errorCode: null,
  wasCalled: false,
  hasResponded: false
}

export const apiStatuses = {
  getInitial: () => ({ ...initialApiStatus }),

  getStarted: () => ({
    ...initialApiStatus,
    isLoading: true,
    wasCalled: true
  }),

  getSuccess: (response) => ({
    ...initialApiStatus,
    response,
    isSuccess: true,
    wasCalled: true,
    hasResponded: true
  }),

  getError: (errorCode) => ({
    ...initialApiStatus,
    isError: true,
    errorCode,
    wasCalled: true,
    hasResponded: true
  })
}
