import { apiAction } from "store/middleware/api"
import { setApiResponse } from "store/modules/auth"
import { dynamoAction } from "store/middleware/api"
import { toastr } from "react-redux-toastr"
import moment from "moment"
import { date } from "yup/lib/locale"
import { printError } from "utils/printError"
import { sanitizeAnythingForRedux } from "utils/preventReactInRedux"

export const SET_VALIDATION_RULES = "backData/SET_VALIDATION_RULES"
export const SET_DICTIONARY = "backData/SET_DICTIONARY"
export const DELETE_SINGLE_DICTIONARY = "backData/DELETE_SINGLE_DICTIONARY"
export const SET_ALL_DICTIONARIES = "backData/SET_ALL_DICTIONARIES"
export const SET_PRODUCT_CATEGORY = "backData/SET_PRODUCT_CATEGORY"
export const SET_ENUM = "backData/SET_ENUM"
export const SET_LOGIN_CALLS = "backData/SET_LOGIN_CALLS"
export const UPDATE_LOGIN_CALLS = "backData/UPDATE_LOGIN_CALLS"
export const SET_LIST = "backData/SET_LIST"
export const LOAD_ALL_OPTIONS = "backData/LOAD_ALL_OPTIONS"
export const SET_PRODUCTS_TO_EXCEL = "backData/SET_PRODUCTS_TO_EXCEL"

//new action: loadd all options

const initialState = {
  ddOptions: {},
  dictionary: {},
  enums: {},
  productCategory: [],
  lists: {}, //simmilar with dictionary/ENUMs but arbitrary data structure
  loginCalls: 0, // used to redirect after all the login calls are done
  productsToExcel: []
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, unsanitizedAction) {

  const action = sanitizeAnythingForRedux(unsanitizedAction)

  switch (action.type) {
    case SET_PRODUCT_CATEGORY:
      return {
        ...state,
        productCategory: action.payload
      }
    case SET_LOGIN_CALLS:
      return {
        ...state,
        loginCalls: action.payload
      }
    case UPDATE_LOGIN_CALLS:
      return {
        ...state,
        loginCalls: state.loginCalls + action.payload
      }
    case SET_DICTIONARY:
      return {
        ...state,
        dictionary: { ...state.dictionary, ...action.payload }
      }
    case DELETE_SINGLE_DICTIONARY:
      return {
        ...state,
        dictionary: { ...state.dictionary, [action?.payload]: undefined }
      }
    case SET_ALL_DICTIONARIES:
      return {
        ...state,
        dictionary: { ...state.dictionary, ...action.payload }
      }
    case SET_ENUM:
      return {
        ...state,
        enums: { ...state.enums, ...action.payload }
      }
    case SET_LIST:
      return {
        ...state,
        lists: { ...state.lists, ...action.payload }
      }
    case LOAD_ALL_OPTIONS:
      return {
        ...state,
        ddOptions: { ...state.ddOptions, ...action.payload }
      }
    case SET_PRODUCTS_TO_EXCEL:
      return {
        ...state,
        productsToExcel: action.payload
      }
    default:
      return state
  }
}

export const setDictionary = (data) => ({
  type: SET_DICTIONARY,
  payload: { ...data }
})

export const deleteSingleDictionary = (key) => ({
  type: DELETE_SINGLE_DICTIONARY,
  payload: key
})



export const setBackList = (id, data) => ({
  type: SET_LIST,
  payload: { [id]: data }
})
export const setLoginCalls = (data) => ({
  type: SET_LOGIN_CALLS,
  payload: data
})
export const incrementLoginCall = () => ({
  type: UPDATE_LOGIN_CALLS,
  payload: 1
})
export const decrementLoginCall = () => ({
  type: UPDATE_LOGIN_CALLS,
  payload: -1
})

export const loadDictionary = (id, isPublic = false) => {

  const endPoint = isPublic 
    ? `/public/dictionary/type/${id}` 
    : `/dictionary/type/${id}`

  return apiAction({
    endPoint,
    onSuccess: (data, dispatch) => {
      dispatch(setApiResponse(data))
      incrementLoginCall() //dispatch
      let payload = {}
      payload[id] = data
      return {
        type: SET_DICTIONARY,
        payload
      }
    },
    onFailure: (data, dispatch) => {
      printError("loadDictionary()", data)
      incrementLoginCall() //dispatch

      return {
        type: "a"
      }
    }
  })
}
  
export const loadPublicDictionary = id => loadDictionary(id, true)


export const loadAllDictionaries = () =>
  apiAction({
    endPoint: "/public/dictionary/all",
    onSuccess: (data, dispatch) => {

      const processed = data?.reduce?.((prev, current) => {

        const key = current?.type
    
        if(!key) return prev
        if(!current) return prev
    
        const existingArray = prev?.[key] || []
        const extendedArray = [...existingArray, current]
    
        return {
            ...prev, 
            [key]: extendedArray,
        }
    
      }, {})

      /**
       * Removed the PRODUCT_BRAND from the list of ALL dictionaries because 
       * it is meant to be loaded separately, from the API endpoint of 
       * /public/dictionary/type/PRODUCT_BRAND. Reason: the ALL endpoint is 
       * heavily cached, but the PRODUCT_BRAND is not (we must react quickly 
       * to brand changes), so we need to load the brands separately.
       */
      const { PRODUCT_BRAND, ...filteredDictionary } = processed || {}

      return {
        type: SET_ALL_DICTIONARIES,
        payload: filteredDictionary
        // payload: processed
      }
    },
    
    onFailure: (data, dispatch) => {
      printError("loadAllDictionaries()", data)
      return {
        type: "a"
      }
    }
  })

export const getAllClients = () =>
  apiAction({
    endPoint: "/organization/webshop-client/my-options", // alloptions",
    onSuccess: (data, dispatch) => {
      let payload = {}
      payload["CLIENTS"] = data
      return {
        type: SET_DICTIONARY,
        payload
      }
    },
    onFailure: (data, dispatch) => {
      printError("getAllClients()", data)
      incrementLoginCall() //dispatch

      return {
        type: "a"
      }
    }
  })
export const getAllClientsOptions = () =>
  apiAction({
    endPoint: "/organization/webshop-client/alloptions", //
    onSuccess: (data, dispatch) => {
      let payload = {}
      payload["ALL_CLIENTS"] = data
      return {
        type: SET_DICTIONARY,
        payload
      }
    },
    onFailure: (data, dispatch) => {
      printError("getAllClientsOptions()", data)
      incrementLoginCall() //dispatch

      return {
        type: "a"
      }
    }
  })
export const getAllClientCustomers = () =>
  apiAction({
    endPoint: "/customer-offer/external-client/alloptions", //
    onSuccess: (data, dispatch) => {
      let payload = {}
      payload["ALL_CLIENT_CUSTOMERS"] = data
      return {
        type: SET_DICTIONARY,
        payload
      }
    },
    onFailure: (data, dispatch) => {
      printError("getAllClientCustomers()", data)
      incrementLoginCall() //dispatch

      return {
        type: "a"
      }
    }
  })
export const getAllClientExternalProducts = () =>
  apiAction({
    endPoint: "/customer-offer/external-product/alloptions", //
    onSuccess: (data, dispatch) => {
      let payload = {}
      payload["ALL_CLIENT_EXTERNAL_PRODUCTS"] = data
      return {
        type: SET_DICTIONARY,
        payload
      }
    },
    onFailure: (data, dispatch) => {
      printError("getAllClientExternalProducts()", data)
      incrementLoginCall() //dispatch

      return {
        type: "a"
      }
    }
  })
export const getAllAgents = () =>
  apiAction({
    endPoint: "/organization-user-relation/user-list-by-relation/sales_manager",
    onSuccess: (data, dispatch) => {
      let payload = {}
      payload["AGENTS"] = data
      return {
        type: SET_DICTIONARY,
        payload
      }
    },
    onFailure: (data, dispatch) => {
      printError("getAllAgents()", data)
      incrementLoginCall() //dispatch

      return {
        type: "a"
      }
    }
  })
export const getAllRoles = (toList) =>
  apiAction({
    endPoint: "/group/alloptions/",
    onSuccess: (data, dispatch) => {
      let payload = {}
      payload["ALL_ROLES"] = data
      return {
        type: SET_DICTIONARY,
        payload
      }
    },
    onFailure: (data, dispatch) => {
      printError("getAllRoles()", data)
      incrementLoginCall() //did not recognize dispatch on error branch on server down

      return {
        type: "a"
      }
    }
  })
export const getExternalRoles = () =>
  apiAction({
    endPoint: "/uniprest/group/alloptions/EXTERNAL",
    onSuccess: (data, dispatch) => {
      let payload = {}
      payload["EXTERNAL_ROLES"] = data
      return {
        type: SET_DICTIONARY,
        payload
      }
    },
    onFailure: (data, dispatch) => {
      printError("getExternalRoles()", data)
      incrementLoginCall() //dispatch

      return {
        type: "a"
      }
    }
  })
export const getInternalRoles = () =>
  apiAction({
    endPoint: "/uniprest/group/alloptions/INTERNAL",
    onSuccess: (data, dispatch) => {
      let payload = {}
      payload["INTERNAL_ROLES"] = data
      return {
        type: SET_DICTIONARY,
        payload
      }
    },
    onFailure: (data, dispatch) => {
      printError("getInternalRoles()", data)
      incrementLoginCall() //dispatch

      return {
        type: "a"
      }
    }
  })
export const getAllUniLocations = () =>
  apiAction({
    endPoint: "/organization/workplace/alloptions",
    onSuccess: (data, dispatch) => {
      let payload = {}
      payload["ALL_UNI_LOCATIONS"] = data
      return {
        type: SET_DICTIONARY,
        payload
      }
    },
    onFailure: (data, dispatch) => {
      printError("getAllUniLocations()", data)
      incrementLoginCall() //dispatch

      return {
        type: "a"
      }
    }
  })
export const loadProductCategories = (isAdmin) =>
  apiAction({
    endPoint: `${isAdmin ? "/product-category/admin/" : "/public/product-category/"}all`,
    onSuccess: (data, dispatch) => {
      dispatch(setApiResponse(data))
      incrementLoginCall() //dispatch

      return {
        type: SET_PRODUCT_CATEGORY,
        payload: data
      }
    },
    onFailure: (data, dispatch) => {
      printError("loadProductCategories()", data)
      incrementLoginCall() //dispatch

      return {
        type: "a"
      }
    }
  })

export const getRankingOptions = () =>
  apiAction({
    endPoint: "/product/goods/shop/product-ranking-list",
    onSuccess: (data, dispatch) => {
      let payload = {}
      payload["RANKINGS"] = data
      return {
        type: SET_DICTIONARY,
        payload
      }
    },
    onFailure: (data, dispatch) => {
      printError("getRankingOptions()", data)
      incrementLoginCall() //dispatch

      return {
        type: "a"
      }
    }
  })

export const loadEnums = (shortType) =>
  // enums?type=eu.mgs.synergic.model.product.ProductStockStatus
  apiAction({
    endPoint: "/enums?type=eu.mgs.synergic.model." + shortType,
    onSuccess: (data, dispatch) => {
      dispatch(setApiResponse(data))
      incrementLoginCall() //dispatch
      let payload = {}
      payload[shortType] = data
      return {
        type: SET_ENUM,
        payload
      }
    },
    onFailure: (data, dispatch) => {
      printError("loadEnums()", data)
      incrementLoginCall() //dispatch

      return {
        type: "a"
      }
    }
  })

export const loadAllDdOptions = () =>
  dynamoAction({
    method: "QUERY",
    parameters: {
      TableName: process.env.REACT_APP_TABLE_NAME,
      KeyConditionExpression: "#pk = :pkvalue",
      IndexName: "SK-PK",
      ExpressionAttributeNames: {
        "#pk": "SK"
      },
      ExpressionAttributeValues: {
        ":pkvalue": { S: "DDOPTIONS" }
      }
    },
    onSuccess: (data, dispatch) => {
      return {
        payload: data?.items?.map?.((item) => ({
          [item?.name]: item?.options
        })),
        type: LOAD_ALL_OPTIONS
        // lastEvalKey: data?.lastEvalKey,
      }
    },
    onFailure: (err) => {
      printError("loadAllDdOptions()", err)
      // showMagicToaster({title: err})

      return {
        type: "a"
      }
    }
  })
export const loadTestPdf = () =>
  apiAction({
    endPoint: "/product/relation/export",
    method: "POST",
    responseType: "blob",
    data: {
      format: "PDF",
      language: "RO",
      entityCode: "productRelation",
      queryCriteria: {
        firstRow: 0,
        pageSize: 10,
        filters: [],
        searchTerm: null,
        jsonLogic: null
      },
      columns: [
        "mainProductCode",
        "mainProductName",
        "mainProductBrand",
        "mainProductUnit",
        "mainProductStatus",
        "mainProductPriceNetMin",
        "mainProductPriceNetMax",
        "mainProductCurrency",
        "relationType",
        "relatedProductCode",
        "relatedProductName",
        "relatedProductBrand",
        "relatedProductUnit",
        "relatedProductStatus",
        "relatedProductPriceNetMin",
        "relatedProductPriceNetMax",
        "relatedProductCurrency"
      ]
    },
    onSuccess: (data, dispatch) => {
      const downloadLink = window.document.createElement("a")
      downloadLink.href = window.URL.createObjectURL(
        new Blob([data], { type: "application/pdf" })
      )
      downloadLink.download = "testfile.pdf"
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
      return {
        type: "a"
      }
    },
    onFailure: (data, dispatch) => {
      printError("loadTestPdf()", data)
      //toastr.error(".......")
      return {
        type: "a"
      }
    }
  })

export const loadInvoice = (nr, id) =>
  apiAction({
    endPoint: `/client-invoice/report-export/${id}`,
    method: "GET",
    responseType: "blob",
    // data: {
    //   "name" : "Factura",
    //   "path" : "Invoice.jrxml",
    //   "parameters": [
    //         {
    //             "name":"Invoice_id",
    //             "type": null,
    //             "subType": null,
    //             "value": id,
    //             "querySearchMethod": null,
    //             "queryValueField": null,
    //             "queryCriteria": null
    //         }
    //     ],
    //   "property" : {
    //         "idx": 1,
    //         "displayName": "Raport_RZB",
    //         "permission": null
    //     }
    // },
    onSuccess: (data, dispatch) => {
      const downloadLink = window.document.createElement("a")
      downloadLink.href = window.URL.createObjectURL(
        new Blob([data], { type: "application/pdf" })
      )
      downloadLink.download = `Factura_${nr}_${moment(Date.now()).format(
        "DD.MM.YYYY"
      )}.pdf`
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
      return {
        type: "a"
      }
    },
    onFailure: (data, dispatch) => {
      printError("loadInvoice()", data)
      toastr.error(
        "Momentan factura nu a putut fi descarcata, incearca mai tarziu"
      )
      return {
        type: "a"
      }
    }
  })
