import { history } from "utils/history"
import { sanitizeAnythingForRedux } from "utils/preventReactInRedux"
// import { API, apiAction } from "store/middleware/api";
// import { toastr } from "react-redux-toastr";
// import { loadDictionary, loadEnums, loadProductCategories } from "store/modules/backData";
// import { getAllProducts } from "store/modules/menu";
export const LOGIN_USER = "LOGIN_USER"
export const SET_USER = "SET_USER"
export const LOGOUT_USER = "auth/LOGOUT_USER"
export const SET_ONBOARDING = "auth/SET_ONBOARDING"
export const SET_API_RESPONSE = "auth/SET_API_RESPONSE"
export const SET_AUTHENTICATED = "auth/SET_AUTHENTICATED"
export const SET_AUTH_TIME_STAMP = "auth/SET_AUTH_TIME_STAMP"

const initialState = {
  authenticated: false,
  token: "",
  idToken: "",
  email: "",
  userData: {},
  apiResponse: null,
  authTimeStamp: null
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, unsanitizedAction) {

  const action = sanitizeAnythingForRedux(unsanitizedAction)

  switch (action.type) {
    case LOGIN_USER:
      return {
        authenticated: true,
        token: action.payload?.AccessToken,
        idToken: action.payload?.IdToken,
        email: action.payload?.email
      }
    // case SET_USER:
    //     return {
    //         ...state,
    //         userData: action.payload
    //     };
    case SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: action.payload
      }
    case SET_USER:
      return {
        ...state,
        userData: action.payload
      }
    case SET_API_RESPONSE:
      return {
        ...state,
        apiResponse: action.payload
      }
    case SET_AUTH_TIME_STAMP:
      return {
        ...state,
        authTimeStamp: action.payload
      }

    // case LOGOUT_USER:
    //     return { ...initialState };

    default:
      return state
  }
}

export const logoutUser = () => {
  // setTimeout(() => {
  //     history?.push?.('/')
  // }, 500)
  return {
    type: LOGOUT_USER
  }
}

export const clearApiResponse = () => {
  return {
    type: SET_API_RESPONSE,
    payload: null
  }
}

export const setApiResponse = (data) => {
  return {
    type: SET_API_RESPONSE,
    payload: data
  }
}
