import { createSelector } from "reselect"
import {
  baseDataViewSelector,
  headerProcessor
} from "store/selectors/dataView/baseDataView.selector"//"views/ImportFromExcel/excelBaseDataView.selector"
import { parseFloatOrZero } from "utils/numberUtils"
import { round } from "utils/serviceFunctions"
import { searchExcelItems } from "utils/stringUtils"


const searchString = (state) => state?.dataView?.checkSearchString

const templateHeader = [
  {
    type: "select",
    label: "Select"
  },
  {
    label: "Detalii produs",
    type: "cartProductDetails",
    // urlPrefix: "/shop/orders/details?id=",
    keys: {
      // title: "code",
      // subtitle: "date"
    },
    clickNotAllowed: true,
    passAllKeys: true
  },
  {
    label: "Valoare - Cantitate si disponibilitate",
    type: "cartProductCard",
    //urlPrefix: "/shop/orders/details?id=",
    keys: {
      title: "value",
      subtitle: "valueGross"
    },
    staticProps: {
      onlyRight: true,
      cardModalState: "excelCard", //"saveInListCard",
      renderedBy: "importFromExcel"
    },
    passAllKeys: true
  }
]

const multiActions = [
  {
    label: "Adauga in cos",
    action: "addExcelToCart",
    icon: "cartadd clear",
    strokeColor: "text-light-01"
  },
  { label: "Sterge din import", action: "deleteFromExcel", icon: "trash" }
]
export const checkIsLowStock = (i) => {
  if(parseFloatOrZero(i?.quantity) > (i?.centralStockQuantity || 0) + (i?.localStockQuantity || 0)) return true
  if((i?.localStockQuantity || 0) + (i?.centralStockQuantity || 0) === 0)  return true
  return false
}
const listDetailsTableSelector = createSelector(
  baseDataViewSelector,
  searchString,
  (prevSelector, searchString) => {
    let hasErrorLines = false
    let hasFrontZeroPrice = false
    let hasFrontLowStock = false 
    const rows = searchExcelItems(searchString, prevSelector?.rows)?.map?.(
      (i, index) => {
        const isLowStock = checkIsLowStock(i)
        if(hasErrorLines === false && i?.id?.includes?.("incorrect")) hasErrorLines = true  
        if(hasFrontZeroPrice === false && parseFloatOrZero(i?.priceNet) === 0) hasFrontZeroPrice = true  
        if(hasFrontLowStock === false && isLowStock) hasFrontLowStock = true  
        return {
          ...i,
          isWarning: parseFloatOrZero(i?.priceNet) === 0 || isLowStock,
          i,
          // url: undefined,
          title: i?.name,
          productCode: i?.code,
          quantity: i?.quantity || 0,
          quantityForModification: i?.quantity || 0,
          minQuantity: i?.sellQuantityIncrement,
          unit: i?.unitOfMeasure,
          primaryPrice:
            round(i?.priceNet * (i?.quantity ?? 0)) + ` ${i?.currency}`, //mainPrice
          //price: round(item?.priceNet),
          youSave: i?.discount ? `-${i?.discount} %` : "",
          secondaryPrice:
            round(i?.priceGross * (i?.quantity ?? 0)) +
            ` ${i?.currency}` +
            " (cu TVA)",
          primaryImage:
            i?.img ??
            i?.mainImage?.reference ??
            i?.mainImage ??
            i?.attachmentList?.[0]?.reference,
          //stockState: true,
          noStock: !i?.localStockQuantity && !i?.centralStockQuantity,
          // noStock: !item?.localStockQuantity && !item?.centralStockQuantity,
          nullStock:
            i?.localStockQuantity === null &&
            i?.centralStockQuantity === null,
          stockState:
            i?.quantity >
              (i?.centralStockQuantity ?? 0) +
                (i?.localStockQuantity ?? 0) ||
            (i?.localStockQuantity ?? 0) +
              (i?.centralStockQuantity ?? 0) ===
              0
              ? "low"
              : "etwas" //ACHTUNG
          //stockState is used in cart product card to chose proper icon but it is used to detect no stock state
          //as well... that is why I added a random string, to not display in red the quantity
          // ((!item?.localStockQuantity && !item?.centralStockQuantity) ||((item?.localStockQuantity === null) && (item?.centralStockQuantity === null)) ||
        }
      }
    )
    const header = headerProcessor({ ...prevSelector, templateHeader })
    return {
      hasErrorLines,
      hasFrontLowStock,
      hasFrontZeroPrice,
      tableData: { ...prevSelector, templateHeader, rows, header, multiActions }
    }
  }
)

export default listDetailsTableSelector
