import { Link } from "react-router-dom"
import UniLogo from "assets/AboutUniprest/UniLogo.svg"
import footerContent from "../../mockData/footer"
import MagicButton from "components/MagicButton/MagicButtonV3"
import { withLocalize } from "components/Lang/Lang"

import AnpcLogo1 from "assets/legal/AnpcLogo1.png"
import AnpcLogo2 from "assets/legal/AnpcLogo2.png"

import "./index.scss"
import { tryToScrollToTop } from "views/productsList"
import MyLink from "components/MyLink"

const Footer = (props) => {
  const { className, translate } = props
  const { FooterLinks, social } = footerContent
  const filteredFooterLinks = FooterLinks
  
  return (
    <footer data-test-id='PbiZ' className={`footer ${className ?? ""}`}>
      <div data-test-id='HSMV' className="footer-inner">
        <div data-test-id='iY5x' className="leftFooter">
          <Link to={"/"} className={"footer-logo"}>
            <img 
              data-test-id='1BmI'
              onContextMenu={(e) => e?.preventDefault?.()}
              src={UniLogo}
              alt="UniLogo"
            />
          </Link>
          <div data-test-id='XBig' className={"copy"}>
            {translate?.("footer.copyRight")}
            {/* {`${lang("footer.copyRight")} ${new Date().getFullYear()}`}*/}
          </div>
        </div>
        <div data-test-id='aqr8' className="rightFooter">
          <ul data-test-id='tt9X' className="textFooter">
            {filteredFooterLinks?.map?.((fLink, index) => (
              <li data-test-id='6KbB' key={index} className="footerLine">
                <a href={fLink.url} onClick={tryToScrollToTop} target="_blank" rel="noopener noreferrer">
                  {fLink.label}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div data-test-id='iyhU' className="social">
          <div data-test-id='SyRp' className="ptype-5-strong text-light-03">{social}</div>
          <div data-test-id='EOGs' className="flex mt4">
            <MagicButton
              svg="facebook"
              className="socialButton mr2"
              href={"https://www.facebook.com/UniprestInstal"}
              targetBlank
            />
            <MagicButton
              svg="youtube"
              className="socialButton mr2"
              href={"https://www.youtube.com/channel/UCdi1nl8fWeRuQrIzgMgA3XA"}
              targetBlank
            />
            <MagicButton
              svg="linkedin"
              className="socialButton"
              href={"https://www.linkedin.com/company/uniprest-instal-s.r.l./"}
              targetBlank
            />
          </div>
          <div data-test-id='EOGt' className="legalButtons">
            <MyLink to="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage">
              <img src={AnpcLogo1} />
            </MyLink>
            <MyLink to="https://anpc.ro/ce-este-sal/">
              <img src={AnpcLogo2} />
            </MyLink>
          </div>

          <MyLink to="https://uniprest.ro/media/formular_de_raportare_privind_incalcari_ale_legii_uniprest.pdf">
            <p className="ptype-5-strong text-light-03">
              {translate?.('footer.anpcForm')}
            </p>
          </MyLink>

        </div>
        <div data-test-id='gpg8' className={"copy-mobile"}>
          {translate?.("footer.copyRight")}
          {/* {`${lang("footer.copyRight")} ${new Date().getFullYear()}`} */}
        </div>
      </div>
    </footer>
  )
}
export default withLocalize(Footer)
