import { apiAction } from "store/middleware/api"
import { printError } from "utils/printError"
import { setApiResponse } from "store/modules/auth"
import { sanitizeAnythingForRedux } from "utils/preventReactInRedux"

const TOGGLE_LANGUAGE = "user/TOGGLE_LANGUAGE"
const SET_USER_INFO = "user/SET_USER_INFO"
export const RESET_USER_INFO = "user/RESET_USER_INFO"

const initialState = {
  language: "EN",
  id: null,
  groupId: "",
  userId: "",
  name: "",
  organizationId: ""
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, unsanitizedAction) {

  const action = sanitizeAnythingForRedux(unsanitizedAction)

  switch (action.type) {
    case TOGGLE_LANGUAGE:
      return {
        ...state,
        language: state.language === "EN" ? "RO" : "EN"
      }
    case SET_USER_INFO:
      return {
        ...state,
        ...action.payload
      }
    case RESET_USER_INFO:
      /**
       * We don't use this action anymore. We clear the 
       * entire redux store instead in rootReducer.js with 
       * dispatch({ type: LOGOUT_USER })
       */
      return initialState
    default:
      return state
  }
}

export const toggleLanguage = () => ({
  type: TOGGLE_LANGUAGE
})
export const setUserInfo = (data) => ({
    type: SET_USER_INFO,
    payload: data
})

const saveUserData = {
  accessKeyList: [],
  id: null,
  language: "RO",
  loginName: null,
  name: "Uniprest user",
  newLoginName: "uniprest@test.com",
  attachmentList: [],
  avatar: null,
  contactInfo: null,
  department: "TRANSPORT",
  groupIds: ["XlDbrD"],
  organizationId: "0lYvRm",
  phone: null,
  position: "08",
  reminderList: [],
  status: "UserStatus.ACTIVE",
  userDirectory: "INTERN",
  workplace: null
}

export const saveUser = (data = saveUserData) =>
  apiAction({
    endPoint: `/user`,
    method: "POST",
    data,
    onSuccess: (data, dispatch) => {
      dispatch(setApiResponse(data))
      return {
        type: "a"
      }
    },
    onFailure: (error) => {
      printError("saveUser()", error)
      return {
        type: "a"
      }
    }
  })
