import { useSelector } from "store"
import { mapRole } from "utils/lookupFunctions"

export const useUserInfo = () => {
  const user = useSelector((state) => state.user)
  let companyType = useSelector((state) => state.company?.type) ?? "GUEST"
  const roleName = mapRole(user?.groupId)
  if (roleName === "GUEST") companyType = "GUEST"
  return {
    ...user,
    isPublic: roleName === "GUEST",
    companyType,
    adminAccess: adminGroupIds?.includes?.(user.groupId)
  }
}

const adminGroupIds = [
  "4lOKlq",
  "9m18l7",
  "7rq0mP",
  "krwylx",
  "WmGwm7",
  "5l7vZR",
  "0lYOrQ"
]

const allGroups = [
  { id: "JlJeZ8", name: "Guest", type: "ANONYMOUS", code: "GUES" },
  { id: "3ZVWlG", name: "Anonymous", type: "ANONYMOUS", code: "ANONIM" },
  { id: "grK9lA", name: "User Companie", type: "EXTERNAL", code: "UADM" },
  { id: "yZyDr3", name: "Admin Companie", type: "EXTERNAL", code: "CADM" },
  { id: "4lOKlq", name: "BO Sales", type: "INTERNAL", code: "BOSA" },
  { id: "9m18l7", name: "Account Manager", type: "INTERNAL", code: "ACCM" },
  { id: "7rq0mP", name: "Manager Marketing", type: "INTERNAL", code: "MKTM" },
  { id: "krwylx", name: "Sales Manager", type: "INTERNAL", code: "RFPM" },
  { id: "WmGwm7", name: "Business Super User", type: "INTERNAL", code: "BSUR" },
  {
    id: "5l7vZR",
    name: "Administrator de sistem",
    type: "INTERNAL",
    code: "ADMI"
  },
  { id: "0lYOrQ", name: "Manager Region", type: "INTERNAL", code: "ADMI" }
]
