import React, { useEffect } from "react"
import { leftMenu, rightMenu } from "../../mockData/menus"
import MagicButton from "components/MagicButton/MagicButtonV3"
import MB from "components/MB/MB"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router"
import {
  setCollapsedMenu,
  setLeftMenuItems,
  setOnBehalfIdInState,
  setPageEdit
} from "store/modules/page"
import { useSelector } from "store"
import { adminLeftMenu, adminBottomMenu, adminHRLeftMenu, adminMarketingManagerLeftMenu } from "mockData/adminMenu"
import { logoutUser } from "store/modules/auth"
import {
  Lang,
  lang,
  Translate,
  withLocalize,
  getTranslate
} from "components/Lang/Lang"
import { removeJavaToken } from "store/justActions/javaAuth"

export default withLocalize(function SideMenuClosed(props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const { left } = props
  const translate = props.translate
  const collapsedMenu = useSelector((state) => state?.page?.collapsedMenu)
  const leftMenuItems = useSelector((state) => state?.page?.leftMenuItems)
  const isEdit = useSelector((state) => state.page?.isEdit)
  const groupName = useSelector(
    (state) => state.auth?.userData?.role?.groupName
  )
  const userPermissions = useSelector(
    (state) => state.auth?.userData?.role?.permissions)
  const isHrAdmin = userPermissions?.includes?.("WEBSHOP_HR_ADMIN")
  const isMarketingManagerAdmin = userPermissions?.includes?.("WEBSHOP_MARKETING_ADMIN")
  const crtUrl = window.location.pathname
  const url = crtUrl?.split?.("/")
  useEffect(() => {
    // dispatch(setCollapsedMenu(false))
    // if (!leftMenuItems?.[0]) dispatch(setLeftMenuItems(adminLeftMenu))
    // const children = adminLeftMenu?.find?.(item => crtUrl === item?.url)?.children
    // if (children) dispatch(setLeftMenuItems(children))
    // else dispatch(setLeftMenuItems(adminLeftMenu))
      dispatch(setLeftMenuItems(getInitialMenuBasedOnRole(isHrAdmin, isMarketingManagerAdmin)))
  }, [userPermissions])
  const menuButton = (itemIn, index) => {
    const nameString = itemIn?.name ? translate?.(itemIn?.name) : ''
    const item = { ...itemIn, name: nameString }
    return (
      <MagicButton
        key={index}
        h={10}
        square
        outline
        className="text-light-01 selected-primary"
        // p={3}s
        clear
        popLeft={!left ? item?.name : undefined}
        popRight={left ? item?.name : undefined}
        onClick={
          item?.action === "logoutUser"
            ? () => {
              dispatch(setOnBehalfIdInState())
              dispatch(logoutUser())
              // dispatch(removeJavaToken()) check root reducer for changes
            }
            : undefined
        }
        {...item}
      />
    )
  }

  const backButton = {
    name: "Back",
    svg: "back",
    onClick: () => {
      dispatch(setLeftMenuItems(getInitialMenuBasedOnRole(isHrAdmin, isMarketingManagerAdmin)))
    }
  }
  const roForm = leftMenuItems?.find?.((item) =>
    item?.url?.includes?.(url?.slice?.(0, url?.length - 1)?.join?.("/"))
  )?.roForm
  const formButtons = [
    {
      ...backButton,
      onClick: () => history?.push?.(backFromForm())
    },
    {
      name: "View",
      svg: "details",
      url: undefined,
      selected: !isEdit,
      onClick: () => dispatch(setPageEdit(false))
    },
    !roForm && {
      name: "Edit",
      svg: "edit",
      url: undefined,
      selected: isEdit,
      // active: url?.[url?.length-1] === "edit",
      onClick: () => dispatch(setPageEdit(true))
    }
  ]

  let menuEntries
  const section = url?.[1]
  if (section === "shop") {
    if (left) menuEntries = leftMenu
    else menuEntries = rightMenu
  }

  const backFromForm = () => {
    let lastIndex = 1
    if (url[url.length - 2] === "form") lastIndex = 2

    return url?.slice?.(0, url?.length - lastIndex)?.join?.("/") + "/list"
  }
  const getAdminLeftItems = (defaultMenus) => {
    if (url?.[url?.length - 1] === "form" || url?.[url?.length - 2] === "form")
      return formButtons

    return leftMenuItems?.map?.((item) => ({
      ...item,
      url: undefined, //- if undefined, it will be simple button, not Link
      onClick: () => {
        if (item?.svg === "back") {
          dispatch(setLeftMenuItems(defaultMenus))
          setTimeout(() => history?.push?.("/admin/dashboard"), 10)
        } else {
          const children = item?.children
          if (children) dispatch(setLeftMenuItems([backButton, ...children || []]))
          setTimeout(() => {
            if (item?.isExternal) {
              window.location.href = `https://${window.location.host}${item?.url}`;
            } else {
                history?.push?.(item?.url)
            }
          }, 10)
        }
      },
      active: window.location.pathname === item?.url
    }))
  }

  if (section === "admin") {
    if (left)
      menuEntries = {
        topItems: getAdminLeftItems(getInitialMenuBasedOnRole(isHrAdmin, isMarketingManagerAdmin)),
        bottomItems: adminBottomMenu
      }
  }

  return (
    <nav data-test-id='4AFl' className="leftMenu">
      <MB flex dc>
        {menuEntries?.topItems?.map?.(
          (item, index) =>
            !item?.rejectedGroups?.includes?.(groupName) &&
            menuButton(item, index)
        )}
      </MB>
      <div data-test-id='JKhb'>
        {menuEntries?.bottomItems?.map?.((item, index) =>
          menuButton(item, index)
        )}
      </div>
    </nav>
  )
})

const getInitialMenuBasedOnRole = (isHrAdmin, isMarketingManagerAdmin) => {
  if (isHrAdmin) return adminHRLeftMenu
  if (isMarketingManagerAdmin) return adminMarketingManagerLeftMenu
  return adminLeftMenu
}