import SvgIcon from "components/SvgIcon/SvgIcon"
import { useDispatch } from "store"
import { dataViewMultiActions } from "store/modules/dataView/dataViewActions.actions"

export const MultiActionsModal = (props) => {
  const { miltiSelectText } = props
  const dispatch = useDispatch()
  return (
    <div data-test-id='Yh1N' className="multy-select-modal">
      <div data-test-id='TRTI' className="title">Actiuni colective</div>
      <div data-test-id='FfLM' className="selected">{miltiSelectText}</div>
      <div data-test-id='4Ip0' className="line" />
      <div data-test-id='YSlR' className="actions-title">Actiuni</div>
      {props?.multiActions &&
        props.multiActions?.map?.((item, index) => (
          <div 
            data-test-id='fwZ3'
            key={index}
            className="action"
            onClick={() => dispatch(dataViewMultiActions(item?.action))}
          >
            <div data-test-id='DNE9' className={`icon stroke-${item?.strokeColor ?? ""}`}>
              <SvgIcon name={item?.icon} />
            </div>
            <div data-test-id='NtR0' className="name">{item?.label}</div>
          </div>
        ))}
    </div>
  )
}
