import React, { useEffect } from 'react'
import LoadingScreen from 'components/LoadingScreen'
import { useSelector } from 'react-redux'

const FullPageLoadingScreen = () => {

  const apiCallsInProgress = useSelector((state) => state?.apiCallsInProgress)

  const filteredApiCalls = apiCallsInProgress?.filter?.(
    apiCall => 
      apiCall?.endPoint?.includes?.('/basket/address-change')
    )

  const isLoading = (filteredApiCalls?.length || 0) > 0

  useEffect(() => {

    /** The class 'page-content' exists in multiple pages. */
    const body = document?.querySelector?.("body")
    body?.classList?.toggle?.("page-content--loading", isLoading)

  }, [isLoading])


  return isLoading 
    ? <LoadingScreen isLoading={isLoading} isFullPage /> 
    : null

}

export default FullPageLoadingScreen
