import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import useFirestoreRealtimeDb from 'utils/useFirestoreRealtimeDb'
import { randomIntFromInterval } from 'utils/numberUtils'

  
const reloadAfterMillis = randomIntFromInterval(1000, 20000)//1 - 20 sec


/**
 * Does a forced refresh whenever it is triggered from the back-end 
 * (triggered from Firebase RealtimeDB through socket communication).
 */
export const useForcedRefresh = () => {

  const prevValueRef = useRef()

  const changingThisForcesRefresh = useSelector(
    state => state?.firebaseRealtimeDb?.changingThisForcesRefresh
  )
  
  // Responsible for fetching the data from firestore and updating the redux state:
  useFirestoreRealtimeDb() 
  
  useEffect(() => {

    const wasValueKnownUntilNow = Boolean(prevValueRef?.current)
    const didValueJustChange = prevValueRef?.current !== changingThisForcesRefresh

    if(wasValueKnownUntilNow && didValueJustChange && changingThisForcesRefresh) {
      console.log(
        `Doing forced refresh. ` + 
        `Old value: ${prevValueRef?.current}, new value: ${changingThisForcesRefresh}` + 
        `, in ${reloadAfterMillis} milliseconds.`
      )

      /**
       * Delay the forced refresh by n seconds to give 
       * time to the redux persistence to properly persist the value of
       * state.firebaseRealtimeDb.changingThisForcesRefresh
       * 
       * That is needed because after the reload the redux state will be 
       * reinitialized and we need the new value by then. Otherwise,
       * the non-updated value will differ from the one sent by Firebase and
       * the forced refresh will be triggered again (causing an infinite 
       * reload loop).
       */
      setTimeout(() => {
        window.location.reload(forceDisabledCache)  
      }, reloadAfterMillis)
      
    }

    if(prevValueRef) prevValueRef.current = changingThisForcesRefresh
      
  }, [changingThisForcesRefresh])

  return null

}


const forceDisabledCache = true
