import PropTypes from "prop-types"

export default function ProgressBar(props) {
  const {
    min,
    max,
    value,
    percent,
    dangerLimit,
    criticalLimit,
    strokeColor,
    reverseLimits,
    labels
  } = props
  let minValue = min ? min : 0
  let maxValue = max ? max : 100
  let currentPercent = percent
    ? percent
    : calculatePercent(minValue, maxValue, value)

  return (
    <div data-test-id='lf2H' className="pgbar">
      {labels?.title && <div data-test-id='yjYr' className="title-container">{labels.title}</div>}
      <div data-test-id='AfRS' className="base">
        <div 
          data-test-id='zflf'
          className={`level level-color-${getLevelStage(
            currentPercent,
            dangerLimit,
            criticalLimit,
            reverseLimits
          )}`}
          style={{ width: `${currentPercent}%`, backgroundColor: strokeColor }}
        />
      </div>

      {currentPercent && (
        <div data-test-id='fQ5e' className="label-container">
          <div data-test-id='6Plb'>
            {labels?.currentValue &&
              `${labels.currentValue}: ${value ? value : currentPercent + "%"}`}
          </div>
          <div data-test-id='SnlG'>
            {labels?.total && `${labels.total}: ${maxValue}${max ? "" : "%"}`}
          </div>
        </div>
      )}
    </div>
  )
}

function calculatePercent(min, max, value) {
  if (!value) return 0
  if (min >= max) return 100
  return (value / (max - min)) * 100
}

function getLevelStage(
  currentPercent,
  dangerLimit,
  criticalLimit,
  reverseLimits
) {
  if (reverseLimits) {
    return criticalLimit && criticalLimit > currentPercent
      ? 2
      : dangerLimit && dangerLimit > currentPercent
      ? 1
      : 0
  } else {
    return criticalLimit && criticalLimit < currentPercent
      ? 2
      : dangerLimit && dangerLimit < currentPercent
      ? 1
      : 0
  }
}

ProgressBar.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number,
  percent: PropTypes.number, // percent
  dangerLimit: PropTypes.number, // percent
  criticalLimit: PropTypes.number, // percent
  strokeColor: PropTypes.string,
  reverseLimits: PropTypes.bool,
  labels: PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.string,
    maxValue: PropTypes.string
  })
}
