import React from "react"

// import Menu from "assets/MenuIcon/Atom/Icons/Menu.svg";
// import User from ".assets/MenuIcon/Atom/Icons/User.svg";
// import CartEmpty from ".assets/MenuIcon/Atom/Icons/CartEmpty.svg";
// import ALERT from ".assets/MenuIcon/Atom/Icons/ALERT.svg";

import LeftOpenMenu from "components/LeftOpenMenu"
import RightOpenMenu from "components/RightOpenMenu"
import { useState } from "react"
import MagicButton from "components/MagicButton"
import SideMenuClosed from "components/SideMenuClosed/SideMenuClosedV3"
// import Footer from 'components/Footer/Footer';
import MenuSearch from "views/dev/gyula/MenuSearch"
import { useSelector } from "store"
// import { useSelector } from '.store';

import { isIOS } from "react-device-detect"
import { useUserInfo } from "utils/useUserInfo"
import { getInitialsFromName } from "utils/stringUtils"
import HomePageLogoButton from "components/customButtons/HomePageLogoButton"
import { Lang, lang, Translate, withLocalize } from "components/Lang/Lang"
function AdminLayout({ children, translate }) {
  const [showLeftMenu, setShowLeftMenu] = useState(false)
  const [showRightMenu, setShowRightMenu] = useState(false)
  const [showHamburger, setShowHamburger] = useState(false)
  const { isPublic, name } = useUserInfo()

  const shoppingCart = useSelector((state) => state.shop.cart)
  const userFullName = useSelector((state) =>
    state?.user?.name?.split?.(" ")?.slice?.(-1)?.join?.(" ")
  )
  // const cart = useSelector(state => state.shop.cart)
  const userPermissions = useSelector(
    (state) => state.auth?.userData?.role?.permissions
  )
  const isHrAdmin = userPermissions?.includes?.("WEBSHOP_HR_ADMIN")
  const isMarketingManagerAdmin = userPermissions?.includes?.("WEBSHOP_MARKETING_ADMIN")
  const restrictedAdminRole = isHrAdmin || isMarketingManagerAdmin
  const leftHandler = (e) => {
    setShowLeftMenu((e) =>  restrictedAdminRole ? e : !e)
    setShowRightMenu(false)
    setShowHamburger((e) => restrictedAdminRole ? e : !e)
  }

  const rightHandler = (e) => {
    setShowRightMenu((e) => !e)
    setShowLeftMenu(false)
    setShowHamburger(false)
  }

  return (
    <div data-test-id='L5br' className={`app theme-default ${isIOS ? "ios" : ""}`}>
      <header data-test-id='WKUw' className="topMenu">
        <div data-test-id='YCWj' className="left">
          <button 
            data-test-id='zPkb'
            type="button"
            name="hamburger"
            className={`hamburger ${showHamburger ? "active" : ""}`}
            onClick={(e) => leftHandler(e)}
          >
            <span data-test-id='bKdl' />
          </button>
          <HomePageLogoButton shouldNotRenderAsLink={restrictedAdminRole}/>
        </div>
        { !restrictedAdminRole &&
          <MenuSearch
            palceholder={`${translate?.("menusearch.placeholder")} ${userFullName}`}
          />
        }
        <nav data-test-id='TNXs' className="right">
          { !restrictedAdminRole &&
            <>
              <MagicButton
                  h={10}
                  // p={3}
                  //svg={shoppingCart?.length ? 'cart not empty' :  "cartEmpty" }
                  svg="tasklistk3" //??
                  badge={shoppingCart?.length ? shoppingCart?.length : undefined}
                  type="clear-green"
                  url="/shop/cart"
                  activeColor="green"
                  className="hideOnSmall"
              />
              
              {/* 
              
                This entire bell icon button (aka alert or notification)  
                has been deactivated as per the request of the client.
                
                <MagicButton
                  h={10}
                  svg={"bell"}
                  type="simple"
                  url="/shop/alerts"
                  className="hideOnSmall"
                />
               
               */}
            </>
          
          }
          {isPublic && (
            <MagicButton
              h={10}
              // p={3}
              svg={"User"}
              type="grey"
              onClick={(e) => rightHandler(e)}
            />
          )}
          {!isPublic && (
            <div data-test-id='sV8C' className="logged-in-button" onClick={(e) => rightHandler(e)}>
              <div data-test-id='9nIv' className="inner-circle">{getInitialsFromName(name)}</div>
            </div>
          )}
        </nav>
      </header>
      <SideMenuClosed left />
      <SideMenuClosed right />
      <LeftOpenMenu visible={showLeftMenu} className={"shop"} leftHandler={leftHandler}/>
      <RightOpenMenu visible={showRightMenu} className={"shop"} />
      <div data-test-id='hWnf' className="pageContent page-flex-column">
        <main data-test-id='aazN' className="">{children}</main>
        {/* <main data-test-id='O4gu'>{children}</main>
        <Footer /> */}
      </div>
    </div>
  )
}

export default withLocalize(AdminLayout)
