
const START_API_PROGRESS = "apiCallInProgress/start"
const STOP_API_PROGRESS = "apiCallInProgress/stop"


const initialState = []

export default function (state = initialState, action) {

  const payload = action?.payload || {}
  // const { endpoint, apiCallRandomId } = action?.payload || {}

  switch (action.type) {

    case START_API_PROGRESS:
      return [...state || [], payload]

    case STOP_API_PROGRESS:
      return state?.filter?.(apiCall => apiCall?.apiCallRandomId !== payload)

    default:
      return state
  }
}


export const apiLoadingStarted = data => ({ type: START_API_PROGRESS, payload: data })
export const apiLoadingEnded = data => ({ type: STOP_API_PROGRESS, payload: data })
