import nr2 from "assets/Career/nr2.svg"
import nr3 from "assets/Career/nr3.svg"
import nr4 from "assets/Career/nr4.svg"
import { sanitizeAnythingForRedux } from "utils/preventReactInRedux"

export const cariereList = [
  {
    title: "Cariere",
    text: "Titlu loc de munca numarul 2",
    image: nr2,
    url: "/shop/article"
  },
  {
    title: "Cariere",
    text: "Titlu loc de munca numarul 3",
    image: nr3,
    url: "/shop/article"
  },
  {
    title: "Cariere",
    text: "Titlu loc de munca numarul 4",
    image: nr4,
    url: "/shop/article"
  }
]

const initialState = {
  cariere: [...cariereList || []]
}

export default function (state = initialState, unsanitizedAction) {

  const action = sanitizeAnythingForRedux(unsanitizedAction)

  switch (action.type) {
    default:
      return state
  }
}
