import SvgIcon from "components/SvgIcon/SvgIcon"
import { useDispatch } from "react-redux"
import { dataViewActionClicked } from "store/modules/dataView/dataViewActions.actions"

export const mockAvatars = [
  {
    id: 1,
    label: "A!",
    url: "#",
    src: ""
  },
  {
    id: 2,
    label: "AA",
    url: "#",
    src: "https://avatarfiles.alphacoders.com/186/thumb-186939.jpg"
  },
  {
    id: 3,
    label: "UL",
    url: "#",
    src: ""
  },
  {
    id: 4,
    label: "GY",
    url: "#",
    src: ""
  },
  {
    id: 5,
    label: "OS",
    url: "#",
    src: ""
  },
  {
    id: 6,
    label: "BL",
    url: "#",
    src: ""
  },
  {
    id: 7,
    label: "KO",
    url: "#",
    src: ""
  },
  {
    id: 8,
    label: "UG",
    url: "#",
    src: ""
  },
  {
    id: 9,
    label: "UC",
    url: "#",
    src: ""
  },
  {
    id: 10,
    label: "UM",
    url: "#",
    src: ""
  },
  {
    id: 11,
    label: "UB",
    url: "#",
    src: ""
  },
  {
    id: 12,
    label: "KO",
    url: "#",
    src: ""
  }
]

const CircleAvatarsCell = (props) => {
  const { addMore, avatars, row, onAdd } = props
  const dispatch = useDispatch()

  const addMoreAction = () =>
    dispatch(dataViewActionClicked(row.id, "avatar-addMore"))

  const addAvatar = (
    <button data-test-id='tcZV' className="circle add" onClick={onAdd ? onAdd : addMoreAction}>
      <SvgIcon name="user" />
      <span data-test-id='nBlj' className="icon" />
    </button>
  )

  const avatar = ({ id, url, src, label }) => (
    <div data-test-id='OQ50' key={id} className={`circle ${label?.[0] === "+" ? "grey" : ""}`}>
      {url && src ? (
        <a data-test-id='WsCi' href={url}>
          <img 
            data-test-id='5Esd'
            onContextMenu={(e) => e?.preventDefault?.()}
            src={src}
            alt="Avatar"
          />
        </a>
      ) : (
        <span data-test-id='cT11'>{label}</span>
      )}
    </div>
  )

  return (
    <div 
      data-test-id='JerQ'
      className={`circle-avatars-cell ${
        props.className ? props.className : ""
      }`}
    >
      <div data-test-id='6Bf5' className="line">
        {addMore && addAvatar}
        {avatars?.map?.((item) => avatar({ ...item }))}
      </div>
    </div>
  )
}
export default CircleAvatarsCell
