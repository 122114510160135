
/**
 * Every product has stock in the local deposit and the central deposit. 
 * These are two different cities. The productList API returns the codes 
 * of these cities. This function finds the names of these cities in the
 * localization data. 
 * 
 * For example, the 'warehouseName.021' translates to 'București'.
 * 
 * If the localization fails, we return 'local' or 'central' instead.
 */
export const getDepositNames = ({ product, translate }) => {

  const localizationKeys = {
    local: `warehouseName.${product?.localWorkplaceCode}`,
    central: `warehouseName.${product?.centralWorkplaceCode}`
  }

  const depositNamesMaybe = {
    // Called 'maybe', because the localization might fail. 
    // Avoiding translation attempt if the code is falsy.
    local: product?.localWorkplaceCode ? translate?.(localizationKeys?.local) : null,
    central: product?.centralWorkplaceCode ? translate?.(localizationKeys?.central) : null
  }

  // Check if the localization failed, and if it did, use 'local' or 'central' instead.
  // The localization failed if the result is the same as the input.
  const didLocalizationsFail = {
    local: !depositNamesMaybe?.local || localizationKeys?.local === depositNamesMaybe?.local,
    central: !depositNamesMaybe?.central || localizationKeys?.central === depositNamesMaybe?.central
  }

  const depositNamesFinal = {
    local: didLocalizationsFail?.local ? 'local' : depositNamesMaybe?.local,
    central: didLocalizationsFail?.central ? 'central' : depositNamesMaybe?.central
  }

  return depositNamesFinal
}
