import "./LoadingScreen.scss"

export default function LoadingScreen({ isFullPage = false }) {
  return (
    <div data-test-id='abRB' className={`loader-screen ${isFullPage ? 'fullPageLoader' : ''}`}>
      <div data-test-id='zLaT' className="lds-roller">
        <div data-test-id='PMFe'/><div data-test-id='FkHW'/><div data-test-id='VUPt'/><div data-test-id='eDM7'/><div data-test-id='IS9a'/><div data-test-id='Tskr'/><div data-test-id='kNrQ'/><div data-test-id='QNDw'/>
      </div>
    </div>
  )
}
