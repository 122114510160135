import PropTypes from "prop-types"
import SvgIcon from "components/SvgIcon/SvgIcon"

export default function TagCell(props) {
  const {
    label,
    onIconClick,
    backgroundColor,
    iconColor,
    iconName,
    fontColor,
    disabled,
    value,
    fullSize,
    bold,
    heightBase,
    widthBase,
    row
  } = props

  const isTiny = iconName === undefined
  const bgColorClass =
    !disabled && backgroundColor ? "tag-bg-" + backgroundColor : ""
  const fontColorClass = fontColor ? "tag-color-" + fontColor : ""
  const iconColorClass = disabled
    ? "tag-disabled"
    : iconColor
    ? "tag-fill-" + iconColor
    : ""
  const sizeClass = onIconClick ? "medium" : "tiny"
  const tagIconName = iconName ? iconName : "closeK3"
  const boldClass = bold ? "bold-font" : ""

  function iconClickHandler(data) {
    if (onIconClick && !disabled) onIconClick(data)
  }
  if (isTiny && !value) return null

  return (
    <div data-test-id='qtPc' className="flex column">
      {row?.isSmallScreen && (
        <div data-test-id='9Ex5' className="teable-header default">{label}</div>
      )}
      <div 
        data-test-id='J7om'
        className={`tag-wrapper ${bgColorClass} ${sizeClass} ${
          fullSize ? "full-size" : ""
        }`}
      >
        {value && (
          <div data-test-id='nFUk' className={`${fontColorClass} ${boldClass}`}>{value}</div>
        )}
        {!isTiny && (
          <div 
            data-test-id='hsRR'
            className="icon-container"
            onClick={() => iconClickHandler(value)}
          >
            <SvgIcon
              h={heightBase ?? null}
              w={widthBase ?? null}
              name={tagIconName}
              className={`tag-icon ${iconColorClass}`}
            />
          </div>
        )}
      </div>
    </div>
  )
}

TagCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  backgroundColor: PropTypes.string,
  fontColor: PropTypes.string,
  iconColor: PropTypes.string,
  onClose: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  row: PropTypes.object
}
