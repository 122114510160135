// import SvgIcon from "components/SvgIcon/SvgIcon"

import { ReactComponent as ReviewStarEmpty16 } from "assets/icons/Review_star_empty_16.svg"
import { ReactComponent as ReviewStarFull16 } from "assets/icons/Review_star_full_16.svg"
// import { ReactComponent as ReviewStarHalf16} from "assets/icons/Review_star_half_16.svg"

const Rating5StarsCell = (props) => {
  const { value } = props
  return (
    <div data-test-id='vjDK'>
      {[1, 2, 3, 4, 5]?.map?.((star) =>
        value >= star ? <ReviewStarFull16 /> : <ReviewStarEmpty16 />
      )}
    </div>
  )
}

export default Rating5StarsCell
