import React from "react"
import "./index.scss"
import { useSelector, useDispatch } from "react-redux"
import { useState } from "react"
import { getCart } from "store/modules/shop"
import BreadCrumbs from "components/BreadCrumbs/BreadCrumbsPremium"
import HorizontalFilterUniprest from "views/dev/select/HorizontalFilterUniprest"
import MagicButton from "components/MagicButton/MagicButtonV2"
import Close from "assets/general/Close.svg"
import { useEffect } from "react"
import { setPageModal } from "store/modules/page"
import useMagicForm from "utils/useMagicForm"
import * as yup from "yup"
import MagicTable from "components/MagicTable/MagicTable"
import listDetailsTableSelector from "views/ImportFromExcel/tableSelector"
import { addDataItems, clearDataViewItems, resetCheckSearchString, setDataViewId, setImportedDataFromExcel } from "store/modules/dataView"
import { ReactComponent as IlluEmptyList } from "assets/_illustrations/emptyListIllu.svg"
import BigModal from "components/_modals/BigModal"
import ModalListCard1 from "components/_modals/listCards/ModalListCard1"
import ListModalV2 from "components/_modals/ListModalV2"
import { addMultiProductsToList } from "store/justActions/lists"
import { toastr } from "react-redux-toastr"
import { showMagicToaster } from "utils/MagicToasterCall"
import { saveApiItem } from "store/justActions/saveApiIems"
import store from "store"
import { loadItem } from "store/justActions/loadApiIems"
import SvgIcon from "components/SvgIcon/SvgIcon"
import { Lang, lang, Translate, withLocalize } from "components/Lang/Lang"
import BasketErrorModal from "components/_modals/BasketErrorModal"
import { printError } from "utils/printError"
import useQueryStringFilters from "utils/useQueryStringFilters"
import { parseFloatOrZero } from "utils/numberUtils"


const filterIds = ["FLT-SEARCH01"]
// const filterIds = ["FLT-SEARCH01", "FLT-sortart", "FLT-sort0cmp", "FLT-SEARCH01", "FLT-sort00p1", "FLT-sort0prc", "FLT-sort0ord", "FLT-tipClient"]
export const filterConditions = (i) => {
  if(i?.id?.includes?.("incorrect")) return false 
  if(i?.stockState === "low") return false 
  if (
    (i?.quantity > (i?.centralStockQuantity ?? 0) + (i?.localStockQuantity ?? 0)) ||
    (i?.localStockQuantity ?? 0) + (i?.centralStockQuantity ?? 0) === 0
    ) return false
  if(!i?.priceNet > 0) return false
  if(!i?.quantity > 0) return false
  return true
}
const escapeSlashesInProductCode = (code) => {
  if(!code?.includes?.("/")) return code
  return code?.replaceAll("/", "%2F")
}
const RegisteredListDetails = (props) => {
  
  const dispatch = useDispatch()
  const {
    setSearchString, 
  } = useQueryStringFilters()
  function checkDuplicates(products) {
    const duplicatedCodes = {}
    const markedDuplicated = products?.map?.(product => {
      if(!duplicatedCodes?.[product?.productCode]) {
        duplicatedCodes[product?.productCode] = product?.quantity
        return product
      } else {
        return {...product, duplicated: true}
      }
    })
    return markedDuplicated
  }
  const translate = props?.translate
  const pasteFunction = (event) => {
    const paste = (event?.clipboardData || window?.clipboardData)
      ?.getData("text")
      ?.split?.("\n")
      ?.slice?.(0, -1) //"\n" adds an additional empty line
    //console.log("RAW PASTE ", (event?.clipboardData || window?.clipboardData)?.getData("text"));
    //console.log("EXCEL PASTE ", paste);

    let allGood = true
      //if no data provided
    if(paste?.length === 0) allGood = false
    paste?.forEach?.(rowFromExcel => {
      if (
        !rowFromExcel?.includes?.("\t")
        || rowFromExcel?.split?.("\t")?.length > 2
      ) {
        allGood = false
        return
      }
    })
    const products = []
    if (allGood) {
      const filteredLines = paste?.filter?.(line =>
        line !== "" && line !== "\t" && line !== "\r")
      filteredLines?.forEach?.((product, i) => {
        const helper = product?.split?.("\t")
        const productCode = helper?.[0]
        const quantity = helper?.[1]//?.replaceAll?.(/[^0-9.,0-9]/g, "")
        // if (!productCode || !quantity) {//NOT NECESSARY BUT LEFT HERE FOR NOW
        //   toastr.error("Aţi introdus produse fără nume sau cantitate")
        //   return
        // }
        if (productCode) {// & quantity
          products?.push?.({
            productCode,
            quantity
          })
        }
      })
      const productsDuplicatedMarked = checkDuplicates(products)
        // console.log("PRODUCT ", product);
        // console.log("DUPLICATED1 ", productsDuplicatedMarked);
      productsDuplicatedMarked?.forEach?.(i => {
        if (i?.duplicated)
          store.dispatch(
            setImportedDataFromExcel({
              name: ` Produsul ${i?.productCode} există deja pe lista importată.`,
              id:
                "incorrect" +
                Math.floor((1 + Math.random()) * 0x10000)
                  ?.toString?.(16)
                  ?.substring?.(1)
            })
          )
        else if (parseFloatOrZero(i?.quantity?.replaceAll(",",".")) === 0 &&
          i?.quantity && parseFloat(i?.quantity) !== 0) {
          store.dispatch(
            setImportedDataFromExcel({
              name: ` Cantitatea la produsul ${i?.productCode} e incorectă.`,
              id:
                "incorrect" +
                Math.floor((1 + Math.random()) * 0x10000)
                  ?.toString?.(16)
                  ?.substring?.(1)
            })
          )
        }
        else if (!(parseFloatOrZero(i?.quantity?.replaceAll(",",".")) === 0)) //{
        //   store.dispatch(
        //     setImportedDataFromExcel({
        //       // name: ` Cantitatea la produsul ${i?.productCode} este incorectă sau 0.`,
        //       name: ` CANTITATEA ESTE INCORECTĂ LA PRODUSUL:  ${i?.productCode}: ai introdus "${i?.quantity}"`,
        //       id:
        //         "incorrect" +
        //         Math.floor((1 + Math.random()) * 0x10000)
        //           ?.toString?.(16)
        //           ?.substring?.(1)
        //     })
        //   )
        // }
        // else
          store.dispatch(
            loadItem(
              "product/goods/shop/code",
              escapeSlashesInProductCode(i?.productCode),
              true,
              true,
              false,
              i?.quantity?.replaceAll?.(/[^0-9.,0-9]/g, ""),
              i?.quantity?.replaceAll?.("\n", "")?.replaceAll?.("\r", ""),
            )
          )
      })
    } else {
      //toastr.error("Structura datelor importate este incorectă!")
      toastr.error(translate?.("importexceldetails.dataStructure"))
    }
  }
  useEffect(() => {
    document?.getElementById?.("pasteHere")?.addEventListener?.("paste", pasteFunction)
    return () => {
        document?.getElementById?.("pasteHere")?.removeEventListener?.("paste", pasteFunction)
    }
  }, [])
  useEffect(() => {
    dispatch(setDataViewId())
    dispatch(clearDataViewItems())
    setSearchString("")
    dispatch(resetCheckSearchString(""))
  }, [])
  const [selectedProductsToAdd, setSelectedProductsToAdd] = useState([])
  const pageModal = useSelector((state) => state.page?.modal)
  const { tableData, hasErrorLines,hasFrontLowStock, hasFrontZeroPrice } = useSelector((state) => listDetailsTableSelector(state))
  //console.log("TABLE DATA ", tableData)
  const productsToExcelModalProps = {
    title: <Lang value="importexceldetails.addList" />,
    placeholder: translate?.("importexceldetails.searchProduct"),
    itemsType: "product/goods/shop",
    selectionLabel: ["produs selectat", "produse selectate"],
    //formatAdapter,
    label: <Lang value="importexceldetails.selectProduct" />,
    //value:
    onChange: (e) => setSelectedProductsToAdd(e)
  }
  //console.log(selectedProductsToAdd)
  const addProductsToExcel = () => {
    dispatch(addDataItems( selectedProductsToAdd ))
  }
  const addAllToCart = () => {
    // let error = false

    // tableData?.rows?.forEach?.((item) => {
    //   if (
    //     item?.quantity === 0
    //     || item?.quantity >
    //         (item?.centralStockQuantity || 0) + (item?.localStockQuantity || 0)
    //     || !item?.priceGross
    //   )
    //     error = true
    //   })

    if (tableData?.rows?.length) {// && !error
      if(hasFrontLowStock || hasFrontZeroPrice) 
        showMagicToaster({
          type: "warning",
          title: translate?.("importexceldetails.frontNoPriceOrStock"),
          timeOut: 5000,
        })
      if(hasErrorLines && !hasFrontLowStock && !hasFrontZeroPrice)
        showMagicToaster({
          type: "warning",
          title: translate?.("importexceldetails.frontHasErrorLines"),
          timeOut: 5000,
        })
      dispatch(
        saveApiItem({
          type: "basket/add-item-list",
          data: tableData?.rows
          ?.filter?.(i => filterConditions(i))
          ?.map?.(i => ({goodsId: i?.id, quantity: i?.quantity})),
          onSuccess: () => {
            dispatch(getCart())
            showMagicToaster({
              title: `${
                tableData?.rows?.length === 1 ? (
                  translate?.("importexceldetails.addedProduct")
                ) : (
                  translate?.("importexceldetails.addedProducts")
                )
              } in cos!`,
              timeOut: 5000,
            })
          },
          onFailure: (error) => {
            printError("addAllToCart()", error)
            if (
              error?.response?.data?.localizationCode === "error.offerInBasket"
            )
              dispatch(
                setPageModal({
                  name: "basketErrorOfferShopConflictModal",
                  data: "În coș se găsesc deja produs(e) adăugate dintr-o ofertă. Finalizează comanda, sau golește coșul ca să poți adăuga produse din magazin."
                })
              )
            else if(error?.response?.data?.localizationCode === "error.notEnoughStock")
              showMagicToaster({
                type: "error",
                title: translate?.("importexceldetails.haveNoStock"),
                timeOut: 5000,
              })
            else if(error?.response?.data?.localizationCode === "error.productNoPrice")
              showMagicToaster({
                type: "error",
                title: translate?.("importexceldetails.haveNoPrice"),
                timeOut: 5000,
              })
            else
              showMagicToaster({
                type: "error",
                title: translate?.("importexceldetails.generalBEError"),
                timeOut: 5000,
              })
            return {
              type: "a"
            }
          }
        })
      )
    } 
    else {
      toastr.error(translate?.("importexceldetails.noValidProducts"))
    }
  }

  return (
    <div data-test-id='AenA' className="list-details-wrapper">
      <div data-test-id='po2u' className={`delivery-notification-card`}>
        <div data-test-id='MSba' className="mr4 alert-blue">
          <SvgIcon name={"warning"} />
        </div>
        <div data-test-id='UX2y' className={`content-container`}>
          <div data-test-id='gS9w'>
            <span data-test-id='nC2g'>
              <strong data-test-id='7xmN'>
                <Lang value="importexceldetails.faceImport" />{" "}
              </strong>
            </span>
            <br data-test-id='kvTR'></br>
            <br data-test-id='VB9a'></br>

            <span data-test-id='f7BD' className="color-white ptype-5">
              <Lang value="importexceldetails.steps" />{" "}
            </span>
            <br data-test-id='nRxp'></br>
            <span data-test-id='kDYU' className="color-white ptype-5">
              <Lang value="importexceldetails.useColumns" />{" "}
            </span>
            <br data-test-id='oANq' />
            <span data-test-id='SAj0' className="color-white ptype-5">
              <Lang value="importexceldetails.select2Columns" />
            </span>
            <br data-test-id='eV9v'></br>
            <span data-test-id='LKdC' className="color-white ptype-5">
              <Lang value="importexceldetails.thisScreen" />
            </span>
            <br data-test-id='3lZe'></br>
            <br data-test-id='lh09'></br>
            <span data-test-id='IsF1'>
              <Lang value="importexceldetails.commaWarning" />
            </span>
            <br data-test-id='Y3gu'></br>
            <br data-test-id='3FZI'></br>
            <span data-test-id='jAqh'>
              <Lang value="importexceldetails.case" />
            </span>
          </div>
        </div>
      </div>
      <div data-test-id='fx3c' className="bg-white filter-wrapper">
            <HorizontalFilterUniprest
              noMargins
              {...{ filterIds: filterIds }}
              wideSearch
            />
            { tableData?.rows?.length > 0 &&
            <>
            <span data-test-id='j8zr' className="ptype-5 total-imported">
              <strong data-test-id='i2zI'>
                <Lang value="importexceldetails.totalProducts" />{" "}
                {
                  tableData?.rows?.filter?.(
                    (product) => !product?.id?.includes?.("incorrect")
                  )?.length
                }
              </strong>
            </span>
            <MagicButton
              wc
              w = "100"
              mr={2}
              svg="cartadd"
              label={<Lang value="importexceldetails.addToCart" />}
              onClick={addAllToCart}
            />
            <MagicButton
              mr={2}
              w = "100"
              svg="addtolist"
              label={<Lang value="importexceldetails.addProduct" />}
              showModal="addProductForExcelModal"
            />
            <MagicButton
              mr={2}
              w = "100"
              svg="trash"
              type="redAlert"
              label={<Lang value="importexceldetails.deleteInport" />}
              onClick={() => {
                dispatch(clearDataViewItems())
                setSearchString("")
                dispatch(resetCheckSearchString(""))
              }}
              className="excel-import-delete-button"
            />
            </>}
          </div>
      <div data-test-id='L7Du' id="pasteHere">
        {tableData?.rows?.length ? (
          <>
            
            <div data-test-id='zAUP' className="mt6 p6 bg-white">
              <MagicTable {...tableData} rowHeight={6} noCall/>
            </div>
          </>
        ) : (
          <div data-test-id='xwRS' className="flex column alignCenter mt15">
            <IlluEmptyList />
            <div data-test-id='uYjk' className="mt12 etype-2 color-text-light-01">
              <Lang value="importexceldetails.youDontHaveAProduct" />
            </div>
            <MagicButton
              h={7}
              mt={3}
              label={<Lang value="importexceldetails.addFromShop" />}
              showModal="addProductForExcelModal"
              mr={2}
            />
          </div>
        )}
      </div>
      
      {pageModal === "addProductForExcelModal" && (
        <BigModal
          title={<Lang value="importexceldetails.addProduct" />}
          saveAction={() => {
            addProductsToExcel()
          }}
        >
          <ListModalV2 noModal {...{ ...productsToExcelModalProps }} openIndex>
            <ModalListCard1 apiProduct />
          </ListModalV2>
        </BigModal>
      )}
      <BasketErrorModal />
    </div>
  )
}
export default withLocalize(RegisteredListDetails)
