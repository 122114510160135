import { createSelector } from "reselect"
import { uni_location } from "staticData/dynamoTypes"
// import { mockInvoices } from "mockData/invoices";
import { compare } from "store/selectors/dataView/finalFilters.selector"
// const language = (state) => state.user?.language;
// const customAPIoptions = (state) => state?.options?.myspecialOptions;

const ddOptions = (state) => state?.backData?.ddOptions
const locationOptions = (state) =>
  state?.backData?.dictionary?.LOCATION_RESPONSE
const avaliableJobsOptions = (state) =>
  state?.backData?.dictionary?.JOB_RESPONSE
const uni_locationOptions = (state) =>
  state?.backData?.dictionary?.[uni_location]
const backData = (state) => state?.backData

const orderProcessMethod = [
  { value: "AUTOMATIC", label: "Procesare automata" },
  { value: "MANUAL", label: "Procesare manuala" }
]
const mockOptions1 = [
  { value: "ID1", label: "Option 1" },
  { value: "ID2", label: "Option 2" },
  { value: "ID3", label: "Option 3" },
  { value: "ID4", label: "Option 4" }
]
const mockOptions2 = [
  { value: "ID1", label: "Option 21" },
  { value: "ID2", label: "Option 22" },
  { value: "ID3", label: "Option 23" },
  { value: "ID4", label: "Option 24" }
]
const mockOptionsId = [
  { id: "ID1", name: "Option 21" },
  { id: "ID2", name: "Option 22" },
  { id: "ID3", name: "Option 23" },
  { id: "ID4", name: "Option 24" }
]

const experienceOptions = [
  // { value: 1, label: ' Intership' },
  // { value: 2, label: 'Junior' },
  // { value: 3, label: 'Engineer' }
  { value: 1, label: "Fără experiență" },
  { value: 2, label: "Entry level (<2 ani)" },
  { value: 3, label: "Mid level (2-5 ani)" },
  { value: 4, label: "Senior level (>5 ani)" },
  { value: 5, label: "Manager / Executiv" }
]

const jobAreaOptions = [
  { value: 1, label: "Recepție" },
  { value: 2, label: "Consultant vânzări" },
  { value: 3, label: "Consultant tehnic vânzări" },
  { value: 4, label: "Reprezentant vânzări" },
  { value: 5, label: "Reprezentant vânzări proiecte" },
  { value: 6, label: "Management de risc" },
  { value: 7, label: "Management al datelor" },
  { value: 8, label: "Management al stocurilor" },
  { value: 9, label: "Management de produs" },
  { value: 10, label: "Contabilitate" },
  { value: 11, label: "Resurse umane" },
  { value: 12, label: "Marketing" },
  { value: 13, label: "IT" },
  { value: 14, label: "Lucrător gestionar" },
  { value: 15, label: "Operator logistic" },
  { value: 16, label: "Manipulant marfă depozit" },
  { value: 17, label: "Facturist" },
  { value: 18, label: "Șofer distribuție" },
  { value: 19, label: "Alta" }
]

const contactPurposeOptions = [
  { id: "ID1", label: "Raportez o problema cu o comanda" },
  { id: "ID2", label: "HR" },
  { id: "ID3", label: "Marketing" },
  { id: "ID4", label: "Administration" }
]

const docForm = {
  fieldName: [
    { id: 1, label: "Yes" },
    { id: 0, label: "No" }
  ]
}

const yesNo = [
  { id: 1, label: "Yes" },
  { id: 0, label: "No" }
]
const nuDa = [
  { id: "1", label: "DA" },
  { id: "0", label: "NU" }
]

const status = [
  { id: 1, label: "Arhivat" },
  { id: 0, label: "Nearhivat" }
]
const jobStatus = [
  { value: 1, label: "Draft" },
  { value: 2, label: "Arhivat" },
  { value: 3, label: "Live" }
]

const aplicantStatus = [
  { value: 1, label: "NOU" },
  { value: 2, label: "ARHIVAT" },
  { value: 3, label: "GESTIONAT" }
]

const volumeOptions = [
  { value: "ID1", label: "Intre 20.000 si 49.999 de EUR" },
  { value: "ID2", label: "Intre 49.999 si 99.999 de EUR" }
]

const roleOptions = [
  { value: "ID1", label: "Administrator" },
  { value: "ID2", label: "Editor" }
]

const generalOptions = {
  defualt: mockOptions1,
  ddOnex: mockOptions1,
  yesNo,
  status,
  orderProcessMethod
}

const testForm = {
  select: mockOptions1,
  ddOne: mockOptions2,
  ddMulti: mockOptions1,
  simpleList: mockOptionsId,
  experienta: experienceOptions,
  ariaDeLucru: jobAreaOptions,
  contactPurpose: contactPurposeOptions
}

const productPackForm = {
  pachetInformation: [
    {
      pachetName: "Numele pachet",
      stocmax: 300,
      stocactual: 1120,
      numberOfSales: 380,
      generatedIncome: 2.8,
      inititalDate: "31.August.10",
      expirationDate: "31.December.10",
      remainingDays: 25,
      productPrice: "11.200 - 15.800",
      companiiNumbers: 245
    }
  ]
}

const companyForm = {
  numeScurt: {
    label: "Nume scurt",
    placeholder: "Nume companie "
  },
  numeJuridica: {
    label: "Nume entitate juridica",
    placeholder: "Numele companiei"
  },
  cui: {
    label: "CUI",
    placeholder: "RO 1234568"
  },
  onrc: {
    label: "ONRC",
    placeholder: "J40/21/12.03.2021"
  },
  sediul: {
    label: "Sediul social",
    placeholder: "Adresa sediului"
  },
  administrator: {
    label: "",
    placeholder: "Andrei Popescu"
  },
  email: {
    label: "Unde iti putem scrie?",
    placeholder: "Email"
  },
  telefon: {
    label: "Unde te putem suna?",
    placeholder: "0742111222"
  },
  factura: {
    label: "Factura la termen (din limita de credit)"
    //placeholder: "factura"
  },
  transfer: {
    label: "Plata prin transfer bancar"
    //placeholder: "transfer"
  },
  card: {
    label: "Plata cu card bancar"
    //placeholder: "card"
  },
  bilet: {
    label: "Bilet la ordin / CEC la livrare marfa"
    //placeholder: "card"
  },
  relationManagers: [
    { id: 1, rol: "Manager de Cont", name: "Ion", },
    {
      id: 2,
      rol: "manager de vanzari",
      name: "Adrian",
    },
    { id: 3, rol: "Manager de Cont", name: "Vlad", },
    {
      id: 4,
      rol: "Manager de Vanzari",
      name: "Razvan",
    }
  ]
}

const adreseForm = {
  adresa: {
    label: "Adresa*",
    placeholder: "Adresa* "
  },
  relationManagers: [
    {
      id: 1,
      role: "Suport technic",
      name: "Andrei",
      telNumber: "+40 875 855 510"
    },
    {
      id: 2,
      role: "Suport technic",
      name: "Adrian",
      telNumber: "+40 875 855 510"
    },
    {
      id: 3,
      role: "Suport technic",
      name: "Andrei",
      telNumber: "+40 875 855 510"
    },
    {
      id: 4,
      role: "Suport technic",
      name: "Razvan",
      telNumber: "+40 875 855 510"
    }
  ],
  phoneNumebrs: [
    { id: 1, telNumber: "+40 875 855 510", type: "Telefon general" },
    {
      id: 2,

      telNumber: "+40 875 855 510"
    },
    { id: 3, telNumber: "+40 875 855 510", type: "Telefon general" },
    {
      id: 4,

      telNumber: "+40 875 855 510",
      type: "Livrare de comenzi"
    }
  ]
  // adressList: [
  //   {
  //     id: 1,
  //     title: "Adress title 1",
  //     content:
  //       "Bulevardul Nume Lung si important, nr. 210-212, bl Andromeda, sc Burebista, et Mezanin, ap 4158, Sectorul 2, Bucuresti",
  //   },
  //   {
  //     id: 2,
  //     title: "Adress title 2",
  //     content:
  //       "Bulevardul Nume Lung si important, nr. 210-212, bl Andromeda, sc Burebista, et Mezanin, ap 4158, Sectorul 2, Bucuresti",
  //   },
  //   {
  //     id: 3,
  //     title: "Adress title 3",
  //     content:
  //       "Bulevardul Nume Lung si important, nr. 210-212, bl Andromeda, sc Burebista, et Mezanin, ap 4158, Sectorul 2, Bucuresti",
  //   },
  // ],
}

const mockUserPosition = [
  { id: "07", label: "Consultant vânzări" },
  { id: "08", label: "Coordonator relații clienți" },
  { id: "09", label: "Manager zonal" },
  { id: "10", label: "Reprezentant vânzări" }
]

export const selectFormOptions = createSelector(
  ddOptions,
  locationOptions,
  avaliableJobsOptions,
  uni_locationOptions,
  backData,
  (
    ddOptions,
    locationOptions,
    avaliableJobsOptions,
    uni_locationOptions,
    backData
  ) => {
    const javaDictToOptions = (dict) =>
      backData?.dictionary?.[dict]?.map?.((item) => ({
        id: item?.code,
        label: item?.value
      }))
    const javaUniLocationsToOptions = () =>
      backData?.dictionary?.["ALL_UNI_LOCATIONS"]
    const javaRolesToOptions = (external) =>
      backData?.dictionary?.["ALL_ROLES"]
        ?.filter?.((e) =>
          external
            ? ["grK9lA", "WmGwm7"]?.includes?.(e?.value)
            : !["grK9lA", "WmGwm7"]?.includes?.(e?.value)
        ) //these are the external group ids
        ?.map?.((item) => ({
          id: item?.value,
          label: item?.label
        }))
    const javaInternalRolesToOptions = () =>
      backData?.dictionary?.["INTERNAL_ROLES"]
    const javaExternalRolesToOptions = () =>
      backData?.dictionary?.["EXTERNAL_ROLES"]
        //?.filter?.(e => e?.value !== "JlJeZ8") //not the guest role

        ?.map?.((item) => ({
          id: item?.value,
          label: item?.label
        }))
    const topProductCategory = backData?.productCategory?.map?.((item) => ({
      id: item?.id,
      label: item?.name
    }))
    const allUsers = backData?.dictionary?.API_USERS?.map?.((item) => ({
      id: item?.id,
      label: item?.name
    }))
    const hrUsers = backData?.dictionary?.API_USERS?.filter?.(i => i?.roleName === "HR Manager").map?.((item) => ({
      id: item?.id,
      label: item?.name
    }))
    const orgDict = backData?.dictionary?.ALL_CLIENTS?.map?.((item) => ({
      id: item?.value,
      label: item?.label
    }))
    //handling double unilocation points for dropdowns
    const doublePoints = ["BIROU VÂNZĂRI BUCUREȘTI", "SEDIU SOCIAL TÂRGU MUREȘ"]
    const locationDropdowns = locationOptions
      ?.filter(i => !doublePoints?.includes(i?.name))
      ?.map(i => ({
        value: i?.id,
        label: i?.locationName
      }))
      ?.sort?.(compare)
    

    return {
      allOptions: {
        general: {
          ...generalOptions,
          // uni_location: uni_locationOptions?.map?.((item) => ({
          //   value: item?.PK,
          //   label: item?.values?.nume
          // })),
          brandName: javaDictToOptions("PRODUCT_BRAND"),
          productSupplier: javaDictToOptions("PRODUCT_SUPPLIER"),
          groupIdsExternal: javaExternalRolesToOptions(),
          groupIdsInternal: javaInternalRolesToOptions(), //javaRolesToOptions(false),
          workplaceList: javaUniLocationsToOptions(),
          //reasonOfBanning:
          position: mockUserPosition,
          positionPublic: mockUserPosition,
          productCategory: topProductCategory,
          // position : javaDictToOptions("USER_POSITION"),
          organizationId: orgDict
        },
        generalOptions: { ...generalOptions, ddOptions },
        testForm: {
          ...testForm,
          // avaliableJobs: avaliableJobsOptions?.map?.((item) => ({
          //   value: item?.PK,
          //   // value: item?.values?.avaliableJobs,
          //   label: item?.values?.content?.[0]?.title
          // })),
          uni_location: locationDropdowns,
          // uni_location: locationOptions?.map?.((item) => ({
          //   value: item?.id,//changed for new locations from java
          //   label: item?.name
          // })),
          avaliableJobs: avaliableJobsOptions
            ?.filter?.(
              (i) =>
                i?.values?.expireDate > new Date().toISOString() &&
                i?.values?.postDate < new Date().toISOString()
            )
            ?.map?.((item) => ({
              value: item?.PK,
              // value: item?.values?.avaliableJobs,
              label: item?.values?.content?.[0]?.title
            }))
        },
        aplicantForm: {
          experienta: experienceOptions,
          uni_location: locationDropdowns,
          locationName: locationDropdowns,
          // locationOptions?.map?.((item) => ({
          //   value: item?.id,//changed for new locations from java
          //   label: item?.name
          // })),
          availableJobs: avaliableJobsOptions
            ?.filter?.(
              (i) => i?.values?.expireDate === new Date().toISOString()
            )
            ?.map?.((item) => ({
              value: item?.PK,
              // value: item?.values?.avaliableJobs,
              label: item?.values?.content?.[0]?.title
            })),
          creatDe: backData?.dictionary?.API_USERS?.map?.((i) => ({
            value: i?.id,
            label: i?.name
          })),
          status: aplicantStatus,
          ariaDeLucru: jobAreaOptions
        },
        jobForm: {
          status: jobStatus,
          experienta: experienceOptions,
          uni_location: locationDropdowns,
          name: hrUsers,
          ariaDeLucru: jobAreaOptions,
        },
        contactForm: {
          // uni_location: locationOptions?.map?.((item) => ({
          //   value: item?.id,//changed for new locations from java
          //   label: item?.name
          // })),
          uni_location: locationDropdowns
        },
        accountRequestForm: {
          volume: volumeOptions,
          role: roleOptions
        },
        docForm,
        companyForm: {
          ...companyForm,
          classify: backData?.dictionary?.["ORGANIZATION_CLASSIFY"]?.map?.(
            (i) => ({
              value: i?.code, //changed for new locations from java
              label: i?.value
            })
          )
        },
        adreseForm,
        productPackForm,
        productForm: {
          supplierName: javaDictToOptions("PRODUCT_SUPPLIER")
        }
      }
    }
  }
)
