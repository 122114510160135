import MagicButton from "components/MagicButton/MagicButton"
import MB from "components/MB/MB"
import React from "react"
import CustomPicker from "views/dev/gyula/CustomPicker/CustomPicker"
import { Lang, lang, Translate, withLocalize } from "components/Lang/Lang"
function FastPickerCard(props) {
  const {
    setCardState,
    unitOfMeasure,
    addToCartAction,
    fastOptions,
    selectedQuantity,
    setSelectedQuantity
  } = props
  return (
    <>
      {/* <div data-test-id='Kjmy'> */}
      <MagicButton
        h={6}
        ml={-1}
        mb={1}
        svg="BackArrow"
        type="simple"
        onClick={(e) => {
          setCardState("addToCartCard")
        }}
      />
      <div data-test-id='bcfH' className="ptype-6">
        <Lang value="fastPickerCard.choose" />
      </div>
      <div data-test-id='ewb0' className="ptype-6">
        <Lang value="fastPickerCard.fast" />
      </div>
      <MB mt={3} mb={1} className="ptype-4-price text-light-03">
        <Lang value="productcard.chooseAmount" />
      </MB>
      <CustomPicker
        {...{
          options: fastOptions,
          unit: unitOfMeasure,
          onChange: setSelectedQuantity,
          value: selectedQuantity
        }}
      />
      <div data-test-id='zfHd'>
        <MagicButton
          h={8}
          mt={3}
          p={1}
          label={<Lang value="productcard.addCart" />}
          onClick={() => addToCartAction()}
        />
      </div>
    </>
  )
}

export default FastPickerCard
