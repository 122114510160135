/**
 * @returns a number
 */
export const parseFloatOrZero = (input) => parseFloat(input) || 0


/**
 * @returns a number
 * @throws Error if input cannot be parsed to a number
 */
export const parseFloatOrThrow = (input) => {
  const parsed = parseFloat(input)
  if (isNaN(parsed)) {
    throw new Error(`Could not parse ${input} to float`)
  }
  return parsed
}


export const parseFloatOrDefault = (input, defaultValue = 0) => {
  console.log('parseFloatOrDefault input', input)
  const parsed = parseFloat(input)
  if (isNaN(parsed)) return defaultValue
  return parsed
}



/**
 * @returns a string
 */
export const to2Decimals = (input) => parseFloatOrZero(input)?.toFixed(2)


export const  randomIntFromInterval = (min, max) => 
  Math.floor(Math.random() * (max - min + 1) + min) // min and max included


/**
 * Rounds to the given number of decimals. 
 * @returns a number
 * @throws Error if input cannot be parsed to a number
 */ 
export const roundOrThrow = (input, decimals = 2) => {

  try{
    const inputFloat = parseFloatOrThrow(input)
    if(decimals === 0) return Math.round(inputFloat) 
    
    const roundedString = inputFloat?.toFixed?.(decimals)
    return parseFloatOrThrow(roundedString)

  } catch (e) {
    throw new Error(`Could not parse ${input} to float`)
  }

}