import { onValue, ref } from 'firebase/database'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setFirebaseRealtimeDbFields } from 'store/modules/firebaseRealtimeDb'
import { firebaseRealtimeDbInstance } from 'utils/firebase'


/**
 * Fetches the data from Firebase RealtimeDB and updates the redux state.
 */
const useFirestoreRealtimeDb = () => {
  
  const dispatch = useDispatch()

  useEffect(() => {

    const query = ref(firebaseRealtimeDbInstance)

    return onValue(query, (snapshot) => {
      const data = snapshot?.val?.()

      dispatch(setFirebaseRealtimeDbFields({
        changingThisForcesRefresh: data?.changingThisForcesRefresh,
        isMaintenanceModeOn: data?.isMaintenanceModeOn,
      }))

    })
  }, [])

  
  return null
}





export default useFirestoreRealtimeDb
