import { useEffect } from "react"
// import { setFilterSelection, setMultipleFilterSelection } from "store/modules/dataView"
import useQueryStringFilters from "utils/useQueryStringFilters"

const { useSelector } = require("react-redux")

const useCategoryUrl = (props) => {
  let { customUrl } = props ?? {}
  // const dispatch = useDispatch()
  const { setFilter } = useQueryStringFilters()
  customUrl = processName(customUrl)
  const selectedFilter = []
  const cateogries = useSelector((state) => state.backData?.productCategory)

  useEffect(() => {
    if (customUrl && cateogries?.length > 0) {
      // const topLevelCategories = cateogries?.map?.(item => processName(item?.name))
      //for item in topLevelCategories
      const [topCategory, newUrl] = findCategory(cateogries, customUrl) ?? []
      customUrl = newUrl
      if (topCategory) {
        selectedFilter?.push?.(topCategory)
        const [secondCategory, newUrl] =
          findCategory(topCategory.childList, customUrl) ?? []
        customUrl = newUrl
        if (secondCategory) {
          selectedFilter?.push?.(secondCategory)
          const [thirdCategory] =
            findCategory(secondCategory.childList, customUrl) ?? []
          if (thirdCategory) selectedFilter?.push?.(thirdCategory)
        }
      }

      if (selectedFilter?.length > 0) {
        const filterValue = selectedFilter?.slice?.(-1)?.[0]?.treeCode
        // debugger
        // dispatch(setFilterSelection("FLT-CATEG001", filterValue ))
        setFilter("FLT-CATEG001", [filterValue])
      }
    }
  }, [cateogries])
}

const findCategory = (categories, customUrl) => {
  for (const item of categories) {
    const normalizedName = processName(item?.name)
    if (customUrl?.includes?.(normalizedName)) {
      customUrl = customUrl?.replace?.(`${normalizedName}-`, "")
      return [item, customUrl]
    }
  }
}

const processName = (input) =>
  input
    ?.toLowerCase?.()
    ?.replace?.(/,/g, " ")
    ?.replace?.(/ {2}/g, " ")
    ?.replace?.(/ /g, "-")

export default useCategoryUrl

// Local
// this: undefined
// cateogries: Array(6)
// 0:
// childList: (3) [{…}, {…}, {…}]
// id: "ZyqY8Z"
// name: "AC, VENTILATIE"
// treeCode: "#27398"
