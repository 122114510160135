import { apiAction } from "store/middleware/api"
import { apiStatuses } from "store/middleware/apiStatuses"
import { sanitizeAnythingForRedux } from "utils/preventReactInRedux"
import { printError } from "utils/printError"

const TRANSLATIONS_FETCH_START = "localizationApi/TRANSLATIONS_FETCH_START"
const TRANSLATIONS_FETCH_SUCCESS = "localizationApi/TRANSLATIONS_FETCH_SUCCESS"
const TRANSLATIONS_FETCH_ERROR = "localizationApi/TRANSLATIONS_FETCH_ERROR"
const TRANSLATIONS_CLEAR = "localizationApi/TRANSLATIONS_CLEAR"

const initialState = {
  translations: apiStatuses.getInitial()
}

export default function (state = initialState, unsanitizedAction) {

  const action = sanitizeAnythingForRedux(unsanitizedAction)

  const payload = action?.payload

  switch (action.type) {
    case TRANSLATIONS_FETCH_START:
      return { ...state, translations: apiStatuses.getStarted() }
    case TRANSLATIONS_FETCH_SUCCESS:
      return { ...state, translations: apiStatuses.getSuccess(payload) }
    case TRANSLATIONS_FETCH_ERROR:
      return { ...state, translations: apiStatuses.getError(payload) }
    case TRANSLATIONS_CLEAR:
      return { ...state, translations: apiStatuses.getInitial() }

    default:
      return state
  }
}

export const fetchTranslations = () => (dispatch) => {
  dispatch({ type: TRANSLATIONS_FETCH_START })

  dispatch(
    apiAction({
      endPoint: "/public/localization",
      method: "GET",
      onSuccess: (response) => ({
        type: TRANSLATIONS_FETCH_SUCCESS,
        payload: response
      }),
      onFailure: (error) => {
        printError("fetchTranslations(): ", error)
        return { type: TRANSLATIONS_FETCH_ERROR, payload: error }
      }
    })
  )
}

export const clearTranslations = () => (dispatch) => {
  dispatch({ type: TRANSLATIONS_CLEAR })
}
