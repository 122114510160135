import { toastr } from "react-redux-toastr"

/**
 * TODO: localize these through the regular <Lang/> methodology.
 * (Solve the problem of not having access to withLocalize in redux actions)
 */
const errorTranslations = {
  "error.moreActiveBasket": "Eroare: Aveţi coşuri multiple",
  "error.goodsNotFound": "Eroare: Produsul nu a fost găsit",
  "error.quantityIsNull": "Eroare: cantitatea nu poate fi 0",
  "error.notEnoughStockAccessories":
    "Cel puțin 1 produs dintre accesorii obligatorii nu are stoc.",
  "error.notEnoughStock": "Cel puțin 1 dintre produse nu are stoc.",
  "error.addressNotExist": "Eroare: Adresă inexistentă",
  "error.invalidSalesManager":
    "Eroare: Agent de vânzări inexistent la companie.",
  "error.productNoPrice": "Cel puțin 1 dintre produse nu are stoc.",
  "error.noDeliveryRule": "Eroare: Nu există regulă de livrare setată.",
  "error.offerExpired": "Oferta a expirat! Produsele nu mai pot fi adăugate în coș!" 

  //'error.basketIsNotEmpty': 'În coș se găsesc produs(e) dintr-o altă ofertă sau din magazin. Finalizează comanda, sau golește coșul ca să poți adăuga în coș produse din această ofertă.',
  //'error.offerInBasket': 'În coș se găsesc deja produs(e) adăugate dintr-o ofertă. Finalizează comanda, sau golește coșul ca să poți adăuga produse din magazin.',
}

export const translatedToastr = (errorCode) => {
  const defaultErrorTranslation =
    "A apărut o eroare la adăugarea produsului în coş"
  const errorTranslation =
    errorTranslations?.[errorCode] || defaultErrorTranslation
  if (errorTranslation) toastr.error(errorTranslation)
}
