export const mapOptionsToDynamoList = (list, dynamoOptions, key = "nume") => {
  return list?.map?.(
    (id) => dynamoOptions?.find?.((i) => id === i?.PK)?.values?.[key]
  )
}

export const mapRole = (groupId) =>
  groupId === "3ZVWlG"
    ? "GUEST"
    : // : groupId === 'WmGwm7' ? "GUEST"
      "SYS_ADMIN"

// {
//   ALL_ROLES: [
//     {
//       value: 'yZyDr3',
//       label: 'Admin Companie'
//     },
//     {
//       value: '5l7vZR',
//       label: 'Administrator de sistem'
//     },
//     {
//       value: '3ZVWlG',
//       label: 'Anonymous'
//     },
//     {
//       value: 'WmGwm7',
//       label: 'Business Super User'
//     },
//     {
//       value: '7rq0mP',
//       label: 'Manager Marketing'
//     },
//     {
//       value: 'grK9lA',
//       label: 'User Companie'
//     }
//   ]
// }
export const addId = (items) => {
  items?.forEach?.((e) => {
    if (!e.id) e.id = e?.PK + e?.SK
  })
  return items
}
