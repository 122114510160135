import React, { useEffect, useLayoutEffect } from "react"
import { useHistory } from "react-router"
import SvgIcon from "components/SvgIcon/SvgIcon"
import "./index.scss"
import { leftMenuEntries } from "mockData/menus"
import { useDispatch, useSelector } from "react-redux"
import { toggleLeftMenuVisibility } from "store/modules/guest"
import { frontEndVersion } from "views/dev/ApiCalls"
import { Link } from "react-router-dom"
import { createNewImport, loadImports } from "store/justActions/lists"
import useWindowSize from "utils/useWindowsSize"
import {
  Lang,
  lang,
  Translate,
  withLocalize,
  getTranslate
} from "components/Lang/Lang"

export default withLocalize(function LeftOpenMenu({
  visible,
  leftHandler,
  className,
  translate
}) {
  let containerClass = visible ? "menuOpen left" : "menuOpen left hidden"
  const history = useHistory()
  const dispatch = useDispatch()
  const windowSize = useWindowSize()
  const companyType = useSelector((state) => state.company?.type)
  const authenticated = useSelector((state) => state.auth?.authenticated)
  const userPermissions = useSelector(
    (state) => state.auth?.userData?.role?.permissions
  )

  const filteredTopLeftMenus = leftMenuEntries?.top?.filter?.(menuItem => {
    if(menuItem?.id === "leftEntriesExcel"
      && (!authenticated || windowSize?.width < 768))
        return false
    else if (menuItem?.id === "leftEntriesOfferts"
      && (!authenticated ||
          windowSize.width < 1340 ||
          !userPermissions?.includes?.("CUSTOMER_OFFER_READ")))
            return false
    else return true
  })

  useLayoutEffect(() => {
    if (!visible) dispatch(toggleLeftMenuVisibility(false))
  }, [visible])
  let content = leftMenuEntries.bottom?.map?.((item, index) => {
    return (
      <Link key={index} className="entry button grey"
        onClick={leftHandler}
        to={item?.url}
      >
        <SvgIcon className="stroke-text-light-01" name={item?.svg} />
        <div data-test-id='Ticc' className="text">{translate?.(item?.name)}</div>
      </Link>
    )
  })
  const filteredMenu =
    companyType === "GUEST"
      ? filteredTopLeftMenus
      : filteredTopLeftMenus?.filter?.((i) => i?.id !== "leftEntriesCareers") //"Cariere"
  return (
    <>
      <nav 
        data-test-id='IvZT'
        className={`${containerClass} ${
          className !== undefined ? className : ""
        }`}
      >
        <div data-test-id='kWML' className="entryContainer">
          {filteredMenu?.map?.((item, index) => (
            <div data-test-id='aQIs' key={index}>
                <Link className="entry button"
                  onClick={leftHandler}
                  to={item?.url}
                >
                  {item?.svg ? (
                    <SvgIcon className="stroke-text-light-01" name={item?.svg} />
                  ) : (
                    <img 
                      data-test-id='iCZv'
                      onContextMenu={(e) => e?.preventDefault?.()}
                      className=""
                      alt="logo"
                      src={item?.icon}
                    />
                  )}
                  <div data-test-id='rNov' className="text">{translate?.(item?.name)}</div>
                </Link>
            </div>
          ))}
          {/* <div data-test-id='Tr6L' className="mt10 ml8 grey-20">{frontEndVersion}</div> */}
        </div>
        {/* {companyType !== "GUEST" && (
          <div data-test-id='Pug5' className="entryContainer">{content}</div>
        )} */}
      </nav>
    </>
  )
})
