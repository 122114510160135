import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  //setPageTabs,
  removePageTab,
  selectPageTab
} from "store/modules/pageTabs"
import { useDispatch, useSelector } from "react-redux"
import SvgIcon from "components/SvgIcon/SvgIcon"

function searchTabIndex(tabs, selectedPageId) {
  if (tabs && selectedPageId && Array.isArray(tabs)) {
    return tabs?.findIndex?.((tab) => tab.pageId === selectedPageId)
  }
  return 0 // fallback to the first tabIndex?
}

export default function TabBar(props) {
  const dispatch = useDispatch()
  // const { selectedPageId } = props;

  const tabsState = useSelector((state) => state.pageTabs?.tabs)
  const selectedPageIdState = useSelector(
    (state) => state.pageTabs?.selectedPageId
  )
  const [tabIndex, setTabIndex] = useState(-1)

  function onTabClick(pageId, e) {
    //Hint: skip tab change if:
    //1.) the remove icon or
    //2.) an already selected tab was clicked
    if (
      e?.target?.className?.includes &&
      !e?.target.className?.includes?.("close") &&
      pageId !== selectedPageIdState
    ) {
      dispatch(selectPageTab(pageId))
    }
  }

  function closeBtnHandler(tab) {
    dispatch(removePageTab(tab.pageId))
    if (tab?.onCloseCallBack) tab.onCloseCallBack(tab)
  }

  //Hint: to overrule selected page in redux
  // React.useEffect(() => {
  //   if (selectedPageId){
  //     dispatch(selectPageTab(selectedPageId));
  //   }
  // }, [selectedPageId])

  React.useEffect(() => {
    if (selectedPageIdState) {
      setTabIndex(searchTabIndex(tabsState, selectedPageIdState))
    }
  }, [tabsState, selectedPageIdState])

  return (
    <div data-test-id='2pNi' className="page-tab-bar">
      <div data-test-id='NfMX' className="tabs">
        {tabsState &&
          tabsState?.map?.((tab, index) => {
            return (
              <div 
                data-test-id='G4h1'
                key={index}
                className={`tab ${
                  tab.pageId === selectedPageIdState ? "selected" : ""
                }`}
                onClick={(e) => onTabClick(tab.pageId, e)}
                tabIndex={index + 1}
              >
                {tab.warning && (
                  <div data-test-id='4bHG' className="flex mr2">
                    <SvgIcon name={"warningK3"} />
                  </div>
                )}
                <div data-test-id='7A2b' className="title-container">{tab.title}</div>
                <div 
                  data-test-id='HLrY'
                  className="close-btn-container"
                  onClick={() => closeBtnHandler(tab)}
                >
                  <SvgIcon name={"closeK3"} className={"close-icon"} />
                </div>
              </div>
            )
          })}
      </div>
      {tabsState &&
        tabIndex > -1 &&
        tabIndex < tabsState?.length &&
        tabsState?.[tabIndex]?.content !== undefined &&
        tabsState?.[tabIndex]?.content}
    </div>
  )
}

TabBar.propTypes = {
  selectedPageId: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      pageId: PropTypes.string.isRequired,
      onCloseCallBack: PropTypes.func
    })
  )
}
