import React from "react"
import { Helmet } from "react-helmet"
import { useSelector } from "react-redux"

export const defaultPageTitle = 'Uniprest'
export const defaultOgLocale = 'ro_RO'
export const defaultOgDescription = 'Un partener de incredere pentru firmele de constructii, pentru proiectanti si pentru partenerii nostri re-vanzatori.'

// const defaultmeta = [
//   {property:"og:title", content:"Piesa"},
//   {property:"product:availability", content:"instock"},
// ]

//=================================================================================================
// Data comes from:
//  - 1. default values in this file
//  - 2. per route inserted values in the route file (routes.js and subfiles)
//  - 3. src/store/modules/page.js. populated by REDUX action setPageSeo
//=================================================================================================

const HelmetMetaData = (props) => {

  const { pageMeta, pageTitle } = props

  const pageTitleStore = useSelector((state) => state.page.pageTitle)
  const metaTagsStore = useSelector((state) => state.page.metaTags)

  const metaTagsToRender = metaTagsStore || pageMeta
  const metaKeys = Object?.keys?.(metaTagsToRender || {})


  return (
    <Helmet>
      <title data-test-id='tbyS'>{pageTitle || pageTitleStore || defaultPageTitle}</title>
      {metaKeys?.map?.(metaKey =>
        <meta 
          data-test-id='3qFw'
          key={metaKey}
          name={metaKey}
          content={metaTagsToRender?.[metaKey]}
        />
      )}
    </Helmet>
  )
}

export default HelmetMetaData

// const helmetProps = {
//   title: "Servicii",
//   description: "Servicii",
//   keywords: "Servicii",
//   image: "https://www.uniprest.ro/assets/servicii.jpg"
// }
