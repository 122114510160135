import { isValidElement } from "react"
import * as Sentry from "@sentry/react"


/**
 * Tells if the given component is a React element.
 * Meant to be used to prevent saving React elements in Redux store.
 * Example of a saved React element, as seen saved in Redux:
 * 
 * {
 *   key: null,
 *   ref: null,
 *   props: {
 *     value: 'something something'
 *   },
 *   _owner: null,
 *   _store: {}
 * }
*/
const isReactJsxElement = component => {
  
  if(isValidElement(component)) return true

  if(component?.$$typeof?.toString?.() === 'Symbol(react.element)') 
    return true
    
  if(
    component?.hasOwnProperty?.('_owner') && 
    component?.hasOwnProperty?.('ref') && 
    component?.hasOwnProperty?.('props') && 
    component?.hasOwnProperty?.('key')
  ) return true

  return false

}


/**
 * Turns a React element into a string.
 * Meant to be used to prevent saving React elements in Redux store.
 */
const sanitizeComponentForRedux = input => {
  
  if(!isReactJsxElement(input)) return input

  const errorMessage = 
    'Do not save React elements in Redux store! Tried to save: '

  console.error(errorMessage, input)
  Sentry.captureException(`${errorMessage} ${input}`)
  
  const maybeValue = input?.props?.value
  return maybeValue || null

}



const sanitizeArrayForRedux = input => {
  
  if(!Array.isArray(input)) return input

  return input?.map?.(item => {
    const sanitizedForRedux = sanitizeComponentForRedux(item)
    const sanitizedForAnything = sanitizeAnythingForRedux(sanitizedForRedux)
    return sanitizedForAnything
  })

}

const sanitizeObjectForRedux = input => {
  
  const isObject = 
    typeof input === 'object' &&
    !Array.isArray(input) &&
    input !== null

  if(!isObject) return input


  return Object?.keys?.(input || {})?.reduce?.((prev, key) => {
    
    const value = input?.[key]

    const sanitizedForRedux = sanitizeComponentForRedux(value)
    const sanitizedForAnything = sanitizeAnythingForRedux(sanitizedForRedux)

    return {
      ...prev, 
      [key]: sanitizedForAnything
    }

  }, {})
  
}



export const sanitizeAnythingForRedux = input => {
    
  if(!input) return input

  const sanitizedComponent = sanitizeComponentForRedux(input)

  if(Array.isArray(sanitizedComponent)) 
    return sanitizeArrayForRedux(sanitizedComponent)

  if(typeof sanitizedComponent === 'object') 
    return sanitizeObjectForRedux(sanitizedComponent)

  return sanitizedComponent

}

