import searchnoresults from "assets/iconsK3/SearchNoResults.svg"
import "./NoSearchResults.scss"
import { Lang, lang, Translate, withLocalize } from "components/Lang/Lang"
const NoSearchResults = (props) => {
  const { isOnBehalf, hasAlreadySelectedOnBehalfCompany } = props
  const translate = props.translate
  //const text = "We searched everywhere... <br data-test-id='1kVH'/> <strong data-test-id='fIAo'>However, we could not find anything <br data-test-id='slTk'/> based on your search criteria.</strong>"
  const text = translate?.("productlist.Search")
  const onBehalfText = translate?.("productlist.Search2")
  return (
    <div data-test-id='Xgv8' className="search-not-found">
      <div data-test-id='MfIm' className="wrapper">
        <div data-test-id='hcU1' className="inside-logo">
          <img 
            data-test-id='VAt7'
            onContextMenu={(e) => e?.preventDefault?.()}
            alt="logo"
            src={searchnoresults}
          />
          <p data-test-id='tprF' className="page-not-found-text">
            {" "}
            {(isOnBehalf && !hasAlreadySelectedOnBehalfCompany) ? onBehalfText : text}{" "}
          </p>
        </div>
      </div>
    </div>
  )
}

export default withLocalize(NoSearchResults)
