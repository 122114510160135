// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getDatabase } from "firebase/database"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDHlp7NLCoqcfcpHh3u_ahTC3ZsqUHphss",
  authDomain: "uniprest-magic-live.firebaseapp.com",
  databaseURL: "https://uniprest-magic-live-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "uniprest-magic-live",
  storageBucket: "uniprest-magic-live.appspot.com",
  messagingSenderId: "97829345711",
  appId: "1:97829345711:web:b28316994c8f766a48f75a"
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const firebaseRealtimeDbInstance = getDatabase(app)

