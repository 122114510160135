import MagicButton from "components/MagicButton/MagicButtonV2"
import SvgIcon from "components/SvgIcon/SvgIcon"
import { useDispatch, useSelector } from "react-redux"
import { setPageModal } from "store/modules/page"
import BigModal from "components/_modals/BigModal"
import { isCampaignActive } from "utils/dateUtils"

const defaultMessage = "Esti sigur ca vrei sa finalizezi aceasta actiune?"
function ConfirmActionModal(props) {
  const dispatch = useDispatch()
  const actionToConfirm = useSelector((state) => state.page?.actionToConfirm)
  const modalData = useSelector((state) => state.page?.modalData)
  const {
    message,
    campaignMessage,
    svg,
    svgName,
    closeAction,
    primaryLabel,
    primaryAction,
    secondaryLabel,
    secondaryAction,
    campaignCta,
    campaignButtonLabel
  } = modalData ?? {}
  const isCampaignPeriod = isCampaignActive()
  const onClose = () => {
    dispatch(setPageModal())
    if (closeAction) closeAction()
    if (secondaryAction) secondaryAction()
  }
  const onConfirm = () => {
    if (actionToConfirm) {
      actionToConfirm()
    }
    if (primaryAction) {
      primaryAction()
    }
    dispatch(setPageModal())
  }
  return (
    <BigModal
      noFooter
      name="SYS-confirm-action"
      title=""
      className="sys-confirm-action"
      closeAction={() => setPageModal()}
    >
      {isCampaignPeriod &&
        <span data-test-id='1tBs' className="etype-2 message noBold" dangerouslySetInnerHTML={{ __html: campaignMessage }}></span>
      }
      {svg ?? (svgName ? <SvgIcon name={svgName} /> : defaultIllustration)}
      <span data-test-id='Zvc7' className="etype-2 message">{message ?? defaultMessage}</span>
      <div data-test-id='lYwj' className={`flex ${isCampaignPeriod ? "campaign" : ""}`}>
        <MagicButton
          w={27}
          h={6.5}
          label={secondaryLabel ?? "Anuleaza"}
          outline
          mr={3}
          onClick={onClose}
        />
        {campaignCta && isCampaignPeriod &&
          <MagicButton
            w={27}
            h={6.5}
            label={campaignButtonLabel ?? "Confirm"}
            mr={3}
            aUrl={campaignCta}
            // onClick={() => {campaignCta(); dispatch(setPageModal())}}
          />
        }
        
        <MagicButton
          w={27}
          h={6.5}
          label={primaryLabel ?? "Confirm"}
          onClick={onConfirm}
        />
      </div>
    </BigModal>
  )
}

export default ConfirmActionModal

/* const defaultIllustration = <svg data-test-id='sFPv' width="240" height="240" viewBox="0 0 240 240" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle data-test-id='kNsY' cx="120" cy="120" r="120" fill="#EDF5FF"/>
<path d="M93.3182 109.513H96.5582V123.513H93.3182V109.513ZM99.8807 109.513H103.121V120.873H110.141V123.513H99.8807V109.513ZM111.951 109.513H115.191V120.873H122.211V123.513H111.951V109.513ZM130.066 123.753C128.066 123.753 126.506 123.199 125.386 122.093C124.279 120.986 123.726 119.406 123.726 117.353V109.513H126.966V117.233C126.966 119.739 128.006 120.993 130.086 120.993C131.099 120.993 131.872 120.693 132.406 120.093C132.939 119.479 133.206 118.526 133.206 117.233V109.513H136.406V117.353C136.406 119.406 135.846 120.986 134.726 122.093C133.619 123.199 132.066 123.753 130.066 123.753Z" fill="black"/>
</svg> */

const defaultIllustration = (
  <svg 
    data-test-id='TTqa'
    width="300"
    height="309"
    viewBox="0 0 300 309"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M144.572 30C147.651 24.6667 155.349 24.6667 158.428 30L254.124 195.75C257.203 201.083 253.354 207.75 247.196 207.75H55.8042C49.6458 207.75 45.7968 201.083 48.876 195.75L144.572 30Z"
      fill="#D9D9D9"
    />
    <path
      d="M44 309L231 309L231 179L146.695 179L44 179L44 309Z"
      fill="url(#paint0_linear_17778_347164)"
    />
    <rect data-test-id='xBaS' x="57" y="230" width="12" height="79" rx="6" fill="white" />
    <rect data-test-id='GSSc' x="87" y="252" width="12" height="57" rx="6" fill="white" />
    <rect data-test-id='UX0v' x="117" y="216" width="12" height="93" rx="6" fill="white" />
    <rect data-test-id='RJ4w' x="147" y="263" width="12" height="46" rx="6" fill="white" />
    <rect data-test-id='Y2LR' x="177" y="224" width="12" height="85" rx="6" fill="white" />
    <rect data-test-id='6qLz' x="207" y="238" width="12" height="71" rx="6" fill="white" />
    <path
      d="M128.335 12C131.414 6.66666 139.112 6.66667 142.191 12L245.476 190.894C248.555 196.228 244.706 202.894 238.548 202.894H31.9782C25.8198 202.894 21.9708 196.228 25.05 190.894L128.335 12Z"
      fill="#0175EC"
    />
    <path
      d="M129.013 33.9888C132.092 28.6554 139.79 28.6554 142.869 33.9888L226.823 179.401C229.902 184.734 226.053 191.401 219.895 191.401H51.9876C45.8292 191.401 41.9803 184.734 45.0595 179.401L129.013 33.9888Z"
      fill="white"
    />
    <path
      d="M133.14 129.723C133.14 127.156 133.707 125.034 134.843 123.356C136.027 121.677 137.73 119.827 139.951 117.803C141.58 116.322 142.764 115.088 143.505 114.101C144.294 113.065 144.689 111.905 144.689 110.621C144.689 108.795 143.949 107.364 142.468 106.327C141.037 105.242 139.112 104.699 136.693 104.699C134.374 104.699 132.301 105.192 130.474 106.179C128.698 107.117 127.192 108.45 125.958 110.177L117 104.921C119.073 101.762 121.862 99.3187 125.366 97.5912C128.92 95.8637 133.115 95 137.952 95C143.628 95 148.169 96.2092 151.575 98.6277C155.03 101.046 156.757 104.403 156.757 108.697C156.757 110.72 156.412 112.497 155.721 114.027C155.079 115.557 154.265 116.865 153.277 117.951C152.34 118.988 151.106 120.197 149.576 121.579C147.749 123.208 146.417 124.59 145.578 125.725C144.739 126.811 144.319 128.143 144.319 129.723H133.14ZM138.766 148.306C136.743 148.306 135.04 147.664 133.658 146.381C132.325 145.048 131.659 143.444 131.659 141.568C131.659 139.693 132.325 138.138 133.658 136.904C134.991 135.621 136.693 134.979 138.766 134.979C140.839 134.979 142.542 135.621 143.875 136.904C145.208 138.138 145.874 139.693 145.874 141.568C145.874 143.444 145.183 145.048 143.801 146.381C142.468 147.664 140.79 148.306 138.766 148.306Z"
      fill="#CDCDCD"
    />
    <path
      d="M131.14 128.723C131.14 126.156 131.707 124.034 132.843 122.356C134.027 120.677 135.73 118.827 137.951 116.803C139.58 115.322 140.764 114.088 141.505 113.101C142.294 112.065 142.689 110.905 142.689 109.621C142.689 107.795 141.949 106.364 140.468 105.327C139.037 104.242 137.112 103.699 134.693 103.699C132.374 103.699 130.301 104.192 128.474 105.179C126.698 106.117 125.192 107.45 123.958 109.177L115 103.921C117.073 100.762 119.862 98.3187 123.366 96.5912C126.92 94.8637 131.115 94 135.952 94C141.628 94 146.169 95.2092 149.575 97.6277C153.03 100.046 154.757 103.403 154.757 107.697C154.757 109.72 154.412 111.497 153.721 113.027C153.079 114.557 152.265 115.865 151.277 116.951C150.34 117.988 149.106 119.197 147.576 120.579C145.749 122.208 144.417 123.59 143.578 124.725C142.739 125.811 142.319 127.143 142.319 128.723H131.14ZM136.766 147.306C134.743 147.306 133.04 146.664 131.658 145.381C130.325 144.048 129.659 142.444 129.659 140.568C129.659 138.693 130.325 137.138 131.658 135.904C132.991 134.621 134.693 133.979 136.766 133.979C138.839 133.979 140.542 134.621 141.875 135.904C143.208 137.138 143.874 138.693 143.874 140.568C143.874 142.444 143.183 144.048 141.801 145.381C140.468 146.664 138.79 147.306 136.766 147.306Z"
      fill="#999999"
    />
    <path d="M211.1 72.5002V49.2002H234.5L211.1 72.5002Z" fill="#FF849A" />
    <path
      d="M293.1 56.8H201C197.7 56.8 195 54.1 195 50.8V6C195 2.7 197.7 0 201 0H293.1C296.4 0 299.1 2.7 299.1 6V50.8C299.1 54.1 296.4 56.8 293.1 56.8Z"
      fill="#FF849A"
    />
    <path
      d="M243.141 12H251.859L250.406 33.3008H244.594L243.141 12ZM247.5 45C246.219 45 245.141 44.596 244.266 43.7881C243.422 42.9492 243 41.9393 243 40.7585C243 39.5777 243.422 38.5989 244.266 37.822C245.109 37.0141 246.188 36.6102 247.5 36.6102C248.812 36.6102 249.891 37.0141 250.734 37.822C251.578 38.5989 252 39.5777 252 40.7585C252 41.9393 251.562 42.9492 250.688 43.7881C249.844 44.596 248.781 45 247.5 45Z"
      fill="white"
    />
    <defs data-test-id='tIcg'>
      <linearGradient
        id="paint0_linear_17778_347164"
        x1="166.264"
        y1="302.316"
        x2="171.001"
        y2="187.279"
        gradientUnits="userSpaceOnUse"
      >
        <stop data-test-id='iArb' stop-color="white" stop-opacity="0" />
        <stop data-test-id='ctDl' offset="0.4449" stop-color="#E6E6E6" />
        <stop data-test-id='xpGk' offset="1" stop-color="#D0D0D0" />
      </linearGradient>
    </defs>
  </svg>
)
