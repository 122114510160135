import { stringify } from "query-string"
import {
  useQueryParams,
  StringParam,
  // NumberParam,
  // ArrayParam,
  JsonParam,
  withDefault,  
  NumberParam,
  encodeQueryParams,
} from "use-query-params"
import { isEmptyObject } from "utils/objectUtils"


const categFilterKey = 'FLT-CATEG001'
const brandFilterKey = 'FLT-SMaaaa00'
const jobFilterKey = 'FLT-M0ctyjob'


const MyFiltersParam = withDefault(JsonParam, {})
const PageParam = withDefault(NumberParam, 1)
const useQueryStringFilters = () => {
  //log render

  const paramConfigMap = {
    q: StringParam,
    filters: MyFiltersParam,
    page: PageParam,
    sortBy: StringParam,
  }

  const [query, setQuery] = useQueryParams(paramConfigMap)

  const getUrlWithNewCateg = id => {

    const oldCategCodeChain = query?.filters?.[categFilterKey]?.[0] || ''

    const alreadyHasCategory = oldCategCodeChain?.includes?.(id)
    if(alreadyHasCategory) return getModifiedQueryString(query)

    
    const newQuery = {
      ...query,
      filters: {
        ...query.filters,
        [categFilterKey]: [`${id}`],
      }, 
      page: 1,
    }

    return getModifiedQueryString(newQuery)
  }

  const getUrlWithoutThisFilter = (filterKey) => {
      
      const { [filterKey]: _, ...restFilters } = query?.filters || {}

      const newQuery = {
        ...query,
        filters: restFilters,
        page: 1,
      }
      return getModifiedQueryString(newQuery)
      
  }


  const getUrlWithNewJobFilter = (newSelectionList) => {

    const newQuery = {
      ...query,
      filters: {
        ...query.filters,
        [jobFilterKey]: newSelectionList
      },
      page: 1,
    }

    return getModifiedQueryString(newQuery)
  }

  const getUrlWithNewBrand = (newSelectionList, isSelected) => {
    const shouldDeleteQueryParamsFilterKey = isSelected && newSelectionList?.length === 0  
    const newlyCreatedFilters = {
      ...query.filters,
      [brandFilterKey]: newSelectionList
    }
    const filteredFilterKeys = Object.keys(newlyCreatedFilters)?.filter(i => !(shouldDeleteQueryParamsFilterKey && i === brandFilterKey)) 
    const filteredFiltersObject = filteredFilterKeys?.reduce((prev, current) => (
      {
        ...prev,
        [current]: current === brandFilterKey ? newSelectionList : query?.filters?.[current]
      }
    ),{})
    const newQuery = {
      ...query,
      filters: filteredFiltersObject,
      page: 1,
    }

    return getModifiedQueryString(newQuery)
  }


  const getModifiedQueryString = (newQuery) => {
    const modifiedQuery = {
      
      ...(query || {}),
      ...(newQuery || {}),
    }

    const finalQuery = {
      ...modifiedQuery,
      filters: isEmptyObject(modifiedQuery?.filters) ? undefined : modifiedQuery?.filters,
    }

    const encodedQueryParams = encodeQueryParams(paramConfigMap, finalQuery)

    const newQueryString = stringify(encodedQueryParams)

    return `?${newQueryString}`
  }

  


  const clearFiler = (id) => {
    const changedFilters = query?.filters
    delete changedFilters?.[id]
    setQuery({ filters: changedFilters })
  }
  const clearSearchString = (id) => {
    setQuery({ q: undefined })
  }
  const setSearchString = (str) => {
    setQuery({ q: str, page: 1 })
  }

  const clearAllFilters = () => {
    setQuery({ filters: undefined })
    // dispatch({type:"TEMP_CLEAR_SHOP_FILTERS"})
  }
  const setFilter = (id, value) => {
    if(!value?.length) clearFiler(id)
    else setQuery({ ...query, filters: { ...query.filters, [id]: value } })
  }
  const setPage = (page) => {
    setQuery({ page })
  }
  const setQsSortBy = (sortBy) => {
    setQuery({ sortBy })
  }

  return {
    query,
    setQuery,
    clearFiler,
    clearSearchString,
    setSearchString,
    clearAllFilters,
    setFilter,
    searchString: query.q,
    filters: query.filters,
    page: query.page,
    sortBy: query.sortBy,
    setPage,
    setQsSortBy, 
    getUrlWithNewCateg,
    getUrlWithNewBrand, 
    getUrlWithNewJobFilter,
    getUrlWithoutThisFilter,
  }
}


export const urlFriendlyToSortBy = (sortBy) => {
  return {
    'popularity': 'shopAccessCount|false',
    'relevance': 'relevance|false',
    'ranking': 'ranking|true',
    'name': 'name|true',
    'nameDescending': 'name|false',
  }[sortBy]
}

export const sortByToUrlFriendly = (sortBy) => {
  return {
    'shopAccessCount|false': 'popularity',
    'relevance|false': 'relevance',
    'ranking|true': 'ranking',
    'name|true': 'name',
    'name|false': 'nameDescending',
  }[sortBy]
}

export default useQueryStringFilters
