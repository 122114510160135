const adreseLivrareTableItem1 = {
  id: 1,
  company: "S.C. Lem’s S.r.l.",
  cui: "16495550",
  locationName: "Sediul central Lem’s",
  name: "Andrei Popescu",
  judet: "Timis",
  localitate: "Timisoara",
  adresa: "21 Str. Alexandru Papadopol, parter, cladirea X",
  priceBrut: "5493.21",
  status: "Locatie noua"
}

const adreseLivrareTableItem2 = {
  id: 2,
  company: "S.C. Lem’s S.r.l.",
  cui: "16495550",
  locationName: "Sediul central Lem’s",
  name: "Andrei Popescu",
  judet: "Timis",
  localitate: "Timisoara",
  adresa: "21 Str. Alexandru Papadopol, parter, cladirea X",
  priceBrut: "5493.21",
  status: "Aprobata"
}

const adreseLivrareTableItem3 = {
  id: 3,
  company: "S.C. Lem’s S.r.l.",
  cui: "16495550",
  locationName: "Sediul central Lem’s",
  name: "Andrei Popescu",
  judet: "Timis",
  localitate: "Timisoara",
  adresa: "21 Str. Alexandru Papadopol, parter, cladirea X",
  priceBrut: "5493.21",
  status: "Locatie noua"
}

const adreseLivrareTableItem4 = {
  id: 4,
  company: "S.C. Lem’s S.r.l.",
  cui: "16495550",
  locationName: "Sediul central Lem’s",
  name: "Andrei Popescu",
  judet: "Timis",
  localitate: "Timisoara",
  adresa: "21 Str. Alexandru Papadopol, parter, cladirea X",
  priceBrut: "5493.21",
  status: "Aprobata"
}

const adreseLivrareTableData = {
  header: {
    text1: "Compania",
    text2: "Numele locatiei",
    text3: "Nume si prenume",
    text4: "Judetul",
    text5: "Localitea",
    text6: "Adresa",
    text7: "Status",
    text8: "Actiuni"
  },
  contents: [
    adreseLivrareTableItem1,
    adreseLivrareTableItem2,
    adreseLivrareTableItem3,
    adreseLivrareTableItem4
  ]
}

export default adreseLivrareTableData
