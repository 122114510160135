import { forwardRef } from "react"
import SvgIcon from "components/SvgIcon/SvgIcon"
import "./index.scss"

const MagicInput = forwardRef((props, ref) => {
  const {
    label,
    icon,
    error,
    grey,
    blue,
    placeholder,
    text,
    white,
    outline,
    disabled,
    small,
    autoComplete,
    type,
    ...rest
  } = props
  const inputLabel = label ? (error && error?.length ? error : label) : undefined
  let inputLabelClass = "label"
  let mainClass = "magic-input"
  let inputContainer = "inputContainer"

  if (grey) {
    inputContainer += " grey"
  }
  if (blue) {
    inputContainer += " blue"
  }
  if (white) {
    inputContainer += " white"
  }
  if (disabled) inputContainer += " disabled"

  if (outline) {
    inputContainer += " outline"
  } else {
    inputContainer += " grey"
  }
  if (error) {
    // inputLabelClass += " error"
    mainClass += " error"
    inputContainer += " error"
  }

  const inputContainerStyle = {
    height: "40px"
  }

  return (
    <div data-test-id='4wdk' className={mainClass}>
      {inputLabel && <div data-test-id='lEzI' className={inputLabelClass}>{inputLabel}</div>}
      <div data-test-id='KHP7' style={inputContainerStyle} className={inputContainer}>
        {icon && <SvgIcon name={icon} />}
        <input 
          data-test-id='QxMo'
          className={small ? "small" : ""}
          label={label}
          placeholder={text ? placeholder + " " + text : placeholder}
          ref={ref}
          {...rest}
          disabled={disabled}
          autoComplete={autoComplete}
          type={`${type ? type : "text"}`}
        />
      </div>
    </div>
  )
})

export default MagicInput
