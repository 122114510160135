import { Redirect } from "react-router"
import { useSelector } from 'react-redux'
import useIsLogged from "utils/useIsLogged"
import { arrayPartialMatch } from "utils/arrayUtils"

const AuthGuard = (props) => {
  const { path } = props
  const userPermissions = useSelector(state => state?.auth?.userData?.role?.permissions)
  //console.log("PERMISSIONS ", userPermissions)
  //console.log("PATH ", path)
  const isLogged = useIsLogged()

  if(path === "/admin") {
    if (!isLogged || !arrayPartialMatch(userPermissions,
        ["WEBSHOP_ADMIN", "WEBSHOP_HR_ADMIN", "WEBSHOP_MARKETING_ADMIN"]))
        //ADMIN_WRITE WEBSHOP_ADMIN ADMIN_READ ADMIN_DELETE
      return <Redirect to="/login" />
  }
  if(path === "/shop/dashboard") {
    if(!isLogged)
    return <Redirect to="/login" />
  }
  if(path === "/shop/cart") {
    if(!isLogged)
    return <Redirect to="/login" />
  }
  if(path === "/shop/invoices") {
    if(!isLogged || !userPermissions?.includes?.("CLIENT_INVOICE_READ") )
    return <Redirect to="/login" />
  }
  if(path === "/shop/invoice/details") {
    if(!isLogged || !userPermissions?.includes?.("CLIENT_INVOICE_READ") )
    return <Redirect to="/login" />
  }
  if(path === "/shop/orders") {
    if(!isLogged)
    return <Redirect to="/login" />
  }
  if(path === "/shop/orders/details") {
    if(!isLogged)
    return <Redirect to="/login" />
  }
  if(path === "/shop/offers") {
    if(!isLogged || !userPermissions?.includes?.("CUSTOMER_OFFER_READ"))
    return <Redirect to="/login" />
  }
  if(path === "/shop/offers/details") {
    if(!isLogged || !userPermissions?.includes?.("CUSTOMER_OFFER_READ"))
    return <Redirect to="/login" />
  }
  if(path === "/shop/lists") {
    if(!isLogged)
    return <Redirect to="/login" />
  }
  if(path === "/shop/careers") {//TODO ASK QA TEA IF THIS IS OK
    if(isLogged)
    return <Redirect to="/404" />
  }
  if(path === "/shop/job/:customUrl") {//TODO ASK QA TEAM IF IS THIS OK
    if(isLogged)
    return <Redirect to="/404" />
  }
  if(path === "/shop/tutorials") {
    if(!isLogged)
    return <Redirect to="/login" />
  }
  if(path === "/shop/tutorial/:customUrl") {
    if(!isLogged)
    return <Redirect to="/login" />
  }
  if(path === "/shop/lists/details") {
    if(!isLogged)
    return <Redirect to="/login" />
  }
  if(path === "/shop/client/mycompany") {
    if(!isLogged)
    return <Redirect to="/login" />
  }
  if(path === "/shop/client/users") {
    if(!isLogged)
    return <Redirect to="/login" />
  }
  if(path === "/shop/alerts") {
    if(!isLogged)
    return <Redirect to="/login" />
  }
  if(path === "/shop/excelimport") {
    if(!isLogged)
    return <Redirect to="/login" />
  }
  if(path === "/shop/signup") {
    if(isLogged)
    return <Redirect to="/404" />
  }
  if(path === "/shop/client/profile") {
    if(!isLogged)
    return <Redirect to="/login" />
  }
  // if(path === "/shop/signin") {
  //   if(isLogged)
  //     return <Redirect to="/shop/dashboard" />
  // }
  // if(path === "/login") {
  //   if(isLogged)
  //     return <Redirect to="/shop/dashboard" />
  // }
  return props.children
}

export default AuthGuard
