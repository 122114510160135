import { Redirect } from "react-router"
import { myLazy } from "utils/myLazy"

export const adminCompanyRoutes = [
  // client companies list
  {
    exact: true,
    path: "/admin/company/client/list",
    component: myLazy(() => import("../views/admin/companies/companyTable"))
  },

  {
    exact: false,
    path: "/admin/company/client/form",
    component: myLazy(() => import("../views/admin/companies/companyForm"))
  },
  //===================================================================
  // delivery address
  {
    exact: true,
    path: "/admin/company/delivery/list",
    component: myLazy(() => import("../views/admin/adreseDeLivrari/List"))
  },

  {
    exact: false,
    path: "/admin/company/delivery/form",
    component: myLazy(() =>
      import("../views/admin/deliveryFormPage/deliveryFormPage")
    )
  },
  //===================================================================
  // delivery location
  {
    exact: true,
    path: "/admin/company/towns/list",
    component: myLazy(() =>
      import("../views/admin/deliveryLocation/deliveryLocationList")
    )
  },

  {
    exact: false,
    path: "/admin/company/towns/form",
    component: myLazy(() =>
      import("../views/admin/deliveryLocation/deliveryLocationForm")
    )
  },
  //===================================================================
  // account requests
  {
    exact: true,
    path: "/admin/company/accountrequests/list",
    component: myLazy(() => import("../views/admin/accountRequests/List")) // DONE
  },
  {
    exact: false,
    path: "/admin/company/accountrequests/form",
    component: myLazy(() =>
      import("../views/admin/accountRequests/accountRequestForm")
    ) // DONE
  },
  //===================================================================
  {
    exact: true,
    path: "/admin/company/dashboard",
    component: myLazy(() => import("../views/admin/unifiedDashboard"))
  },
  {
    exact: false,
    path: "/admin/company",
    component: () => <Redirect to="/admin/company/dashboard" />
  }
]
