import "./ImageChain.scss"

export const ImageChain = (props) => {
  const { pictures } = props
  return (
    <div data-test-id='zzdq' className="flex column">
      {props.row.isSmallScreen && (
        <div data-test-id='lfMX' className="teable-header default">{props.label}</div>
      )}
      <div data-test-id='C5ku' className={`imageholder `}>
        {pictures?.length &&
          pictures?.map?.((img, index) => {
            return (
              <div data-test-id='tV7u' key={img.id} className={`imgwrap ${img.src ? "clear" : ""}`}>
                <img 
                  data-test-id='WC6I'
                  onContextMenu={(e) => e?.preventDefault?.()}
                  src={img.src}
                  alt={img.alt}
                />
              </div>
            )
          })}
        {pictures?.length > 4 && (
          <div data-test-id='PCRk' className="imgwrap grey">
            {pictures?.length > 4
              ? `+${pictures?.length - 4}`
              : `+${pictures?.length}`}
          </div>
        )}
      </div>
    </div>
  )
}
