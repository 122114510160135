import { createSelector } from "reselect"

const cart = (state) => state.shop.cart
const paymentData = (state) => state.company.paymentData
const deliveryData = (state) => state.company.deliveryData
const invoicingData = (state) => state.company.invoicingData

const allProducts = (state) => state.shop.products
const cartData = (state) => state.shop.cartData

export const selectShopPage = createSelector(
  cart,
  paymentData,
  invoicingData,
  deliveryData,
  cartData,
  allProducts,
  (cart, paymentData, invoicingData, deliveryData, cartData, allProducts) => {
    return {
      paymentData,
      deliveryData,
      invoicingData,
      cartContent: cart,
      cartData
    }
  }
)

export const selectTotalItems = createSelector(cartData, (cartData) => {
  let totalCartItems = 0
  let totalCartProducts = 0

  cartData?.lineList?.forEach?.((i) => {
    if (i.errorCode !== "NOT_ENOUGH_STOCK") {
      totalCartItems += i.quantity
      totalCartProducts++
    } //not sure if should we handle otehr cases
  })

  if (!totalCartItems) totalCartItems = null
  if (!totalCartProducts) totalCartProducts = null

  return {
    totalCartItems,
    totalCartProducts
  }
})
