import { combineReducers } from "@reduxjs/toolkit"
import { reducer as toastrReducer } from "react-redux-toastr"
// import { reducer2 as formReducer } from "react-redux-toastr";
// import { reducer as formReducer } from 'redux-form';
// import { loadingBarReducer } from "react-redux-loading-bar";
import authReducer from "store/modules/auth"
import shopReducer from "store/modules/shop"
import jobReducer from "store/modules/job"
import guestReducer from "store/modules/guest"
import tableReducer from "store/modules/table"
import dataViewReducer from "store/modules/dataView"
import companyReducer from "store/modules/company"
import { addColegiiReducer } from "store/modules/colegii"
import pageReducer from "store/modules/page"
import companyDetailReducer from "store/modules/company_details"
import apiCallsInProgress from "store/modules/apiCallsInProgress"


import translateReducer from "store/modules/translate"
import userReducer from "store/modules/user"
import backDataReducer from "store/modules/backData"
import dashboardReducer from "store/modules/dashboard"

// import menuReducer from "store/modules/menu";
// import authReducer from "store/modules/auth";
import pageTabsReducer from "store/modules/pageTabs"
import storage from "redux-persist/lib/storage" // defaults to localStorage for web and AsyncStorage for react-native
import { reduxStorageKey } from "store"
import { persistReducer } from "redux-persist"
import { LocalizeProvider, localizeReducer } from "react-localize-redux"

import localizationApiReducer from "store/modules/localizationApi"
import firebaseRealtimeDbReducer from "store/modules/firebaseRealtimeDb"
import { clearRefreshToken, setIsSignedInStateInLocalStorage } from "store/middleware/api"
import { clearCookieToken } from "./modules/dataView.search"


// export const LOGOUT_USER = "LOGOUT_USER";

export const rootReducer = (state, action) => {
  if (action.type === "auth/LOGOUT_USER") {
    
    // for all keys defined in your persistConfig(s)
    storage.removeItem(`persist:${reduxStorageKey}`)
    /**
     * this is the actual logout call
     */
    clearCookieToken()
    clearRefreshToken()
    setIsSignedInStateInLocalStorage(false)

    /**
     * If we ever want to perform a proper logout with clearing every user-related data
     * from the redux store (instead of clearing the entire redux store), this is how to start:
     * 
     * dispatch({ type: SET_AUTHENTICATED, payload: false })
     * dispatch({ type: RESET_USER_INFO })
     */

    /**
     * To preserve the state.localize slice for those short 2 seconds in between
     * reload after logout, I propose this hack (instead of state = undefined):
     */
    //  return appReducer(undefined, {}) 
    //  const resetState = {
    //   localize: state.localize
    // }
    
    return {
      ...appReducer(undefined, {}),
      localize: state?.localize,
      localizationApi: state?.localizationApi,
      backData: {
        ...state?.backData,
        dictionary: {
          ...state?.backData?.dictionary,
          UNIT_OF_MEASURE_CUSTOMER_OFFER: undefined,
          CLIENTS: undefined,
          ALL_CLIENTS: undefined,
          AGENTS: undefined,
          RANKINGS: undefined,
        }
      }  
    } 
  }

  return appReducer(state, action)
}
// this should be used in case of nested blacklist needed
// const filtersTrackingConfig = {
//   key: 'filtersTracking',
//   storage,
//   blacklist: ['selectedFilters']
//   };

const appReducer = combineReducers({
  auth: authReducer,
  shop: shopReducer,
  page: pageReducer,
  company: companyReducer,
  job: jobReducer,
  colegii: addColegiiReducer,
  // form: formReducer,
  toastr: toastrReducer,
  translate: translateReducer,
  user: userReducer,
  table: tableReducer,
  dataView: dataViewReducer,
  // this should be used in case of nested blacklist needed
  //persistReducer(filtersTrackingConfig, dataViewReducer),
  guest: guestReducer,
  company_details: companyDetailReducer,
  backData: backDataReducer,
  pageTabs: pageTabsReducer,
  dashboard: dashboardReducer,
  // loadingBar: loadingBarReducer,
  localize: localizeReducer,
  localizationApi: localizationApiReducer, 
  firebaseRealtimeDb: firebaseRealtimeDbReducer, 
  apiCallsInProgress: apiCallsInProgress,
})

export default rootReducer
