import store from "store/index"
import { apiAction, dynamoAction } from "store/middleware/api"
import { showMagicToaster } from "utils/MagicToasterCall"
import moment from "moment"
import { setLists } from "store/modules/shop"
import { setCrtList, SET_TEMP_PRODUCT_LIST_DETAILS } from "store/modules/page"
import { addId } from "utils/lookupFunctions"
import { loadDynamoFormItemByPK_v2 } from "store/modules/dataView.search"
import { history } from "utils/history"
import { marshall } from "@aws-sdk/util-dynamodb"
import { printError } from "utils/printError"
import { fetchShortDetailsByIdListJava } from "store/modules/dataView/dataViewActions.actions"
import { loadViewData } from "store/modules/dataView/dataView-dataLoader"

const TableName = process.env.REACT_APP_TABLE_NAME

export const createNewList = ({ name, products = [], crtList }) => {
  const eDate = moment().toISOString()
  const SK = `ORG_LIST-${eDate}` //PK si SK erau
  const cDate = eDate
  // const cDate = values.cDate ?? eDate
  const { organizationId, name: userName, id } = store.getState()?.user ?? {}
  const PK = `ORG-${organizationId}`

  // const userId = store.getState()?.user?.id
  // const userName = store.getState()?.user?.name

  const safeToCreate = organizationId && name
  if (!safeToCreate) return { type: "a" }
  return dynamoAction({
    method: "PUT",
    parameters: {
      TableName,
      Item: marshall(
        {
          PK,
          SK,
          //   SK: values.SK ?? SK,
          cDate, //creation date
          eDate, //last edit date
          cByName: userName,
          cById: id,
          name,
          products
        }, 
        { removeUndefinedValues: true }
      )
    },
    onSuccess: (data, dispatch) => {
      showMagicToaster({ title: "Datele au fost salvate" })
      dispatch(loadOrganizationLists({ crtList }))//: true
      return { type: "a" }
    },
    onFailure: (err) => {
      printError("createNewList()", err)
      showMagicToaster({
        title: "A aparut o eroare la salvarea datelor",
        subtitle: "E201",
        type: "error"
      })
      return {
        type: "a"
      }
    }
  })
}

export const createNewImport = ({ name, products = [] }) => {
  const eDate = moment().toISOString()
  const SK = `EIP_LIST-${eDate}` //PK si SK erau
  const cDate = eDate
  // const cDate = values.cDate ?? eDate
  const { organizationId, name: userName, id } = store.getState()?.user ?? {}
  const PK = `EIP-${organizationId}`

  // const userId = store.getState()?.user?.id
  // const userName = store.getState()?.user?.name

  const safeToCreate = organizationId && name
  if (!safeToCreate) return { type: "a" }
  return dynamoAction({
    method: "PUT",
    parameters: {
      TableName,
      Item: marshall({
        PK,
        SK,
        //   SK: values.SK ?? SK,
        cDate, //creation date
        eDate, //last edit date
        cByName: userName,
        cById: id,
        name,
        products
      }, 
      { removeUndefinedValues: true }
    )
    },
    onSuccess: (data, dispatch) => {
      dispatch(loadOrganizationLists({ crtList: true }))

      setTimeout(() => {
        history?.push?.(
          `/shop/importfromexcel/details?id=EIP-${organizationId}|EIP_LIST-${eDate}`
        )
      }, 700)

      return { type: "a" }
    },
    onFailure: (err) => {
      printError("createNewImport()", err)
      showMagicToaster({
        title: "A aparut o eroare la salvarea datelor",
        subtitle: "E201",
        type: "error"
      })
      return {
        type: "a"
      }
    }
  })
}

export const addProductToList = ({ listItem, product }) => {
  const eDate = moment().toISOString()
  if(listItem) { listItem.eDate = eDate }
  const alreadyInList = listItem?.products?.find?.(
    (item) => item?.id === product.id
  )
  if (!alreadyInList)
    if(listItem) { listItem.products = [...(listItem?.products ?? []), product] }

  const safeToCreate = listItem?.PK && listItem?.SK && product && !alreadyInList
  if (!safeToCreate) return { type: "a" }
  return dynamoAction({
    method: "PUT",
    parameters: {
      TableName,
      Item: marshall(listItem, { removeUndefinedValues: true })
    },
    onSuccess: (data, dispatch) => {
      showMagicToaster({ title: "Datele au fost salvate" })
      dispatch(loadOrganizationLists())
      return { type: "a" }
    },
    onFailure: (err) => {
      printError("addProductToList()", err)
      showMagicToaster({
        title: "A aparut o eroare la salvarea datelor",
        subtitle: "E201",
        type: "error"
      })
      return {
        type: "a"
      }
    }
  })
}
export const updateListQuantityAction = ({ productId, quantity }) => {
  const listItem = store.getState()?.page?.crtFormItem
  const products = store.getState()?.dataView?.items
  const eDate = moment().toISOString()
  if(listItem) { listItem.eDate = eDate }
  const alreadyInList = products?.find?.((item) => item?.id === productId)
  //listItem?.products?.find?.(item => item?.id === productId)
  if (!alreadyInList) {
    showMagicToaster({
      title: "A aparut o eroare la salvarea datelor",
      subtitle: "E203",
      type: "error"
    })
    return { type: "a" }
  }
  //listItem?.products?.forEach?.(item => {
  products?.forEach?.((item) => {
    if (item?.id === productId) {
      item.quantity = Number(quantity)
    }
  })
  if(listItem) { listItem.products = products }
  return dynamoAction({
    method: "PUT",
    parameters: {
      TableName,
      Item: marshall(listItem, { removeUndefinedValues: true })
    },
    onSuccess: (data, dispatch) => {
      showMagicToaster({ title: "Datele au fost salvate" })
      dispatch(loadOrganizationLists())
      dispatch(
        loadDynamoFormItemByPK_v2({
          input: listItem?.PK + "|" + listItem?.SK,
          setDvListProducts: true,
          moreSteps: enrichListFromJava
        })
      )
      return { type: "a" }
    },
    onFailure: (err) => {
      printError("updateListQuantityAction()", err)
      showMagicToaster({
        title: "A aparut o eroare la salvarea datelor",
        subtitle: "E201",
        type: "error"
      })
      return {
        type: "a"
      }
    }
  })
}
export const addMultiProductsToList = ({ newProducts }) => {
  const listItem = store.getState()?.page?.crtFormItem
  const products = store.getState()?.dataView?.items
  const eDate = moment().toISOString()
  if(listItem) { listItem.eDate = eDate }
  const alreadyInListIds = products?.map?.((prod) => prod.id)
  //listItem?.products?.map?.(prod => prod.id)
  const newProductsFiltered = newProducts?.filter?.(
    (prod) => !alreadyInListIds?.includes?.(prod.id)
  )
  if(listItem) { 
    listItem.products = [ 
      ...(products?.length ? products : []), 
      ...(newProductsFiltered?.length ? newProductsFiltered : [])
    ] ?? []
  }
  const safeToCreate = listItem?.PK && listItem?.SK && products
  if (!safeToCreate) return { type: "a" }
  return dynamoAction({
    method: "PUT",
    parameters: {
      TableName,
      Item: marshall(listItem, { removeUndefinedValues: true })
    },
    onSuccess: (data, dispatch) => {
      showMagicToaster({ title: "Datele au fost salvate" })
      //dispatch(loadOrganizationLists())
      dispatch(
        loadDynamoFormItemByPK_v2({
          input: listItem?.PK + "|" + listItem?.SK,
          moreSteps: enrichListFromJava,
          setDvListProducts: true
        })
      )
      return { type: "a" }
    },
    onFailure: (err) => {
      printError("addMultiProductsToList()", err)
      showMagicToaster({
        title: "A aparut o eroare la salvarea datelor",
        subtitle: "E201",
        type: "error"
      })
      return {
        type: "a"
      }
    }
  })
}

export const addMultiProductsToList2 = ({ newProducts }) => {
  const listItem = store.getState()?.page?.crtFormItem
  const products = store.getState()?.dataView?.items
  const eDate = moment().toISOString()
  if(listItem) { listItem.eDate = eDate }
  const alreadyInListIds = products?.map?.((prod) => prod.id)
  //listItem?.products?.map?.(prod => prod.id)
  const newProductsFiltered = newProducts?.filter?.(
    (prod) => !alreadyInListIds?.includes?.(prod.id)
  )
  if(listItem) { listItem.products = [...products || [], ...newProductsFiltered || []] ?? [] }

  const safeToCreate = listItem?.PK && listItem?.SK && products
  if (!safeToCreate) return { type: "a" }
  return dynamoAction({
    method: "PUT",
    parameters: {
      TableName,
      Item: marshall(listItem, { removeUndefinedValues: true })
    },
    onSuccess: (data, dispatch) => {
      showMagicToaster({ title: "Datele au fost salvate" })
      //dispatch(loadOrganizationLists())
      dispatch(
        loadDynamoFormItemByPK_v2({
          input: listItem?.PK + "|" + listItem?.SK,
          moreSteps: enrichListFromJava2,
          setDvListProducts: true
        })
      )
      return { type: "a" }
    },
    onFailure: (err) => {
      printError("addMultiProductsToList2()", err)
      showMagicToaster({
        title: "A aparut o eroare la salvarea datelor",
        subtitle: "E201",
        type: "error"
      })
      return {
        type: "a"
      }
    }
  })
}

export const deleteProductsFromList = ({ products }) => {
  let listItem = store.getState()?.page?.crtFormItem
  const eDate = moment().toISOString()
  if(listItem) { 
    listItem.eDate = eDate
    listItem.products = products ?? []
  }
  const safeToCreate = listItem?.PK && listItem?.SK && listItem?.products
  if (!safeToCreate) return { type: "a" }
  return dynamoAction({
    method: "PUT",
    parameters: {
      TableName,
      Item: marshall(listItem, { removeUndefinedValues: true })
    },
    onSuccess: (data, dispatch) => {
      showMagicToaster({ title: "Datele au fost salvate" })
      dispatch(loadOrganizationLists())
      return { type: "a" }
    },
    onFailure: (err) => {
      printError("deleteProductsFromList()", err)
      showMagicToaster({
        title: "A aparut o eroare la salvarea datelor",
        subtitle: "E201",
        type: "error"
      })
      return {
        type: "a"
      }
    }
  })
}
export const updateListNameDescription = (
  list,
  newName,
  newDescriptionValue
) => {
  const eDate = moment().toISOString()
  list.eDate = eDate
  // const alreadyInList = list.products?.find?.(item => item?.id === product.id)
  // if (!alreadyInList)
  //     list.products = [...(list.products ?? []), product]
  list.name = newName
  list.description = newDescriptionValue
  const safeToUpdate = list?.SK && list?.PK && newName
  if (!safeToUpdate) return { type: "a" }
  return dynamoAction({
    method: "PUT",
    parameters: {
      TableName,
      Item: marshall(list, { removeUndefinedValues: true })
    },
    onSuccess: (data, dispatch) => {
      showMagicToaster({ title: "Datele au fost salvate" })
      dispatch(loadOrganizationLists())
      dispatch(
        loadDynamoFormItemByPK_v2({
          input: list?.PK + "|" + list?.SK,
          moreSteps: enrichListFromJava
        })
      )
      return { type: "a" }
    },
    onFailure: (err) => {
      printError("updateListNameDescription()", err)
      showMagicToaster({
        title: "A aparut o eroare la salvarea datelor",
        subtitle: "E201",
        type: "error"
      })
      return {
        type: "a"
      }
    }
  })
}

export const deleteList = (PK, SK) => {
  const safeToDelete = PK && SK
  if (!safeToDelete) {
    printError("deleteList(), PK and SK cannot be empty. ", {
      PK,
      SK
    })
    return { type: "a" }
  }
  return dynamoAction({
    method: "DELETE",
    parameters: {
      TableName,
      Key: {
        PK: { S: PK },
        SK: { S: SK }
      }
    },
    onSuccess: (data, dispatch) => {
      showMagicToaster({ title: "Datele au fost sterse" })
      dispatch(loadOrganizationLists())
      return { type: "a" }
    },
    onFailure: (err) => {
      printError("deleteList()", err)
      showMagicToaster({
        title: "A aparut o eroare la stergerea listei",
        subtitle: "E201",
        type: "error"
      })
      return {
        type: "a"
      }
    }
  })
}

export const enrichListFromJava = (data, dispatch) => {
  const listOfIds = data?.products?.map?.((item) => ({
    // dispatch(fetchProdDetailsForList(item?.id))
    "id": item?.id
  }))
  dispatch(fetchShortDetailsByIdListJava(listOfIds, SET_TEMP_PRODUCT_LIST_DETAILS))
}

export const enrichListFromJava2 = (data, dispatch) => {
  data?.products?.forEach?.((item) => {
    dispatch(fetchProdDetailsForList2(item?.productCode))
  })
}

const fetchProdDetailsForList = (id) =>
  apiAction({
    endPoint: `/product/goods/shop/` + id,
    onSuccess: (data, dispatch) => {
      return { type: SET_TEMP_PRODUCT_LIST_DETAILS, payload: data }
    },
    onFailure: (error) => {
      printError("fetchProdDetailsForList()", error)
      return {
        type: "a"
      }
    }
  })

const fetchProdDetailsForList2 = (productCode) =>
  apiAction({
    endPoint: `/product/goods/shop/code/` + productCode,
    onSuccess: (data, dispatch) => {
      return { type: SET_TEMP_PRODUCT_LIST_DETAILS, payload: data }
    },
    onFailure: (error) => {
      printError("fetchProdDetailsForList2()", error)
      return {
        type: "a"
      }
    }
  })

export const deleteProductFromList = (list, id) => {
  const eDate = moment().toISOString()
  let stateList = store.getState()?.page?.crtFormItem
  const products = store.getState()?.dataView?.items
  const listItem = list ?? stateList
  const alreadyInList = products?.find?.((item) => item?.id === id)
  //listItem?.products?.find?.(item => item?.id === id)
  const safeToDelete = listItem?.PK && listItem?.SK && id && alreadyInList
  if (!safeToDelete) return { type: "a" }
  if (alreadyInList)
  if(listItem) { 
    listItem.products = products?.filter?.((item) => item?.id !== id)
    listItem.eDate = eDate
  }
  //listItem?.products?.filter?.(item => item?.id !== id)
  return dynamoAction({
    method: "PUT",
    parameters: {
      TableName,
      Item: marshall(listItem, { removeUndefinedValues: true })
    },
    onSuccess: (data, dispatch) => {
      // dispatch(loadOrganizationLists())
      dispatch(
        loadDynamoFormItemByPK_v2({
          input: listItem?.PK + "|" + listItem?.SK,
          setDvListProducts: true,
          moreSteps: enrichListFromJava
        })
      )
      showMagicToaster({ title: "Datele au fost sterse" })
      return { type: "a" }
    },
    onFailure: (err) => {
      printError("deleteProductFromList()", err)
      showMagicToaster({
        title: "A aparut o eroare la stergerea datelor",
        subtitle: "E201",
        type: "error"
      })
      return {
        type: "a"
      }
    }
  })
}

export const loadOrganizationLists = (props) => {
  const crtList = props?.crtList
  const SKprefix = `ORG_LIST-`
  // const cDate = values.cDate ?? eDate
  // const userId = store.getState()?.user?.id

  const state = store.getState()
  const organizationId = state?.page?.onBehalfId || state?.user?.organizationId

  const PK = `ORG-${organizationId}`

  return dynamoAction({
    method: "QUERY",
    parameters: {
      TableName,
      KeyConditionExpression: "PK = :pk and begins_with(SK,:sk_prefix)",
      ExpressionAttributeValues: {
        ":pk": { S: PK },
        ":sk_prefix": { S: SKprefix }
      }
    },
    onSuccess: (data, dispatch) => {
      if (crtList) dispatch(setCrtList({ items: addId(data?.items) ?? [] }))
      else dispatch(setLists(data?.items ?? []))
      return { type: "a" }
    },
    onFailure: (err) => {
      printError("loadOrganizationLists()", err)
      // showMagicToaster({title: err})

      return {
        type: "a"
      }
    }
  })
}

export const loadImports = (props) => {
  const crtList = props?.crtList
  const SKprefix = `EIP_LIST-`
  // const cDate = values.cDate ?? eDate
  // const userId = store.getState()?.user?.id
  const { organizationId } = store.getState()?.user

  const PK = `EIP-${organizationId}`

  return dynamoAction({
    method: "QUERY",
    parameters: {
      TableName,
      KeyConditionExpression: "PK = :pk and begins_with(SK,:sk_prefix)",
      ExpressionAttributeValues: {
        ":pk": { S: PK },
        ":sk_prefix": { S: SKprefix }
      }
    },
    onSuccess: (data, dispatch) => {
      if (crtList) dispatch(setCrtList({ items: addId(data?.items) ?? [] }))
      else dispatch(setLists(data?.items ?? []))
      return { type: "a" }
    },
    onFailure: (err) => {
      printError("loadImports()", err)
      // showMagicToaster({title: err})

      return {
        type: "a"
      }
    }
  })
}

export const deleteDataViewSelectedRowsFromDynamoDB = (inSK, selectedItems) => {

  const params = {
    RequestItems: {
      [TableName]: []
    }
  };

  // selectedItems?.forEach?.(i => {
  //   params.RequestItems[TableName] = 
  //   [
  //     ...params.RequestItems[TableName],
  //     {
  //       DeleteRequest: {
  //         Key: {
  //           PK: { S: i },
  //           SK: { S: inSK }
  //         }
  //       }
  //     }
  //   ]
  // })
  const processedPKs = selectedItems?.reduce?.((prev, current) => {
    return {
        ...prev, 
        RequestItems: {
          [TableName]: [...prev?.RequestItems?.[TableName] || [],
              {
                DeleteRequest: {
                  Key: {
                    PK: { S: current },
                    SK: { S: inSK }
                  }
                }
              }
            ]
        }
    }
  }, params)

  return dynamoAction({
    method: "batch_write",
    parameters: {
      TableName,
      IndexName: "PK",
      RequestItems: processedPKs?.RequestItems    
    },
    onSuccess: (data, dispatch) => {
      showMagicToaster({ title: "Datele au fost salvate" })
      dispatch(loadViewData())
      return { type: "a" }
    },
    onFailure: (err) => {
      printError("deleteDataViewSelectedRowsFromDynamoDB()", err)
      showMagicToaster({
        title: "A aparut o eroare la salvarea datelor",
        subtitle: "E201",
        type: "error"
      })
      return {
        type: "a"
      }
    }
  })
}

