import { adminCompanyRoutes } from "routes/admin.company.routes"
import { adminGuestRoutes } from "routes/admin.guest.routes"
import { adminProductRoutes } from "routes/admin.product.routes"
import { adminUserRoutes } from "routes/admin.user.routes"
import { Redirect } from "react-router"
import { myLazy } from "utils/myLazy"

export const adminRoutes = [
  /// sub paths
  {
    path: "/admin/user",
    routes: adminUserRoutes
  },
  {
    path: "/admin/company",
    routes: adminCompanyRoutes
  },
  {
    path: "/admin/guest",
    routes: adminGuestRoutes
  },
  {
    path: "/admin/product",
    routes: adminProductRoutes
  },

  //===================================================================
  // 5.0 Orders
  {
    exact: true,
    path: "/admin/maintenance",
    component: myLazy(() =>
      import("../views/admin/startAndStopMaintenance/startAndStopMaintenance")
    )
  },
  {
    exact: true,
    path: "/admin/order/list",
    component: myLazy(() => import("../views/admin/order/List"))
  },
  {
    exact: true,
    path: "/admin/order/view",
    component: myLazy(() => import("../views/admin/orderDetails/orderDetails"))
  },
  {
    exact: true,
    path: "/admin/order/invoice/list",
    component: myLazy(() => import("../views/admin/invoice/List"))
  },
  {
    exact: true,
    path: "/admin/order/invoice/form",
    component: myLazy(() => import("../views/admin/invoice/form/InvoiceDetails"))
  },
  //missing invoice view
  {
    exact: false,
    path: "/admin/order",
    component: myLazy(() => import("../views/admin/unifiedDashboard"))
  },
  //===================================================================
  //6.0 Liste predefinite
  {
    exact: true,
    path: "/admin/admin-lists/list",
    component: myLazy(() => import("../views/admin/listePredefinite/List"))
  },
  {
    exact: false,
    path: "/admin/admin-lists/form",
    component: myLazy(() => import("views/admin/listePredefinite/addList"))
  },
  {
    exact: true,
    path: "/admin/admin-lists/dashboard",
    component: myLazy(() => import("../views/admin/unifiedDashboard"))
  },
  {
    exact: false,
    path: "/admin/admin-lists",
    component: () => <Redirect to="/admin/admin-lists/dashboard" />
  },
  //===================================================================
  // 8.1 Depozite
  // {
  //   exact: true,
  //   path: '/admin/warehouse/list',
  //   component: myLazy(() =>
  //     import('../views/admin/depoziteUniprest/depoziteUniprest')
  //   )
  // },
  // {
  //   exact: false,
  //   path: '/admin/warehouse/details',
  //   component: myLazy(() => import('../views/admin/product/warehouseDetails'))
  // },

  //===================================================================
  // ########## Next Module ##############
  {
    exact: true,
    path: "/admin/oferte/list",
    component: myLazy(() => import("../views/admin/oferte/oferteTable")) // DONE
  },
  {
    exact: true,
    path: "/admin/offer/list",
    component: myLazy(() => import("../views/admin/offer/List"))
  },
  // // //proiecte
  {
    exact: true,
    path: "/admin/project/",
    component: myLazy(() => import("../views/admin/project/Dashboard"))
  },
  {
    exact: true,
    path: "/admin/project/list",
    component: myLazy(() => import("../views/admin/project/List"))
  },

  //===================================================================
  {
    exact: false,
    path: "/admin",
    component: myLazy(() => import("../views/admin/unifiedDashboard"))
  }
]

// // //joburi
// {
//   exact: true,
//   path: "/admin/jobs/",
//   component: myLazy(() => import("./views/admin/jobs/jobs"))
// },
// // //pagini prezentare
// {
//   exact: true,
//   path: "/admin/pages/",
//   component: myLazy(() => import("./views/admin/pages/pages"))
// },
// // //promotii
// {
//   exact: true,
//   path: "/admin/promo/",
//   component: myLazy(() => import("./views/admin/promotions/promotions"))
// },
// // //parametrizari
// {
//   exact: true,
//   path: "/admin/parameters/",
//   component: myLazy(() => import("./views/admin/parametrizari/parametrizari"))
// },
// // //setari
// {
//   exact: true,
//   path: "/admin/settings/",
//   component: myLazy(() => import("./views/admin/settings/settings"))
// },
