import SvgIcon from "components/SvgIcon/SvgIcon"
// import moment from "moment"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setColumns } from "store/modules/dataView"
import useShowModalOutsideClick from "utils/useShowModalOutsideClick"
import "./DatePicker.scss"
import ColumnsReorderDragAndDrop from "views/dev/select/ReorderColumnsDragDrop/ColumnsReorderDragAndDrop"

// const columnSortFilter = {
//   id: "FLT-C0SORTER",
//   fb : 70,
//   value: "String",
//   type: "columnSort",
//   placeholder: undefined,
//   label: "Categorie",
//   // loadMore: "Incarca mai multe...",
// };

export const ColumnSortFilter = (props) => {
  const [value, setValue] = useState()
  const { ref, showModal, setShowModal } = useShowModalOutsideClick()
  const stateColumns = useSelector((state) => state?.dataView?.columns)

  let className = "cdt-button no-label coll-sort stroke-text-light-01 "
  if (showModal) className += "open "
  if (!showModal) className += "close "
  if (stateColumns) className += "selected "
  // if (value) className += "selected"

  const data = props.templateHeader?.map?.((col, index) => ({
    id: index,
    disabled: col.noHide,
    label: col.type !== "moreActions" ? col.label : undefined, //used to hide moreActions col in reorder modal. Select is already wo label from deffinition
    visible:
      !stateColumns || stateColumns?.length === 0
        ? true
        : stateColumns?.includes?.(index)
  }))

  // let label = value?.[0] ? "selectata" : "Select Date"
  return (
    <div data-test-id='CgFk' ref={ref}>
      <button data-test-id='uLBi' {...{ className }} onClick={() => setShowModal((p) => !p)}>
        <SvgIcon name="visibility" />
      </button>
      {showModal && (
        <SortFilterModal
          {...{ ...props, value, setValue, setShowModal, data }}
        />
      )}
    </div>
  )
}

const SortFilterModal = (props) => {
  const { value, setValue, setShowModal } = props
  const dispatch = useDispatch()
  return (
    <div data-test-id='Uh8X' className={true ? "cdt-main" : "cdt-main standalone"}>
      <div data-test-id='Ht9b'>
        <ColumnsReorderDragAndDrop {...props} />

        <div data-test-id='lV24' className="filter-buttons">
          <button 
            data-test-id='kAMZ'
            onClick={() => {
              setValue()
              dispatch(setColumns())
              setShowModal(false)
            }}
          >
            Anuleaza
          </button>
          <button 
            data-test-id='qflR'
            onClick={() => {
              dispatch(setColumns(value))
              setShowModal(false)
            }}
          >
            Salveaza
          </button>
        </div>
      </div>
    </div>
  )
}
export default ColumnSortFilter
