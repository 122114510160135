import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import useFirestoreRealtimeDb from 'utils/useFirestoreRealtimeDb'
import { randomIntFromInterval } from 'utils/numberUtils'

  
const turnOffAfterMillis = randomIntFromInterval(1000, 20000) // 1 - 20 sec

export const useShouldShowMaintenanceScreen = () => {

  /** The final value that determines whether maintenance 
   * mode is on or off is stored here in this local state. */
  const [isMaintenanceStateOn, setIsMaintenanceStateOn] = useState(false)


  // Redux state fetched from firestore
  const isMaintenanceModeOn = useSelector(
    state => state?.firebaseRealtimeDb?.isMaintenanceModeOn
  )
  
  
  // Responsible for fetching the data from firestore and updating the redux state:
  useFirestoreRealtimeDb() 


  useEffect(() => {
    /** 
     * Turning maintenance mode ON: instant.
     * Turning maintenance mode OFF: delayed.
     * 
     * (To avoid every client overwhelming the server with requests at the same time.)
     */
    const wasMaintenanceOnUntilNow = isMaintenanceStateOn

    if(!isTrue(isMaintenanceModeOn)){
      if(wasMaintenanceOnUntilNow){
        console.log(`Turning maintenance mode off in ${turnOffAfterMillis} milliseconds. `)
        const timeout = setTimeout(() => setIsMaintenanceStateOn(false), turnOffAfterMillis)
  
        return () => clearTimeout(timeout)
      }
    } else {
      setIsMaintenanceStateOn(true)
    }
    
  }, [isMaintenanceModeOn])
  
  return Boolean(isMaintenanceStateOn)
  
}


const isTrue = value => {
  /**
   * When setting the value of this variable to false (in the database), 
   * someone may accidentally enter the string value 'false' instead of 
   * the boolean value false. To avoid evaluating 'false' to the boolean 
   * true, (because non-empty strings are truthy), we need to check for 
   * this case specifically. 
   */
  if(value?.toLowerCase?.() === 'false') return false
  return Boolean(value)
}
