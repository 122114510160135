const stockRequestTableItem1 = {
  id: 1,
  mainImage: {
    id: "mnz6vX",
    type: "IMAGE",
    name: "P2.jpg",
    main: true,
    exterior: true,
    reference:
      "https://uniprest.compano.com/Data/Environments/000001/Attachment/Bijlage/Overzicht%20Product%20classifications/P2.jpg",
    meta: null,
    description: null
  },
  name: 'ADAPTOR 28-3/4" INT PRESS',
  code: "IBDB52601072",

  companyName: "FERRUM TECH",
  companyType: "Reseller",

  username: "Kovacs Tamas",
  email: "tamas.kovacs@magicsolutions.ro",

  requestedAmount: "128",
  unitOfMeasure: "BUC",

  requestDate: "",

  status: "Notificat"
}

const stockRequestTableItem2 = {
  id: 2,
  mainImage: {
    id: "r27OB9",
    type: "IMAGE",
    name: "ecounit.jpg",
    main: true,
    exterior: true,
    reference:
      "https://uniprest.compano.com/Data/Environments/000001/Attachment/Bijlage/Overzicht%20Product%20classifications/ecounit.jpg",
    meta: null,
    description: null
  },
  name: "FERROLI BOILER CU SERPENTINA ECOUNIT 750-1 WB - 750L",
  code: "C1120029XXXXXXX",

  companyName: "BOSCH",
  companyType: "Reseller",

  username: "Elod Balazs",
  email: "elod.alazs@magicsolutions.ro",

  requestedAmount: "986",
  unitOfMeasure: "BUC",

  requestDate: "",

  status: "Nou"
}

const stockRequestTableItem3 = {
  id: 3,
  mainImage: {
    id: "mgxxnB",
    type: "IMAGE",
    name: "12049121100.jpg%20rehau.jpg",
    main: true,
    exterior: true,
    reference:
      "https://uniprest.compano.com/Data/Environments/000001/Attachment/Bijlage/Overzicht%20Product%20classifications/12049121100.jpg%20rehau.jpg",
    meta: null,
    description: null
  },
  name: "REHAU RAUBASIC TEAVA 20X2 EVAL ALBA - 100ML",
  code: "T0050014X202XXX",

  companyName: "AFRUM",
  companyType: "Reseller",

  username: "Ion Popescu",
  email: "ion.popescu@magicsolutions.ro",

  requestedAmount: "22",
  unitOfMeasure: "BUC",

  requestDate: "",

  status: "Anulat"
}

const stockRequestTableData = {
  header: {
    text1: "Produs",
    text2: "Compania",
    text3: "User",
    text4: "Volumul solicitat",
    text5: "Data solicitarii",
    text6: "Status",
    text7: "Actiuni"
  },
  contents: [
    stockRequestTableItem1,
    stockRequestTableItem2,
    stockRequestTableItem3
  ]
}

export default stockRequestTableData
