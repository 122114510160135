import MagicButton from "components/MagicButton/MagicButtonV2"
import { useDispatch, useSelector } from "react-redux"
import { cloneElement } from "react"
import { setPageModal } from "store/modules/page"
import "./list-modal.scss"
import useMagicForm from "utils/useMagicForm"

import useWindowSize from "../../utils/useWindowsSize"

import { useEffect, useRef, useState } from "react"
import { Lang, lang, Translate, withLocalize } from "components/Lang/Lang"
// !!! IMPORTANT !!!
// if you supply onSubmit Big Modal will have magic form inside!

export default function BigModal(props) {
  const { onSubmit } = props
  if (onSubmit) return <BigModalWithMagic {...props} />
  return <BigModalBase {...props} />
}

const BigModalWithMagic = (props) => {
  const magicFormProps = useMagicForm({ ...props })
  const { reset } = magicFormProps
  const { defaultValues } = props
  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues])
  return <BigModalBase {...{ ...props, magicFormProps }} />
}

function BigModalBase(props) {
  const {
    title,
    closeAction,
    saveAction,
    disableSubmit,
    name,
    grid,
    open,
    submitForm,
    steps,
    ro, //shows only the cancel button
    noFooter, //hides the footer altoghether
    noFullScreen, //modal will not open in full screen size
    className,
    magicFormProps
  } = props
  const pageModal = useSelector((state) => state.page?.modal)

  const [height, setHeight] = useState({ maxHeight: undefined })
  const windowSize = useWindowSize()
  const windowHeight = windowSize.height
  const modalHeader = useRef(null)
  const modalFooter = useRef(null)
  const modalBody = useRef(null)
  useEffect(() => {
    const modalHeaderHeight = modalHeader?.current?.clientHeight
    const modalFooterHeight = modalFooter?.current?.clientHeight
    setHeight({
      maxHeight:
        windowHeight - (80 + modalHeaderHeight + modalFooterHeight) + "px"
    })
  }, [windowSize])

  const dispatch = useDispatch()

  if (!(pageModal === name || pageModal?.name === name || open)) return null

  const onClose = () => {
    dispatch(setPageModal())
    if (closeAction) closeAction()
  }

  const onSave = () => {
    if (saveAction) saveAction()
    if (submitForm) submitForm()
    if (magicFormProps?.submitForm) magicFormProps.submitForm()
    dispatch(setPageModal())
  }

  const header = (
    <div data-test-id='vuTQ' className="header" ref={modalHeader}>
      <div data-test-id='XVyf' className="title">{title ?? "add title prop to <BigModal/>"}</div>
      <button data-test-id='0PhD' className={"close"} onClick={() => onClose()}>
        {close}
      </button>
    </div>
  )

  const disableSubmitComputed =
    disableSubmit ?? magicFormProps?.disableSubmit ?? false
  const enrichedChildren = magicFormProps
    ? cloneElement(props.children, { ...magicFormProps })
    : props.children

  const footer = (
    <div data-test-id='cgFu' className="big-modal-footer" ref={modalFooter}>
      {props.topFooter ?? null}
      <div data-test-id='xzyn' className={`buttons ${steps ? "" : "right"}`}>
        <MagicButton
          type="white"
          label={
            ro ? (
              <Lang value="bigmodal.btnExit" />
            ) : (
              <Lang value="bigmodal.btnCancel" />
            )
          }
          onClick={() => onClose()}
        />
        {!ro && (
          <MagicButton
            label={
              name === "add-related-products-to-basket" ? (
                <Lang value="bigmodal.btnAddCart" />
              ) : (
                <Lang value="bigmodal.btnSave" />
              )
            }
            onClick={() => {
              onSave()
            }}
            disabled={disableSubmitComputed}
          />
        )}
      </div>
    </div>
  )

  return (
    <div 
      data-test-id='G7Nu'
      className={`central-modal-overlay ${
        noFullScreen ? "" : "fixed-modal"
      } big-modal ${className ?? ""} ${noFooter ? "no-footer" : ""}`}
    >
      <div data-test-id='RXXj' className="list-modal-wrapper">
        {header}
        <div 
          data-test-id='FOcA'
          className={`modal-body ${grid ? "grid" : ""}`}
          style={height}
          ref={modalBody}
        >
          {enrichedChildren}
        </div>
        {!noFooter && (props.footer ?? footer)}
      </div>
    </div>
  )
}

const close = (
  <svg 
    data-test-id='PZoz'
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
      fill="#F3F3F3"
    />
    <path
      d="M21.3332 10.666L10.6665 21.3327"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.3332 21.3327L10.6665 10.666"
      stroke="#333333"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
