import { Router } from "react-router-dom"
import routes, { renderRoutes } from "routes/routes"
import "./App.css"
import React, { useEffect, useState } from "react"
import ThemeContext from "./theme/themeProvider"
import { theme } from "./theme/theme"
// import { history } from './utils/history';
import { Amplify } from "aws-amplify"
import * as yup from "yup"
import { useSelector } from "react-redux"
import { selectFormErros } from "store/selectors/form/formErrors.selector"
import "style/index.scss"
import { useDispatch } from "store"
import { clearAllLoadingIndicators, setOnBehalfIdInState, setPageEdit, setPageSeo, setTestFieldForDebugging } from "store/modules/page"
import { javaLogin } from "store/justActions/javaAuth"
import { QueryParamProvider } from "use-query-params"
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5"
import { parse, stringify } from "query-string"
import { myLazy } from "utils/myLazy"

// import { LocalizeProvider } from "react-localize-redux";
// import store from 'store/index'
// const history = createBrowserHistory({});
// import queryString from "query-string";
// import { setCheckFilters } from 'store/modules/dataView';
import { history } from "utils/history"
import { setPageModal } from "store/modules/page"
import Lang from "components/Lang/Lang"
import { useForcedRefresh } from "utils/useForcedRefresh"
import { useShouldShowMaintenanceScreen } from "utils/useShouldShowMaintenanceScreen"
import { clearOldReduxPersistence } from "utils/localStorageUtils"
import { searchStuff } from "store/modules/dataView.search"
import { loadAllDictionaries, loadDictionary, loadProductCategories, loadPublicDictionary } from "store/modules/backData"
import useIsLogged from "utils/useIsLogged"

import AppErrorBoundary from "layouts/AppErrorBoundary"
import { fetchTranslations } from "store/modules/localizationApi"
import FullPageLoadingScreen from "components/LoadingScreen/FullPageLoadingScreen"

// AWS.config.region = process.env.REACT_APP_USER_REGION; // Region
// AWS.config.credentials = new AWS.CognitoIdentityCredentials({
//     IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
//     // IdentityPoolId: 'eu-central-1:62eceb07-e41b-4a6f-8f6e-74cef9a1bac1',
// });

// Called when an identity provider has a token for a logged in user
// ===== NOT USED =====
// export function userLoggedIn(providerName, token) {
//   const creds = AWS.config.credentials
//   creds.params.Logins = creds.params.Logins || {};
//   creds.params.Logins[providerName] = token;
//   //console.log(creds.params.Logins)
//   // Expire credentials to refresh them on the next request
//   creds.expired = true;
// }

//===== NOT USED =====
// export function userLogOut() {
//   const creds = AWS.config.credentials
//   creds.params.Logins = {};
//   creds.expired = true;
// }

export const frontEndVersion = "V-0.7.68"

const options = {
  searchStringToObject: parse,
  objectToSearchString: stringify
}

Amplify.configure({
  Auth: {
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_USER_REGION,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_USER_POOL_ID
  }
})

function App() {
  const { yupLocale } = useSelector((state) => selectFormErros(state))
  const dictionaries = useSelector((state) => state?.backData?.dictionary)
  const productCategories = useSelector((state) => state?.backData?.productCategory)
  const isMaintenance = useShouldShowMaintenanceScreen()

  const dispatch = useDispatch() //temp auto login
  useEffect(() => {
    yup.setLocale(yupLocale)
  }, [yupLocale])

  const translationsApiData = useSelector((state) => state.localizationApi?.translations)
  const [isLocReFetchNeeded, setIsLocReFetchNeeded] = useState(false)

  // useEffect(() => {
  //   if (authenticatedState === undefined || authenticatedState === false)
  //     dispatch(javaLogin("anonymous", "Password123!!!!", true)) //anonymus login

  // }, [authenticatedState])

  // load vital dictionaries
  useEffect(() => {
    if (Object.keys?.(dictionaries || {})?.length === 0) {
      dispatch(
        searchStuff({
          type: "public/organization/workplace",
          dictionary: "LOCATION_RESPONSE",
          searchTerm: null,
          reload: true
        })
      )
      dispatch(loadAllDictionaries())
    }
  }, [dictionaries])


  useEffect(() => {
    dispatch(loadPublicDictionary('PRODUCT_BRAND'))
  }, [])



  useEffect(() => {
    if(!productCategories?.length) {
      dispatch(loadProductCategories())//!myArr.length
    }
    // dispatch(loadDictionary("PRODUCT_BRAND"))
    // dispatch(loadDictionary("PRODUCT_SUPPLIER"))
    // dispatch(loadDictionary("USER_POSITION"))
    // dispatch(loadDictionary("UNIT_OF_MEASURE"))
    // dispatch(loadDictionary("COUNTY"))
    // dispatch(getRankingOptions())
    // dispatch(setOnBehalfIdInState())

  }, [productCategories])

  // load localizations safety check
  useEffect(()=> {
    if(isLocReFetchNeeded)
        if(translationsApiData?.wasCalled && !translationsApiData?.hasResponded)
          dispatch(fetchTranslations())
}, [isLocReFetchNeeded])

useEffect(() => {
  console.log('frontEndVersion', frontEndVersion) // This is meant to be kept here even for production

  const locRefetchTimeout = setTimeout(() => setIsLocReFetchNeeded(true), 3000)
  return () => clearTimeout(locRefetchTimeout)
},[])


  useEffect(() => {
    maybeThrowFatalErrorForTesting()
    
    const { search } = window?.location || {}
    if (search?.includes?.("fatalErrorDirtyRedux")) {
      dispatch(setTestFieldForDebugging(
        <Lang value='TEST ERROR: saving react component into redux. '/>
      ))
    }

    clearOldReduxPersistence(dispatch)

  }, [])

  const url = window.location.pathname
  useEffect(() => {
    dispatch(setPageSeo())
    if (url?.includes?.("form")) dispatch(setPageEdit(false))
    dispatch(setPageModal(undefined))

    // const qsParams = queryString.parse(window.location.search);
    // if (qsParams) {
    //   const filterKeys = Object?.entries?.(qsParams)
    //   ?.filter?.(([key, value]) => key?.includes?.("FLT"))
    //   ?.reduce?.((acc, [key, value]) => {
    //     acc[key] = value
    //     return acc
    //   }, {})
    //   //log

    //   dispatch(setCheckFilters({filterKeys}))
    // }
  }, [url])

  
  /**
   * Does a forced refresh whenever it is triggered from the back-end 
   * (from Firebase RealtimeDB through socket communication).
   */
  useForcedRefresh()

  useEffect(() => {
    dispatch(clearAllLoadingIndicators())
  },[])


  return (
    // <LocalizeProvider store={store}>

    <ThemeContext.Provider value={theme}>
      <Router history={history}>
        <QueryParamProvider adapter={ReactRouter5Adapter}>
          <>
            <FullPageLoadingScreen />
            {/* <QueryParamProvider {...{options}} adapter={ReactRouter5Adapter}> */}
            {renderRoutes(isMaintenance ? maintenanceRoutes : routes)}
          </>
          
        </QueryParamProvider>
      </Router>
    </ThemeContext.Provider>
    //  </LocalizeProvider>
  )
}

/**
 * When maintenance mode is on, every single URL
 * (route) is served by the maintenance page.
 *
 * The user intuitively keeps refreshing the page.
 * Eventually when the maintenance mode is turned off,
 * the original routes are used again to match a page.
 */
const maintenanceRoutes = [
  {
    exact: false,
    path: "*",
    component: myLazy(() => import("views/MaintenancePage/MaintenancePage"))
  },
]


const maybeThrowFatalErrorForTesting = () => {
  const { search } = window?.location || {}
  if (search?.includes?.("throwFatalError")) {
    throw new Error("Fatal error for testing")
  }
}


const WrappedApp = () => {
  return (
    <AppErrorBoundary>
      <App />
    </AppErrorBoundary>
  )
}


// export default App
export default WrappedApp
