import { history } from "utils/history"
// import { Lang, lang, Translate, withLocalize } from "components/Lang/Lang"
import { isCampaignActive } from "utils/dateUtils"
import { campaignConfig } from "views/shoppingCart/useCampaignInfo"


const getCampaignMessage = (orderCode, nrOfOrderPerWeek, isCompanyInCampaign) => {
    if(nrOfOrderPerWeek === 1 && isCompanyInCampaign && isCampaignActive() && campaignConfig.doesAffectCart)
      return `Felicitări! <br data-test-id='lS0S'> Comanda ta cu numărul ${orderCode || "-"} a fost înscrisă în tragerea la sorți a campaniei <b data-test-id='0pLz'><a data-test-id='oLew' href='${campaignConfig.url}' target='_blank' style="color: black;">${campaignConfig.name}</b></a> <br data-test-id='ryJR'/>Nu uita să plasezi și săptămâna viitoare o comandă, pentru încă o șansă de câștig!`
    else if(nrOfOrderPerWeek > 1 && isCompanyInCampaign && campaignConfig.doesAffectCart)
      return `Îți mulțumim pentru comandă! <br data-test-id="qNGm"> Compania ta este deja înscrisă în tragerea la sorți a campaniei <b data-test-id="9gSS"><a data-test-id="bnWU" href="${campaignConfig.url}" target="_blank"  style="color: black;">${campaignConfig.name}</b></a>, <br data-test-id="hgLc"/> Te invităm să revii și săptămâna viitoare cu o nouă comandă.`
      // <Lang value="sytemMessages.orderUniprest**" />
    else
      return null

}

export const orderAutoModalData = (orderId, source, orderCode, nrOfOrderPerWeek, isCompanyInCampaign) =>
  orderSuccessModalBase({
    orderId,
    // message: "Comanda ta a fost plasată și preluată automat pentru livrare.",//<Lang value="systemMessages.order" />,
    message: "Comanda ta a fost preluată automat pentru livrare",//<Lang value="systemMessages.order" />,
    source,
    campaignMessage: getCampaignMessage(orderCode, nrOfOrderPerWeek)
  })
// export const orderManualModalData = orderId =>
//     orderSuccessModalBase({orderId,
//     message: "Felicitari! Comanda ta a fost plasata si va fi preluata de un agent Uniprest."
//     })

// TODO: hardcode: needs proper messages
export const orderManualModalData = (orderId, errorCode, source, orderCode, nrOfOrderPerWeek, isCompanyInCampaign) => {
  // let message = "Comanda ta a fost plasată și va fi preluată de un agent Uniprest Instal."
  // "Comanda ta a fost preluată automat pentru livrare de un agent Uniprest Instal."
  let message = "Comanda ta a fost plasată și preluată automat pentru livrare."
  // <Lang value="systemMessages.Uniprest" />
  switch (errorCode) {
    case "OVER_LIMIT":
      // message = "Comanda ta a fost plasată și va fi preluată de un agent Uniprest Instal, din cauza limitei de credit depășite."
      // message = "Comanda ta a fost preluată automat pentru livrare de un agent Uniprest Instal, din cauza limitei de credit depășite."
      message = "Comanda ta a fost plasată și va fi preluată de un agent Uniprest Instal, din cauza limitei de credit depășite."
      // <Lang value="sytemMessages.orderUniprest" />
      break
    case "NON_PAYED_INVOICE":
      // message = "Comanda ta a fost plasată și va fi preluată de un agent Uniprest Instal, deoarece compania ta are cel puțin o factură a cărei scadențe a depășit 20 de zile."
      // message = "Comanda ta a fost preluată automat pentru livrare de un agent Uniprest Instal, deoarece compania ta are cel puțin o factură a cărei scadențe a depășit 20 de zile."
      message = "Comanda ta a fost plasată și va fi preluată de un agent Uniprest Instal, deoarece compania ta are cel puțin o factură a cărei scadențe a depășit 20 de zile."
      // <Lang value="systemMessages.20day" />
      break
    case "SPECIAL_ADDRESS":
      // message = "Comanda ta a fost plasată și va fi preluată de un agent Uniprest Instal, deoarece adresa aleasă pentru livrare este considerată o adresă specială."
      // message = "Comanda ta a fost preluată automat pentru livrare de un agent Uniprest Instal, deoarece adresa aleasă pentru livrare este considerată o adresă specială."
      message = "Comanda ta a fost plasată și va fi preluată de un agent Uniprest Instal, deoarece adresa aleasă pentru livrare este considerată o adresă specială."
      // <Lang value="systemMessages.admin" />
      break
    default:
      break
  }
  const campaignMessage = getCampaignMessage(orderCode, nrOfOrderPerWeek, isCompanyInCampaign)
  
  return orderSuccessModalBase({
    orderId,
    message,
    source,
    campaignMessage,
    showCampaignCta: 
      nrOfOrderPerWeek > 0 && 
      isCompanyInCampaign && 
      isCampaignActive() && 
      campaignConfig.doesAffectCart
  })
}

const orderSuccessModalBase = ({ orderId, message, source, campaignMessage, showCampaignCta }) => {
  return {
    message,
    campaignMessage,
    //svg: successBigSVGwand,
    svgName: "successbigsvgwand",
    secondaryLabel: "Înapoi la magazin",
    //<Lang value="systemMessages.back" />,
    primaryAction: () =>
      history?.push?.(`/shop/orders/details?id=${orderId}&source=${source}`),
    primaryLabel: "Vezi comanda",//<Lang value="systemMessages.seeOrder" />,
    secondaryAction: () => history?.push?.("/shop/productlist"),
    campaignButtonLabel: showCampaignCta ? "Vezi pagina campaniei" : null,
    campaignCta: showCampaignCta ? campaignConfig.url : null,
  }

}
export const successBigSVGwand =
  <svg 
    data-test-id='ky8Y'
    width="240"
    height="240"
    viewBox="0 0 240 240"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M120 240C186.274 240 240 186.274 240 120C240 53.7258 186.274 0 120 0C53.7258 0 0 53.7258 0 120C0 186.274 53.7258 240 120 240Z"
      fill="#D0E2FF"
    />
    <path
      d="M192.248 24.2276C172.476 9.26426 147.752 0.294877 120.977 0.0772484C94.202 -0.14038 69.4291 8.52097 49.3227 23.0659C36.0779 51.8091 41.0724 86.9234 64.6373 110.874C95.0285 141.764 144.619 142.167 175.508 111.776C199.366 88.1157 204.93 53.0873 192.248 24.2276Z"
      fill="white"
    />
    <path
      d="M138.96 92.4932C137.8 92.4932 136.64 92.2265 135.547 91.6798L122.187 84.9198C120.814 84.2265 119.174 84.2265 117.8 84.9198L104.44 91.6798C101.88 92.9732 98.8669 92.7198 96.5735 91.0132C94.3735 89.3732 93.3469 86.8131 93.8135 84.1598L96.3602 69.8531C96.6135 68.4265 96.1202 66.9598 95.0269 65.9465L84.2135 55.8132C82.2135 53.9465 81.4802 51.1865 82.3069 48.6131C83.1469 45.9598 85.4402 44.0532 88.2802 43.6665L103.214 41.5732C104.747 41.3598 106.054 40.4398 106.734 39.1198L113.414 26.0931C114.667 23.6531 117.187 22.1465 119.987 22.1465C122.787 22.1465 125.307 23.6531 126.56 26.0931L133.24 39.1198C133.92 40.4398 135.24 41.3598 136.76 41.5732L151.694 43.6665C154.534 44.0665 156.814 45.9598 157.667 48.6131C158.48 51.1865 157.747 53.9465 155.747 55.8132L144.934 65.9465C143.854 66.9598 143.347 68.4265 143.6 69.8531L146.147 84.1598C146.614 86.8131 145.587 89.3732 143.387 91.0132C142.107 91.9865 140.547 92.4932 138.96 92.4932Z"
      fill="#005CB9"
    />
    <path
      d="M120 223.64C118.893 223.64 118 222.747 118 221.64C118 218.613 116.507 217.16 114.613 215.333C112.56 213.347 110 210.867 110 206.147C110 201.427 112.56 198.947 114.613 196.96C116.507 195.12 118 193.68 118 190.64C118 187.613 116.507 186.173 114.613 184.333C112.56 182.347 110 179.867 110 175.147C110 170.427 112.56 167.947 114.613 165.96C116.507 164.12 118 162.68 118 159.653C118 156.627 116.507 155.187 114.613 153.347C112.56 151.36 110 148.88 110 144.16C110 139.44 112.56 136.973 114.613 134.987C116.507 133.147 118 131.707 118 128.68C118 125.653 116.507 124.213 114.613 122.373C112.56 120.387 110 117.907 110 113.187C110 108.467 112.56 106 114.613 104C116.507 102.16 118 100.72 118 97.6934C118 96.5867 118.893 95.6934 120 95.6934C121.107 95.6934 122 96.5867 122 97.6934C122 102.413 119.44 104.88 117.387 106.88C115.493 108.72 114 110.16 114 113.187C114 116.213 115.493 117.667 117.387 119.493C119.44 121.48 122 123.96 122 128.68C122 133.4 119.44 135.867 117.387 137.853C115.493 139.693 114 141.133 114 144.16C114 147.187 115.493 148.627 117.387 150.467C119.44 152.453 122 154.933 122 159.64C122 164.36 119.44 166.84 117.387 168.827C115.493 170.667 114 172.107 114 175.133C114 178.16 115.493 179.6 117.387 181.44C119.44 183.427 122 185.907 122 190.627C122 195.347 119.44 197.827 117.387 199.813C115.493 201.653 114 203.093 114 206.12C114 209.147 115.493 210.6 117.387 212.427C119.44 214.413 122 216.893 122 221.613C122 222.747 121.107 223.64 120 223.64Z"
      fill="#005CB9"
    />
    <path
      d="M69.0133 113.214C71.3867 113.387 73.6133 112.241 74.84 110.201C76.0133 108.241 75.9867 105.947 74.7733 104.054L68.5467 94.3472C67.9733 93.4538 67.9067 92.3338 68.3466 91.3472L73.1867 80.6272C74.12 78.5472 73.8 76.2005 72.36 74.4805C70.8667 72.7072 68.5067 71.9872 66.2 72.5738L54.6267 75.5872C53.52 75.8672 52.3733 75.6272 51.5467 74.9205L42.6933 67.4138C40.96 65.9472 38.5467 65.5872 36.3867 66.4805C34.24 67.3738 32.7867 69.3338 32.6 71.6005L31.6667 83.1738C31.5733 84.2538 30.9467 85.2538 29.96 85.8272L19.6533 91.9072C17.6 93.1205 16.4533 95.3072 16.6533 97.6138C16.8533 99.8538 18.2933 101.734 20.4267 102.534L31.4267 106.667C32.44 107.054 33.1867 107.894 33.4133 108.934L35.8933 120.201C36.3733 122.387 37.9867 124.027 40.2 124.587C41.0133 124.787 41.84 124.827 42.64 124.707C44.0933 124.494 45.44 123.761 46.4533 122.587L54.1867 113.574C54.92 112.707 56.04 112.254 57.1733 112.334L69.0133 113.214Z"
      fill="#A6C8FF"
    />
    <path
      d="M102.386 210.467C103.306 207.76 104.453 204.387 102.293 200.187C100.133 195.987 96.733 194.96 93.9997 194.12C91.4797 193.347 89.4797 192.747 88.1064 190.054C86.7197 187.36 87.3997 185.387 88.2397 182.894C89.1597 180.187 90.3064 176.814 88.1464 172.614C85.9864 168.414 82.5864 167.387 79.853 166.547C77.333 165.774 75.333 165.174 73.9597 162.48C72.5864 159.787 73.253 157.814 74.093 155.32C75.013 152.614 76.1597 149.254 73.9997 145.054C71.8397 140.854 68.4397 139.827 65.7064 138.987C63.1864 138.214 61.1864 137.614 59.813 134.92C58.4397 132.227 59.1064 130.254 59.9464 127.76C60.8664 125.054 62.013 121.68 59.853 117.494C59.3464 116.507 58.1464 116.12 57.1597 116.627C56.173 117.134 55.7864 118.334 56.293 119.32C57.6797 122.014 56.9997 123.987 56.1597 126.48C55.2397 129.187 54.093 132.56 56.253 136.747C58.413 140.947 61.813 141.987 64.5464 142.814C67.0664 143.587 69.0664 144.187 70.4397 146.88C71.813 149.574 71.1464 151.547 70.3064 154.04C69.3864 156.747 68.2397 160.107 70.3997 164.307C72.5597 168.507 75.9597 169.534 78.693 170.374C81.213 171.147 83.213 171.747 84.5864 174.44C85.973 177.134 85.293 179.107 84.453 181.6C83.533 184.307 82.3864 187.68 84.5464 191.88C86.7064 196.08 90.1064 197.107 92.8397 197.947C95.3597 198.72 97.3597 199.32 98.733 202.014C100.12 204.707 99.4397 206.68 98.5997 209.174C97.6797 211.88 96.533 215.254 98.693 219.454C99.053 220.147 99.7464 220.547 100.48 220.547C100.786 220.547 101.093 220.48 101.386 220.32C102.373 219.814 102.76 218.614 102.253 217.627C100.866 214.934 101.546 212.96 102.386 210.467Z"
      fill="#A6C8FF"
    />
    <path
      d="M170.986 113.213C168.612 113.386 166.386 112.24 165.159 110.2C163.986 108.24 164.012 105.946 165.226 104.053L171.452 94.3462C172.026 93.4529 172.092 92.3329 171.652 91.3462L166.812 80.6262C165.879 78.5462 166.199 76.1995 167.639 74.4795C169.132 72.7062 171.492 71.9862 173.799 72.5729L185.372 75.5862C186.479 75.8662 187.626 75.6262 188.452 74.9195L197.306 67.4129C199.039 65.9462 201.452 65.5862 203.612 66.4795C205.759 67.3729 207.212 69.3329 207.399 71.5995L208.332 83.1729C208.426 84.2529 209.052 85.2529 210.039 85.8262L220.346 91.9062C222.399 93.1195 223.546 95.3062 223.346 97.6129C223.146 99.8529 221.706 101.733 219.572 102.533L208.572 106.666C207.559 107.053 206.812 107.893 206.586 108.933L204.106 120.2C203.626 122.386 202.012 124.026 199.799 124.586C198.986 124.786 198.159 124.826 197.359 124.706C195.906 124.493 194.559 123.76 193.546 122.586L185.812 113.573C185.079 112.706 183.959 112.253 182.826 112.333L170.986 113.213Z"
      fill="#A6C8FF"
    />
    <path
      d="M137.613 210.466C136.693 207.759 135.546 204.386 137.706 200.186C139.866 195.986 143.266 194.959 145.999 194.119C148.519 193.346 150.519 192.746 151.893 190.053C153.279 187.359 152.599 185.386 151.759 182.893C150.839 180.186 149.693 176.813 151.853 172.613C154.013 168.413 157.413 167.386 160.146 166.546C162.666 165.773 164.666 165.173 166.039 162.479C167.413 159.786 166.746 157.813 165.906 155.319C164.986 152.613 163.839 149.253 165.999 145.053C168.159 140.853 171.559 139.826 174.293 138.986C176.813 138.213 178.813 137.613 180.186 134.919C181.559 132.226 180.893 130.253 180.053 127.759C179.133 125.053 177.986 121.679 180.146 117.493C180.653 116.506 181.853 116.119 182.84 116.626C183.826 117.133 184.213 118.333 183.706 119.319C182.319 122.013 183 123.986 183.84 126.479C184.759 129.186 185.906 132.559 183.746 136.746C181.586 140.946 178.186 141.986 175.453 142.813C172.933 143.586 170.933 144.186 169.559 146.879C168.186 149.573 168.853 151.546 169.693 154.039C170.613 156.746 171.759 160.106 169.599 164.306C167.439 168.506 164.039 169.533 161.306 170.373C158.786 171.146 156.786 171.746 155.413 174.439C154.026 177.133 154.706 179.106 155.546 181.599C156.466 184.306 157.613 187.679 155.453 191.879C153.293 196.079 149.893 197.106 147.159 197.946C144.639 198.719 142.639 199.319 141.266 202.013C139.879 204.706 140.559 206.679 141.399 209.173C142.319 211.879 143.466 215.253 141.306 219.453C140.946 220.146 140.253 220.546 139.519 220.546C139.213 220.546 138.906 220.479 138.613 220.319C137.626 219.813 137.239 218.613 137.746 217.626C139.133 214.933 138.453 212.959 137.613 210.466Z"
      fill="#A6C8FF"
    />
  </svg>

