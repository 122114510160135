import React, { useEffect, useState } from "react"
import {
  adminTopMenu,
  leftMenu,
  rightMenu,
  adminBottomMenu,
  rightMenuUnauthenticated
} from "mockData/menus"
import MagicButton from "components/MagicButton/MagicButtonV3"
import MB from "components/MB/MB"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router"
import { setCollapsedMenu, setOnBehalfIdInState } from "store/modules/page"
import { useSelector } from "store"
import { logoutUser } from "store/modules/auth"
import { removeJavaToken } from "store/justActions/javaAuth"
import { createNewImport, loadImports } from "store/justActions/lists"
//import ReactDOMServer from 'react-dom/server'
import {
  Lang,
  lang,
  Translate,
  withLocalize,
  getTranslate
} from "components/Lang/Lang"
import useWindowSize from "utils/useWindowsSize"

export default withLocalize(function SideMenuClosed(props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const { left, leftHandler, rightHandler } = props
  const closeOpenMenusIfOpen = () => {
    left ? rightHandler(true) : leftHandler(true)
  }
  const translate = props.translate
  const windowSize = useWindowSize()
  const collapsedMenu = useSelector((state) => state?.page?.collapsedMenu)
  const url = window.location.pathname?.split?.("/")
  const authenticatedState = useSelector((state) => state.auth?.authenticated)
  const userPermissions = useSelector(
    (state) => state.auth?.userData?.role?.permissions
  )

  const filteredRightMenu = rightMenu?.topItems?.filter?.(menuItem => {
    if(menuItem?.id === "rightInvoices" && !userPermissions?.includes?.("CLIENT_INVOICE_READ"))
      return false
    if(menuItem?.id === "leftEntriesOfferts"
      && !userPermissions?.includes?.("CUSTOMER_OFFER_READ"))
        return false
    else if(menuItem?.id === "leftEntriesOfferts"
      && windowSize.width < 1340)
        return false
    else if(menuItem?.id === "leftEntriesExcel"
      && windowSize.width < 768)
        return false
    else return true
  })
  const filteredLeftMenu = leftMenu?.topItems
  // ?.filter?.(menuItem => {
  //   if(menuItem?.id === "leftEntriesOfferts"
  //     && !userPermissions?.includes?.("CUSTOMER_OFFER_READ"))
  //       return false
  //   else if(menuItem?.id === "leftEntriesOfferts"
  //     && windowSize.width < 1340)
  //       return false
  //   else if(menuItem?.id === "leftEntriesExcel"
  //     && windowSize.width < 768)
  //       return false
  //   else return true

  // })



  useEffect(() => {
    // dispatch(setCollapsedMenu(false))
  }, [])
  const menuButton = (itemIn, index) => {
    const nameString = translate?.(itemIn?.name) //gettranslate?.(`${itemIn?.name}`)
    // <Translate>{
    //   ({ translate }) => translate?.(`${itemIn?.name}`)
    // }</Translate>
    //`${lang(itemIn?.name)}`
    const item = { ...itemIn, name: nameString }
    return (
      <MagicButton
        key={index}
        h={10} //{10}
        //p={3}//{3}
        //w={12}
        square
        menu
        //simple
        //type="simple"
        popLeft={!left ? item?.name : undefined}
        popRight={left ? item?.name : undefined}
        onClick={
          item?.action === "logoutUser"
            ? () => {
                dispatch(logoutUser())
                dispatch(setOnBehalfIdInState())
                // dispatch(removeJavaToken()) check root reducer for changes
                closeOpenMenusIfOpen()
              }

            : () => closeOpenMenusIfOpen()
        }
        {...item}
      />
    )
  }

  const openEditMode = () => {
    if (url?.[url?.length - 1] !== "edit")
      history?.push?.(
        `${window.location.pathname}/edit${window.location.search ?? ""}`
      )
  }

  const backButton = {
    name: "Back",
    svg: "back",
    onClick: () => {
      dispatch(setCollapsedMenu(false))
    }
  }

  const formButtons = [
    {
      name: "View",
      svg: "details",
      url: `/${url?.[1]}/${url?.[1]}/form`,
      onClick: () =>
        history?.push?.(
          window.location.search
            ? `/${url?.[1]}/${url?.[1]}/form${window.location.search}`
            : `/${url?.[1]}/${url?.[1]}/`
        )
    },
    {
      name: "Edit",
      svg: "edit",
      url: `/${url?.[1]}/${url?.[1]}/form/edit`,
      onClick: () => openEditMode()
    },
    {
      name: "Add",
      svg: "expand",
      url: `/${url?.[1]}/${url?.[1]}/form/add`
    }
  ]

  let menuEntries
  const section = url?.[1]
  const directProductPage = url?.[2] === "pd"
  const directCategoryPage = url?.[2] === "ct"
  if (section !== "admin" || directProductPage || directCategoryPage) {
    if (left)
      menuEntries = authenticatedState
        ? {
            ...leftMenu,
            topItems: filteredLeftMenu?.filter?.(
              (i) => i.id !== "leftEntriesCareers"
            ),
            bottomItems: undefined
          }
        : {
            ...leftMenu,
            topItems: filteredLeftMenu?.filter?.(
              (i) =>
                ![
                  "leftEntriesExcel",
                  "leftEntriesOfferts"
                ]?.includes?.(i?.id)
            )
          }
    else menuEntries = authenticatedState ? {...rightMenu, topItems: filteredRightMenu} : rightMenuUnauthenticated
  }

  const getAdminLeftItems = () => {
    if (collapsedMenu) {
      // let activeSection = leftAdminMenu?.find?.(item => item?.split?.[1] === url?.[1])
      let activeSection = adminTopMenu?.find?.(
        (item) => item?.url?.split?.("/")[2] === url?.[2]
      )
      return [{ ...backButton }, { ...activeSection, active: true }]
    } else
      return adminTopMenu?.map?.((item) => ({
        ...item,
        url: undefined,
        onClick: () => {
          history?.push?.(item?.url)
          if (item?.subMenu) dispatch(setCollapsedMenu(true))
        },
        active: window.location.pathname?.includes?.(item?.url)
      }))
  }

  const getAdminRightItems = () => {
    if (url?.[url?.length - 1] !== "form")
      return adminTopMenu?.find?.(
        (item) => item?.url?.split?.("/")[2] === url?.[2]
      )?.rightTopButtons
    else return formButtons
  }

  if (section === "admin") {
    if (left)
      menuEntries = {
        topItems: getAdminLeftItems(),
        bottomItems: adminBottomMenu
      }
    if (!left) menuEntries = { topItems: getAdminRightItems() }
  }

  return (
    <nav data-test-id='6Uin' className={left ? "leftMenu" : "rightMenu"}>
      <MB flex dc>
        {menuEntries?.topItems?.map?.((item, index) => menuButton(item, index))}
      </MB>
      {authenticatedState && (
        <div data-test-id='nKpq'>
          {menuEntries?.bottomItems?.map?.((item, index) =>
            menuButton(item, index)
          )}
        </div>
      )}
    </nav>
  )
})

//change
