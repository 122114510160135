import { useDispatch, useSelector } from "react-redux"
import { fetchTranslations } from "store/modules/localizationApi"
import { useEffect } from "react"

/**
 * We fetch data from the API.
 * If it already isLoading, do not fetch again.
 * If we already have the data, do not fetch again.
 */
const useTranslations = () => {
  const dispatch = useDispatch()
  const apiData = useSelector((state) => state.localizationApi?.translations)

  useEffect(() => {
    if (!apiData?.wasCalled) dispatch(fetchTranslations())
  }, [apiData])

  return apiData
}

export default useTranslations
