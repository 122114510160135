import BreadCrumbs from "components/BreadCrumbs/BreadCrumbsV2" ///////////
import MagicButton from "components/MagicButton/MagicButtonV2"
import { useHistory } from "react-router"
import { Lang, lang, Translate, withLocalize } from "components/Lang/Lang"
const FormPormPageHeader = ({
  pageTitle,
  submitForm,
  disableSubmit,
  cancelEdit,
  setEdit,
  isEdit,
  addNew,
  ro,
  specificButtonLabelsInEditMode,
  buttonIconsInEditMode,
  rejectRequest,
  onlyTitle,
  userStatusButtonProps,
  changeStatus,
  deleteAction
}) => {
  const history = useHistory()

  return (
    <>
      <div data-test-id='AtG8' className="flex sb mb6">
        <div data-test-id='riwe'>
          <BreadCrumbs />
          <div data-test-id='O3w3' className="etype-2 mt7">{pageTitle}</div>
        </div>
        {onlyTitle ? (
          <></>
        ) : (
          !ro &&
          (isEdit ? (
            <div data-test-id='9YDd' className="flex">
              <MagicButton
                svg={buttonIconsInEditMode && "back"}
                mr={3}
                w={18}
                outline
                label={
                  specificButtonLabelsInEditMode ? (
                    specificButtonLabelsInEditMode.revert
                  ) : (
                    <Lang value="formPormPageHeader.giveUp" />
                  )
                }
                onClick={() =>
                  specificButtonLabelsInEditMode?.revert ===
                  "Respinge solicitare"
                    ? rejectRequest()
                    : addNew
                    ? history.goBack()
                    : cancelEdit()
                }
              />
              <MagicButton
                svg={buttonIconsInEditMode && "savelist"}
                w={18}
                label={
                  specificButtonLabelsInEditMode ? (
                    specificButtonLabelsInEditMode.save
                  ) : (
                    <Lang value="formPormPageHeader.save" />
                  )
                }
                onClick={submitForm}
                disabled={disableSubmit}
              />
            </div>
          ) : (
            <div data-test-id='rDmS' className="flex">
              {userStatusButtonProps && !addNew && (
                <>
                  <MagicButton
                    wc
                    //svg= {buttonIconsInEditMode && "back"}
                    mr={3}
                    w={18}
                    type={userStatusButtonProps.firstColor}
                    label={userStatusButtonProps.first}
                    onClick={() =>
                      changeStatus(userStatusButtonProps.firstAction)
                    }
                  />
                  <MagicButton
                    wc
                    //svg= {buttonIconsInEditMode && "back"}
                    mr={15}
                    w={18}
                    type={userStatusButtonProps.secondColor}
                    label={userStatusButtonProps.second}
                    onClick={() =>
                      changeStatus(userStatusButtonProps.secondAction)
                    }
                  />
                </>
              )}
              {deleteAction && !addNew &&
                <MagicButton
                  w={18}
                  mr={4}
                  wc
                  label="Sterge"
                  onClick={deleteAction}
                />
              }
              <MagicButton
                w={18}
                label={<Lang value="formPormPageHeader.edit" />}
                onClick={setEdit}
              />
            </div>
          ))
        )}
      </div>
    </>
  )
}
export default FormPormPageHeader
