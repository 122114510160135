const ButtonLoadingStateInner = () => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  }
  return (
    <div data-test-id='9dyC' {...{ style, className: "loading-indicator" }}>
      <div data-test-id='SUx4' className="lds-roller">
        <div data-test-id='s80w'></div>
        <div data-test-id='sCcn'></div>
        <div data-test-id='sttH'></div>
        <div data-test-id='b3IO'></div>
        <div data-test-id='A8Kg'></div>
        <div data-test-id='xX5H'></div>
        <div data-test-id='J7eK'></div>
        <div data-test-id='ShMo'></div>
      </div>
    </div>
  )
}

export default ButtonLoadingStateInner
