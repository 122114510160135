import React from "react"
import UniLogo from "assets/UniLogo1.svg"
import { Link } from "react-router-dom"

function HomePageLogoButton(props) {
  const { companyType, shouldNotRenderAsLink } = props
  const userHomePage = React.useMemo(
    () => (companyType === "GUEST" ? "/" : "/shop/dashboard"),
    [companyType]
  )

  const logoImage = <img 
      data-test-id='9yKS'
      onContextMenu={(e) => e?.preventDefault?.()}
      className={`logo ${shouldNotRenderAsLink && "noHoverEffect"}`}
      alt="logo"
      src={UniLogo}
    />

  return (
    shouldNotRenderAsLink
    ?
      logoImage
    :
    <Link className="a-link-logo" to={userHomePage}>
      {logoImage}
    </Link>
  )
}

export default HomePageLogoButton
