import { createSelector } from "reselect"

const categoryFilter = (state) =>
  state?.dataView?.selectedFilters?.["FLT-CATEG001"]
const megaFilter = (state) => state?.dataView?.selectedFilters?.["FLT-CATMEGA1"]
const categoryValues = (state) => state?.backData?.productCategory

export const shopBreadcrumbsSelector = createSelector(
  megaFilter,
  categoryFilter,
  categoryValues,
  (megaFilter, categoryFilter, categoryValues) => {
    let selectedFilterID
    const categoryNames = []
    let categoryTreeCode
    let treeCodeValues = []

    let searchInTree = categoryValues
    let counter = 1
    // the order of filters is as it is, because in the java search stuff method
    // before calling api check if there is mega and only after uses small cat filter
    // QUESTION: should we unify these two filters in one category filter ?
    if (megaFilter) {
      selectedFilterID = "FLT-CATMEGA1"
      categoryTreeCode = megaFilter?.[0]
      treeCodeValues = megaFilter?.[0]
        ?.split?.("#")
        ?.slice?.(1)
        ?.map?.((v) => (v = `#${v}`))
      populateCategoryNames(
        treeCodeValues,
        counter,
        searchInTree,
        categoryNames
      )
    } else if (categoryFilter) {
      selectedFilterID = "FLT-CATEG001"
      categoryTreeCode = categoryFilter?.[0]
      treeCodeValues = categoryFilter?.[0]
        ?.split?.("#")
        ?.slice?.(1)
        ?.map?.((v) => (v = `#${v}`))
      populateCategoryNames(
        treeCodeValues,
        counter,
        searchInTree,
        categoryNames
      )
    }

    return {
      selectedFilterID,
      categoryNames: ["Toate produsele", ...categoryNames || []],
      //categoryTreeCode,
      treeCodeValues
    }
  }
)

const populateCategoryNames = (
  treeCodeValues,
  counter,
  searchInTree,
  categoryNames
) => {
  while (treeCodeValues?.[counter - 1]) {
    let newSearchInTree = searchInTree?.filter?.(
      (tree) =>
        tree.treeCode === treeCodeValues?.slice?.(0, counter)?.join?.("")
    )
    categoryNames?.push?.(
      `${newSearchInTree?.[0]?.name?.[0]?.toUpperCase?.()}${newSearchInTree?.[0]?.name
        ?.slice?.(1)
        ?.toLowerCase?.()}`
    )
    counter += 1
    searchInTree = newSearchInTree?.[0]?.childList?.filter?.(
      (ch) => ch.treeCode === treeCodeValues?.slice?.(0, counter)?.join?.("")
    )
  }
}
