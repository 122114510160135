import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import SvgIcon from "components/SvgIcon/SvgIcon.jsx"
import { setFilterSelection } from "store/modules/dataView"
import MagicButton from "./MagicButton/MagicButtonV2"
import "./MegaCategoryFilter.scss"
import { capitalizeFirstLetter } from "utils/stringUtils"
import BigModal from "./_modals/BigModal"
import { setPageModal } from "store/modules/page"
import useWindowSize from "utils/useWindowsSize"
import useQueryStringFilters from "utils/useQueryStringFilters"
import MyLink from "./MyLink"

const mockProps = {
  id: "FLT-CATEG001"
}

export const getCategorySvgName = (id) => {
  const svgNames = {
    "#27442": "security", // not yet in design
    "#27398": "ventilatie",
    "#27407": "apa",
    "#27448": "green-energy",
    "#27465": "gaz",
    "#27472": "sanitare",
    "#27501": "termice",
    "#27610": "lichidare",
    "#27616": "lichidare",
  }
  return svgNames?.[id]
}

const collumnOrderList = {
  //key = min screen size for the layout
  1200: [["#27501"], ["#27398", "#27472", "#27465"], ["#27448", "#27407"]],
  620: [["#27501"], ["#27398", "#27472", "#27465", "#27448", "#27407"]],
  0: [["#27501", "#27398", "#27472", "#27465", "#27448", "#27407"]]
}
let collumnOrder = []

function MegaCategoryFilter(props) {
  // const { id, noTopMargin} = props
  const id = "FLT-CATEG001"
  const { query, setFilter, setPage, getUrlWithNewCateg } = useQueryStringFilters()
  const selection = query?.filters?.[id] ?? []
  const cateogries = useSelector((state) => state.backData?.productCategory)
  const dispatch = useDispatch()
  const windowSize = useWindowSize()

  for (let key in collumnOrderList)
    if (key < windowSize.width) collumnOrder = collumnOrderList?.[key]

  const childProps = (item, level) => ({
    key: item?.id,
    className: `child--level${level} ${
      item?.treeCode === selection?.[0] ? "selected" : ""
    }`,
    to: getUrlWithNewCateg(item?.treeCode),

    onClick: () => {
      // dispatch(setFilterSelection(id, [item?.treeCode])) //logic moved to useQueryStringFilters
      setFilter(id, [item?.treeCode]) // this seemingly does nothing
      setPage(1)
      dispatch(setPageModal())
    }
  })

  const moveOtherToEnd = (item) => {
    const newItem = [...(item ?? [])]
    const otherIndex = item?.findIndex?.(
      (e) => e?.name?.toLowerCase?.() === "altele"
    )
    if (otherIndex >= 0) {
      const otherItem = newItem?.splice?.(otherIndex, 1)
      return [...newItem || [], ...otherItem || []]
    }
    return newItem
  }
  const createChildren = (item, level) =>
    moveOtherToEnd(item)?.map?.((child, index) => (
      <div data-test-id='CU3X' key={`child-${index}`}>
        <MyLink data-test-id='RNE5' {...childProps(child, level)}>
          {capitalizeFirstLetter(child?.name)}
        </MyLink>
        {createChildren(child?.childList, level + 1)}
      </div>
    ))

  const oneCategory = (item) => (
    <div data-test-id='MOHL' key={`one-category-${item?.id}`} className="one-category ptype-5">
      <div data-test-id='FTzo' className="top-category-wrapper">
        <div data-test-id='f7iP' className="top">
          <SvgIcon name={getCategorySvgName(item?.treeCode)} />
          <div data-test-id='Vrre' className="top--right">
            <div data-test-id='11Nl' className="etype-1">{item?.name}</div>
            {/* <div data-test-id='lc8k'>{item?.count}</div> */}
          </div>
        </div>
        <div data-test-id='uusM' className="top--spacer" />
      </div>
      {createChildren(item?.childList, 0)}
    </div>
  )

  return (
    <div data-test-id='3Hso' className={`mega-category-filter-wrapper`}>
      <MagicButton
        label="Gama de produse"
        rightSvg="expand-clear"
        showModal="mega-category-filter"
      />
      <BigModal name="mega-category-filter" title="Gama de produse Uniprest" ro>
        <div data-test-id='Ufw2' className="inner-grid">
          {collumnOrder?.map?.((collIds, index) => (
            <div data-test-id='5MOT' key={`column-${index}`} className="collumn">
              {collIds?.map?.((id) =>
                oneCategory(cateogries?.find?.((e) => e?.treeCode === id))
              )}
            </div>
          ))}
        </div>
      </BigModal>
    </div>
  )
}

export default MegaCategoryFilter
