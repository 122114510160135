import MagicButton from "components/MagicButton/MagicButton"
import MagicInput from "components/MagicInput/MagicInpuv2"
import MB from "components/MB/MB"
import BigModal from "components/_modals/BigModal"
import ModalListCard1 from "components/_modals/listCards/ModalListCard1"
import ListModal from "components/_modals/ListModalV2"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { addProductToList, createNewList } from "store/justActions/lists"
import { selectListCardData } from "store/selectors/dataView/dataView.productsListFinal.selector"
import "./AddToListModal.scss"
import { Lang, lang, Translate, withLocalize } from "components/Lang/Lang"
function AddToListModal(props) {
  // const {setCardState, item, closeModal} = props
  const translate = props.translate
  const [listItem, setListItem] = useState({})
  const [listName, setListName] = useState("")
  const dispatch = useDispatch()
  const modalData = useSelector((state) => state.page?.modalData)
  const saveAction = () => {
    dispatch(addProductToList({ listItem: listItem?.[0], product: modalData }))
  }
  // const {lists} = useSelector(state => selectListCardData(state))
  // const onKeyDown = e => {
  //     setListName(e?.target?.value)
  //     if (e?.key === 'Enter')
  //         if (e?.target?.value) {
  //             dispatch(createNewList({name: e?.target?.value, products:[item]}));
  //             setListName('')
  //         }
  // }
  // const addToList = () => {
  //     const listItem = lists?.find?.(item => item?.id === selectedList)?.item
  //     dispatch(addProductToList({listItem, product:item}))
  //     closeModal() ?? setCardState("listCard")
  // }
  // const alreadyInList = (lists?.find?.(l => l.id === selectedList)?.item?.products ?? [])?.find?.(e => e?.id === item?.id)

  const onAddList = (e) => {
    dispatch(createNewList({ name: listName, products: [], crtList: true }))
    setListName("")
  }
  const listsListModalProps = {
    title: translate?.("addToListModal.add"),
    name: "addToList",
    single: true,
    noSelectionToggle: true,
    onChange: setListItem,
    // selectionLabel : ["utilizator selectat", "utilizatori selectati"],
    placeholder: translate?.("addToListModal.search"),
    // label: "Alege Agentul",
    itemsType: "dynamoComapnyLists"
  }

  // const disabledSave = selectedList === '' || alreadyInList
  return (
    <BigModal {...{ ...listsListModalProps, saveAction }}>
      <div data-test-id='T55v' className="list-of-lists-modal-wrapper">
        <div data-test-id='uHnM' className="flex">
          <MagicInput
            h={8}
            outline
            {...{
              value: listName,
              onChange: (e) => setListName(e?.target?.value),
              placeholder: translate?.("addToListModal.write"),
              icon: "add"
            }}
          />
          <MagicButton
            label={<Lang value="offerList.add" />}
            onClick={onAddList}
            disabled={listName?.length < 3}
          />
        </div>
        <div data-test-id='11Wj' className="line" />

        <ListModal noModal single noResultCount {...listsListModalProps}>
          <ModalListCard1 listOfLists noCheckBox />
        </ListModal>
      </div>
    </BigModal>
  )
}

export default withLocalize(AddToListModal)

const mockLists = [
  {
    id: 1,
    name: "Nume lista 1",
    products: "23 produse",
    date: "Creata pe 12 APR 2020 de Ionut Popescu "
  },
  {
    id: 2,
    name: "Nume lista 2",
    products: "23 produse",
    date: "Creata pe 12 APR 2020 de Ionut Popescu "
  },
  {
    id: 3,
    name: "Nume lista 3",
    products: "23 produse",
    date: "Creata pe 12 APR 2020 de Ionut Popescu "
  },
  {
    id: 4,
    name: "Nume lista 4",
    products: "23 produse",
    date: "Creata pe 12 APR 2020 de Ionut Popescu "
  }
]

// <>
//       <MB mb={3} flex sb va>
//         <div data-test-id='VuFa' className="etype-1">Adauga in lista</div>
//         <MagicButton
//           h={4}
//           svg="Close round"
//           type="simple"
//           onClick={() => closeModal ? closeModal() : setCardState( "listCard")}
//         />
//       </MB>
//       <div data-test-id='DIGh' className="grey-list-outer">
//         <div data-test-id='H080' className="grey-list-wrapper">
//       <div data-test-id='z3hX' className="mb3 ptype-4-price color-text-light-03">  ADAUGA INTR-O LISTA NOUA </div>
//       <MagicInput blue placeholder="Scrie aici numele listei noi" {...{onKeyDown, value: listName, onChange: e => setListName(e?.target?.value)}}/>
//       <div data-test-id='LhYX' className="mb3 mt6 ptype-4-price color-text-light-03">  ALEGE O LISTA EXISTENTA </div>
//           {lists?.map?.((item,index) => { return (
//             <div data-test-id='yjfX' key={`prod-${index}`}
//               className={item?.id === selectedList?"grey-option list selected":"grey-option list"}
//               onClick={() => setListItem(item?.id)}
//             >
//               <div data-test-id='UZOZ' className="mb1 ptype-5-strong">{`${item?.name}`}</div>
//               <div data-test-id='AIzW' className="ptype-3">{`${item?.products}`}</div>
//               <div data-test-id='6vJ3' className="ptype-3">{`${item?.date}`}</div>
//             </div>
//           )})}
//         </div>
//       </div>
//       <MagicButton h={8} disabled={disabledSave} noFlex label={alreadyInList ? 'Deja in lista' : "Confirma"} onClick={addToList}/>
//     </>
