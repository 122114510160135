import { apiAction, reDispatch } from "store/middleware/api"
import {
  getAllClientCustomers,
  getAllClientExternalProducts,
  setBackList,
  SET_DICTIONARY
} from "store/modules/backData"
import { SET_VOLUME_DISCOUNT_QUNTITIES } from "store/modules/dataView"
import { loadViewData } from "store/modules/dataView/dataView-dataLoader"
import { showMagicToaster } from "utils/MagicToasterCall"
import store from "store/index"
import { loadItem } from "store/justActions/loadApiIems"
import { searchStuff } from "store/modules/dataView.search"
import { printError } from "utils/printError"

export const changeStatusAPI = (props) => {
  const { itemType, id, status, successMessage } = props

  if (!itemType || !id || !status) {
    console.error(
      "Missing some data to call API action (itemType, id or status)"
    )
    return { type: "error" }
  }
  return apiAction({
    endPoint: `/${itemType}/status-change?id=${id}&status=${status}`,
    method: "POST",
    onSuccess: () => {
      //TODO: toastr issues
      // if (successMessage)
      //   showMagicToaster({ title: successMessage})
      return reDispatch((dispatch) => dispatch(loadViewData({ reload: true })))
    },
    onFailure: (error) => {
      printError("changeStatusAPI()", error)
      return { type: "a" }
    }
  })
}

export const changePatchStatus = (props) => {
  const { itemType, id, status, successAction } = props

  if (!itemType || !id || !status) {
    console.error(
      "Missing some data to call API action (itemType, id or status)"
    )
    return { type: "error" }
  }
  return apiAction({
    endPoint: `/${itemType}/status-change?id=${id}&status=${status}`,
    method: "PATCH",
    onSuccess: () => {
      //TODO: toastr issues
      // if (successMessage)
      //   showMagicToaster({ title: successMessage})
      return reDispatch((dispatch) => dispatch(successAction()))
    },
    onFailure: (error) => {
      printError("changePatchStatus()", error)
      return { type: "a" }
    }
  })
}

export const saveProductsRank = ({ modalData, data }) => {
  return apiAction({
    endPoint: "/product/set-rank",
    method: "POST",
    data: {
      rank: data?.rank,
      idList: modalData
    },
    onSuccess: (data, dispatch) => {
      showMagicToaster({ title: "Datele au fost salvate" })
      return { type: "a" }
    },
    onFailure: (error) => {
      printError("saveProductsRank()", error)
      return {
        type: "a"
      }
    }
  })
}

export const changeOfferStatus = (id, status) => {
  return apiAction({
    // endPoint: `/customer-offer/client-delete/${id}`,
    endPoint: `/customer-offer/status-change?id=${id}&status=${status}`,
    //method: "DELETE",
    method: "PATCH",
    onSuccess: (data, dispatch) => {
      dispatch(loadItem("customer-offer", id, true, false))
      return { type: "a" }
    },
    onFailure: (error) => {
      printError("changeOfferStatus()", error)
      showMagicToaster({
        title: "A aparut o eroare, incearca mai tarziu",
        type: "error"
      })
      return {
        type: "a"
      }
    }
  })
}

export const deleteCustomerClient = (label) => {
  return apiAction({
    endPoint: `/customer-offer/external-client/${label}`,
    method: "DELETE",
    onSuccess: (data, dispatch) => {
      dispatch(getAllClientCustomers())

      showMagicToaster({ title: "Clientul a fost șters" })
      return { type: "a" }
    },
    onFailure: (error) => {
      printError("deleteCustomerClient()", error)
      showMagicToaster({
        title: "A aparut o eroare, incearca mai tarziu",
        type: "error"
      })
      return {
        type: "a"
      }
    }
  })
}

export const deleteClientExternalProduct = (label) => {
  return apiAction({
    endPoint: `/customer-offer/external-product/${label}`,
    method: "DELETE",
    onSuccess: (data, dispatch) => {
      dispatch(getAllClientExternalProducts())

      showMagicToaster({ title: "Produsul a fost șters" })
      return { type: "a" }
    },
    onFailure: (error) => {
      printError("deleteClientExternalProduct()", error)
      showMagicToaster({
        title: "A aparut o eroare, incearca mai tarziu",
        type: "error"
      })
      return {
        type: "a"
      }
    }
  })
}

export const loadSearchInList = (type = "uniprest/user") => {
  // let state = store.getState();

  return apiAction({
    endPoint: `/${type}/search`,
    method: "POST",
    data: {
      // ...productSearchData,
      // filterText: "",
      sortAsc: true,
      sortField: "id",
      firstRow: 0,
      pageSize: 1000
      // searchTerm: "",
      // filters: createClassicFilters(),
      // jsonLogic: {
      //   "operation": "AND",
      //   "children": null,
      //   "field": null,
      //   "value": null
      // }
    },
    onSuccess: (data, dispatch) => {
      dispatch(setBackList(type, data?.items))
      return { type: "a" }
    },
    onFailure: (error) => {
      printError("loadSearchInList()", error)
      return {
        type: "a"
      }
    }
  })
}

export const getVolumeDiscountQuantities = (id, productId) =>
  apiAction({
    endPoint: `/product/goods/discount/volume/quantities/${id}`,
    // method: "POST",
    // data : {
    //   goodsId: id,
    //   quantity: quantity,
    // },
    onSuccess: (data, dispatch) => {
      // dispatch(setCartData(data?.basket));
      return {
        type: SET_VOLUME_DISCOUNT_QUNTITIES,
        payload: {
          data,
          productId
        }
      }
    },
    onFailure: (error) => {
      printError("getVolumeDiscountQuantities()", error)
      return {
        type: "a"
      }
    }
  })
export const getCartDeliveryAddress = (inId) => {
  let state = store.getState()
  const orgId = state?.user?.organizationId
  if (!orgId) return { type: "a" }
  return apiAction({
    //Needed to separate, if onBehalf calls we use the change api, otherwise the basic addresslist
    endPoint: inId
      ? `/address/by-organization/${inId}`
      : "/address/my-organization", //my-organization
    // /uniprest-api/address/by-organization/Xl0Jr1
    // method: "POST",
    // data :
    //   {"firstRow":0,"pageSize":13,"sortField":"id","sortAsc":false,"filters":[],"filterText":"","searchTerm":null,"jsonLogicText":"{\"and\": []}"}
    // ,
    onSuccess: (data, dispatch) => {
      // const final = data?.items?.filter?.((address) => address.status === "ACTIVE")

      let payload = {}
      payload["ORG_ADDRESS_LIST"] = data ?? []
      return {
        type: SET_DICTIONARY,
        payload
      }
    },
    onFailure: (error) => {
      printError("getCartDeliveryAddress()", error)
      return {
        type: "a"
      }
    }
  })
}

export const setUserStatus = (id, status, isAdmin) => {
  //const itemType = "productDetails";
  return apiAction({
    endPoint: `/user/status-change?id=${id}&status=${status}&suspendReason=EXIT`,
    //endPoint: `/product/status-change/?id=${id}&status=${status}`,
    method: "PATCH",
    // data : {
    //   goodsId: id,
    //   quantity: quantity,
    // },
    onSuccess: (data, dispatch) => {
      // dispatch(loadItem(itemType, id))
      !isAdmin &&
        dispatch(
          searchStuff({
            type: "uniprest/user/my-organization",
            pageSize: 100,
            reload: true
          })
        )
      isAdmin && dispatch(loadItem("user", id))
      return {
        type: "a"
      }
    },
    onFailure: (error) => {
      printError("setUserStatus()", error)
      return {
        type: "a"
      }
    }
  })
}

export const setProductState = (id, status) => {
  const itemType = "productDetails"

  return apiAction({
    endPoint: `/product/status-change/?id=${id}&status=${status}`,
    method: "POST",
    // data : {
    //   goodsId: id,
    //   quantity: quantity,
    // },
    onSuccess: (data, dispatch) => {
      dispatch(loadItem(itemType, id))
      return {
        type: "a"
      }
    },
    onFailure: (error) => {
      printError("setProductState()", error)
      return {
        type: "a"
      }
    }
  })
}
