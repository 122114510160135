import { apiAction, dynamoAction } from "store/middleware/api"
import geenrateId from "utils/geenrateIds"
import { showMagicToaster } from "utils/MagicToasterCall"
import moment from "moment"
import { marshall } from "@aws-sdk/util-dynamodb"

import { setPageEdit } from "store/modules/page"
import store from "store/index"
import { loadDynamoFormItemByPK_v2 } from "store/modules/dataView.search"
import { printError } from "utils/printError"
import { isClientInvoiceCancelled } from "utils/invoiceUtils"

// import { SET_AR  TICLES, SET_ARTICLES_SS, SET_JOBS, SET_PARTNERS, SET_SELECT_DATA, SET_UNISERVICES, TOGGLE_LEFT_MENU_VISIBILITY } from 'store/modules/guest'

export const savePriceRequest = (
  product,
  selectedQuantity,
  status,
  companyName,
  companyId,
  companyType,
  type,
  user,
  reqId
) => {
  let state = store.getState()
  const eDate = moment().toISOString()
  const cDate = eDate

  const SK = "REQUEST_PRICE"
  const pageReloadCases = ["RESPONSE", "DELETE"]
  const itemToMarshall = {
    PK: product?.PK ?? geenrateId("priceRequest"),
    SK: SK,
    product: product,
    selectedQuantity: selectedQuantity,
    user: user ? user : state.auth?.userData?.role?.user,
    status: status,
    companyName: companyName,
    companyId: companyId,
    companyType: companyType,
    cDate,
    eDate
  }
  return dynamoAction({
    method: "PUT",
    parameters: {
      TableName: process.env.REACT_APP_TABLE_NAME,
      Item: marshall(itemToMarshall, { removeUndefinedValues: true })
    },
    onSuccess: (data, dispatch) => {
      type !== "RESPONSE" &&
        dispatch(
          notifyRequest(product?.props?.id, "PRODUCT_NO_PRICE_REQUEST_NOTIFY")
        )
      pageReloadCases?.includes?.(type) &&
        dispatch(loadDynamoFormItemByPK_v2({ input: reqId, combine: true }))
      showMagicToaster({
        title:
          isClientInvoiceCancelled(product?.status)
            ? "Solicitarea a fost anulată cu succes"
            : product?.status === "NOTIFIED"
            ? "Emailul a fost trimis"
            : "Datele au fost salvate"
      })
      dispatch(setPageEdit(false))
      return { type: "a" }
    },
    onFailure: (err) => {
      printError("savePriceRequest()", err)
      showMagicToaster({
        title: "A aparut o eroare la salvarea datelor",
        subtitle: "E201",
        type: "error"
      })
      return {
        type: "a"
      }
    }
  })
}

export const saveStockRequest = (
  product,
  selectedQuantity,
  status,
  companyName,
  companyId,
  companyType,
  type,
  user,
  reqId
) => {
  let state = store.getState()
  const eDate = moment().toISOString()
  const cDate = eDate

  const SK = "REQUEST_STOCK"

  const itemToMarshall = {
    PK: product?.PK ?? geenrateId("stockRequest"),
    SK: SK,
    product,
    selectedQuantity: selectedQuantity,
    user: user ? user : state.auth?.userData?.role?.user,
    status: status,
    companyName: companyName,
    companyId: companyId,
    companyType: companyType,
    cDate,
    eDate
  }

  const pageReloadCases = ["RESPONSE", "DELETE"]
  return dynamoAction({
    method: "PUT",
    parameters: {
      TableName: process.env.REACT_APP_TABLE_NAME,
      Item: marshall(itemToMarshall, { removeUndefinedValues: true })
    },
    onSuccess: (data, dispatch) => {
      type !== "RESPONSE" &&
        dispatch(
          notifyRequest(product?.props?.id, "PRODUCT_NO_STOCK_REQUEST_NOTIFY")
        )
      pageReloadCases?.includes?.(type) &&
        dispatch(loadDynamoFormItemByPK_v2({ input: reqId, combine: true }))
      showMagicToaster({
        title:
          isClientInvoiceCancelled(product?.status)
            ? "Solicitarea a fost anulată cu succes"
            : product?.status === "NOTIFIED"
            ? "Emailul a fost trimis"
            : "Datele au fost salvate"
      })
      dispatch(setPageEdit(false))
      return { type: "a" }
    },
    onFailure: (err) => {
      printError("saveStockRequest()", err)
      showMagicToaster({
        title: "A aparut o eroare la salvarea datelor",
        subtitle: "E201",
        type: "error"
      })
      return {
        type: "a"
      }
    }
  })
}

export const notifyRequest = (productId, notifyTypeCode) => {
  return apiAction({
    endPoint: "/product/goods/notify-request",
    method: "POST",
    data: {
      productId,
      notifyTypeCode
    },
    onSuccess: (data, dispatch) => {
      return {
        type: "a"
      }
    },
    onFailure: (error, dispatch) => {
      printError("notifyRequest()", error)
      return {
        type: "a"
      }
    }
  })
}
