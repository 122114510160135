import DualRangeSlider from "components/VerticalFilters/DualRangeSlider/DualRangeSlider"
import ExpandableSection from "components/ExpandableSection/ExpandableSection"
import { useSelector } from "react-redux"
import "./index.scss"
// import FilterSection from "views/invoices/InvoiceComponents/FilterSection/FilterSection"
import { selectComputedFilters } from "store/selectors/dataView/finalFilters.selector"
import MultiSelectFilter from "components/VerticalFilters/MultiSelectFilter/MultiSelectFilter"
import SelectedFilters from "components/VerticalFilters/SelectedFilters/SelectedFilters"
import SavedFilters from "components/VerticalFilters/SavedFilters/SavedFilters"
import { searchStuff } from "store/modules/dataView.search"
import { useDispatch } from "store"
import { useEffect } from "react"
import { loadViewData } from "store/modules/dataView/dataView-dataLoader"
import CategoryFilter from "components/CategoryFilter"
import useQueryStringFilters from "utils/useQueryStringFilters"
export default function VerticalFilters(props) {
  const {
    filterIds,
    showSelectedFilters,
    showSavedFilters,
    disableSave,
    pageScroll
  } = props

  const { filters, appliedFilters, savedFilters } = useSelector((state) =>
    selectComputedFilters(state)
  )
  // const searchString = useSelector(state => state.page?.crtList?.searchString)
  // const dispatch = useDispatch()
  // const [sortKey, sortDirection] = sort
  // useEffect(() => {
  //   dispatch(loadViewData({reload:true}))
  // }, [appliedFilters, dispatch, searchString, appliedFilters?.["FLT-CATEG001"]])
  const displayFilters = filterIds
    ? filters?.filter?.((item) => filterIds?.includes?.(item?.id))
    : filters
  return (
    <div 
      data-test-id='Az7J'
      className={`vertical-filter-section ${pageScroll ?? null}`}
      id="vertical-section-id"
    >
      {showSelectedFilters && (
        <SelectedFilters {...{ filters, appliedFilters, disableSave }} />
      )}

      {showSavedFilters && (
        <ExpandableSection
          down
          title="Aplica un filtru salvat"
          titleClass="titleStyle ptype-4-bold grey-16"
        >
          <SavedFilters {...{ savedFilters }} />
        </ExpandableSection>
      )}
      {displayFilters?.map?.((filter, index) => (
        <ExpandableSection
          down
          key={index}
          startClosed={filter?.expanded}
          title={filter.label}
          titleClass="bg-white p4 ptype-4-bold grey-16"
          shouldLimitAndScroll={filter?.id === 'FLT-SMaaaa00'}
        >
          {filter.type === "range" && (
            <DualRangeSlider
              key={index}
              id={filter.id}
              defaultValue={filter.selection}
              min={filter?.data?.[0]}
              max={filter?.data?.[1]}
            />
          )}
          {filter.type === "category" && <CategoryFilter withQs {...filter} />}
          {filter.type === "multiSelect" && (
            <MultiSelectFilter key={index} withQs {...filter} />
          )}
          {filter.type === "5stars" && (
            <MultiSelectFilter key={index} {...filter} stars />
          )}
          {filter.type === "jobCityFilter" && (
            <MultiSelectFilter key={index} withQs {...filter} />
          )}
        </ExpandableSection>
      ))}
      {/* <button data-test-id='98qc' onClick={() => dispatch(searchStuff({type:'product', reload:true}))}>Load</button> */}
    </div>
  )
}
