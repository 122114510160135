import React, { useState } from "react"
import add from "components/ExpandableSection/add.svg"
import minus from "components/ExpandableSection/minus.svg"
import MinusSmall from "components/ExpandableSection/MinusSmall.svg"
import downIcon from "components/ExpandableSection/down.svg"
import "./index.scss"

export default function ExpandableSection(props) {
  const {
    title,
    titleClass,
    down,
    startClosed,
    unconditionalBody, //displayed under title in any case
    shouldLimitAndScroll = false, 
  } = props
  const [open, setOpen] = useState(startClosed)
  const icon = open ? (down ? MinusSmall : minus) : down ? downIcon : add
  return (
    <div data-test-id='V0z3' className={`es-container ${titleClass}`}>
      <div data-test-id='vdlK' className={`es-titleSection`} onClick={() => setOpen((p) => !p)}>
        <div data-test-id='K9lG' className="es-title">{title}</div>
        <img data-test-id='vO61' onContextMenu={(e) => e?.preventDefault?.()} src={icon} alt="sign" />
      </div>
      {unconditionalBody}
      <div data-test-id='ypWB' className={`expand-wrap es-body ${open ? "" : "collapsed"} ${shouldLimitAndScroll ? 'limitAndScroll' : ''}`}>
        {props.children}
      </div>
    </div>
  )
}
