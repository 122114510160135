export default function ProductRating({ rating, reviews }) {
  if (!reviews > 0) return null
  return (
    <div data-test-id='lReX' className="mt2 marginLeftAuto flex alignCenter ptype-4-bold text-light-02">
      {starSvg}
      <div data-test-id='URG5' className="">{`${rating} (${reviews})`}</div>
    </div>
  )
}

const starSvg = (
  <svg 
    data-test-id='w5xs'
    width="21"
    height="24"
    viewBox="0 0 21 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 3L6.21807 8.92714L0 9.87214L4.5 14.4879L3.43614 21L9 17.9271L14.5639 21L13.5 14.4879L18 9.87857L11.7819 8.92714L9 3Z"
      fill="#E5BE30"
    />
  </svg>
)
