import { useRef, useLayoutEffect } from "react"

const isBrowser = typeof window !== `undefined`

function getScrollPosition({ element, element2, useWindow }) {
  if (!isBrowser) return { x: 0, y: 0 }

  const target = element ? element : element2 ? element2 : document.body

  const position = target?.getBoundingClientRect()

  return useWindow
    ? { x: window.scrollX, y: window.scrollY }
    : { x: position?.left, y: position?.top }
}

export function useScrollPosition(
  effect,
  deps,
  element,
  element2,
  useWindow,
  wait
) {
  const position = useRef(
    getScrollPosition({ element: false, element2, useWindow })
  )

  let throttleTimeout = null

  const callBack = () => {
    const currPos = getScrollPosition({ element: false, element2, useWindow })
    effect({ prevPos: position?.current, currPos })
    position.current = currPos
    throttleTimeout = null
  }

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout === null) {
          throttleTimeout = setTimeout(callBack, wait)
        }
      } else {
        callBack()
      }
    }
    element
      ? element.addEventListener("scroll", handleScroll)
      : window.addEventListener("scroll", handleScroll)
    return () =>
      element
        ? element.removeEventListener("scroll", handleScroll)
        : window.removeEventListener("scroll", handleScroll)
  }, deps)
}
export default useScrollPosition
