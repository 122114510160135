import { setPageModal } from "store/modules/page"
import SvgIcon from "components/SvgIcon/SvgIcon"
import "./WhiteFormCard.scss"
import { useDispatch } from "react-redux"
import { Lang, lang, Translate, withLocalize } from "components/Lang/Lang"
const defaultRightText = <Lang value="whiteFormCard.modify" />

const WhiteFormCard = (props) => {
  const {
    rightText: inputRightText,
    isEdit,
    onRightClick,
    flex,
    icon,
    className,
    title,
    gridArea,
    children,
    showModal //opens a specific modal on right text click
  } = props

  const dispatch = useDispatch()

  let rightText = inputRightText
  if ((onRightClick || showModal) && !inputRightText)
    rightText = defaultRightText

  let onClick = onRightClick
  if (showModal) onClick = () => dispatch(setPageModal(showModal))

  const style = {
    gridArea,
    flex
  }
  return (
    <div 
      data-test-id='h5rn'
      {...{ style }}
      className={`bg-white p6 white-form-card-wrapper ${className ?? ""}`}
    >
      <div data-test-id='8nby' className="flex-sb mb6 alignCenter">
        {icon && <SvgIcon name={icon} />}
        <div data-test-id='2zM4' className="ptype-5-strong">{title}</div>
        {isEdit && (
          <div data-test-id='7R9t' className="ptype-5-strong btn-txt-button-prime" {...{ onClick }}>
            {rightText}
          </div>
        )}
      </div>
      {children}
    </div>
  )
}

export default WhiteFormCard
