import MyLink from 'components/MyLink'
import React from 'react'
import SvgIcon from "components/SvgIcon/SvgIcon"
import useCampaignInfo, { campaignConfig } from 'views/shoppingCart/useCampaignInfo'

const ShopLayoutFloatingButton = () => {

  const { shouldShowFloatingButton } = useCampaignInfo()

  const isBlack = campaignConfig.shouldDarkenFloatingButton

  if(!shouldShowFloatingButton) return null

  return <div data-test-id='As9M' className='campaign-button-holder'>
    <MyLink to={campaignConfig.floatingButtonUrl}>
      <div data-test-id='tyGn' className={`campaign-button etype-1 ${isBlack ? 'black' : ''}`}>
        {campaignConfig.name}
        <SvgIcon name='arrowdownblue' className='rotate-arrow'/>
      </div>
    </MyLink>
  </div>
}

export default ShopLayoutFloatingButton
