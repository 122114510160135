import SimpleCheckBox from "components/SimpleCheckBox"
import { useState } from "react"
import { toggleIdInArray } from "utils/serviceFunctions"
import { cloneElement } from "react"
import CellListItems from "componentsK3/CellListItems"
export const ListModalContent = (props) => {
  const {
    toggleItem,
    items,
    selected,
    header,
    ro,
    onItemChange,
    showSelection,
    typeKey //create categories based on this one
  } = props
  let defaultOpenSections = []
  // const [showSelection, setShowSelection] = useState(false)

  const tempList = showSelection ? selected : items

  const sections = []
  for (const index in tempList)
    if (!sections?.includes?.(tempList?.[index]?.[typeKey])) {
      sections?.push?.(tempList?.[index]?.[typeKey])
      defaultOpenSections?.push?.(tempList?.[index]?.[typeKey])
    }
  const [openSections, setOpenSections] = useState(defaultOpenSections)
  const toggleSubMenu = (id) => setOpenSections((p) => toggleIdInArray(p, id))

  const sectionCheck = (section) => {
    let noneSelected = true
    let allSelected = true
    for (const index in items)
      if (items?.[index]?.[typeKey] === section) {
        if (items?.[index]?.selected) {
          noneSelected = false
        } else {
          allSelected = false
        }
      }
    if (allSelected) return true
    if (noneSelected) return false
    return null
  }

  const toggleSection = (section) => {
    const direction = !sectionCheck(section)
    for (const index in items)
      if (items?.[index]?.[typeKey] === section) {
        if (items?.[index]?.selected !== direction) {
          toggleItem(items?.[index])
        }
      }
  }

  const sectionCehckboxProps = (section) => ({
    checked: sectionCheck(section),
    onClick: (e) => {
      toggleSection(section)
      e?.stopPropagation?.()
    }
  })

  const sectionItem = (section, index) => (
    <div 
      data-test-id='6Joa'
      key={`main-${index}`}
      className={`main-item ${
        !openSections?.includes?.(section) ? "selected" : ""
      }`}
      onClick={() => toggleSubMenu(section)}
    >
      <SimpleCheckBox {...sectionCehckboxProps(section)} />
      {section}
    </div>
  )

  //item includes .selected. It is computed in the parent component. You can use it for selected state in scss
  const defaultItem = (item, index) => (
    <div 
      data-test-id='McKJ'
      key={`sub-${index}`}
      className={`sub-item ${!typeKey ? "left" : ""} ${
        item?.selected ? "selected" : ""
      }`}
      onClick={() => toggleItem(item)}
    >
      <div data-test-id='bw3g' className={"start-items"}>
        <SimpleCheckBox checked={item?.selected} />
        <div data-test-id='ai88' className="rectangle">
          {item?.img && (
            <img 
              data-test-id='H1dX'
              onContextMenu={(e) => e?.preventDefault?.()}
              src={item?.img}
              alt={"company"}
            />
          )}
        </div>{" "}
        {/* @TODO: I have a hunch, image comes here ??, => missing in API */}
        <div data-test-id='68JZ' className={"company"}>
          {item?.name && <strong data-test-id='gS8Y'>{item?.name}</strong>}
          {item?.cui && <span data-test-id='oWQ7'>{item?.cui}</span>}
        </div>
      </div>
      {item?.tags?.length && (
        <div data-test-id='Ky76' className={"tags"}>
          {item?.tags?.map?.((t, i) => (
            <div data-test-id='BKHz' key={`tag-${i}`} className={"tag"}>
              {t}
            </div>
          ))}
        </div>
      )}
    </div>
  )

  const childItem = (item, index) =>
    cloneElement(props.children, {
      index,
      item,
      toggleItem,
      typeKey,
      onItemChange,
      header,
      disabled: ro
    })

  const listItem = props.children ? childItem : defaultItem

  return (
    <div 
      data-test-id='NbTp'
      className={`list-container bg-white ${
        selected?.length === 0 ? "empty" : ""
      } ${header ? "make-it-a-table" : ""}`}
    >
      {header?.map?.((item, index) => (
        <span data-test-id='bkrR' key={`hcell-${index}`} className="header-cell">
          {item}
        </span>
      ))}
      {typeKey &&
        sections?.map?.((section, index) => {
          return (
            <div data-test-id='SjBm' key={`list-${index}`}>
              {sectionItem(section, index)}
              {openSections?.includes?.(section) &&
                tempList
                  ?.filter?.((i) => i?.[typeKey] === section)
                  ?.map?.((item, index) => listItem(item, index))}
            </div>
          )
        })}
      {!typeKey && tempList?.map?.((item, index) => listItem(item, index))}
    </div>
  )
}
