// import store from 'store/index'
import { history } from "utils/history"

export const multiSelectClick = () => {}
export const titleClick = (urlPrefix, id, SK, sourceType) => {
  // const dataViewId = store.getState()?.dataView?.dataViewId

  // if (dataViewId === "mock-users-table") history?.push?.(`/admin/user/form?id=${id}`)
  history?.push?.(`${urlPrefix}${id}${SK ? "|" + SK : ""}${sourceType ? `&source=${sourceType}` : ""}`)
}
export const itemActionClick = () => {}
