import { useSelector } from "react-redux"

const useIsLogged = () => {
  const isLogged = useSelector((state) => {
    if (!state.company?.type) return false
    if (state.company?.type === "GUEST") return false
    return true
  })

  return isLogged
}

export default useIsLogged
